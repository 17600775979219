"use strict";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

import { AlexaConnectionService } from "./alexa-connection.service";
import { Ml4WebSocket, WsService } from "../../common/ws/ws.service";

import "./tv-template.scss";

// time to keep the alert on the screen
const ALERT_TIMEOUT = 3000;

// time to expire the "connected" status -- should match the length of the audio clip
const ALEXA_TIMEOUT = 120000; // 2 minutes

@Component({
  selector: "tv-template",
  template: `
    <div
      class="tv-template"
      [ngClass]="{
        connected: connected == true,
        'not-connected': connected == false
      }"
    >
      <div class="tv-alert" *ngIf="message">
        <div class="alert alert-info" role="alert">
          {{ message }}
        </div>
      </div>

      <div class="tv-alexa-session-indicator"></div>

      <div ui-view></div>
    </div>
  `
})
export class TvTemplateComponent implements OnInit, OnDestroy {
  socket: Ml4WebSocket = null;
  doReconnect = true;
  subscription;
  connected = false;
  disconnectTimerInstance = null;

  message = null;

  constructor(
    private alexaConnectionService: AlexaConnectionService,
    private stateService: StateService,
    private wsService: WsService
  ) {}

  ngOnInit(): void {
    this.connectWebsocket();
    this.subscription = this.alexaConnectionService.isConnected.subscribe(
      val => {
        this.connected = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
    this.doReconnect = false;
    this.socket.close();
  }

  connectWebsocket() {
    this.socket = this.wsService.getSocket(
      "/ws/alexaws",
      this.onWsOpen.bind(this),
      this.onWsClose.bind(this),
      this.onWsMessage.bind(this)
    );
  }

  onWsOpen() {
    console.log("open, cc:", this.stateService.params.cc);
    this.socket.send(this.stateService.params.cc);
  }

  onWsClose() {
    if (this.doReconnect) {
      this.connectWebsocket();
    }
  }

  onWsMessage(event) {
    let json = JSON.parse(event.data);
    if (json.command) {
      this.handleCommand(json);
    } else if (json.message) {
      this.handleMessage(json);
    }
  }

  resetDisconnectTimer() {
    this.disconnectTimerInstance && clearTimeout(this.disconnectTimerInstance);

    this.disconnectTimerInstance = setTimeout(() => {
      this.alexaConnectionService.alexaClose();
    }, ALEXA_TIMEOUT);
  }

  showMessage(msgText) {
    // XXX: binding issues preventing the use of `this`
    this.message = msgText;
    setTimeout(() => {
      this.message = null;
    }, ALERT_TIMEOUT);
  }

  handleCommand(json) {
    if (json.command === "HOME") {
      this.stateService.go("tv.alexaMessage.welcome", {
        cc: this.stateService.params.cc
      });
    } else if (
      json.command === "RECOMMEND" ||
      json.command === "SHOWWISHLIST"
    ) {
      this.stateService.go("tv.alexaRecommend", {
        movies: [json.movieIds],
        query: json.query
      });
    } else if (json.command === "ABOUT") {
      this.stateService.go("tv.alexaAboutMovie", {
        movie: json.movieId,
        query: json.query,
        more: false
      });
    } else if (json.command === "TRAILER") {
      this.showMessage("starting the movie trailer");
      this.stateService.go("tv.alexaTrailer", {
        movie: json.movieId
      });
    } else if (json.command === "MULTIPLEMATCHES") {
      this.stateService.go("tv.alexaAboutMovie", {
        movie: json.movieId,
        query: json.query,
        more: true
      });
    } else if (json.command === "ADDEDWISHLIST") {
      this.showMessage("added movie to wishlist");
    } else if (
      json.command === "NOTFOUND" ||
      json.command === "NOTITLE" ||
      json.command === "NOINTENT"
    ) {
      this.stateService.go("tv.alexaMessage.error", {
        query: json.query,
        msg: json.msg
      });
    } else if (json.command === "HELP") {
      this.stateService.go("tv.alexaMessage.welcome");
    }
  }

  handleMessage(json) {
    switch (json.message) {
      case "ALEXA_SESSION_PING":
        this.alexaConnectionService.alexaPing();
        this.resetDisconnectTimer();
        break;
      case "ALEXA_SESSION_CLOSE":
        this.alexaConnectionService.alexaClose();
        break;
    }
  }
}
