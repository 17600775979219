"use strict";

import {NgModule} from "@angular/core";
import {InteractiveProfileInfoWindowComponent} from "./interactive_profile_info_window";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalDialogModule} from "../../components/modal-dialog/modal-dialog.module";
import {CommonModule} from "@angular/common";
import {DropdownModule} from "../../components/dropdown-new/dropdown.module";
import {PipesModule} from "../../common/pipes/pipes.module";
import {RatingModule} from "../../components/rating/rating.module";
import {RatingOrPredictionModule} from "../../components/rating-or-prediction/rating-or-prediction.module";
import {YoutubeModule} from "../../components/youtube/youtube.module";
import {UIRouterModule} from "@uirouter/angular";
import {ExternalRecsModule} from "../externalrecs/external-recs.module";
import {AngularMyDatePickerModule} from "angular-mydatepicker";
import { InteractiveProfileService } from "./interactive_profile.service";

export const introPage = {
    states: [
        {
            name: "restricted.PageInteractiveProfileInfoWindow",
            url: "/interactive-profile-info-window",
            component: InteractiveProfileInfoWindowComponent
        }
    ]
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ModalDialogModule,
        CommonModule,
        DropdownModule,
        ModalDialogModule,
        PipesModule,
        RatingModule,
        RatingOrPredictionModule,
        YoutubeModule,
        UIRouterModule.forChild(introPage),
        ExternalRecsModule,
        ReactiveFormsModule,
        AngularMyDatePickerModule
    ],
    exports: [],
    declarations: [
        InteractiveProfileInfoWindowComponent
    ],
    entryComponents: [
        InteractiveProfileInfoWindowComponent
    ],
    providers : [InteractiveProfileService]
})

export class InteractiveProfileExptModule {
}