import { Component, Input, OnInit } from "@angular/core";
import { ExplanationsService } from "../explanations.service";
import { MovieDataService } from "../../../common/movie-data/movie-data.service";

import { IVisualComponent } from "./IVisualComponent";

import "../explanations-module.scss";
const tmpl = require("./natural-language-explanations.component.html");

@Component({
  selector: "natural-language-explanations",
  template: tmpl
})
export class NaturalLanguageExplanationsComponent
  implements OnInit, IVisualComponent {
  @Input() movieId: number;
  @Input() explainer: string;
  @Input() explanations: string;
  @Input() recPrediction: number;
  @Input() localPrediction: number;

  private randomIntroText = null;

  private movieAllGenres = null;
  private goodGenres = [];
  private badGenres = [];

  private goodTags = [];
  private badTags = [];

  private goodGenresStr: string;
  private badGenresStr: string;
  private goodTagsStr: string;

  private maxFeaturesNumber = 3;

  private allGenres = [
    "Animation",
    "Comedy",
    "Family",
    "Adventure",
    "Fantasy",
    "Romance",
    "Drama",
    "Action",
    "Crime",
    "Thriller",
    "Horror",
    "History",
    "Science Fiction",
    "Mystery",
    "War",
    "Music",
    "Documentary",
    "Western",
    "Indie"
  ];

  private introTemplates: string[] = [
    "We recommend this movie because",
    "We think you would like this movie because",
    "You should watch this movie because",
    "This movie might be a good pick for you because",
    "You might find this movie interesting because "
  ];

  private recPredictionRounded = null;

  constructor(
    private explanationsService: ExplanationsService,
    private movieDataService: MovieDataService
  ) {}

  ngOnInit(): void {
    this.randomIntroText = this.introTemplates[
      Math.floor(Math.random() * this.introTemplates.length)
    ];

    // round
    this.recPredictionRounded = Math.round(this.recPrediction * 2) / 2;

    // get explanation
    var explanationsDict = JSON.parse(this.explanations);
    var keysSorted = Object.keys(explanationsDict).sort(function(a, b) {
      return Math.abs(explanationsDict[b]) - Math.abs(explanationsDict[a]);
    });

    this.movieDataService.getMovie(this.movieId, response => {
      this.movieAllGenres = response.movie.genres;

      for (var i = 0; i < keysSorted.length; i++) {
        var attrName = keysSorted[i];
        var attrValue = explanationsDict[attrName];

        if (attrValue > 0) {
          this.updateGoodLists(attrName);
        } else {
          this.updateBadLists(attrName);
        }
      }

      //
      this.buildStrings();
    });
  }

  private buildStrings(): void {
    if (this.goodGenres.length > 0) {
      this.goodGenresStr = this.goodGenres.join(", ");
    }
    if (this.badGenres.length > 0) {
      this.badGenresStr = this.badGenres.join(", ");
    }

    if (this.goodTags.length > 0) {
      this.goodTagsStr = this.goodTags
        .slice(0, this.maxFeaturesNumber)
        .join(", ");
    }
  }

  private updateGoodLists(attrName) {
    if (this.allGenres.indexOf(attrName) == -1) {
      this.goodTags.push(attrName);
    } else {
      if (this.movieAllGenres.includes(attrName)) {
        this.goodGenres.push(attrName);
      }
    }
  }

  private updateBadLists(attrName) {
    if (this.allGenres.indexOf(attrName) == -1) {
      this.badTags.push(attrName);
    } else {
      if (this.movieAllGenres.includes(attrName)) {
        this.badGenres.push(attrName);
      }
    }
  }

}
