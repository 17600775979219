"use strict";

import {
  Component,
  ElementRef,
  Input,
  OnDestroy, Optional, SkipSelf,
  ViewChild
} from "@angular/core";
import { get } from "lodash";
import { fromEvent, Subscription } from "rxjs";
import "./dropdown-new.scss"

/**
 * Lightweight wrapper for a bootstrap dropdown.
 *
 * See MovieCardActionsMenuComponent for a usage example.
 */
@Component({
  selector: "dropdown-new",
  template: `
    <div *ngIf="!nested" class="dropdown" [class.open]="showDropdown">

      <div (click)="onClick()" #el>
        <ng-content select="[dropdownTrigger]"></ng-content>
      </div>

      <ul #dropDown class="dropdown-menu" [class.dropdown-menu-right]="this.alignRight">
        <ng-container *ngTemplateOutlet="dropDownContentTempl"></ng-container>
      </ul>

    </div>
    
    <li *ngIf="nested" class="dropdown-submenu-root">
      
      <a class="dropdown-item" (mouseover)= "onClick()">
        <ng-content select="[subMenuLabel]"></ng-content>
      </a>
      
      <!-- dropdown-submenu-left = "be on the left side of the main menu" -- which matches "alignRight") -->
      <ul #dropDown class="dropdown-menu dropdown-submenu" [class.dropdown-submenu-left]="this.alignRight">
        <ng-container *ngTemplateOutlet="dropDownContentTempl"></ng-container>
      </ul>
      
    </li>

    <!-- reuse the same content in more than one place -->
    <ng-template #dropDownContentTempl><ng-content select="[dropdownContent]"></ng-content></ng-template>
  `
})
export class DropdownNewComponent implements OnDestroy {
  @ViewChild("el", { read: ElementRef })
  el: ElementRef;

  @ViewChild("dropDown", { read: ElementRef })
  elemRef: ElementRef;

  @Input() alignRight: boolean = false;

  clickSub: Subscription = null;
  showDropdown: boolean = false;

  nested = false
  constructor(@Optional() @SkipSelf() parentDropdown: DropdownNewComponent) {
    if (parentDropdown) {
      this.nested = true;
    }
  }

  ngOnDestroy() {
    if (this.clickSub) {
      this.clickSub.unsubscribe();
    }
  }

  listenForClicksOutside() {
    if (this.clickSub && !this.clickSub.closed) {
      return;
    }

    this.clickSub = fromEvent(document, "click").subscribe(event => {
      if (!this.el.nativeElement.contains(event.target)) {
        this.closeMenu();
      }
    });
  }

  onClick() {
    if (!this.nested) {
      this.listenForClicksOutside();
    }
    this.showDropdown = true;
    // after it's shown...
    setTimeout(() => this.reposition(), 1);
  }

  reposition() {
    let elem:HTMLElement = this.elemRef.nativeElement
    elem = elem.parentElement;
    while(elem != null  && getComputedStyle(elem).overflowX == "visible"){
      elem = elem.parentElement;
    }
    if (!elem) {
      elem = document.body;
    }
    const box = elem.getBoundingClientRect();
    const box2 = this.elemRef.nativeElement.getBoundingClientRect()
    if (box.right < box2.right) {
      this.alignRight=true;
    }
  }

  closeMenu() {
    this.clickSub.unsubscribe();
    this.showDropdown = false;
  }
}
