"use strict";

import { Injectable } from "@angular/core";
import { get } from "lodash";
import { SessionService } from "../../common/session/session.service";

@Injectable()
export class InteractiveProfileService {
    private readonly userSubscription;

    inExpt = false;
    enrolled = false;

    constructor(
        private sessionService: SessionService
    ) {
        this.userSubscription = this.sessionService.currentUserData.subscribe(
            data => {
                let exptInfo = get(data, "exptInfo");
                let interactiveProfileData = get(exptInfo, "InteractiveProfile");
                let userData = get(interactiveProfileData, "user");
                let exptId = get(userData, "exptId");
                let status = get(userData, "status");
                this.inExpt = exptId == "InteractiveProfile";
                this.enrolled = status == "yes";
            }
        );
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}