"use strict";

import { AddTagModule } from "./add-tag/add-tag.module";
import { CarouselModule } from "./carousel/carousel.module";
import { DropdownModule } from "./dropdown-new/dropdown.module";
import { IfExptModule } from "./if-expt/if-expt.module";
import { ModalDialogModule } from "./modal-dialog/modal-dialog.module";
import { MovieCardModule } from "./movie-card/movie-card.module";
import { MovieGroupsService } from "./pick-groups/movie-groups.service";
import { MovieRowModule } from "./movie-row/movie-row.module";
import { MovieTableModule } from "./movie-table/movie-table.module";
import { NgModule } from "@angular/core";
import { PickGroupsModule } from "./pick-groups/pick-groups.module";
import { RatingOrPredictionModule } from "./rating-or-prediction/rating-or-prediction.module";
import { RatingModule } from "./rating/rating.module";
import { TagModule } from "./tag/tag.module";
import { ToggleModule } from "./toggle/toggle.module";
import { UserListsModalModule } from "./user-lists-modal/user-lists-modal.module";
import { UserListsAddDropdownModule } from "./user-lists-add-dropdown/user-lists-add-dropdown.module";
import { YoutubeModule } from "./youtube/youtube.module";

@NgModule({
  imports: [
    AddTagModule,
    CarouselModule,
    IfExptModule,
    DropdownModule,
    ModalDialogModule,
    MovieCardModule,
    MovieRowModule,
    MovieTableModule,
    PickGroupsModule,
    RatingModule,
    RatingOrPredictionModule,
    TagModule,
    ToggleModule,
    YoutubeModule,
    UserListsModalModule,
    UserListsAddDropdownModule
  ],
  providers: [MovieGroupsService]
})
export class ComponentsModule {}
