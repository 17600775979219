import { Component, forwardRef, HostListener, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { clamp, debounce, get } from "lodash";

/**
 * autocomplete debounce for this component in ms
 */
const DEBOUNCE_MS = 250;

/**
 * custom form control
 *
 * docs:
 * - https://alligator.io/angular/custom-form-control/
 * - https://blog.angulartraining.com/tutorial-custom-form-controls-with-angular-22fc31c8c4cc
 */
@Component({
  selector: "location-field-new",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationFieldComponent),
      multi: true
    }
  ],
  template: `
    <div class="location-field-new">
      <div class="dropdown" [class.open]="showDropdown">
        <input type="text" class="form-control"
               placeholder="Minneapolis, MN" 
               [(ngModel)]="value"
               (blur)="onBlur()"
               [disabled]="isDisabled"
               >
        <ul class="dropdown-menu">
          <li *ngFor="let l of locations; index as i" [class.active]="isActive(i)">
            <a class="cursor-pointer" (click)="pickLocation(i)">{{ l.ml4Label }}</a>
          </li>
        </ul>
      </div>
    </div>
  `,
  styles: [
    `
      .dropdown-menu {
        width: 100%;
        overflow: hidden;
      }
    `
  ]
})
export class LocationFieldComponent implements ControlValueAccessor {
  @Input("value") val: string | Object;

  private allowDropdownOpen: boolean = false;
  private locations = [];
  private activeLocationIdx: number = -1;
  private onChange: Function;
  private onTouched: Function;
  private isDisabled: boolean;

  constructor() {
    // debounce the API calls!
    this.getAngoliaPlaces = debounce(this.getAngoliaPlaces.bind(this), DEBOUNCE_MS);
  }

  get value() {
    if (this.val && typeof this.val === "object") {
      // val is an object (user selected from list)
      return this.val["ml4Label"];
    } else {
      // val is a string (no value selected)
      return this.val;
    }
  }

  set value(val: string | Object) {
    if (typeof val === "string") {
      // handle typed input
      this.getAngoliaPlaces(val);
      this.val = {
        ml4Label: val
      }
    } else {
      // handle clicked-on value
      this.val = val;
      this.val["ml4Clicked"] = true;
    }
    this.onChange(this.val);
    this.onTouched();
    this.allowDropdownOpen = true;
    this.activeLocationIdx = -1;
  }

  get showDropdown(): boolean {
    return (
      this.locations && this.locations.length > 0 && this.allowDropdownOpen
    );
  }

  isActive(idx: number): boolean {
    return idx === this.activeLocationIdx;
  }

  onBlur(): void {
    // XXX: allow click events to be handled before the blue handler kicks in
    setTimeout(() => (this.allowDropdownOpen = false), 100);
  }

  pickLocation(idx: number): void {
    const selectedLoc = this.locations[idx];
    if (selectedLoc) {
      this.value = selectedLoc;
    }
    this.allowDropdownOpen = false;
  }

  getAngoliaPlaces(loc: string): void {
    if (!loc) {
      this.locations = [];
      return;
    }

    // testing:
    // Minneapolis
    // SAO PAULO, BRAZIL
    // 광진구

    // docs: https://community.algolia.com/places/api-clients.html#endpoints
    const url = "https://places-dsn.algolia.net/1/places/query";
    const body = JSON.stringify({
      query: loc,
      type: "city",
      hitsPerPage: 8,
      language: "en"
    });
    fetch(url, { method: "post", body })
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        let hits = get(myJson, "hits", []);
        if (hits.length > 0) {
          hits.map(h => {
            // Minneapolis
            let locale = get(h, "locale_names[0]", "");
            // Minnesota
            let administrative = get(h, "administrative[0]", "");
            // USA
            let country = get(h, "country", "");

            if (locale !== administrative) {
              h.ml4Label = `${locale}, ${administrative}, ${country}`;
            } else {
              // XXX: Sau Paulo is both a locale and an administrative
              h.ml4Label = `${locale}, ${country}`;
            }

            // trim the result down
            delete h["_highlightResult"];
            delete h["postcode"];
          });
          this.locations = hits;
        }
      })
      .catch(() => {
        console.error("error querying angolia");
      });
  }

  _clampActiveLocationIdx(newIdx: number): void {
    this.activeLocationIdx = clamp(
      newIdx,
      -1,
      this.locations.length - 1
    );
  }

  /**
   * support keyboard navigation and selection
   */
  @HostListener("keydown", ["$event"])
  onClick(event: KeyboardEvent) {
    if (event.key === "ArrowUp") {
      this._clampActiveLocationIdx(this.activeLocationIdx - 1);
      return false;
    }
    if (event.key === "ArrowDown") {
      this._clampActiveLocationIdx(this.activeLocationIdx + 1);
      return false;
    }
    if (event.key === "Enter") {
      this.pickLocation(this.activeLocationIdx);
      return false;
    }
  }

  //
  // ControlValueAccessor implementation for custom angular reactive form field
  //

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(val: string): void {
    if (val) {
      this.val = val;
    }
  }
}
