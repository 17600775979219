import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService {
  constructor() { }

  formattedDate(dateString: string): string {
    if(dateString != null) {
      dateString = new Intl.DateTimeFormat().format(new Date(dateString));
    }
    return dateString;
  }

  formattedDateFromTimestamp(timestamp: number): string {
      if(timestamp != null){
          let dateString: string = new Intl.DateTimeFormat().format(new Date(timestamp));
          return dateString;
      }
  }
}