"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";
import { ApiService } from "../../common/api/api.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { UrlsService } from "../../common/urls/urls.service";

import "./admin-movie-flags-page.scss";
import "./admin-movie-page.scss";

@Component({
  selector: "admin-movie-edit-page",
  template: `
    <div class="admin-movie-page" *ngIf="movieData">
      <div class="actions">
        Actions:
        <button class="btn btn-warning" uiSref="admin.adminMovie" [uiParams]="{id: movieId}">Cancel</button>
        <button class="btn btn-warning" (click)="submit()">Submit</button>
      </div>
      <div class="movieProperty">
        <h4 >TMDB</h4>
        <span class="value" *ngIf="movieData.tmdbMovieId != 0">
          {{ movieData.tmdbMovieId }}
          <a [href]="urlService.tmdbUrl(movieData.tmdbMovieId)">(link)</a>
        </span>
        <span class="value" *ngIf="movieData.tmdbMovieId == 0"> No tmdb data found
        </span>
      </div>
      <div class="movieProperty">
        <h4 >IMDB</h4>
        <span class="value" >
          {{ movieData.imdbMovieId }}
          <a [href]="urlService.imdbUrl(movieData.imdbMovieId)">(link)</a>
        </span>
      </div>
      <div>
        Please seperate actors, directors, and languages with ", ".
        Release date should be in format YYYY-MM-DD
      </div>
      <div class="edit-field">
        <span>Title:</span> <input [(ngModel)]="title" />
      </div>
      <div class="edit-field">
        <span>Actors:</span> <textarea rows=6 cols=70 [(ngModel)]="actors" ></textarea>
      </div>
      <div class="edit-field">
        <span>Directors:</span> <textarea rows=6 cols=70 [(ngModel)]="directors" ></textarea>
      </div>
      <div class="edit-field">
        <span>ReleaseDate:</span> <input [(ngModel)]="releaseDate" />
      </div>
      <div class="edit-field">
        <span>Languages</span><input [(ngModel)]="languages" />
      </div>
      <div class="edit-field">
        <span>Comment</span><input [(ngModel)]="comment" />
      </div>
    </div>
  `
})
export class AdminMovieEditPageComponent {
  private movieId: number;
  private adminMovieData: any;
  private movieData: any;

  title: String;
  actors: String;
  directors: String;
  releaseDate: String;
  languages: String;
  comment: String;

  constructor(
    private titleService: Title,
    private apiService: ApiService,
    private stateService: StateService,
    private urlService: UrlsService
  ) {}

  ngOnInit() {
    this.adminMovieData = false;
    this.titleService.setTitle("Admin | MovieLens | Movie");
    this.movieId = parseInt(this.stateService.params.id);

    this.reloadData();
  }

  reloadData() {
    this.movieData = null;
    this.apiService.ml4Get(
      "GET /api/admin/movie/:id",
      "/api/admin/movie/" + this.movieId,
      {},
      response => {
        var data = get(response, "data.adminMovieData");
        console.log(data);
        this.movieData = data.current;
        this.title = data.current.title;
        this.actors = data.current.actors.join(", ");
        this.directors = data.current.directors.join(", ");
        this.languages = data.current.languages.join(", ");
        this.releaseDate = data.current.releaseDate;
        this.comment = "update legacy data"
      }
    );
  }


  submit() {
    this.apiService.ml4Put(
      "PUT /api/admin/movie/:id",
      "/api/admin/movie/" + this.movieId,
      {'title':this.title, 'actors':this.actors, 'directors': this.directors, 'releaseDate':this.releaseDate, 'languages':this.languages, "comment":this.comment},
      response => {
        this.reloadData();
        },
      response => {alert("Failed to update");
      console.log(response);
      this.reloadData();}
    )
    console.log()
  }
}
