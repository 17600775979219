"use strict";

import {
  Component, EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChange,
  SimpleChanges
} from "@angular/core";
import { StateService } from "@uirouter/core";
import { get, set } from "lodash";

import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { UserActionsService } from "../../common/user-actions/user-actions.service";

@Component({
  selector: "movie-card-smart",
  template: `
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'sm1'">
        <movie-card-sm1
            *ngIf="movieDetails"
            [movieDetails]="movieDetails"
            (onClick)="onClickLocal()"
        ></movie-card-sm1>
      </ng-container>
      <ng-container *ngSwitchCase="'sm2'">
        <movie-card-sm2
            *ngIf="movieDetails"
            [movieDetails]="movieDetails"
            (onClick)="onClickLocal()"
        ></movie-card-sm2>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <movie-card-md1
            *ngIf="movieDetails"
            [movieDetails]="movieDetails"
            [doLink]="doLink"
            (onClick)="onClickLocal()"
            (onRate)="onRate($event)"
            (onClearRating)="onClearRating()"
            (onHide)="onHide(doHide)"
            (onWishlist)="onWishlist(doWishlist)"
        ></movie-card-md1>
      </ng-container>
    </ng-container>
    <movie-modal-new 
        *ngIf="modal" 
        [movieDetails]="movieDetails" 
        [doShow]="showModal" 
        (onClose)="closeModal()"
    ></movie-modal-new>
  `
})
export class MovieCardSmartComponent implements OnChanges {
  // set one of these, no need for both
  @Input() movieId: string = null;
  @Input() movieDetails: any = null;

  // Open a modal on click, rather than triggering a state transition
  @Input() modal: boolean = false;

  // Custom click handler
  @Input() overrideClick: boolean = false;
  @Output() onClick = new EventEmitter<null>();

  // Indicate which format to display
  @Input() type: string;

    // open/close state of the modal
  showModal: boolean = false;

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private userActionsService: UserActionsService,
  ) {}

  ngOnInit() {
    if (this.movieId && !get(this.movieDetails, "movieId")) {
      // fetch the movie
      this.movieDataService.getMovie(this.movieIdInt, movieDetails => {
        this.movieDetails = movieDetails;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const movieDetailsChange: SimpleChange = changes["movieDetails"];
    if (movieDetailsChange && movieDetailsChange.currentValue) {
      this.movieId = get(
        movieDetailsChange,
        "currentValue.movieId",
        this.movieId
      );
    }
  }

  get movieIdInt() {
    return parseInt(this.movieId);
  }

  get rating() {
    return get(this.movieDetails, "movieUserData.rating", null);
  }

  set rating(val: number) {
    set(this.movieDetails, "movieUserData.rating", val);
  }

  set dateRated(val: String) {
    set(this.movieDetails, "movieUserData.dateRated", val);
  }

  get wishlist() {
    return get(this.movieDetails, "movieUserData.wishlist", null);
  }

  set wishlist(val: boolean) {
    set(this.movieDetails, "movieUserData.wishlist", val);
  }

  get hidden() {
    return get(this.movieDetails, "movieUserData.hidden", null);
  }

  set hidden(val: boolean) {
    set(this.movieDetails, "movieUserData.hidden", val);
  }

  get prediction() {
    return get(this.movieDetails, "movieUserData.prediction");
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  get doLink() {
    return this.overrideClick !== true && this.modal !== true;
  }

  onClickLocal() {
    if (this.overrideClick === true) {
      this.onClick.emit();
    } else if (this.modal === true) {
      this.openModal();
    } else {
      this.stateService.go("base.movie", { id: this.movieId });
    }
  }

  onRate(rating) {
    const prevRating = this.rating;
    this.rating = rating;
    this.dateRated = new Date().toISOString(); // set date Rated field to ISO format (matching json format over-the-wire)

    this.userActionsService.rate(
      this.movieIdInt,
      rating,
      prevRating,
      this.prediction,
      () => {},
      () => {
        this.rating = prevRating;
      }
    );
  }

  onClearRating() {
    const prevRating = this.rating;
    this.rating = null;
    this.dateRated = null;

    this.userActionsService.unrate(
      this.movieIdInt,
      () => {},
      () => {
        this.rating = prevRating;
      }
    );
  }

  onHide() {
    this.hidden = !this.hidden;
    if (this.hidden) {
      this.userActionsService.hide(this.movieIdInt, this.prediction);
    } else {
      this.userActionsService.unhide(this.movieIdInt);
    }
  }

  onWishlist() {
    this.wishlist = !this.wishlist;
    if (this.wishlist) {
      this.userActionsService.wishlist(this.movieIdInt);
    } else {
      this.userActionsService.unwishlist(this.movieIdInt);
    }
  }
}
