"use strict";

import { NgModule } from "@angular/core";

import { UserActionsService } from "./user-actions.service";

@NgModule({
  providers: [UserActionsService]
})
export class UserActionsModule {}
