"use strict";

import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { BrowserModule } from "@angular/platform-browser";

import { MovieCardModule } from "../../components/movie-card/movie-card.module";

import { EmojiSurveyComponent } from "./emoji-survey.component";
import { EmojiTutorialComponent } from "./emoji-tutorial.component";
import { EmojiConsentComponent } from "./emoji-consent.component";
import { EmojiAddTagsComponent } from "./emoji-add-tags.component";
import { EmojiFeedbackComponent } from "./emoji-feedback.component";

import "@ctrl/ngx-emoji-mart/picker.css";
import {UIRouterModule} from "@uirouter/angular";

const emojiConsentPage = {
  name: "restricted.emojiConsent",
  url: "/emoji",
  component: EmojiConsentComponent
};

const emojiTutorialPage = {
  name: "restricted.emojiTutorial",
  url: "/emoji/tutorial",
  component: EmojiTutorialComponent
};

const emojiSurveyPage = {
  name: "restricted.emojiSurvey",
  url: "/emoji/survey",
  component: EmojiSurveyComponent
};

const emojiAddTagsPage = {
  name: "restricted.emojiAddTags",
  url: "/emoji/addTags",
  component: EmojiAddTagsComponent
};

const emojiFeedbackPage = {
  name: "restricted.emojiFeedback",
  url: "/emoji/feedback",
  component: EmojiFeedbackComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MovieCardModule,
    PickerModule,
    ReactiveFormsModule,
    UIRouterModule.forChild({
      states: [
        emojiConsentPage,
        emojiTutorialPage,
        emojiSurveyPage,
        emojiAddTagsPage,
        emojiFeedbackPage
      ]
    })
  ],
  declarations: [
    EmojiConsentComponent,
    EmojiTutorialComponent,
    EmojiSurveyComponent,
    EmojiAddTagsComponent,
    EmojiFeedbackComponent
  ]
})
export class EmojiExptModule {}
