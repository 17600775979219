"use strict";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { cloneDeep, get, filter, find, forOwn, keys, pickBy } from "lodash";

import "./explore-control-tools.scss";

import { GenresService } from "../../common/genres/genres.service";

@Component({
  selector: "explore-control-tools",
  template: `
    <div class="explore-control-tools" xmlns="http://www.w3.org/1999/html">

      <!-- search term tools -->
      <form role="form" [formGroup]="filtersForm">

        <!-- title search -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'searchTermTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">title search</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <input type="text" class="form-control" formControlName="q">
            </div>
            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({q: filtersForm.get('q').value})">submit
            </button>
            <button type="button" class="btn btn-danger clear-button"
                    (click)="changeFilter({q: null})"
                    *ngIf="filtersForm.get('q').value">clear this search
            </button>
          </div>
        </div>

        <!-- genre tools -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'genreTool'">
          <button type="button" class="close" aria-hidden="true"
                  (click)="hideSearchTools()">&times;
          </button>
          <div class="card-header">
            <h3 class="card-title">filter results by genre</h3>
            
            <div class="btn-group btn-group-sm" data-toggle="buttons" [ngStyle]="{paddingTop : '10px'}">
              <label class="btn btn-default btn-sm" [ngClass]="{active: (this.filtersForm.get('multiGenre').value.toLowerCase() === 'and') ? true : false}">
                <input type="radio" class="form-control" formControlName="multiGenre" value="and" style="display: none;"> All Of
              </label>
              <label class="btn btn-default btn-sm" [ngClass]="{active: (this.filtersForm.get('multiGenre').value.toLowerCase() === 'or') ? true : false}">
                <input type="radio" class="form-control" formControlName="multiGenre" value="or" style="display: none;"> Any Of
              </label>
            </div>
            
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3" *ngFor="let g of genresService.all()">
                <span [ngStyle]="{textAlign: 'center'}">
                  <input type="checkbox" id="{{g}}" (change)="updateGenreCheckboxValues(g)" [checked]="isGenreApplied(g)" />
                  <label [ngStyle]="{paddingLeft: '10px'}" for={{g}}>{{g}}</label>
                </span>
              </div>
            </div> <!-- /row -->
            <div class="row">
              <div class="col-sm-12">
                <hr/>
                <button type="submit" class="btn btn-primary" (click)="changeGenres()">
                  Apply Genres
                </button>
                <button *ngIf="filtersForm.get('genre').value" type="button" class="btn btn-danger clear-button"
                        (click)="changeFilter({genre: null, multiGenre: null})">clear this filter
                </button>
              </div>
            </div> <!-- /row -->
          </div>
        </div>

        <!-- tag search -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'tagTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">tag search</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <input type="text" class="f" formControlName="tag">
            </div>
            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({tag: filtersForm.get('tag').value})">submit
            </button>
            <button type="button" class="btn btn-danger clear-button"
                    (click)="changeFilter({tag: null})"
                    *ngIf="filtersForm.get('tag').value">clear this search
            </button>
          </div>
        </div>

        <!-- people search -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'peopleTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">actor/director search</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <input type="text" class="form-control" formControlName="people">
            </div>
            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({people: filtersForm.get('people').value})">submit
            </button>
            <button type="button" class="btn btn-danger clear-button"
                    (click)="changeFilter({people: null})"
                    *ngIf="filtersForm.get('people').value">clear this search
            </button>
          </div>
        </div>

        <!--mpaa tools-->
        <div class="search-tools-box card" *ngIf="visibleTool == 'mpaaTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">filter results by mpaa</h3>
          </div>
          <div class="card-body">
            <div formGroupName="mpaa">
              <label><input type="checkbox" formControlName="G"> G</label>
              <br>
              <label><input type="checkbox" formControlName="PG"> PG</label>
              <br>
              <label><input type="checkbox" formControlName="PG-13"> PG-13</label>
              <br>
              <label><input type="checkbox" formControlName="R"> R</label>
              <br>
              <label><input type="checkbox" formControlName="NC-17"> NC-17</label>
            </div>
            <button type="submit" class="btn btn-primary" (click)="changeMpaa()">submit</button>
          </div>
        </div>

        <!-- rating tools -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'popularityTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">filter results by number of ratings</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="minPopInput">minimum # ratings</label>
              <input id="minPopInput" type="number" class="narrow-input form-control"
                     min="0" max="10000000" formControlName="minPop">
            </div>

            <div class="form-group">
              <label for="maxPopInput">maximum # ratings</label>
              <input id="maxPopInput" type="number" class="narrow-input form-control"
                     min="0" max="10000000" formControlName="maxPop">
            </div>

            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({minPop: filtersForm.get('minPop').value, maxPop: filtersForm.get('maxPop').value})">
              submit
            </button>
            <button type="button" class="btn btn-danger clear-button"
                    (click)="changeFilter({minPop: null, maxPop: null})"
                    *ngIf="filtersForm.get('minPop').value || filtersForm.get('maxPop').value">clear this search
            </button>
          </div>
        </div>

        <!-- release date tools -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'releaseDateTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">filter results by release date</h3>
          </div>
          <div class="card-body">

            <div class="form-group">
              <label for="minYearInput">earliest release year</label>
              <input id="minYearInput" type="number" class="narrow-input form-control"
                     min="1900" max="2050" placeholder="YYYY" formControlName="minYear">
            </div>

            <div class="form-group">
              <label for="maxYearInput">latest release year</label>
              <input id="maxYearInput" type="number" class="narrow-input form-control"
                     min="1900" max="2050" placeholder="YYYY" formControlName="maxYear">
            </div>

            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({minYear: filtersForm.get('minYear').value, maxYear: filtersForm.get('maxYear').value})">
              submit
            </button>
            <button type="button" class="btn btn-danger clear-button"
                    (click)="changeFilter({minYear: null, maxYear: null, maxDaysAgo: null, maxFutureDays: null})">clear
              this search
            </button>
          </div>
        </div>

        <!-- show rated / show unrated tools -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'ratedStatusTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">rated movies</h3>
          </div>
          <div class="card-body">
            <div style="padding: 5px 0 10px 0">
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasRated" value="no"/> show only unrated movies
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasRated" value="yes"/> show only rated movies
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasRated" value="ignore"/> show both rated and unrated movies
              </label>  
            </div>
            </div>
            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({hasRated: filtersForm.get('hasRated').value})">submit
            </button>
          </div>
        </div>

        <!-- show hidden tools -->
        <div class="search-tools-box card" *ngIf="visibleTool == 'hiddenStatusTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">hidden movies</h3>
          </div>
          <div class="card-body">
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasHidden" value="yes"/> show only hidden movies
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasHidden" value="no"/> show only not-hidden movies (default)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasHidden" value="ignore"/> show both hidden and not-hidden movies
              </label>
            </div>
            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({hasHidden: filtersForm.get('hasHidden').value})">submit
            </button>
          </div>
        </div>

        <!--wishlist status tools-->
        <div class="search-tools-box card" *ngIf="visibleTool == 'wishlistStatusTool'">
          <button type="button" class="close" aria-hidden="true" (click)="hideSearchTools()">&times;</button>
          <div class="card-header">
            <h3 class="card-title">wishlisted movies</h3>
          </div>
          <div class="card-body">
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasWishlisted" value="yes"/> show only wishlisted movies
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasWishlisted" value="no"/> show only not-wishlisted movies
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="radio" formControlName="hasWishlisted" value="ignore"/> show both wishlisted and
                not-wishlisted movies (default)
              </label>
            </div>
            <button type="submit" class="btn btn-primary"
                    (click)="changeFilter({hasWishlisted: filtersForm.get('hasWishlisted').value})">submit
            </button>
          </div>
        </div>

      </form>

    </div>
  `
})
export class ExploreControlToolsComponent implements OnInit {
  @Input() qp = null;
  @Input() visibleTool: string = null;
  @Output() onChange = new EventEmitter<any>();
  @Output() onHideTools = new EventEmitter<null>();

  filtersForm: FormGroup;
  genresForm: FormGroup;

  genresMap:object = {};

  constructor(private fb: FormBuilder, private genresService: GenresService) {}

  ngOnInit(): void {
    this.filtersForm = this.fb.group({
      q: [this.qp.q],
      tag: [this.qp.tag],
      genre: [this.qp.genre],
      multiGenre:[this.qp.multiGenre || "and"],
      people: [this.qp.people],
      mpaa: this.fb.group({
        G: [false],
        PG: [false],
        "PG-13": [false],
        R: [false],
        "NC-17": [false]
      }),
      minPop: [this.qp.minPop],
      maxPop: [this.qp.maxPop],
      minYear: [this.qp.minYear],
      maxYear: [this.qp.maxYear],
      hasRated: [this.qp.hasRated],
      hasHidden: [this.qp.hasHidden],
      hasWishlisted: [this.qp.hasWishlisted]
    });


    if(this.qp.genre){
        let appliedGenres = this.qp.genre.split(",");
        for(let appliedGenre of appliedGenres){
            this.genresMap[appliedGenre] = true;
        }
    }
  }

  changeGenres() {
      let genres = Object.keys(this.genresMap).join(",");
      this.changeFilter({genre: genres, multiGenre: this.filtersForm.get("multiGenre").value});
  }

  isGenreApplied(genre){
      if(genre in this.genresMap){
          return true
      }
      else{
          return false;
      }
  }

  updateGenreCheckboxValues(genre){
    if(genre in this.genresMap){
        delete this.genresMap[genre];
    }
    else{
        this.genresMap[genre] = true;
    }
  }

  hideSearchTools() {
    this.onHideTools.emit();
  }

  changeFilter(params) {
    this.onChange.emit(params);
  }

  changeMpaa() {
    const mpaa = this.filtersForm.get("mpaa").value;

    const picked: object = pickBy(mpaa, function(value, key) {
      return value === true;
    });

    if (picked) {
      this.changeFilter({
        mpaa: keys(picked)
          .join(",")
          .toLowerCase()
      });
    } else {
      this.changeFilter({ mpaa: null });
    }
  }
}
