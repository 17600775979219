"use strict";

import { StateService } from "@uirouter/core";
import "./pop-tuner-page.scss";
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FlashService } from "../../common/flash/flash.service";
import { Title } from "@angular/platform-browser";
import { SessionService } from "../../common/session/session.service";
import { PopTunerService } from "./pop-tuner.service";
import { RectuneService } from "../../common/rectune/rectune.service";
import { Subscription } from "rxjs";

@Component({
  selector: "pop-tuner-page",
  template: require("./pop-tuner-page.html")
})
export class PopTunerPageComponent implements OnInit, OnDestroy, AfterViewInit {
  private recommender;
  private subscription: Subscription;
  private state = {
    loading: true,
    previousAction: null,
    popWeight: null
  };
  searchResults = null;
  private addedMovies = null;
  private removedMovies = null;

  constructor(
    private stateService: StateService,
    private flashService: FlashService,
    private page: Title,
    private session: SessionService,
    private popTunerService: PopTunerService,
    private rectuneService: RectuneService
  ) {}

  ngOnInit() {
    this.page.setTitle("Recommender Settings");
    this.rectuneService.logInit();
    this.refreshTopPicks();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.subscription = this.session.currentUserData.subscribe(val => {
      this.recommender = val.preferences.recommender;
    });
  }

  initAction(name) {
    this.state.loading = true;
    this.state.previousAction = name;
  }

  refreshTopPicks() {
    this.searchResults = null;
    this.addedMovies = null;
    this.removedMovies = null;
    this.popTunerService.getTopPicks(searchData => {
      if (!this.rectuneService.isUserQualified()) {
        // XXX: add this protection in the callback so that users can directly call "configure" from the star menu
        //      in the case that they are currently using the peasant or the bard
        this.flashService
          .addInfo('pick the "warrior" or the "wizard" to adjust popularity')
          .show();
        this.stateService.go("base.frontpage");
      }

      this.popTunerService.setNewTopPicks(searchData.searchResults);
      this.addedMovies = this.popTunerService.addedMovies;
      this.removedMovies = this.popTunerService.removedMovies;
      this.searchResults = this.popTunerService.topPicksMovies;

      this.state.popWeight = this.recommender.popWeight;
      this.state.loading = false;
    });
  }

  refreshTopPicksAndSession() {
    this.session.reloadSession();
    this.refreshTopPicks();
  }

  onMorePopButton() {
    this.initAction("morePop");
    this.popTunerService.morePop(() => this.refreshTopPicksAndSession());
  }

  onLessPopButton() {
    this.initAction("lessPop");
    this.popTunerService.lessPop(() => this.refreshTopPicksAndSession());
  }

  onResetButton() {
    this.initAction(null);
    this.popTunerService.reset(() => this.refreshTopPicksAndSession());
  }

  onDoneButton() {
    this.rectuneService.logDone();
    this.stateService.go("base.frontpage");
  }
}
