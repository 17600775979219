import {Component, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';

import "./user-profile-page.scss";
import {FlashService} from "../../common/flash/flash.service";
import {StateService} from "@uirouter/core";
import {ApiService} from "../../common/api/api.service";
import {get, sortBy} from "lodash";

declare const google: any;

@Component({
    selector: 'treemap-page',
    template: require("./treemap-page.html") // Reference your existing HTML file
})
export class TreemapPageComponent implements OnInit, AfterViewInit {
    @ViewChild('treemapContainer') treemapContainer!: ElementRef;

    // Treemap Data
    public treemapData: any[] = [];

    // Data for each map
    public genreMap: any;
    public languageMap: any;
    public actorMap: any;
    public directorMap: any;
    public releaseYearMap: any;
    public popularityMap: any;

    constructor(private flashService: FlashService,
                private stateService: StateService,
                private apiService: ApiService,) {
    }

    ngOnInit(): void {
        // GET treemap data
        this.apiService.ml4Get(
            "GET /api/interactive-profile/get_treemap_data",
            "/api/interactive-profile/get_treemap_data",
            {},
            response => {
                this.genreMap = get(response, "genreMap", {});
                this.languageMap = get(response, "languageMap", {});
                this.actorMap = get(response, "actorMap", {});
                this.directorMap = get(response, "directorMap", {});
                this.releaseYearMap = get(response, "releaseYearMap", {});
                this.popularityMap = get(response, "popularityMap", {});

                // Transform data into a treemap-friendly structure
                this.buildTreemapData();

                // Draw the treemap
                this.drawTreemap();
            }
        )
    }

    ngAfterViewInit(): void {
    }


    buildTreemapData(): void {
        // Initialize the treemap data with the required header and root node.
        this.treemapData = [
            ['Name', 'Parent', 'Size', 'Color'],
            ['All Movies', null, 0, 0]
        ];

        // Create sub-root nodes for each category.
        const categories = ['Actors', 'Directors', 'Genres', 'Languages', 'Popularity', 'Release Years'];
        categories.forEach(category => {
            this.treemapData.push([category, 'All Movies', 0, 0]);
        });

        // Helper function to process each category.
        const processCategory = (
            mapData: { [key: string]: number },
            category: string
        ) => {
            // Optional: define prefixes only for Actors and Directors
            const prefixMap: { [key: string]: string } = {
                'Actors': 'Actor: ',
                'Directors': 'Director: '
            };

            // Convert map to an array of objects, sort descending, and take top 15.
            Object.keys(mapData)
                .map(key => ({ name: key, count: mapData[key] }))
                .sort((a, b) => b.count - a.count)
                .slice(0, 15)
                .forEach(item => {
                    // If it's Actors or Directors, prepend the prefix; otherwise, no prefix.
                    const prefix = prefixMap[category] || '';
                    this.treemapData.push([
                        `${prefix}${item.name}`,  // e.g., "Actor: Stan Lee"
                        category,                 // parent node (e.g., 'Actors')
                        item.count,               // size
                        item.count                // color
                    ]);
                });
        };

        // Process each category with the helper function.
        processCategory(this.actorMap, 'Actors');
        processCategory(this.directorMap, 'Directors');
        processCategory(this.genreMap, 'Genres');
        processCategory(this.languageMap, 'Languages');
        processCategory(this.popularityMap, 'Popularity');
        processCategory(this.releaseYearMap, 'Release Years');
    }



    drawTreemap(): void {
        // Load the Google Charts library
        google.charts.load('current', {packages: ['treemap']});
        google.charts.setOnLoadCallback(() => {
            const dataTable = google.visualization.arrayToDataTable(this.treemapData);
            const tree = new google.visualization.TreeMap(this.treemapContainer.nativeElement);

            const options = {
                // Color range
                minColor: '#009688',
                midColor: '#f7f7f7',
                maxColor: '#ee8100',

                // Increase header height
                headerHeight: 25,
                // Set the background color of headers
                headerColor: '#333333',
                // Highlight color for headers on hover
                headerHighlightColor: '#555555',

                // Text Styling
                textStyle: {
                    color: '#FFFFFF',
                    fontName: 'Raleway',
                    fontSize: 18,
                    bold: true
                },

                showScale: true,
                highlightOnMouseOver: true,
                maxDepth: 1,
                maxPostDepth: 2,

                // Highlight color range
                minHighlightColor: '#0c6bb1',
                midHighlightColor: '#9ebcda',
                maxHighlightColor: '#edf8fb'
            };


            tree.draw(dataTable, options);

            // Handle interactions
            google.visualization.events.addListener(tree, 'select', () => {
                this.onThumbsUp();
            });

            google.visualization.events.addListener(tree, 'onmouseover', () => {
                this.onMouseOver();
            });

            google.visualization.events.addListener(tree, 'onmouseout', () => {
                this.onMouseLeave();
            });
        });
    }

    goProfile(): void {
        this.stateService.go("base.userProfilePage");
    }

    goTreemap(): void {
        this.stateService.go("base.treemapPage");
    }

    goTimeline(): void {
        this.stateService.go("base.timelinePage");
    }

    onThumbsUp(): void {
        console.log('User agrees with the treemap representation.');
    }

    onThumbsDown(): void {
        console.log('User disagrees with the treemap representation.');
    }

    onMouseOver(): void {
        console.log('Hovered over a treemap item.');
    }

    onMouseLeave(): void {
        console.log('Mouse left a treemap item.');
    }
}