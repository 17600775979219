"use strict";

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";


import {DropdownModule} from "../../../components/dropdown-new/dropdown.module";
import {ModalDialogModule} from "../../../components/modal-dialog/modal-dialog.module";
import {PipesModule} from "../../../common/pipes/pipes.module";
import {RatingModule} from "../../../components/rating/rating.module";
import {RatingOrPredictionModule} from "../../../components/rating-or-prediction/rating-or-prediction.module";
import {YoutubeModule} from "../../../components/youtube/youtube.module";

import {ExptMovieCardMd1Component} from "./expt-movie-card-md1.component";
import {ExptMovieCardSmartComponent} from "./expt-movie-card-smart.component";
import {UIRouterModule} from "@uirouter/angular";
import {BarRatingModule} from "ngx-bar-rating";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {ReactiveFormsModule} from '@angular/forms';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';

@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        ModalDialogModule,
        PipesModule,
        RatingModule,
        RatingOrPredictionModule,
        UIRouterModule.forChild(),
        YoutubeModule,
        BarRatingModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        AngularMyDatePickerModule,
    ],
    exports: [ExptMovieCardSmartComponent,ExptMovieCardMd1Component],
    declarations: [
        ExptMovieCardMd1Component,
        ExptMovieCardSmartComponent,
    ],
    entryComponents: [ExptMovieCardSmartComponent]
})
export class ExptMovieCardModule {
}
