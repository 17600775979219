"use strict";

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from "@angular/core";

// lazy load this many images to the left/right of the current img
const IMG_BUFFER_SIZE = 2;

/**
 * Angular wrapper for a bootstrap carousel.
 */
@Component({
  selector: "carousel",
  template: `
    <div id="carouselImg" class="carousel slide">

      <!-- Indicators -->
      <ol class="carousel-indicators">
        <li data-bs-target="#carouselImg"  *ngFor="let image of images;let i = index" (click)="onGoTo.emit(i)" [class.active]="index == i"></li>
      </ol>
      <!-- Left and right controls -->
      <a class="left carousel-control-prev cursor-pointer" (click)="onPrev.emit()">
        <!-- XXX: we do not have glyphicons installed, but bootstrap uses this class for positioning -->
        <span class="glyphicon glyphicon-chevron-left"><span class="fa fa-chevron-left"></span></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control-next cursor-pointer" (click)="onNext.emit()">
        <span class="glyphicon glyphicon-chevron-right"><span class="fa fa-chevron-right"></span></span>
        <span class="sr-only">Next</span>
      </a>
      <!-- Wrapper for slides -->
      <div class="carousel-inner">

        <div class="carousel-item item"
             *ngFor="let image of images; index as i" 
             [class.active]="index == i" 
             [ngStyle]="imageStyle(i)"></div>
      </div>
    </div>
  `,
  styles: [
    `
      .carousel.slide {
        background-color: #000;
      }
      .item {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .carousel,
      .carousel-inner,
      .item {
        width: 100%;
        height: 100%;
      }
    `
  ]
})
export class CarouselComponent implements OnInit, OnChanges {
  @Input() images = [];
  @Input() index: number = 0;

  @Output() onPrev = new EventEmitter<null>();
  @Output() onNext = new EventEmitter<null>();
  @Output() onGoTo = new EventEmitter<number>();

  // lazy load images to save bandwidth, etc.
  loadedMin = 0;
  loadedMax = 0;

  ngOnInit(): void {
    this.loadedMin = this.index - IMG_BUFFER_SIZE;
    this.loadedMax = this.index + IMG_BUFFER_SIZE;
    // console.log(`init buffer ${this.index}: ${this.loadedMin}--${this.loadedMax}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const indexChange: SimpleChange = changes["index"];
    if (indexChange && indexChange.currentValue) {
      this.rebuffer(this.index);
    }
  }

  imageStyle(i) {
    const bi =
      i >= this.loadedMin && i <= this.loadedMax
        ? `url('${this.images[i]}')`
        : "none";
    return { "background-image": bi };
  }

  rebuffer(i) {
    // change lazy load range
    if (i - IMG_BUFFER_SIZE < this.loadedMin) {
      this.loadedMin = i - IMG_BUFFER_SIZE;
    }
    if (i + IMG_BUFFER_SIZE > this.loadedMax) {
      this.loadedMax = i + IMG_BUFFER_SIZE;
    }
    // console.log(`rebuffer ${i}: ${this.loadedMin}--${this.loadedMax}`);
  }

  @HostListener("document:keydown.arrowleft", ["$event"])
  onKeyboardLeftHandler(event: KeyboardEvent) {
    this.onPrev.emit();
  }

  @HostListener("document:keydown.arrowright", ["$event"])
  onKeyboardRightHandler(event: KeyboardEvent) {
    this.onNext.emit();
  }
}
