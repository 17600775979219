
"use strict";

import {NgModule} from "@angular/core";
import {FlashService} from "./flash.service";
import {AlertContainerComponent} from "./alert-container.component";
import {CommonModule} from "@angular/common";
import {CloseableAlertComponent} from "./closeable-alert.component";
import {AlertMessageComponent} from "./alert-message.component";


// TODO: does this need the import?
// TODO: does this need to be an entry component?
@NgModule({
  providers: [FlashService],
    imports: [CommonModule],
    exports: [AlertContainerComponent, AlertMessageComponent, CloseableAlertComponent],
    declarations: [AlertContainerComponent, AlertMessageComponent, CloseableAlertComponent],
    entryComponents: [AlertContainerComponent, AlertMessageComponent, CloseableAlertComponent],
})
export class FlashModule {}
