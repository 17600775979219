"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalDialogComponent } from "./modal-dialog.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule],
  exports: [ModalDialogComponent],
  declarations: [ModalDialogComponent]
})
export class ModalDialogModule {}
