import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { StateService } from "@uirouter/core";
import { HttpClient } from "@angular/common/http";
import { FlashService } from "../../common/flash/flash.service";
import { SessionService } from "../../common/session/session.service";

import { DropdownComponent } from "../../components/dropdown/dropdown.component";

import "./base-star-dropdown.scss";
import "./base-template.scss";
import {ApiService} from "../../common/api/api.service";

@Component({
  selector: "base-star-dropdown",
  template: require("./base-star-dropdown.html")
})
export class BaseStarDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;

  notifications: any = {};
  engineId = null;
  hasPickedGroups = false;
  numRatings = 0;

  notificationsSubscription = null;
  userDataSubscription = null;
  ratingsSubscription = null;

  joinedExternalRecs = false;

  buttonClass = "btn base-compact-header-btn btn-sm";
  updateButtonContent() {
    this.buttonContent = `<span class="hidden-xs">${this.numRatings}</span>
       <i class="fa fa-star-o ml4-fa-1x"></i>
       <i class="fa fa-caret-down"></i>`;
  }

  buttonContent = `<span class="hidden-xs">${this.numRatings}</span>
     <i class="fa fa-star-o ml4-fa-1x"></i>
     <i class="fa fa-caret-down"></i>`;
  offset = "8px";
  name = "baseStarDropdown";

  constructor(
    private stateService: StateService,
    private sessionService: SessionService,
    private flash: FlashService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.notificationsSubscription = this.sessionService.currentNotifications.subscribe(
      val => {
        this.notifications = val;
      }
    );
    this.numRatings = 1000;
    this.userDataSubscription = this.sessionService.currentUserData.subscribe(
      val => {
        this.engineId = val.preferences.recommender.engineId;
        this.hasPickedGroups = val.preferences.hasPickedGroups;
      }
    );
    this.ratingsSubscription = this.sessionService.currentRatingCount.subscribe(
      val => {
        this.numRatings = val;
        this.updateButtonContent();
      }
    );

    this.joinedExternalRecs = this.engineId === "external";
  }

  ngOnDestroy() {
    this.notificationsSubscription.unsubscribe();
    this.userDataSubscription.unsubscribe();
    this.ratingsSubscription.unsubscribe();
  }

  bardEnabled() {
    return this.hasPickedGroups;
  }
  warriorEnabled() {
    return !this.notifications.ratingsBelowThreshold;
  }
  wizardEnabled() {
    return (
      !this.notifications.ratingsBelowThreshold &&
      !this.notifications.tooNewForSvd
    );
  }

  model = {
    recommenders: [
      {
        key: "peasant",
        name: '"the peasant"',
        desc: "non-personalized",
        enabled: function() {
          return true;
        }
      },
      {
        key: "bard",
        name: '"the bard"',
        desc: "based on movie group point allocation",
        configureLink: this.stateService.href("base.pickGroups", {}),
        enabled: () => {
          return this.bardEnabled();
        }
      },
      {
        key: "warrior",
        name: '"the warrior"',
        desc: "based on ratings",
        configureLink: this.stateService.href("base.rectune", {}),
        enabled: () => {
          return this.warriorEnabled();
        }
      },
      {
        key: "wizard",
        name: '"the wizard"',
        desc: "based on ratings",
        configureLink: this.stateService.href("base.rectune", {}),
        enabled: () => {
          return this.wizardEnabled();
        }
      }
    ]
  };

  switchingRecommender = false;
  switchRecommender(recommender) {
    if (this.switchingRecommender == true) {
      // protect against double-switch requests (the dropdown widget triggers them)
      return;
    }
    this.switchingRecommender = true;

    if (!recommender.enabled()) {
      return;
    }

    this.http.put("/api/users/me", { engineId: recommender.key }).subscribe(
      val => {
        this.switchingRecommender = false;
        this.flash
          .addSuccess(
            `<strong>Nice one!</strong> Now you are using the ${
              recommender.key
            } recommender.`
          )
          .show();

        this.stateService.reload();
      },
      error => {
        this.flash
          .addInfo(`Error switching to recommender ${recommender.key}.`)
          .show();
      },
      () => {
        //complete
      }
    );
  }
}
