import { Injectable } from "@angular/core";
import { toLower } from "lodash";

import { ApiService } from "../api/api.service";


@Injectable()
export class TagDataService {
  constructor(private apiService: ApiService) {}

  public ML4_TAG_CONFIG = {
    colorDampingFactor: 10,
    tagAffect: {
      "-1": "negative",
      "0": "neutral",
      "1": "positive"
    }
  };

  static _colorPct(num, total) {
    return Math.round((num / total) * 100);
  }

  // choose blended color based on affect
  // - more neutral, more white
  // - more positive, more green
  // - more negative, more red
  // - (therefore) more controversial, more green + red = yellow
  chooseAColor(tagCountsObj) {
    let dampedTotal =
      tagCountsObj.total < this.ML4_TAG_CONFIG.colorDampingFactor
        ? this.ML4_TAG_CONFIG.colorDampingFactor
        : tagCountsObj.total;
    let posPct = TagDataService._colorPct(tagCountsObj.positive, dampedTotal);
    let negPct = TagDataService._colorPct(tagCountsObj.negative, dampedTotal);

    let red = 100 - posPct;
    let grn = 100 - negPct;
    let blu = 100 - posPct - negPct;
    return "rgba(" + red + "%, " + grn + "%, " + blu + "%, 0.5)";
  }

  normalizeName(name) {
    return toLower(name);
  }

  applyTag(movieId, tag, affect, tagId = null, successCallback = null) {
    let data = {
      movieId: movieId,
      tag: tag,
      affect: affect,
      tagId: tagId
    };
    this.apiService.ml4Post(
      "POST /api/users/me/tags",
      "/api/users/me/tags",
      data,
      successCallback,
      () => {}
    );
  }

  unapplyTag(movieId, tag, tagId = null) {
    let data = {
      movieId: movieId,
      tag: tag,
      tagId: tagId
    };
    this.apiService.ml4Delete(
      "DELETE /api/users/me/tags",
      "/api/users/me/tags",
      data,
      () => {},
      () => {}
    );
  }

  rateTag(movieId, tag, rating) {
    this.apiService.ml4Post(
      "POST /api/users/me/tag-ratings",
      "/api/users/me/tag-ratings",
      {
        movieId: movieId,
        tag: tag,
        rating: rating
      },
      () => {},
      () => {}
    );
  }

  unrateTag(movieId, tag) {
    this.apiService.ml4Delete(
      "DELETE /api/users/me/tag-ratings",
      "/api/users/me/tag-ratings",
      {
        movieId: movieId,
        tag: tag
      },
      () => {},
      () => {}
    );
  }

  // getting all tags for a movie
  getMovieTags(id, successCallback, errorCallback) {
    this.apiService.ml4Get(
      "GET /api/movies/:id/tags",
      "/api/movies/" + id + "/tags",
      {},
      successCallback,
      errorCallback
    );
  }

  // getting all tags for a user
  userTags(successCallback, errorCallback?) {
    this.apiService.ml4Get(
      "GET /api/users/me/tags",
      "/api/users/me/tags",
      {},
      successCallback,
      errorCallback
    );
  }
}
