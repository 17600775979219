"use strict";

import { Component, Input } from "@angular/core";
import { get } from "lodash";

import "./movie-table.scss";

@Component({
  selector: "movie-table",
  template: `
    <div class="ml4-movie-table">
      <div class="ml4-movie-table-responsive-container">
        <div *ngFor="let movieDetail of movies">
          <div
            class="ml4-movie-table-row"
            (click)="toggle(movieDetail.movieId)"
          >
            <div class="ml4-movie-table-row-top">
              <div
                class="ml4-movie-table-col-first ml4-movie-table-stars"
                (click)="blockToggle($event)"
              >
                <rating-smart
                  [movieId]="movieDetail.movie.movieId"
                  [movieUserData]="movieDetail.movieUserData"
                ></rating-smart>
              </div>

              <div
                class="ml4-movie-table-col emphasis-col ml4-movie-table-title"
                [class.hidden-movie-field]="movieDetail.movieUserData.hidden"
              >
                <div class="nowrap">
                  <a
                    uiSref="base.movie"
                    [uiParams]="{ id: movieDetail.movie.movieId }"
                    (click)="blockToggle($event)"
                    class="hyperlink"
                    >{{ movieDetail.movie.title }}</a
                  >
                </div>
              </div>

              <div class="ml4-movie-table-col ml4-movie-table-gutter">
                &nbsp;
              </div>

              <div
                class="ml4-movie-table-col metadata-col ml4-movie-table-year"
              >
                {{ movieDetail.movie.releaseDate | date: "yyyy" }}
              </div>

              <div
                class="ml4-movie-table-col metadata-col ml4-movie-table-mpaa"
              >
                {{ movieDetail.movie.mpaa }}
              </div>

              <div
                class="ml4-movie-table-col metadata-col ml4-movie-table-runtime"
              >
                <span *ngIf="movieDetail.movie.runtime"
                  >{{ movieDetail.movie.runtime }} <i class="fa fa-clock-o"></i
                ></span>
              </div>

              <div
                class="ml4-movie-table-col metadata-col ml4-movie-table-num-ratings"
              >
                {{ movieDetail.movie.numRatings | number }}
                <i class="fa fa-star-o"></i>
              </div>

              <div class="ml4-movie-table-col ml4-movie-table-gutter">
                &nbsp;
              </div>
              <div class="ml4-movie-table-col ml4-movie-table-gutter">
                &nbsp;
              </div>

              <div
                class="ml4-movie-table-col metadata-col ml4-movie-table-genres"
              >
                {{ movieDetail.movie.genres.join(", ") }}
              </div>

              <div
                class="ml4-movie-table-col-last emphasis-col ml4-movie-table-wishlist"
                (click)="blockToggle($event)"
              >
                <ml4-wishlist
                  [movieId]="movieDetail.movie.movieId"
                  [wishlist]="movieDetail.movieUserData.wishlist"
                >
                </ml4-wishlist
                ><ml4-hide-this
                  [movieId]="movieDetail.movie.movieId"
                  [isHidden]="movieDetail.movieUserData.hidden"
                  [predictedRating]="movieDetail.movieUserData.prediction"
                ></ml4-hide-this>
              </div>

              <div class="clearfix"></div>
            </div>

            <div
              class="ml4-movie-table-row-bottom"
              *ngIf="exposedMovieIds[movieDetail.movie.movieId]"
            >
              <div class="ml4-movie-table-extra">
                <div>
                  {{ movieDetail.movie.plotSummary }}
                </div>

                <div class="metadata-col">
                  <strong
                    >Average of
                    {{ movieDetail.movie.numRatings | number }} ratings</strong
                  >: {{ movieDetail.movie.avgRating | number: "1.1-2" }} stars
                </div>

                <div class="metadata-col">
                  <strong>MovieLens predicts for you</strong>:
                  {{
                    movieDetail.movieUserData.prediction | number: "1.1-2"
                  }}
                  stars
                </div>

                <div
                  class="metadata-col"
                  *ngIf="
                    movieDetail.movie.directors.length > 0 &&
                    movieDetail.movie.directors[0]
                  "
                >
                  <strong>Directed by</strong>:
                  {{ movieDetail.movie.directors.join(", ") }}
                </div>

                <div
                  class="metadata-col"
                  *ngIf="
                    movieDetail.movie.actors.length > 0 &&
                    movieDetail.movie.actors[0]
                  "
                >
                  <strong>Cast</strong>:
                  {{ movieDetail.movie.actors.join(", ") }}
                </div>

                <div class="metadata-col">
                  <strong>Links</strong>:
                  <a
                    [href]="movieDetail.movie.imdbUrl"
                    target="_blank"
                    (click)="blockToggle($event)"
                    >imdb</a
                  >,
                  <span *ngIf="movieDetail.movie.tmdbUrl">
                    <a
                      [href]="movieDetail.movie.tmdbUrl"
                      target="_blank"
                      (click)="blockToggle($event)"
                      >tmdb</a
                    >
                  </span>
                </div>
              </div>

              <div class="clearfix"></div>
            </div>
          </div>
          <!-- ml4-movie-table-row -->

          <div class="clearfix"></div>
        </div>
        <!-- ng-repeat -->
      </div>
      <!-- ml4-movie-table-responsive-container -->
    </div>
  `
})
export class MovieTableComponent {
  @Input() movies;

  exposedMovieIds = {};

  constructor() {}

  toggle(movieId) {
    this.exposedMovieIds[movieId] = !!this.exposedMovieIds[movieId] ? 0 : 1;
  }

  // XXX: prevent the toggle event. This allows full clicking on the row, excepting particular (interactive) elements.
  blockToggle($event) {
    $event.stopPropagation();
  }
}
