"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TooltipXModule } from "../tooltip/tooltip-x.module";

import { ToggleComponent } from "./toggle.component";
import { HideThisComponent } from "./hide-this.component";
import { WishlistComponent } from "./wishlist.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule, TooltipXModule],
  exports: [HideThisComponent, WishlistComponent],
  declarations: [HideThisComponent, ToggleComponent, WishlistComponent],
  entryComponents: [HideThisComponent, WishlistComponent]
})
export class ToggleModule {}
