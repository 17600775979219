"use strict";

import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { clone, merge } from "lodash";

import { GenresService } from "../../common/genres/genres.service";

@Component({
  selector: "explore-genre-shortcut",
  template: ""
})
export class ExploreGenreShortcutComponent implements OnInit {
  constructor(
    private genreService: GenresService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.redirect();
  }

  redirect() {
    let genre = this.stateService.params.id;

    genre = genre.replace("-", " ");

    if (this.genreService.all().indexOf(genre) === -1) {
      this.stateService.go("base.allGenres", {}, { inherit: false });
      return;
    }

    const params = {
      hasRated: "no",
      sortBy: "prediction",
      subHeading: "browsing by genre",
      genre: genre,
      heading: genre
    };

    this.stateService.go("base.explore", params, {
      inherit: false,
      location: false
    });
  }
}
