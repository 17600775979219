import { Component, ViewChild } from "@angular/core";
import { StateService } from "@uirouter/core";

import { DropdownComponent } from "../../components/dropdown/dropdown.component";

import "./base-menu-dropdown.scss";
import "./base-template.scss";

@Component({
  selector: "base-menu-dropdown",
  template: require("./base-menu-dropdown.html")
})
export class BaseMenuDropdownComponent {
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;

  buttonClass = "btn btn-sm base-compact-header-btn";
  buttonContent = `
        <i class="fa fa-bars"></i>
        <i class="fa fa-caret-down"></i>
    `;
  offset = "8px";
  name = "baseMenuDropdown";

  constructor(private stateService: StateService) {}
}
