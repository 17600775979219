"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { ApiActivityService } from "./api-activity.service";
import { ApiActivityIndicatorCogComponent } from "./api-activity-indicator-cog.component";
import {UIRouterModule} from "@uirouter/angular";

@NgModule({
  imports: [CommonModule, UIRouterModule.forChild()],
  exports: [ApiActivityIndicatorCogComponent],
  declarations: [ApiActivityIndicatorCogComponent],
  entryComponents: [ApiActivityIndicatorCogComponent],
  providers: [ApiActivityService]
})
export class ApiActivityModule {}
