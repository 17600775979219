"use strict";

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {StateService, UrlService} from "@uirouter/core";
import { BehaviorSubject } from "rxjs";
import { clone, set } from "lodash";

import { FlashService } from "../flash/flash.service";
import { DeferredUrlService } from "../deferred-url/deferred-url.service";
import { UserDataService } from "../user-data/user-data.service";

@Injectable()
export class SessionService {
  // constants
  RATING_THRESHOLD = 15;
  ENABLE_SVD_MIN_SECS = 87600;

  private userData = new BehaviorSubject<any>(null);
  currentUserData = this.userData.asObservable();

  private ratingCount = new BehaviorSubject<number>(0);
  currentRatingCount = this.ratingCount.asObservable();

  private notifications = new BehaviorSubject<any>({});
  currentNotifications = this.notifications.asObservable();

  constructor(
    private http: HttpClient,
    private flash: FlashService,
    private stateService: StateService,
    private userDataService: UserDataService,
    private deferredUrlService: DeferredUrlService,
    private urlService: UrlService,
  ) {}

  // used for one time bindings, not subscriptions
  getUserData() {
    return this.userData.value;
  }

  setCanSendEmail(canSendEmail) {
    let newUserData = clone(this.userData.value);
    set(newUserData, "preferences.canSendEmail", canSendEmail);
    this.userData.next(newUserData);
  }

  setThemePreference(theme) {
    let newUserData = clone(this.userData.value);
    set(newUserData, "preferences.options.theme", theme);
    this.userData.next(newUserData);
  }

  setFirstTime(firstLoginTheme) {
    let newUserData = clone(this.userData.value);
    set(newUserData, "preferences.firstLoginTheme", firstLoginTheme);
    this.userData.next(newUserData);
  }

  onLogin(userData) {
    this.userData.next(userData);
    this.refreshNotifications();
    this.setRatingCount(this.userData.value.numRatings);
  }

  setRatingCount(ratingCount) {
    if (
      this.ratingCount.value == this.RATING_THRESHOLD - 1 &&
      ratingCount == this.RATING_THRESHOLD
    ) {
      this.changeToWarriorRecommender();
    }
    this.ratingCount.next(ratingCount);
    this.refreshNotifications();
  }

  refreshNotifications() {
    let result: any = {};

    if (this.ratingCount.value < this.RATING_THRESHOLD) {
      result.ratingsBelowThreshold = true;
    }

    const timeAsMemberSeconds = this.userData.value.account.timeAsMemberSeconds;
    if (timeAsMemberSeconds < this.ENABLE_SVD_MIN_SECS) {
      result.tooNewForSvd = true;
    }

    if (!this.userData.value.preferences.hasPickedGroups) {
      result.hasNotPickedGroup = true;
    }

    this.notifications.next(result);
  }

  async reloadSession() {
    let redirect = await this.verifyLoggedIn();
    if(redirect) {
        this.stateService.transitionTo(redirect, {});
    }
  }

  /**
   * Set info about the user to session,
   * returns a string (next state, truthy) if a redrect is needed, and false if no redirect is needed.
   */
  async verifyLoggedIn(params?: any) {
    params = params || {};
    // use the async/await protocol to prevent continuing until login data is received
    try {
      let response = await this.http
        .get<any>("/api/users/me", { params: params })
        .toPromise();
      this.onLogin(response.data);
      return false
    } catch (error) {
      if (error && error.status == 401) {
        console.log("unauthorized, redirecting to the login page");
        this.deferredUrlService.setNextUrl(this.urlService.path(), true);
        return "auth.login";
      } else if (error && error.status == 403) {
        console.log("forbidden");
        return "403";
      } else {
        console.log("preload failure", error);
        return "500";
      }
    }
  }

  /**
   * Set info about the user to session (with admin required)
   * returns a string (next state, truthy) if a redrect is needed, and false if no redirect is needed.
   */
  verifyAdmin() {
    return this.verifyLoggedIn({ adminRequired: true });
  }

  showFlash() {
    this.flash
      .addSuccess(
        "<strong>Yeah!</strong> You've rated enough movies to upgrade to a personalized recommender " +
        "that's based on your ratings. Click the star in the top-right to change your recommender any time."
      )
      .show();
  }

  changeToWarriorRecommender() {
    // user has rated enough to grant them a personalized recommender
    // always pick item-item, since svd isn't personalized until a full model build
    this.userDataService.update({ engineId: "item-item" }, () => {
      this.reloadSession();
      this.showFlash();
    });
  }

}
