"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import { FrontpageComponent } from "./frontpage.component";
import { ExternalRecsModule } from "../../expt/externalrecs/external-recs.module";
import { MovieRowModule } from "../../components/movie-row/movie-row.module";
import {UIRouterModule} from "@uirouter/angular";
import {IfExptModule} from "../../components/if-expt/if-expt.module";
import { ThemeChangeModalModule } from "../../components/theme-change-model/theme-change-modal.module";

const frontPage = {
  name: "base.frontpage",
  url: "/home?q",
  component: FrontpageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    ExternalRecsModule,
    MovieRowModule,
    UIRouterModule.forChild({states: [frontPage]}),
    IfExptModule,
    ThemeChangeModalModule,
  ],
  declarations: [FrontpageComponent]
})
export class FrontpageModule {}
