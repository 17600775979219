"use strict";

import { NgModule } from "@angular/core";

import { ErrorPageComponent } from "./error-page.component";
import {UIRouterModule} from "@uirouter/angular";

const error403Page = {
  name: "403",
  url: "/403",
  component: ErrorPageComponent,
  resolve: {
    title: () => "403 &mdash; Forbidden",
    message: () => "You do not have permission to access this page."
  }
};

const error404Page = {
  name: "404",
  url: "/404",
  component: ErrorPageComponent,
  resolve: {
    title: () => "404 &mdash; Not Found",
    message: () =>
      "Darn it. Looks like something went wrong with MovieLens, and here we are."
  }
};

const error500Page = {
  name: "500",
  url: "/500",
  component: ErrorPageComponent,
  resolve: {
    title: () => "500 &mdash; Something is broken!",
    message: () =>
      "Looks like something went wrong with MovieLens, and here we are."
  }
};

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [error403Page, error404Page, error500Page]
    })
  ],
  declarations: [ErrorPageComponent]
})
export class ErrorsModule {}
