"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


import { AddMoviePageComponent } from "./add-movie-page.component";
import { AddMovieService } from "./add-movie.service";
import {UIRouterModule} from "@uirouter/angular";

const addMoviePage = {
  name: "base.addTitle",
  url: "/volunteer/add-a-title",
  component: AddMoviePageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [addMoviePage] })
  ],
  declarations: [
    AddMoviePageComponent
  ],
  providers: [
    AddMovieService
  ]
})
export class AddMovieModule {}
