"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

import { SessionService } from "../../common/session/session.service";

@Component({
  selector: "coldstart-page",
  template: `
    <div class="coldstart-page">
      <h1>welcome!</h1>

      <div class="intro" style="margin: 24px 0;">
        To get started, tell MovieLens about your preferences by distributing 3
        points among your favorite groups of movies below.
      </div>

      <ml4-pick-groups (onSubmit)="onSubmit($event)"></ml4-pick-groups>
    </div>
  `
})
export class ColdstartPageComponent implements OnInit {
  constructor(
    private sessionService: SessionService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Pick Movie Groups | MovieLens");
  }

  onSubmit = evt => {
    if (evt.success) {
      this.sessionService.reloadSession();
    }

    this.stateService.go("restricted.newUser_welcome");
  };
}
