"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { FlashService } from "../../common/flash/flash.service";
import { ApiService } from "../../common/api/api.service";
import { StateService } from "@uirouter/core";

@Component({
  selector: "password-reset-page",
  template: `
    <div class="password-reset">

      <h3 class="page-description">password reset</h3>
    
      <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
      
        <div style="margin-bottom: 18px;">
          Please provide your username or email address, and we will send you an email that will allow you to
          reset your password.
          If you have not set a valid email address, you can contact us at
          <a href='mailto:movielens-accounts@umn.edu'>movielens-accounts@umn.edu</a>
          with your username and your current email address to request a manual password change.
        </div>
    
        <div class="form-group">
          <label>
            username or email
            <input type="text" class="form-control" formControlName="email" required>
          </label>
        </div>
    
        <div class="form-group">
          <button type="submit" class="btn btn-default" [disabled]="thinking || email.invalid">send password reset email</button>
          <span style="margin-left: 5px;" [hidden]="!thinking">
            <i class="fa fa-cog fa-spin"></i>
          </span>
        </div>
      </form>
    
      <p class="spaced">
        <a uiSref="auth.login"><i class="fa fa-chevron-left ml4-fa-1x"></i> back to login page</a>
      </p>
    
    </div>

  `
})
export class PasswordResetPageComponent {
  private thinking: boolean = false;
  private passwordResetForm: FormGroup;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private flashService: FlashService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Password Reset | MovieLens");

    this.passwordResetForm = this.fb.group({
      email: [
        "",
        {
          validators: [Validators.required]
        }
      ]
    });
  }

  get email() {
    return this.passwordResetForm.get("email");
  }

  onSubmit() {
    this.thinking = true;
    this.apiService.ml4Post(
      "POST /api/actions/user/password-reset",
      "/api/actions/user/password-reset",
      { usernameOrEmail: this.email.value },
      () => {
        this.thinking = false;
        this.flashService
          .addSuccess(
            "Check your email! We've sent you instructions for resetting your password."
          )
          .show();
        this.stateService.go("auth.login");
      },
      rsp => {
        this.thinking = false;
        this.flashService.addError("Uh oh: " + get(rsp, "error.message")).show();
      }
    );
  }
}
