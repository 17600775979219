"use strict";

import { difference, each, filter, includes, map } from "lodash";
import { Injectable } from "@angular/core";
import { RectuneService } from "../../common/rectune/rectune.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { ApiService } from "../../common/api/api.service";

@Injectable()
export class PopTunerService {
  private topPicksMovieIds = [];
  public topPicksMovies = [];
  private removedMovieIds = [];
  public removedMovies = [];
  private addedMovieIds = [];
  public addedMovies = [];

  constructor(
    private rectuneService: RectuneService,
    private movieDataService: MovieDataService,
    private api: ApiService
  ) {}

  setNewTopPicks(newTopPicksMovies) {
    // defensive clone of input. based on stack overflow
    // source: https://stackoverflow.com/questions/35504310/deep-copy-an-array-in-angular-2-typescript
    newTopPicksMovies = newTopPicksMovies.map(x => Object.assign({}, x));
    let newTopPicksMovieIds = map(newTopPicksMovies, "movieId");

    if (this.topPicksMovieIds) {
      this.removedMovieIds = difference(
        this.topPicksMovieIds,
        newTopPicksMovieIds
      );
      this.removedMovies = filter(this.topPicksMovies, m => {
        return includes(this.removedMovieIds, m.movieId);
      });

      this.addedMovieIds = difference(
        newTopPicksMovieIds,
        this.topPicksMovieIds
      );
      this.addedMovies = filter(newTopPicksMovies, m => {
        return includes(this.addedMovieIds, m.movieId);
      });
    }

    this.topPicksMovieIds = newTopPicksMovieIds;
    this.topPicksMovies = newTopPicksMovies;

    each(this.topPicksMovies, topPick => {
      if (includes(this.addedMovieIds, topPick.movieId)) {
        //console.log('added this:', topPick.movieId);
        topPick.wasAdded = true;
      }
    });

    this.rectuneService.log("VIEW", {
      topPicks: this.topPicksMovieIds,
      addedMovies: this.addedMovieIds,
      removedMovies: this.removedMovieIds
    });
  }

  getTopPicks(callback) {
    this.movieDataService.movieSearch(
      { hasRated: "no", sortBy: "prediction" },
      callback
    );
  }

  _updateRecommender(params, callback) {
    this.api.ml4Post(
      "POST /api/actions/user/update-recommender",
      "/api/actions/user/update-recommender",
      params,
      callback
    );
  }

  morePop(callback) {
    this._updateRecommender({ action: "MORE_POPULAR" }, callback);
  }
  lessPop(callback) {
    this._updateRecommender({ action: "LESS_POPULAR" }, callback);
  }
  reset(callback) {
    this._updateRecommender({ action: "RESET" }, callback);
  }
}
