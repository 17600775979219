"use strict";

import "./base-template.scss";
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { get } from "lodash";
import { WindowService } from "../../common/window/window.service";
import { BaseOmnisearchComponent } from "./base-omnisearch.component";
import { Subscription } from "rxjs";
// llm-rec sophia sun00587 start
import { LLMRecService } from "../../expt/llm_rec/llm_rec.service";
// llm-rec sophia sun00587 end
// interactive-profile sophia sun00587 start
import { InteractiveProfileService } from "../../expt/interactive_profile/interactive_profile.service";
// interactive-profile sophia sun00587 end
import {SessionService} from "../../common/session/session.service";

declare var ML4_VERSION_MACRO: string;
declare var ML4_BUILD_ID_MACRO: string;
declare var ML4_COPYRIGHT_YEAR_MACRO: string;
declare var ML4_DEV_MODE_MACRO: string;

@Component({
  template: require("./base-template.html")
})
export class BaseTemplateComponent implements OnInit, OnDestroy, AfterViewInit {
  // constants from webpack config
  private ML4_VERSION = ML4_VERSION_MACRO;
  private ML4_BUILD_ID = ML4_BUILD_ID_MACRO;
  private ML4_COPYRIGHT_YEAR = ML4_COPYRIGHT_YEAR_MACRO;
  private ML4_DEV_MODE = ML4_DEV_MODE_MACRO;

  @ViewChild(BaseOmnisearchComponent)
  private omniSearch: BaseOmnisearchComponent;
  private subscription: Subscription;
  private height: number;

  constructor(
    private windowService: WindowService,
    private sessionService: SessionService
  ) {}

  // llm-rec sophia sun00587 start
  exptUserId: string = "";
  // llm-rec sophia sun00587 end

  ml4ContainerCss() {
    return {
      "min-height": this.height + "px"
    };
  }

  ngAfterViewInit(): void {
    this.subscription = this.windowService.currentWindowSize.subscribe(val => {
      this.height = val.height;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  @HostListener("document:keydown./", ["$event"])
  onSlashKey(event: KeyboardEvent) {
    // timeout it to keep it from actually typing / into the omnisearch (unless you are typing into omnisearch already)
    setTimeout(this.omniSearch.focus, 0);
  }

}
