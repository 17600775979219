"use strict";

import { NgModule } from "@angular/core";
import { ConfigureBardPageComponent } from "./configure-bard-page.component";
import { PopTunerPageComponent } from "./pop-tuner-page.component";
import { PopTunerService } from "./pop-tuner.service";
import { PickGroupsModule } from "../../components/pick-groups/pick-groups.module";
import { MovieCardModule } from "../../components/movie-card/movie-card.module";
import { BrowserModule } from "@angular/platform-browser";
import {UIRouterModule} from "@uirouter/angular";

const pickGroupsPage = {
  name: "base.pickGroups",
  url: "/profile/settings/pick-groups",
  component: ConfigureBardPageComponent
};
const popTunerPage = {
  name: "base.rectune",
  url: "/profile/settings/recommender",
  component: PopTunerPageComponent
};

@NgModule({
  imports: [
    PickGroupsModule,
    MovieCardModule,
    BrowserModule,
    UIRouterModule.forChild({ states: [pickGroupsPage, popTunerPage] })
  ],
  declarations: [ConfigureBardPageComponent, PopTunerPageComponent]
})
export class RecTunePageModule {}

@NgModule({
  providers: [PopTunerService]
})
export class PopTunerServiceModule {}
