"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ThemeChangeModalComponent } from "./theme-change-modal.component";
import { ModalDialogModule } from "../modal-dialog/modal-dialog.module";
import { ThemePageComponent } from "../../ui/profile/theme-page.component";
import { UIRouterModule } from "@uirouter/angular";

@NgModule({
  imports: [
    CommonModule,
    ModalDialogModule,
    UIRouterModule.forChild()
  ],
  exports: [ThemeChangeModalComponent],
  declarations: [ThemeChangeModalComponent]
})
export class ThemeChangeModalModule {}
