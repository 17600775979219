import { Injectable } from "@angular/core";
import { LRUMap } from "lru_map";
import { forOwn, get, isNil, merge, pickBy, set } from "lodash";

import { ApiService } from "../api/api.service";
import { UrlsService } from "../urls/urls.service";

const SIMILAR_API_DEFAULTS = {
  num: 8,
  page: 1,
  critique: "",
  includeDefaultTags: false
};

@Injectable()
export class MovieDataService {
  private movieCache: LRUMap<number, object>;

  constructor(
    private apiService: ApiService,
    private urlsService: UrlsService
  ) {
    // cache movie details objects by movieId
    // about 1kb per movie
    // lru cache docs: https://github.com/rsms/js-lru
    this.movieCache = new LRUMap(100);
  }

  /**
   * Decorate a movie object with full links and other data.
   *
   * Adds movie to the cache.
   */
  extendMovie(movieDetails) {
    let movie = get(movieDetails, "movie");
    if (!movie) {
      return;
    }

    movie.imdbUrl = this.urlsService.imdbUrl(movie.imdbMovieId);

    if (movie.tmdbMovieId) {
      movie.tmdbUrl = this.urlsService.tmdbUrl(movie.tmdbMovieId);
      movie.tmdbEditUrl = this.urlsService.tmdbEditUrl(movie.tmdbMovieId);
    }

    if (movie.posterPath) {
      movie.posterW92Url = this.urlsService.tmdbPosterImgUrl(
        movie.posterPath,
        "w92"
      );
      movie.posterW185Url = this.urlsService.tmdbPosterImgUrl(
        movie.posterPath,
        "w185"
      );
      movie.posterW342Url = this.urlsService.tmdbPosterImgUrl(
        movie.posterPath,
        "w342"
      );
      movie.posterW500Url = this.urlsService.tmdbPosterImgUrl(
        movie.posterPath,
        "w500"
      );
      movie.posterOrigUrl = this.urlsService.tmdbPosterImgUrl(
        movie.posterPath,
        "original"
      );
    }

    movie.backdropW300Urls = [];
    movie.backdropOrigUrls = [];
    movie.hasBackdrop = movie.backdropPaths && movie.backdropPaths.length > 0;
    if (movie.hasBackdrop) {
      movie.backdropPaths.map(path => {
        movie.backdropW300Urls.push(
          this.urlsService.tmdbBackdropImgUrl(path, "w300")
        );
        movie.backdropOrigUrls.push(
          this.urlsService.tmdbBackdropImgUrl(path, "original")
        );
      });
    }
    movie.firstBackdropW300Url = get(movie, "backdropW300Urls[0]");
    movie.firstBackdropOrigUrl = get(movie, "backdropOrigUrls[0]");

    movie.hasTrailer =
      movie.youtubeTrailerIds && movie.youtubeTrailerIds.length > 0;
    movie.firstYoutubeTrailerId = get(movie, "youtubeTrailerIds[0]");

    // cache
    this.movieCache.set(movieDetails.movieId, movieDetails);
  }

  /**
   * Call extendMovie for each movie in the list.
   */
  extendMovies(listOfMovieDetails) {
    listOfMovieDetails.map(md => {
      this.extendMovie(md);
    });
  }

  /**
   * Get information about a movie.  Cache movie info.
   *
   * Returns a movieDetails object that looks like:
   * {
   *   movieId: 1,
   *   movie: { ... },
   *   movieUserData: { ... }
   * }
   */
  getMovie(
    movieId: number,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    let m = this.movieCache.get(movieId);
    if (m) {
      successCallback(m);
    } else {
      this.apiService.ml4Get(
        "GET /api/movies/:id",
        "/api/movies/" + movieId,
        {},
        response => {
          m = get(response, "data.movieDetails", null);
          if (m) {
            this.extendMovie(m);
            successCallback(m);
          } else {
            errorCallback(response);
          }
        },
        errorCallback
      );
    }
  }

  /**
   * Search for movies.  Cache movie info, but not the search itself.
   *
   * Returns a searchData object that looks like:
   * {
   *   title: 1,
   *   description: "foo",
   *   ....
   *   searchResults: [
   *     { ... movieDetails, see above }
   *   ],
   *   pager: {
   *     itemsPerPage: 24,
   *     ...
   *   }
   * }
   */
  movieSearch(
    params: any,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    // remove keys that have empty values, or that start with $ (angular junk)
    const cleanParams = pickBy(params, (value, key) => {
      return !isNil(value) && !key.startsWith("$");
    });

    this.apiService.ml4Get(
      "GET /api/movies/explore",
      "/api/movies/explore",
      cleanParams,
      response => {
        const data = get(response, "data", null);
        if (data) {
          // iterate over data.searchResults
          const listOfMovieDetails = get(data, "searchResults", []);
          this.extendMovies(listOfMovieDetails);
          successCallback(data);
        } else {
          errorCallback(response);
        }
      },
      errorCallback
    );
  }

  /**
   * Find similar movies. Cache movie info, but not the search itself.
   */
  similarMovies(
    movieId: number,
    optParams?: any,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    const params = merge({}, SIMILAR_API_DEFAULTS, optParams);

    this.apiService.ml4Get(
      "GET /api/movies/:id/similar",
      `/api/movies/${movieId}/similar`,
      params,
      response => {
        const data = get(response, "data", null);
        if (data) {
          this.extendMovies(get(data, "similarMovies.searchResults", []));
          successCallback(data);
        } else {
          errorCallback(response);
        }
      },
      errorCallback
    );
  }

  /**
   * Get the frontpage: a list of lists.
   *
   * Returns a listOfSearchResults object.
   */
  frontpage(successCallback?: Function, errorCallback?: Function) {
    this.apiService.ml4Get(
      "GET /api/users/me/frontpage",
      "/api/users/me/frontpage",
      {},
      response => {
        // outer list (list of rows)
        const listOfSearchResults = get(
          response,
          "data.listOfSearchResults",
          []
        );
        if (listOfSearchResults) {
          listOfSearchResults.map(sr => {
            // inner list (one row)
            const listOfMovieDetails = get(sr, "searchResults", []);
            this.extendMovies(listOfMovieDetails);
          });
          successCallback(listOfSearchResults);
        } else {
          errorCallback(response);
        }
      }
    );
  }

  /**
   * clear the cache for a movie (e.g., after rating)
   */
  invalidate(movieId: number) {
    this.movieCache.delete(movieId);
  }
}
