import { Component, HostListener, Input, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import "./dropdown.scss";

import * as $ from "jquery";

@Component({
  selector: "dropdown",
  template: require("./dropdown.html")
})
export class DropdownComponent {
  @Input() buttonClass: string = "btn btn-default";
  @Input() buttonContent: string = "toggle";
  @Input() offset: string = "0px";
  @Input() name: string = "default";
  @Input() live: boolean = false;
  @Input() insideClose: boolean = false; // should a click anywhere inside the menu close the widget
  @Input() maxWidth: number = 650;
  justToggled = false;
  style: any = {};
  dropdownWidth: number = 0;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.live) {
      this.refreshStyle();
    }
  }

  @ViewChild('dropdown')
  dropdown: ElementRef;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.live) {
      this.refreshStyle();
    }
  }

  refreshStyle() {
    setTimeout(() => {
      this.dropdownWidth = this.dropdown.nativeElement.offsetWidth;
      this.style = this.getStyle();
    }, 1);
  }

  menuClick() {
    if (this.insideClose) { 
      this.closeMenu();
    }
  }

  outsideClick() {
    // just toggled is necessary because clicking on the toggle button counts as
    // an outside click to prevent layout thrashing
    if (this.justToggled) {
      this.justToggled = false;
    } else {
      this.closeMenu();
    }
  }

  openMenu() {
    this.live = true;
    this.refreshStyle();
  }

  closeMenu() {
    this.live = false;
  }

  toggle() {
    if (this.live) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
    this.justToggled = true;
  }

  normalizedName() {
    // ids can't have certain characters
    var result = "dropdown";
    for (var ix = 0; ix < this.name.length; ix++) {
      if (this.name.substring(ix, ix + 1).match(/^[a-z]+$/i) !== null) {
        result += this.name.substring(ix, ix + 1);
      }
    }
    return result;
  }

  offLeft() {
    if (!$("#" + this.normalizedName() + "Content")) {
      return false;
    }
    var distanceToLeft =
      $("#" + this.normalizedName()).offset().left +
      $("#" + this.normalizedName()).width() / 2;
    return this.dropdownWidth / 2 >= distanceToLeft;
  }

  offRight() {
    if (!$("#" + this.normalizedName() + "Content")) {
      return false;
    }
    var distanceToRight =
      $(window).width() -
      $("#" + this.normalizedName()).offset().left -
      $("#" + this.normalizedName()).width() / 2;
    return this.dropdownWidth / 2 >= distanceToRight;
  }


  /**
   * generates the translation CSS for a dropdown window such that it
   * does not overflow off the screen
   * @returns the css necessary to transform
   */
  getStyle() {
      if (this.offLeft()) { //case if window is off left side
        var distance =
          "-" + String($("#" + this.normalizedName()).offset().left) + "px";
        return {
          left: "0%",
          transform:
            "translateX(" + distance + ") translateY(" + this.offset + ")"
        };
      } else if (this.offRight()) { //case if window is off right side
        var distance =
          String(
            $(window).width() -
              $("#" + this.normalizedName()).offset().left -
              this.dropdownWidth
          ) + "px";
        return {
          left: "0%",
          transform:
            "translateX(" + distance + ") translateY(" + this.offset + ")"
        };
      } else { //default case, if there is no translation necessary
        return {
          left: "50%",
          transform: "translateX(-50%) translateY(" + this.offset + ")"
        };
      }
    }
  

  sanitizeButtonContent() {
    return this.sanitizer.bypassSecurityTrustHtml(this.buttonContent);
  }
}
