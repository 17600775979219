"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import { DropdownModule } from "../../components/dropdown/dropdown.module";
import { TagModule } from "../../components/tag/tag.module";

import { UserTagsPageComponent } from "./user-tags-page.component";
import {UIRouterModule} from "@uirouter/angular";

const userTagsPage = {
  name: "base.yourTags",
  url: "/profile/tags",
  component: UserTagsPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    DropdownModule,
    TagModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [userTagsPage] })
  ],
  declarations: [UserTagsPageComponent]
})
export class UserTagsModule {}
