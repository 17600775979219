import { Injectable } from "@angular/core";
import { ApiService } from "../../common/api/api.service";

@Injectable()
export class ExternalrecsStatusPageService {
  constructor(private apiService: ApiService) {}

  getExternalRecsWorking(successCallback, errorCallback) {
    this.apiService.ml4Get(
      " GET /api/externalrecs/get-external-rec-on",
      "/api/externalrecs/get-external-rec-on  ",
      {},
      value => {
        successCallback(value);
      },
      error => {
        errorCallback(error);
      }
    );
  }

  setExternalRecsWorking(data, successCallback, errorCallback) {
    this.apiService.ml4Post(
      "POST /api/externalrecs/change-external-rec-on",
      "/api/externalrecs/change-external-rec-on",
      data,
      value => {
        successCallback(value);
      },
      error => {
        errorCallback(error);
      }
    );
  }
}
