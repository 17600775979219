"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";

import { ApiService } from "../../common/api/api.service";
import { UrlsService } from "../../common/urls/urls.service";
import { FlashService } from "../../common/flash/flash.service";
import { SessionService } from "../../common/session/session.service";

@Component({
  selector: "import-export-page",
  template: `
    <div class="import-export">

      <h1>import your ratings from IMDb</h1>

      <div>
        <p>
          To import your IMDb ratings into MovieLens, follow these steps:
        </p>
        <ol>
          <li>
            Go to <a href="http://imdb.com" target="_blank">IMDb.com</a> and sign in.
          </li>
          <li>
            Click your name (top right) to open a dropdown menu, select "Your Ratings".
          </li>
          <li>
            Click the three dots to the right of "Your Ratings", and select "Export". It should save your ratings as ratings.csv.
          </li>
          <li>
            Upload this file, then click "import".
          </li>
        </ol>
        <p>
          Importing your ratings may take some time, depending on how many ratings you have. MovieLens will not override
          existing ratings with imported ratings. MovieLens will skip any movies that are not in its database (the IMDb
          database is more comprehensive). If the import is successful we will show you a report with statistics, and a
          list of movies we could not import.
        </p>
      </div>

      <form role="form" name="importForm" (ngSubmit)="importRatings()">

        <div class="form-group file-field" style="margin-top: 24px;">
          <label class="control-label">
            select your imdb ratings file here, then click "import"
            
            <input type="file" id="infile" accept=".csv" required
                   (change)="handleFileInput($event.target.files)">
            
          </label>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-default" [disabled]="fileToUpload === undefined || thinking">import</button>
          <span style="margin-left: 5px;" [hidden]="!thinking">
            <i class="fa fa-cog fa-spin"></i>
          </span>
        </div>

      </form>

      <div class="card" *ngIf="importResults">
        <div class="card-header">
          <h3 class="card-title">Import Statistics</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>found {{ importResults.totalCount }} total ratings to import.</li>
            <li>imported {{ importResults.successCount }} ratings.</li>
            <li>ignored {{ importResults.alreadyRatedCount }} ratings that you've already made in MovieLens.</li>
            <li>{{ importResults.errorCount }} ratings could not be imported (these movies do not
              exist in MovieLens, see below).</li>
          </ul>
        </div>
      </div>

      <div class="card" *ngIf="importResults && importResults.errorCount > 0">
        <div class="card-header">
          <h3 class="card-title">Could Not Import These Ratings</h3>
        </div>
        <div class="card-body">
          The following ratings could not be imported because there is no matching movie in MovieLens.
        </div>
        <table class="table">
          <tr><th>imdbId</th><th>your rating (1-10)</th><th>title</th></tr>
          <tr *ngFor="let err of importResults.errorImdbRatings">
            <td><a href="{{ err.imdbUrl }}" target="_blank">{{ err.imdbId }}</a></td>
            <td>{{ err.rating }}</td>
            <td>{{ err.title }}</td>
          </tr>
        </table>
      </div>

      <h1>
        export your data
      </h1>

      <div>
        <p>
          Click these buttons to download your personal data that is stored by MovieLens
          as a <code>.csv</code> file.
        </p>
      </div>

      <div class="export-buttons">
        <a target="_self" class="btn btn-default" href="/api/users/me/movielens-ratings.csv" download="movielens-ratings.csv">export ratings</a>

        <a target="_self" class="btn btn-default" href="/api/users/me/movielens-tags.csv" download="movielens-tags.csv">export tags</a>

        <a target="_self" class="btn btn-default" href="/api/users/me/movielens-wishlist.csv" download="movielens-wishlist.csv">export wishlist</a>

        <a target="_self" class="btn btn-default" href="/api/users/me/movielens-logs.csv" download="movielens-logs.csv">export activity logs</a>
      </div>

    </div>

  `,
  styles: [
    `
      .export-buttons a {
        margin-right: 10px;
      }
    `
  ]
})
export class ImportExportPageComponent {
  importResults;
  fileToUpload: File;
  thinking = false;

  constructor(
    private apiService: ApiService,
    private flashService: FlashService,
    private sessionService: SessionService,
    private titleService: Title,
    private urlsService: UrlsService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Import or Export Data | MovieLens");
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  importRatings = () => {
    this.thinking = true;

    const formData: FormData = new FormData();
    formData.append("file", this.fileToUpload);

    this.apiService.ml4Post(
      "POST /api/actions/imdb-import",
      "/api/actions/imdb-import",
      formData,
      rsp => {
        this.thinking = false;
        const errorImdbRatings = get(rsp.data, "errorImdbRatings", []);

        errorImdbRatings.map(errorRating => {
          errorRating.imdbUrl = this.urlsService.imdbUrl(errorRating.imdbId);
        });

        this.sessionService.reloadSession();

        this.importResults = rsp.data;
        this.flashService.addSuccess("Great! Your ratings have been imported.");
        this.flashService.show();
      },
      () => {
        this.thinking = false;
        this.flashService
          .addError("Sorry, a problem occurred while importing your ratings.")
          .show();
      }
    );
  };
}
