import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class AddMovieService {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  suggestImdbUrl(data, successCallback, errorCallback) {
    this.http.post("/api/actions/maint/check-suggestion", data).subscribe(
      response => {
        successCallback(response);
      },
      response => {
        errorCallback(response);
      },
      () => {
        //console.log("Check movie post complete");
      }
    );
  }

  addMovie(data, successCallback, errorCallback) {
    this.http.post("/api/actions/maint/add-movie", data).subscribe(
      response => {
        successCallback(response);
      },
      response => {
        errorCallback(response);
      },
      () => {
        //console.log("Add movie post complete");
      }
    );
  }

  trustUrl(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  trustResourceUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
