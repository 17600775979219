"use strict";

import { NgModule } from "@angular/core";
import { AnalyticsModule } from "./analytics/analytics.module";
import { ApiModule } from "./api/api.module";
import { ApiActivityModule } from "./api-activity/api-activity.module";
import { DeferredUrlModule } from "./deferred-url/deferred-url.module";
import { FlashModule } from "./flash/flash.module";
import { GenresModule } from "./genres/genres.module";
import { LoggerModule } from "./logger/logger.module";
import { MovieDataModule } from "./movie-data/movie-data.module";
import { PipesModule } from "./pipes/pipes.module";
import { TagDataModule } from "./tag-data/tag-data.module";
import { SessionModule } from "./session/session.module";
import { UrlsModule } from "./urls/urls.module";
import { UserActionsModule } from "./user-actions/user-actions.module";
import { WindowModule } from "./window/window.module";
import { WsModule } from "./ws/ws.module";
import { UserDataModule } from "./user-data/user-data.module";
import { RectuneModule } from "./rectune/rectune.module";
import { UserListsModule } from "./user-lists/user-lists.module";

@NgModule({
  imports: [
    AnalyticsModule,
    ApiModule,
    ApiActivityModule,
    DeferredUrlModule,
    FlashModule,
    GenresModule,
    LoggerModule,
    MovieDataModule,
    PipesModule,
    RectuneModule,
    SessionModule,
    TagDataModule,
    UrlsModule,
    UserActionsModule,
    UserDataModule,
    UserListsModule,
    WindowModule,
    WsModule
  ]
})
export class MLCommonModule {}
