"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StateService } from "@uirouter/core";

import { FlashService } from "../../common/flash/flash.service";
import { UserDataService } from "../../common/user-data/user-data.service";
import { SessionService } from "../../common/session/session.service";

@Component({
  selector: "account-settings-page",
  template: `
    <div class="account-settings-page">
      <h1>account settings</h1>

      <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>
            email
            <div
              class="validation-msg"
              *ngIf="email.invalid && (email.dirty || email.touched)"
            >
              <span class="small error" *ngIf="email.errors.required"
                >please enter an email address</span
              >
              <span class="small error" *ngIf="email.errors.email"
                >please enter a valid email address</span
              >
              <span class="small error" *ngIf="email.errors.notUnique"
                >already in use</span
              >
              <span class="small error" *ngIf="email.errors.serverError"
                >server error (sorry! try back later)</span
              >
            </div>
            <span class="small" *ngIf="email.pending"
              ><i class="fa fa-cog fa-spin"></i
            ></span>
            <span class="small success" *ngIf="email.valid && email.dirty"
              ><i class="fa fa-check"></i
            ></span>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              autofocus
              required
            />
          </label>
        </div>

        <div class="form-group">
          <label>
            username
            <div
              class="validation-msg"
              *ngIf="username.invalid && (username.dirty || username.touched)"
            >
              <span class="small error" *ngIf="username.errors.required"
                >please enter a username</span
              >
              <span class="small error" *ngIf="username.errors.minlength"
                >username must be at least 3 characters</span
              >
              <span class="small error" *ngIf="username.errors.maxlength"
                >username must be at most 30 characters</span
              >
              <span class="small error" *ngIf="username.errors.notUnique"
                >already in use</span
              >
              <span class="small error" *ngIf="username.errors.serverError"
                >server error (sorry! try back later)</span
              >
            </div>
            <span class="small" *ngIf="username.pending"
              ><i class="fa fa-cog fa-spin"></i
            ></span>
            <span class="small success" *ngIf="username.valid && username.dirty"
              ><i class="fa fa-check"></i
            ></span>
            <input
              type="text"
              class="form-control"
              formControlName="username"
              minlength="3"
              maxlength="30"
              required
            />
          </label>
        </div>

        <div class="form-group" style="margin-top: 15px;">
          <button
            type="submit"
            class="btn btn-default"
            [disabled]="theForm.invalid || thinking"
          >
            update
          </button>
          <span style="margin-left: 5px;" [hidden]="!thinking">
            <i class="fa fa-cog fa-spin"></i>
          </span>
        </div>
      </form>

      <!--<pre>{{ $ctrl.model | json }}</pre>-->
    </div>
  `
})
export class AccountSettingsPageComponent implements OnInit {
  private theForm: FormGroup;

  initialEmail: string;
  initialUsername: string;

  constructor(
    private fb: FormBuilder,
    private flashService: FlashService,
    private sessionService: SessionService,
    private stateService: StateService,
    private titleService: Title,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Account Settings | MovieLens");

    const userData = this.sessionService.getUserData();
    this.initialEmail = get(userData, "account.email", "");
    this.initialUsername = get(userData, "account.userName", "");

    this.theForm = this.fb.group({
      email: [
        "",
        {
          validators: [Validators.required, Validators.email],
          asyncValidators: this.userDataService.getEmailIsUniqueValidator(
            this.initialEmail
          ),
          // run validators when field is blurred, rather than on change
          updateOn: "blur"
        }
      ],
      username: [
        "",
        {
          validators: [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30)
          ],
          asyncValidators: this.userDataService.getUsernameIsUniqueValidator(
            this.initialUsername
          ),
          updateOn: "blur"
        }
      ]
    });

    this.email.setValue(this.initialEmail);
    this.username.setValue(this.initialUsername);
  }

  get email() {
    return this.theForm.get("email");
  }
  get username() {
    return this.theForm.get("username");
  }

  onSubmit() {
    this.userDataService.update(
      { email: this.email.value, loginName: this.username.value },
      () => {
        this.flashService
          .addSuccess("Well done. We have changed your account info.")
          .show();

        this.initialEmail = this.email.value;
        this.initialUsername = this.username.value;
        this.sessionService.reloadSession();
      }
    );
  }
}
