"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "youtube-modal",
  template: `
    <modal-dialog (onClose)="onClose.emit()" *ngIf="doShow" cls="modal-lg" mode="dark">
      <div title>youtube!</div>
      <div main>
        <youtube-embed [ytid]="ytid" autoplay="true"></youtube-embed>
      </div>
    </modal-dialog>
  `
})
export class YoutubeModalComponent {
  @Input() ytid: string;
  @Input() doShow: boolean = false;

  @Output() onClose = new EventEmitter<null>();
}
