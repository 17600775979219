"use strict";

import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StateService } from "@uirouter/core";

import { get } from "lodash";

import { DeferredUrlService } from "../../common/deferred-url/deferred-url.service";
import { LoginService } from "./login.service";

/**
 * attempt to login and redirect to a destination url without user input.
 * If that fails, send user to login page.
 */
@Component({
  selector: "auto-login",
  template: `<div class="auto-login"></div>`
})
export class AutoLoginComponent {
  constructor(
    private deferredUrlService: DeferredUrlService,
    private http: HttpClient,
    private loginService: LoginService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.doRedirect();
  }

  doRedirect() {
    const params = this.stateService.params;

    // For password reset emails and experimental invites
    let token = params.token;

    // For email verification links
    let emailValidation = params.emailValidation;

    // where to go after login
    // example for testing: /go?to=%2Fexplore%3Flist%3Dwishlist%26sortBy%3DuserListedDate&token=foo
    let redirectUrl = params.to;
    if (redirectUrl) {
      this.deferredUrlService.setNextUrl(redirectUrl, params.direct);
    }

    if (token) {
      let callback = emailValidation
        ? () => {
            this.validateEmail(emailValidation);
          }
        : null;
      this.loginWithToken(token, callback);
    } else {
      this.checkLoginStatus();
    }
  }

  loginWithToken(token, afterLoginCallback) {
    this.http.post("/api/sessions", { token: token }).subscribe(
      () => {
        this.loginService.loginSuccessRedirect();
        if (afterLoginCallback) {
          afterLoginCallback();
        }
      },
      response => {
        this.loginService.loginFailureRedirect();
      }
    );
  }

  checkLoginStatus() {
    this.http.get("/api/users/me").subscribe(
      response => {
        const email = get(response, "data.account.email");
        if (email) {
          this.loginService.loginSuccessRedirect();
        } else {
          console.error(
            "missing username from session details",
            get(response, "data")
          );
          this.loginService.loginFailureRedirect();
        }
      },
      () => {
        this.loginService.loginFailureRedirect();
      }
    );
  }

  validateEmail(emailValidationToken) {
    this.http
      .post("/api/actions/user/validate-email", {
        emailValidation: emailValidationToken
      })
      .subscribe(
        () => {
          // do nothing
        },
        response => {
          console.error("Unable to verify email address");
        }
      );
  }
}
