"use strict";

import { Component } from "@angular/core";
import { get } from "lodash";

import "./profile-template.scss";

@Component({
  selector: "profile-template",
  template: `
    <div class="profile-tmpl">
      <div class="row profile-top">
        <div class="col-sm-2 left-nav">
          <h4>account</h4>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.profile.accountSettings">account settings</a>
            </li>
          </ul>
          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.profile.newPassword">change password</a>
            </li>
          </ul>
          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.profile.notifications">notifications</a>
            </li>
          </ul>
          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.profile.theme">theme</a>
            </li>
          </ul>
          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.profile.deleteAccount">delete account</a>
            </li>
          </ul>

          <h4>actions</h4>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.profile.importOrExport">import or export</a>
            </li>
          </ul>
        </div>
        <!-- end col -->

        <div class="col-sm-10 main-profile-content">
          <ui-view ></ui-view>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  `
})
export class ProfileTemplateComponent {}
