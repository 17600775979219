"use strict";

import { Component, Input, OnInit } from "@angular/core";
import { StateService, StateParams } from "@uirouter/core";
import { cloneDeep, get, filter, find, forOwn, keys, pickBy } from "lodash";

import "./explore-control-bar.scss";

const ALL_SEARCH_TOOLS = [
  { key: "searchTermTool", label: "title search", current: null },
  { key: "genreTool", label: "genre", current: null },
  { key: "tagTool", label: "tag", current: null },
  { key: "peopleTool", label: "actor/director search", current: null },
  { key: "mpaaTool", label: "mpaa", current: null },
  { key: "popularityTool", label: "# ratings", current: null },
  { key: "releaseDateTool", label: "release date", current: null },
  { key: "ratedStatusTool", label: "rated status", current: null },
  { key: "hiddenStatusTool", label: "hidden status", current: null },
  { key: "wishlistStatusTool", label: "wishlist status", current: null }
];

const ALL_SORT_OPTIONS = [
  // always available
  {
    key: "prediction",
    label: "recommended",
    isReversible: true,
    isVisible: true
  },
  {
    key: "popularity",
    label: "# ratings",
    isReversible: true,
    isVisible: true
  },
  {
    key: "releaseDate",
    label: "release date",
    isReversible: true,
    isVisible: true
  },
  {
    key: "dateAdded",
    label: "date added to MovieLens",
    isReversible: true,
    isVisible: true
  },
  // conditionally available
  {
    key: "userListedDate",
    label: "date added to list",
    isReversible: true,
    isVisible: false
  },
  {
    key: "userRatedDate",
    label: "date rated",
    isReversible: true,
    isVisible: false
  },
  {
    key: "userRating",
    label: "your rating",
    isReversible: true,
    isVisible: false
  },
  {
    key: "userRatingDiff",
    label: "your rating vs. average",
    isReversible: true,
    isVisible: false
  },
  {
    key: "tagScore",
    label: "tag popularity",
    isReversible: true,
    isVisible: false
  },
  {
    key: "title",
    label: "alphabetical order (title)",
    isReversible: true,
    isVisible: true
  }
];

@Component({
  selector: "explore-control-bar",
  template: `
    <div class="explore-control-bar">

      <div class="control-group not-spaced" *ngIf="!controlsAreVisible">
        {{ totalItems | i18nPlural:moviePlural }}
        <button type="button" class="btn btn-default"
                (click)="showSearchTools()">show search tools
        </button>
      </div>
      <div class="control-group not-spaced" *ngIf="controlsAreVisible">
        view:
        
        <div class="btn-group">
          <tooltip msg="grid view">
            <button type="button" class="btn btn-default btn-xs"
                    (click)="selectView('grid')"><span class="fa fa-th-large"></span></button>
          </tooltip>
          <tooltip msg="list view">
            <button type="button" class="btn btn-default btn-xs not-spaced"
                    (click)="selectView('list')"><span class="fa fa-list"></span></button>
          </tooltip>
        </div>
      </div>
      
      <div class="control-group" *ngIf="controlsAreVisible">
        filters:
        
        <!-- list of current filters -->
        <div *ngFor="let tool of allSearchTools">
          <button type="button" class="btn btn-default"
                  *ngIf="tool.current"
                  (click)="showSearchTool(tool.key)"
          >{{ tool.current }} <span class="caret"></span></button>
        </div>

        <!-- more filters dropdown -->
        <dropdown-new>
          <ng-container dropdownTrigger>
            <button type="button" class="btn btn-default">more <span class='caret'></span></button>
          </ng-container>
          <ng-container dropdownContent>
            <li *ngFor="let tool of allSearchTools"><a (click)="showSearchTool(tool.key)">{{ tool.label }}</a></li>
          </ng-container>
        </dropdown-new>
      </div>
      
      <div class="control-group sort-group">
        sort by:
        
        <!-- sort by pulldown (compact view, left align) -->
        <dropdown-new class="sort-by-dropdown-compact">
          <ng-container dropdownTrigger>
            <button type="button" class="btn btn-default">{{ currentSortLabel }} <span class="fa fa-caret-down"></span>
            </button>
          </ng-container>
          <ng-container dropdownContent>
            <li *ngFor="let option of availableSortOptions"><a (click)="changeSort(option.key)">{{ option.label }}</a></li>
          </ng-container>
        </dropdown-new>

        <!-- sort by pulldown (default view, right align) -->
        <dropdown-new [alignRight]="true" class="sort-by-dropdown-default">
          <ng-container dropdownTrigger>
            <button type="button" class="btn btn-default">{{ currentSortLabel }} <span class="fa fa-caret-down"></span>
            </button>
          </ng-container>
          <ng-container dropdownContent>
            <li *ngFor="let option of availableSortOptions"><a (click)="changeSort(option.key)">{{ option.label }}</a></li>
          </ng-container>
        </dropdown-new>

        <!-- sort direction button -->
        <div *ngIf="currentSort && currentSortIsReversible">
          <button type="button" class="btn btn-default">
              <span class="fa"
                    [ngClass]="{'fa-sort-amount-desc': currentSortDir=='desc', 'fa-sort-amount-asc': currentSortDir=='asc'}"
                    (click)="changeSortDir()"></span>
          </button>
        </div>
      </div>

      <explore-control-tools 
          [qp]="qp" 
          [visibleTool]="visibleTool" 
          (onChange)="onChange($event)" 
          (onHideTools)="onHideTools()"></explore-control-tools>

    </div>
  `
})
export class ExploreControlBarComponent implements OnInit {
  @Input() totalItems: number = null;

  controlsAreVisible: boolean = false;

  moviePlural: any = {
    "=0": "no movies found.",
    "=1": "1 movie found.",
    other: "found # movies."
  };

  allSearchTools = [];

  allSortOptions = [];
  currentSort = null;
  currentSortDir = null;

  qp = null;

  visibleTool = null;

  constructor(private stateService: StateService) {}

  ngOnInit(): void {
    this.qp = this.stateService.params;
    this.initSearchTools(this.qp);
    this.initSortTools(this.qp);
    // console.log("init params:", JSON.stringify(this.qp, null, 2));
  }

  get availableSortOptions() {
    return filter(this.allSortOptions, { isVisible: true });
  }

  get currentSortLabel() {
    return get(this.currentSort, "label");
  }

  get currentSortIsReversible() {
    return get(this.currentSort, "isReversible", false);
  }

  initSearchTools(qp) {
    this.allSearchTools = cloneDeep(ALL_SEARCH_TOOLS);

    if (qp.q) {
      const tool = find(this.allSearchTools, { key: "searchTermTool" });
      tool.current = `title: ${qp.q}`;
    }
    if (qp.genre) {
      const tool = find(this.allSearchTools, { key: "genreTool" });
      tool.current = `genre: ${qp.genre.replaceAll(",", ", ")}`;
    }
    if (qp.tag) {
      const tool = find(this.allSearchTools, { key: "tagTool" });
      tool.current = `tag: ${qp.tag}`;
    }
    if (qp.people) {
      const tool = find(this.allSearchTools, { key: "peopleTool" });
      tool.current = `name: ${qp.people}`;
    }
    if (qp.mpaa) {
      const tool = find(this.allSearchTools, { key: "mpaaTool" });
      let s = qp.mpaa.toUpperCase().replace(/,/g, "/");
      tool.current = `mpaa: ${s}`;
    }
    if (qp.minPop || qp.maxPop) {
      const tool = find(this.allSearchTools, { key: "popularityTool" });
      let s = "";
      if (qp.minPop && !qp.maxPop) {
        s = `> ${qp.minPop}`;
      } else if (!qp.minPop && qp.maxPop) {
        s = `< ${qp.maxPop}`;
      } else if (qp.minPop && qp.maxPop) {
        s = `${qp.minPop} - ${qp.maxPop}`;
      }
      tool.current = `# ratings: ${s}`;
    }
    if (qp.minYear || qp.maxYear || qp.maxDaysAgo || qp.maxFutureDays) {
      const tool = find(this.allSearchTools, { key: "releaseDateTool" });
      let s = "";
      if (qp.minYear && !qp.maxYear) {
        s = `> ${qp.minYear}`;
      } else if (!qp.minYear && qp.maxYear) {
        s = `< ${qp.maxYear}`;
      } else if (qp.minYear && qp.maxYear) {
        s = `${qp.minYear} - ${qp.maxYear}`;
      } else if (!qp.maxDaysAgo && qp.maxFutureDays) {
        s = `up to ${qp.maxFutureDays} days in the future`;
      } else if (qp.maxDaysAgo) {
        // awkward to incorporate future days into summary
        s = `< ${qp.maxDaysAgo} days ago`;
      }
      tool.current = `release date: ${s}`;
    }

    const boolFilterSummary = queryParam => {
      if (queryParam === "yes") {
        return "only";
      } else if (queryParam === "ignore") {
        return "show";
      } else {
        return "hide";
      }
    };

    if (qp.hasRated && qp.hasRated !== "ignore") {
      const tool = find(this.allSearchTools, { key: "ratedStatusTool" });
      tool.current = `rated movies: ${boolFilterSummary(qp.hasRated)}`;
    }
    if (qp.hasHidden && qp.hasHidden !== "no") {
      const tool = find(this.allSearchTools, { key: "hiddenStatusTool" });
      tool.current = `hidden movies: ${boolFilterSummary(qp.hasHidden)}`;
    }
    if (qp.hasWishlisted && qp.hasWishlisted !== "ignore") {
      const tool = find(this.allSearchTools, { key: "wishlistStatusTool" });
      tool.current = `wishlisted movies: ${boolFilterSummary(
        qp.hasWishlisted
      )}`;
    }
  }

  initSortTools(qp) {
    this.allSortOptions = cloneDeep(ALL_SORT_OPTIONS);

    // sortBy=popularity&sortDirection=asc
    const qpSortBy = get(qp, "sortBy");
    const qpSortDir = get(qp, "sortDirection", "desc");

    this.currentSort = find(this.allSortOptions, { key: qpSortBy });
    if (this.currentSort) {
      // only set sort dir if current sort is set
      this.currentSortDir = qpSortDir;
    }

    const makeToolVisible = key => {
      const tool = find(this.allSortOptions, { key });
      tool.isVisible = true;
    };

    if (qp.hasWishlisted && qp.hasWishlisted !== "ignore") {
      makeToolVisible("userListedDate");
    }
    if (qp.tag) {
      makeToolVisible("tagScore");
    }
    if (qp.hasRated === "yes") {
      makeToolVisible("userRatedDate");
      makeToolVisible("userRating");
      makeToolVisible("userRatingDiff");
    }
  }

  showSearchTool(key) {
    this.visibleTool = key;
  }

  showSearchTools() {
    this.controlsAreVisible = true;
  }

  selectView(modeDesc: string) {
    localStorage.setItem("explore-mode", modeDesc);
    this.stateService.reload();
  }

  changeSort(sortByKey) {
    this.stateService.params.sortBy = sortByKey;
    this.stateService.params.page = null;
    delete this.stateService.params.sortDirection;
    this.stateService.reload();
  }

  changeSortDir() {
    this.stateService.params.sortDirection =
      this.currentSortDir === "desc" ? "asc" : "desc";
    this.stateService.params.page = null;
    this.stateService.reload();
  }

  onChange(params) {
    let p: StateParams = this.stateService.params;
    forOwn(params, function(val, key) {
      p[key] = val;
    });

    // always reset page number when we change a filter
    p["page"] = null;
    this.stateService.reload();
  }

  onHideTools() {
    this.visibleTool = null;
  }
}
