"use strict";

import { Injectable } from "@angular/core";
import { get } from "lodash";

import { StateService } from "@uirouter/core";

@Injectable()
export class LoginService {
  constructor(private stateService: StateService) {}

  loginSuccessRedirect() {
    this.stateService.go("afterLoginRedirect", {}, { location: true });
  }

  loginFailureRedirect() {
    this.stateService.go("auth.login");
  }
}
