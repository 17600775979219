import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { StateService } from "@uirouter/core";
import { SessionService } from "../../common/session/session.service";
import { HttpClient } from "@angular/common/http";
import { FlashService } from "../../common/flash/flash.service";

import { DropdownComponent } from "../../components/dropdown/dropdown.component";

import "./base-user-dropdown.scss";
import "./base-template.scss";

@Component({
  selector: "base-user-dropdown",
  template: require("./base-user-dropdown.html")
})
export class BaseUserDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;

  constructor(
    private stateService: StateService,
    private sessionService: SessionService,
    private flash: FlashService,
    private http: HttpClient
  ) {}

  email: string = "";
  subscription = null;
  ngOnInit() {
    this.subscription = this.sessionService.currentUserData.subscribe(val => {
      this.email = val.account.email;
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  buttonClass = "btn base-compact-header-btn btn-sm";
  buttonContent = `<i class="fa fa-user"> <i class="fa fa-caret-down"></i></i>`;
  offset = "8px";
  name = "baseUserDropdown";

  logout() {
    this.http.delete("/api/sessions/me").subscribe(val => {
      this.flash.addInfo("successfully logged out").show();
      this.stateService.transitionTo("auth.login");
    });
  }
}
