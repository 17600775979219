"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TooltipXModule } from "../tooltip/tooltip-x.module";

import { RatingComponent } from "./rating.component";
import { RatingSmartComponent } from "./rating-smart.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule, TooltipXModule],
  exports: [RatingComponent, RatingSmartComponent],
  declarations: [RatingComponent, RatingSmartComponent],
  entryComponents: [RatingSmartComponent]
})
export class RatingModule {}
