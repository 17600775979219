"use strict";

import { MovieDataService } from "../../common/movie-data/movie-data.service";

const d3 = require("d3");
import { get, sortBy } from "lodash";

import "./about-your-ratings-page.scss";
import { Component, OnInit } from "@angular/core";

import { Title } from "@angular/platform-browser";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "about-your-ratings-page",
  template: require("./about-your-ratings-page.html")
})
export class AboutYourRatingsPageComponent implements OnInit {
  public data;
  public numberRated;
  public userRatingsDistribution;
  public ratingHistory;
  public ratingByReleaseDate;
  public ratingDistributionByGenre;
  public averageRatingDistributionByGenre;
  public listOfSearchResults;
  public popularityDistribution;

  constructor(
    private apiService: ApiService,
    private movieDataService: MovieDataService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("About your ratings");

    this.apiService.ml4Get(
      "GET /api/users/me/ratings/stats",
      "/api/users/me/ratings/stats",
      {},
      response => {
        // get the data for our charts from json (prepared by Movie Handlers)
        this.data = get(response, "data");
        let data = this.data;
        this.numberRated = data.numberRated;
        this.userRatingsDistribution = [data.userRatingsDistribution];
        this.popularityDistribution = data.popularityDistribution;
        this.ratingHistory = [data.ratingHistory];
        this.ratingByReleaseDate = [data.ratingByReleaseDate];
        this.ratingDistributionByGenre = [data.ratingDistributionByGenre];
        this.averageRatingDistributionByGenre = [
          data.averageRatingDistributionByGenre
        ];
        this.listOfSearchResults = data.lists.listOfSearchResults;

        // decorate the movies
        this.listOfSearchResults.map(searchResultContainer => {
          this.movieDataService.extendMovies(
            searchResultContainer.searchResults
          );
        });

        // sorting
        this.userRatingsDistribution[0]["values"] = sortBy(
          this.userRatingsDistribution[0]["values"],
          "label"
        );
        this.ratingHistory[0]["values"] = sortBy(
          this.ratingHistory[0]["values"],
          "label"
        );
        this.ratingByReleaseDate[0]["values"] = sortBy(
          this.ratingByReleaseDate[0]["values"],
          "label"
        );
        this.ratingDistributionByGenre[0]["values"] = sortBy(
          this.ratingDistributionByGenre[0]["values"],
          "label"
        );
        this.averageRatingDistributionByGenre[0]["values"] = sortBy(
          this.averageRatingDistributionByGenre[0]["values"],
          "label"
        );
      }
    );
  }

  readonly userRatingsDistributionOptions = {
    chart: {
      type: "discreteBarChart",
      x: function(d) {
        return d.label;
      },
      y: function(d) {
        return d.value;
      },
      height: 400,
      showValues: true,
      valueFormat: d3.format(",.0f"),
      duration: 350,
      color: function() {
        return "#3bace1";
      },
      tooltip: {
        enabled: false
      },
      xAxis: {
        axisLabel: "rating"
      },
      yAxis: {
        axisLabel: "frequency",
        tickFormat: d3.format(",.0f")
      }
    }
  };

  static readonly popularityPieChartColors = ["#3bace1", "#21b62b", "#f06624"];
  readonly popularityPieChartOptions = {
    chart: {
      type: "pieChart",
      x: function(d) {
        return d.label;
      },
      y: function(d) {
        return d.value;
      },
      height: 350,
      showLabels: true,
      showLegend: true,
      color: function(d, i) {
        let popularityPieChartColors =
          AboutYourRatingsPageComponent.popularityPieChartColors;
        return popularityPieChartColors[i % popularityPieChartColors.length];
      },
      noData: "you have not rated movies",
      tooltip: {
        contentGenerator: function(d) {
          return d.data.label + ": " + d.data.value + " movies";
        }
      }
    }
  };

  readonly ratingHistoryOptions = {
    chart: {
      type: "lineChart",
      x: function(d) {
        return d3.time.format("%Y-%m-%d").parse(d.label);
      },
      y: function(d) {
        return d.value;
      },
      height: 400,
      margin: {
        right: 50
      },
      color: function() {
        return "#ff5000";
      },
      interpolate: "step-after",
      showLegend: false,
      tooltip: {
        contentGenerator: function(d) {
          return (
            "You rated " +
            d.point.value +
            " movies before " +
            d3.time.format("%b %d, %Y")(new Date(d.point.x))
          );
        }
      },
      xAxis: {
        tickFormat: function(d) {
          return d3.time.format("%b, %Y")(new Date(d));
        }
      },
      showYAxis: true,
      yAxis: {
        axisLabel: "number of ratings"
      }
    }
  };

  readonly ratingByReleaseDateOptions = {
    chart: {
      type: "multiBarChart",
      x: function(d) {
        return d.label;
      },
      y: function(d) {
        return d.value;
      },
      height: 400,
      reduceXTicks: true,
      showControls: false,
      color: function() {
        return "#ff5000";
      },
      showLegend: false,
      tooltip: {
        contentGenerator: function(d) {
          var intYear = parseInt(d.data.label);
          return (
            "You have rated " +
            d.data.value +
            " movies from " +
            intYear.toString() +
            "-" +
            (intYear + 5).toString()
          );
        }
      },
      yAxis: {
        axisLabel: "number of rated movies",
        tickFormat: d3.format(",.0f")
      }
    }
  };

  readonly ratingDistributionByGenreOptions = {
    chart: {
      type: "multiBarHorizontalChart",
      x: function(d) {
        return d.label;
      },
      y: function(d) {
        return d.value;
      },
      height: 500,
      margin: {
        left: 110
      },
      showValues: true,
      showControls: false,
      showLegend: false,
      valueFormat: d3.format(",.0f"),
      duration: 350,
      color: function() {
        return "#3bace1";
      },
      tooltip: {
        enabled: false
      },
      xAxis: {},
      yAxis: {
        axisLabel: "number of ratings",
        tickFormat: d3.format(",.0f")
      }
    }
  };

  readonly averageRatingDistributionByGenreOptions = {
    chart: {
      type: "multiBarHorizontalChart",
      x: function(d) {
        return d.label;
      },
      y: function(d) {
        return d.value;
      },
      height: 500,
      margin: {
        left: 110
      },
      showValues: true,
      showControls: false,
      showLegend: false,
      valueFormat: d3.format(",.2f"),
      duration: 350,
      color: function() {
        return "#3bace1";
      },
      tooltip: {
        enabled: false
      },
      xAxis: {},
      yAxis: {
        axisLabel: "average rating",
        tickFormat: d3.format(",.2f")
      },
      yDomain: [0, 5.0]
    }
  };
}
