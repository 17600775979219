import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StateService } from "@uirouter/core";

import "./tag.scss";

@Component({
  selector: "tag",
  template: require("./tag.html")
})
export class TagComponent implements OnInit {
  @Input() tag: string = null;
  @Input() num: number = null;
  @Input() canClose: boolean = true;
  @Output() unapply = new EventEmitter<any>();

  constructor(private stateService: StateService) {}

  unapplyTag() {
    this.unapply.emit(this.tag);
  }

  ngOnInit() {}
}
