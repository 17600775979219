"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";
import { ApiService } from "../../common/api/api.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { UrlsService } from "../../common/urls/urls.service";

import "./admin-movie-flags-page.scss";
import "./admin-movie-page.scss";

@Component({
  selector: "admin-movie-page",
  template: `
    <div class="admin-movie-page" *ngIf="adminMovieData">
      <div class="actions">
        Actions:
        <button *ngIf="adminMovieData.current.tmdbMovieId==0" class="btn btn-warning" uiSref="admin.adminMovieEdit" [uiParams]="{id: movieId}">Edit Legacy Data</button>
        <button class="btn btn-warning" (click)="refreshTmdb()">Refresh tmdb</button>
        <button *ngIf="adminMovieData.current.tmdbMovieId!=0" class="btn btn-warning" (click)="deleteTmdb()">Delete tmdb</button>
        <button *ngIf="adminMovieData?.movieStatus == 'LIVE'" class="btn btn-warning" (click)="delete()">Delete</button>
        <button *ngIf="adminMovieData?.movieStatus != 'LIVE'" class="btn btn-warning" (click)="revive()">Make Live</button>
        <button class="btn btn-warning" disabled="disabled">Merge</button>
      </div>
      <div (mouseleave)="movieData = adminMovieData.current">
        Versions:
        <div
          class="version"
          *ngFor="let version of adminMovieData.history | slice: 0:-2"
          (mouseenter)="movieData = version.viewData"
        >
          {{ version.versionNumber }}
        </div>
        <div class="version" (mouseenter)="movieData = adminMovieData.current">
          (current)
        </div>
      </div>
      <h1>{{ movieData.title }}</h1>

      <div class="movieProperty">
        <h4>Movie ID</h4>
        <span class="value">{{ adminMovieData.movieId }}</span>
      </div>
      <div class="movieProperty">
        <h4>Movie status</h4>
        <span class="value">{{ adminMovieData.movieStatus }}</span>
        <a *ngIf="adminMovieData.movieStatus == 'LIVE'" uiSref="base.movie" [uiParams]="{ id: movieId }"
        >(Live on MovieLens)</a
        >
      </div>
      <div class="movieProperty">
        <h4>Release Year</h4>
        <span class="value">{{ movieData.releaseYear }}</span>
      </div>
      <div class="movieProperty">
        <h4 >TMDB</h4>
        <span class="value" *ngIf="movieData.tmdbMovieId != 0">
          {{ movieData.tmdbMovieId }}
          <a [href]="urlService.tmdbUrl(movieData.tmdbMovieId)">(link)</a>
        </span>
        <span class="value" *ngIf="movieData.tmdbMovieId == 0"> No tmdb data found
        </span>
      </div>
      <div class="movieProperty">
        <h4 >IMDB</h4>
        <span class="value" >
          {{ movieData.imdbMovieId }}
          <a [href]="urlService.imdbUrl(movieData.imdbMovieId)">(link)</a>
        </span>
      </div>
      <div class="movieProperty">
        <h4 >title</h4>
        <span class="value" >{{ movieData.title }}</span>
      </div>
      <div class="movieProperty">
        <h4 >originalTitle</h4>
        <span class="value" >{{ movieData.originalTitle }}</span>
      </div>
      <div class="movieProperty">
        <h4 >releaseYear</h4>
        <span class="value" >{{ movieData.releaseYear }}</span>
      </div>
      <div class="movieProperty">
        <h4 >mpaa</h4>
        <span class="value" >{{ movieData.mpaa }}</span>
      </div>
      <div class="movieProperty">
        <h4 >runtime</h4>
        <span class="value" >{{ movieData.runtime }}</span>
      </div>
      <div class="movieProperty">
        <h4 >releaseDate</h4>
        <span class="value" >{{ movieData.releaseDate }}</span>
      </div>
      <div class="movieProperty">
        <h4 >dvdReleaseDate</h4>
        <span class="value" >{{ movieData.dvdReleaseDate }}</span>
      </div>
      <div class="movieProperty">
        <h4 >genres</h4>
        <span class="value" >{{ movieData.genres }}</span>
      </div>
      <div class="movieProperty">
        <h4 >directors</h4>
        <span class="value" >{{ movieData.directors }}</span>
      </div>
      <div class="movieProperty">
        <h4 >languages</h4>
        <span class="value" >{{ movieData.languages }}</span>
      </div>
      <div class="movieProperty">
        <h4 >actors</h4>
        <span class="value" >{{ movieData.actors }}</span>
      </div>
      <div class="movieProperty">
        <h4 >plotSummary</h4>
        <span class="value" >{{ movieData.plotSummary }}</span>
      </div>
      <div class="movieProperty">
        <h4>wishlistCount</h4>
        <span class="value">{{ adminMovieData.wishlistCount }}</span>
      </div>
      <div class="movieProperty">
        <h4>tagCount</h4>
        <span class="value">{{ adminMovieData.tagCount }}</span>
      </div>
      <div class="movieProperty">
        <h4>numRatings</h4>
        <span class="value">{{ adminMovieData.current.numRatings }}</span>
      </div>
      <div class="movieProperty">
        <h4>avgRating</h4>
        <span class="value">{{ adminMovieData.current.avgRating }}</span>
      </div>
      <div class="movieProperty">
        
      <div>
        <h2>Reports:</h2>
        <button
          class="btn btn-warning"
          style="float:right; margin-right:3em;"
          (click)="markAllFlagsAsResolved()"
        >
          Mark all
        </button>
        <table class="table table-striped table-hover movie-flag-events">
          <tr>
            <th>title</th>
            <th></th>
            <th>flag date</th>
            <th>userId</th>
            <th>mark as<br />done</th>
          </tr>
          <tr
            admin-movie-flag-tr
            *ngFor="let movieFlagEvent of adminMovieData.flags"
            [movieFlagEvent]="movieFlagEvent"
            [ngClass]="{ strikeout: movieFlagEvent.adminHasTakenAction }"
            (onMarkDone)="changeFlagStatus(movieFlagEvent)"
          ></tr>
        </table>
      </div>
      <div>
        <h2>Edit History</h2>
        <table class="table table-striped table-hover">
          <tr>
            <th>version</th>
            <th>movieId</th>
            <th>userId</th>
            <th>editDate</th>
            <th>comment</th>
          </tr>
          <tr
            admin-movie-history-tr
            *ngFor="let version of adminMovieData.history"
            [versionData]="version"
          ></tr>
        </table>
      </div>
    </div>
  `
})
export class AdminMoviePageComponent {
  private movieId: number;
  private adminMovieData: any;
  private movieData: any;

  constructor(
    private titleService: Title,
    private apiService: ApiService,
    private stateService: StateService,
    private urlService: UrlsService
  ) {}

  ngOnInit() {
    this.adminMovieData = false;
    this.titleService.setTitle("Admin | MovieLens | Movie");
    this.movieId = parseInt(this.stateService.params.id);

    this.reloadData();
  }

  reloadData() {
    this.apiService.ml4Get(
      "GET /api/admin/movie/:id",
      "/api/admin/movie/" + this.movieId,
      {},
      response => {
        var data = get(response, "data.adminMovieData");
        console.log(data);
        this.movieData = data.current;
        this.adminMovieData = data;
      }
    );
  }

  markAllFlagsAsResolved() {
    for (var flag of this.adminMovieData.flags) {
      if (!flag.adminHasTakenAction) {
        this.changeFlagStatus(flag);
      }
    }
  }

  changeFlagStatus(movieFlagEvent) {
    var originalStatus = movieFlagEvent.adminHasTakenAction;
    movieFlagEvent.adminHasTakenAction = !originalStatus;
    this.apiService.ml4Post(
      "POST /api/admin/movie-flag-events",
      "/api/admin/movie-flag-events",
      movieFlagEvent,
      response => {
        console.log("Admin action taken flag successfully changed");
      },
      response => {
        console.log("Admin action taken flag failed to change", response);
        movieFlagEvent.adminHasTakenAction = originalStatus;
      }
    );
  }

  delete() {
    this.adminMovieData = null;
    this.movieData = null;
    this.apiService.ml4Post(
      "/api/admin/movie/status",
      "/api/admin/movie/status",
      { movieStatus: "REJECTED", movieId: this.movieId },
      response => {
        console.log("Success");
        this.reloadData();
      },
      response => {
        console.log("Failure");
        this.reloadData();
      }
    );
  }

  revive() {
    if (this.adminMovieData.movieStatus == 'REJECTED' || confirm("Movie status is not REJECTED, and was likely made not live outside of this interface. Are you sure you want to make this live?")) {

      this.adminMovieData = null;
      this.movieData = null;
      this.apiService.ml4Post(
        "/api/admin/movie/status",
        "/api/admin/movie/status",
        { movieStatus: "LIVE", movieId: this.movieId },
        response => {
          console.log("Success");
          this.reloadData();
        },
        response => {
          console.log("Failure");
          this.reloadData();
        }
      );
    }
  }

  refreshTmdb() {
    this.adminMovieData = null;
    this.movieData = null;
    this.apiService.ml4Post(
      "POST /api/admin/movie/:id/update-tmdb",
      "/api/admin/movie/"+this.movieId+"/update-tmdb",
      {},
      response => {this.reloadData()},
      response => {
        alert("failed to update");
        console.log(response);
        this.reloadData();
      }
    )
  }

  deleteTmdb() {
    if(confirm("are you sure you want to delete tmdb data - this may not be reversible")) {
      this.adminMovieData = null;
      this.movieData = null;
      this.apiService.ml4Delete(
        "DELETE /api/admin/movie/:id/tmdb",
        "/api/admin/movie/" + this.movieId + "/tmdb",
        {},
        response => {
          this.reloadData()
        },
        response => {
          alert("failed to delete");
          console.log(response);
          this.reloadData();
        })
    }
  }
}
