"use strict";

export default class Constants {
  // unlock personalized recommender at this number of ratings
  RATING_THRESHOLD: 15;

  // don't enable SVD for a day, since it is not personalized until a model build has happened
  // 87600 == 1 day
  ENABLE_SVD_MIN_SECS: 87600;

  // state names that we do not wish to log to db or analytics
  DO_NOT_LOG_STATES = new Set([
    "admin.adminHome",
    "admin.movieFlagEvents",
    "admin.movie",
    "afterLoginRedirect",
    "auth.autoLoginWithShortCode",
    "exploreGenreShortcut",
    "exploreShortcut"
  ]);

  // XXX: these mirror values from variables.less
  COLORS: {
    blue: "#3bace1",
    grayLight: "#bbb",
    grayLighter: "#ddd",
    orange: "#f06624"
  };
}
