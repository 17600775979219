import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "rating",
  template: `
    <div class="rating" [class.read-only]="readOnly">
      <div class="five-stars" [ngStyle]="getContainerStyle()">
        <tooltip
          msg="{{ movieRatingMeaning }}"
          [ngClass]="getRatingTooltipClass()"
        >
          <div
            *ngFor="let val of values; let odd = odd; index as i"
            class="star-box"
            [ngStyle]="getStarBoxStyle(odd)"
            (click)="onRate.emit(val)"
            (mouseover)="showMovieRatingMeaning(val)"
          >
            <svg
              class="star"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              [ngStyle]="getStarStyle(odd)"
            >
              <svg:path
                class="star-shape"
                [ngClass]="{ predicted: isPredicted(i), rated: isRated(i) }"
                d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 
                         1.48 8.279-7.416-3.967-7.417 3.967 
                         1.481-8.279-6.064-5.828 8.332-1.151z"
              />
            </svg>
          </div>
        </tooltip>
      </div>

      <div
        class="close-box"
        style="padding-left: 3px"
        [class.close-box-invisible]="!doShowCloseBox()"
        (click)="onClearRating.emit()"
      >
        <tooltip
          *ngIf="showTooltip; else notooltip"
          msg="clear your rating"
          class="tooltip-right"
          >&#x27F2;</tooltip
        >
        <ng-template #notooltip><b style="padding-bottom: 3px">&#x27F2;</b></ng-template>
    </div>
  `,
  styles: [
    `
      .rating {
        position: relative;
        text-align: center;
      }

      .five-stars {
        display: inline-block;
        cursor: pointer;
      }

      .star-box {
        display: inline-block;
        overflow: hidden;
      }

      .close-box {
        display: inline-block;
        vertical-align: text-bottom;
        top: 10%;
        padding-bottom: 2px;
        cursor: pointer;
      }
      .close-box-invisible {
        display: inline-block;
        vertical-align: text-bottom;
        top: 10%;
        padding-bottom: 2px;
        visibility: hidden;
        cursor: none;
      }

      /*
       * colors and hover (XXX: colors hardcoded outside of less)
       * see https://stackoverflow.com/a/27993987/293087
       */
      .star-shape {
        fill: #bbb;
        stroke: #808080;
      }
      .star-shape.predicted {
        fill: #3bace1;
      }
      .star-shape.rated {
        fill: #f06624;
      }

      /*
       * hover effects -- wrap in media query to avoid touble-tap issue on IOS
       * see https://css-tricks.com/annoying-mobile-double-tap-link-issue/
       */
      @media (hover) {
        .rating:not(.read-only) .five-stars:hover .star-shape {
          fill: #f06624;
        }

        .rating:not(.read-only)
          .five-stars
          .star-box:hover
          ~ .star-box
          .star-shape {
          fill: #bbb;
        }

        .rating:not(.read-only) .close-box:hover {
          color: #f06624;
        }
      }
    `
  ]
})
export class RatingComponent {
  @Input() predictedRating: number = null;
  @Input() userRating: number = null;
  @Input() size: number = 24;
  @Input() showTooltip: boolean = true;
  @Input() readOnly: boolean = false;
  @Input() ratingTooltipPosition?: string = "bottom";

  @Output() onRate = new EventEmitter<number>();
  @Output() onClearRating = new EventEmitter<null>();

  private predictedRatingRounded = null;
  private values = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  private ratingTooltips = {
    "0.5": "Awful",
    "1": "Awful",
    "1.5": "Poor",
    "2": "Poor",
    "2.5": "Ok",
    "3": "Ok",
    "3.5": "Good",
    "4": "Good",
    "4.5": "Must Watch",
    "5": "Must Watch"
  };

  private movieRatingMeaning = "";

  ngOnInit() {
    if (this.predictedRating) {
      // nearest half-star
      this.predictedRatingRounded = Math.round(this.predictedRating * 2) / 2;
    }
  }

  showMovieRatingMeaning(val) {
    this.movieRatingMeaning = this.ratingTooltips[val];
  }

  getRatingTooltipClass() {
    if (this.ratingTooltipPosition == "top") {
      return "tooltip-top";
    } else {
      return "tooltip-rating";
    }
  }

  getContainerStyle() {
    // buffer of 2 pixels (???)
    const h = this.size + 2;
    return {
      height: `${h}px`
    };
  }

  getStarBoxStyle(odd) {
    // hard code spacing between stars
    const w = Math.floor(this.size / 2.0) + 1;
    const h = this.size + 2;
    if (odd) {
      return {
        height: `${h}px`
      };
    }
    return {
      width: `${w}px`,
      height: `${h}px`,
      "margin-left": `1px`
    };
  }

  getStarStyle(odd) {
    const w = Math.floor(this.size / 2.0);
    const marginLeft = odd ? `-${w}px` : "1px";
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      "margin-left": marginLeft
    };
  }

  doShowCloseBox() {
    return this.userRating && !this.readOnly;
  }

  isPredicted(i) {
    return !this.userRating && this.values[i] <= this.predictedRatingRounded;
  }

  isRated(i) {
    return this.values[i] <= this.userRating;
  }
}
