"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


import { ProfileTemplateComponent } from "./profile-template.component";
import { AccountSettingsPageComponent } from "./account-settings-page.component";
import { DeleteAccountPageComponent } from "./delete-account-page.component";
import { ImportExportPageComponent } from "./import-export-page.component";
import { NewPasswordPageComponent } from "./new-password-page.component";
import { NotificationsPageComponent } from "./notifications-page.component";
import {UIRouterModule} from "@uirouter/angular";
import { ThemePageComponent } from "./theme-page.component";

const profileTemplate = {
  name: "base.profile",
  abstract: true,
  component: ProfileTemplateComponent
};

const accountSettingsPage = {
  name: "base.profile.accountSettings",
  url: "/profile",
  component: AccountSettingsPageComponent
};

const notificationsPage = {
  name: "base.profile.notifications",
  url: "/profile/settings/notifications",
  component: NotificationsPageComponent
};

const themePage = {
  name: "base.profile.theme",
  url: "/profile/settings/theme",
  component: ThemePageComponent
};

const newPasswordPage = {
  name: "base.profile.newPassword",
  url: "/profile/settings/password",
  component: NewPasswordPageComponent
};

const deleteAccountPage = {
  name: "base.profile.deleteAccount",
  url: "/profile/settings/delete-account",
  component: DeleteAccountPageComponent
};

const importExportPage = {
  name: "base.profile.importOrExport",
  url: "/profile/settings/import-export",
  component: ImportExportPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({
      states: [
        profileTemplate,
        accountSettingsPage,
        notificationsPage,
        themePage,
        newPasswordPage,
        deleteAccountPage,
        importExportPage
      ]
    })
  ],
  declarations: [
    ProfileTemplateComponent,
    AccountSettingsPageComponent,
    DeleteAccountPageComponent,
    ImportExportPageComponent,
    NewPasswordPageComponent,
    NotificationsPageComponent,
    ThemePageComponent
  ]
})
export class ProfileModule {}
