
"use strict";

import { NgModule } from "@angular/core";
import {UrlsService} from "./urls.service";

@NgModule({
    providers: [UrlsService]
})
export class UrlsModule {}
