"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TagComponent } from "./tag.component";
import { MovieTagComponent } from "./movie-tag.component";

import { DropdownModule } from "../dropdown/dropdown.module";
import {UIRouterModule} from "@uirouter/angular";

@NgModule({
  imports: [
    BrowserModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule.forChild()
  ],
  exports: [TagComponent, MovieTagComponent],
  declarations: [TagComponent, MovieTagComponent]
})
export class TagModule {}
