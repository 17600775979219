"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DropdownModule } from "../../components/dropdown-new/dropdown.module";
import { TooltipXModule } from "../../components/tooltip/tooltip-x.module";

import { ExploreControlBarComponent } from "./explore-control-bar.component";
import { ExploreControlToolsComponent } from "./explore-control-tools.component";
import { ExploreGenreShortcutComponent } from "./explore-genre-shortcut.component";
import { ExplorePageComponent } from "./explore-page.component";
import { ExploreService } from "./explore.service";
import { ExploreShortcutComponent } from "./explore-shortcut.component";
import { MovieCardModule } from "../../components/movie-card/movie-card.module";
import { MovieTableModule } from "../../components/movie-table/movie-table.module";
import { PagerModule } from "../../components/pager/pager.module";
import { UIRouterModule } from "@uirouter/angular";
import { UrlEncoderTypeModule } from "../../common/urlEncoderTypeModule";

// XXX - parameters do not url decode by default (currently) if a query needs
//     decoding it must be decalred as seen below. (ntoe that is a custom data type)
const queryParams = [
  // display control (should not go to server)
  "{heading:urlDecoded}",
  "{subHeading:urlDecoded}",

  // movie characteristics
  "{q:urlDecoded}",
  "{genre:urlDecoded}",
  "{people:urlDecoded}",
  "{mpaa:urlDecoded}",
  "{multiGenre:urlDecoded}",
  "{languages:urlDecoded}",
  "minPop",
  "maxPop",
  "{tag:urlDecoded}",

  // release dates
  "minYear",
  "maxYear",
  "maxDaysAgo",
  "maxFutureDays",

  // user history
  "{list:urlDecoded}",
  "hasWishlisted",
  "hasHidden",
  "hasRated",
  "{myTag:urlDecoded}",

  // sorting/paging
  "{sortBy:urlDecoded}",
  "{sortDirection:urlDecoded}",
  "page",
  "pageSize"
];
const exploreQueryParams = queryParams.join("&");

const exploreShortcut = {
  name: "exploreShortcut",
  url: "/explore/:id",
  parent: "loggedIn",
  component: ExploreShortcutComponent
};

const exploreGenreShortcut = {
  name: "exploreGenreShortcut",
  url: "/explore/genres/:id",
  parent: "loggedIn",
  component: ExploreGenreShortcutComponent
};

const explorePage = {
  name: "base.explore",
  url: "/explore?" + exploreQueryParams,
  component: ExplorePageComponent
};

@NgModule({
  imports: [
    UrlEncoderTypeModule,
    BrowserModule,
    DropdownModule,
    FormsModule,
    MovieCardModule,
    MovieTableModule,
    PagerModule,
    ReactiveFormsModule,
    TooltipXModule,
    UIRouterModule.forChild({
      states: [exploreShortcut, exploreGenreShortcut, explorePage]
    })
  ],
  declarations: [
    ExploreControlBarComponent,
    ExploreControlToolsComponent,
    ExploreGenreShortcutComponent,
    ExplorePageComponent,
    ExploreShortcutComponent
  ],
  entryComponents: [
    ExploreControlBarComponent,
    ExploreControlToolsComponent,
    ExploreGenreShortcutComponent,
    ExplorePageComponent,
    ExploreShortcutComponent
  ],
  providers: [ExploreService]
})
export class ExploreModule {}
