import { NgZone } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { UrlService } from "@uirouter/core";

import { AppModule } from "./app.module";

import "@angular/platform-browser-dynamic";

// noinspection JSIgnoredPromiseFromCall
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // .then(platformRef => {
  //   const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
  //   // upgrade.bootstrap(document.documentElement, ["ml4App"], { strictDi: true });
  //   upgrade.bootstrap(document.documentElement, ["ml4App"]);
  // });
  .then(platformRef => {
    // get() UrlService from DI (this call will create all the UIRouter services)
    const url: UrlService = platformRef.injector.get(UrlService);

    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
      url.listen();
      url.sync();
    }

    const ngZone: NgZone = platformRef.injector.get(NgZone);
    ngZone.run(startUIRouter);
  });
