"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { FormControl, FormGroup } from "@angular/forms";
import { StateService } from "@uirouter/core";

import { FlashService } from "../../common/flash/flash.service";
import { SessionService } from "../../common/session/session.service";
import { UserDataService } from "../../common/user-data/user-data.service";

@Component({
  selector: "delete-account-page",
  template: `
    <div class="delete-account">
      <h1>delete account</h1>

      <p>
        If you delete your account, you will immediately be logged out, and will
        no longer be able to log in. This action cannot be undone.
      </p>

      <form role="form" [formGroup]="theForm">
        <div class="form-group">
          <button
            class="btn btn-warning"
            (click)="deleteClicked = true"
            *ngIf="!deleteClicked"
          >
            delete account
          </button>

          <div *ngIf="deleteClicked" style="margin-top: 20px;">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label class="control-label" for="deleteAccountConfirmation">
                    Please type "I agree" confirm.
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="confirm"
                    placeholder="I agree"
                    style="margin-bottom: 10px;"
                    autofocus
                  />
                  <button
                    type="submit"
                    class="btn btn-danger"
                    [disabled]="!checkDeleteConfirmation()"
                    (click)="deleteAccount()"
                  >
                    yes, I want to delete my account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  `
})
export class DeleteAccountPageComponent implements OnInit {
  confirm: FormControl = new FormControl("");
  theForm: FormGroup = new FormGroup({
    confirm: this.confirm
  });

  deleteClicked = false;

  constructor(
    private flashService: FlashService,
    private sessionService: SessionService,
    private stateService: StateService,
    private titleService: Title,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Delete your account | MovieLens");
  }

  checkDeleteConfirmation() {
    return this.confirm.value === "I agree";
  }

  deleteAccount() {
    if (this.checkDeleteConfirmation()) {
      this.userDataService.deleteAccount({}, () => {
        this.flashService
          .addSuccess(
            "We have deleted your account. Thanks for using MovieLens!"
          )
          .show();
        this.stateService.go("auth.register");
      });
    }
  }
}
