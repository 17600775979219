import { Component, OnInit } from "@angular/core";
import { ExternalrecsStatusPageService } from "./externalrecs-status-page.service";

@Component({
  selector: "externalrecs-status-page",
  template: require("./externalrecs-status-page.html")
})
export class ExternalrecsStatusPageComponent implements OnInit {
  externalRecServers = {};

  constructor(
    private externalrecsStatusPageService: ExternalrecsStatusPageService
  ) {}

  ngOnInit(): void {
    this.refreshStatus();
  }

  private _successCallback = data => {
    for (let name in data.working) {
      if (data.working.hasOwnProperty(name)) {
        this.externalRecServers[name] = {
          name: name,
          enabled: data.working[name]
        };
      }
    }
  };

  refreshStatus(): void {
    this.externalrecsStatusPageService.getExternalRecsWorking(
      this._successCallback,
      console.error
    );
  }

  setStatus(externalRecServer): void {
    this.externalrecsStatusPageService.setExternalRecsWorking(
      {
        name: externalRecServer.name,
        working: externalRecServer.enabled
      },
      this._successCallback,
      console.error
    );
  }

  switchStatus(externalRecServer): void {
    externalRecServer.enabled = !externalRecServer.enabled;
    this.setStatus(externalRecServer);
  }

  setAll(enabled: boolean): void {
    for (let name in this.externalRecServers) {
      this.externalRecServers[name].enabled = enabled;
      this.setStatus(this.externalRecServers[name]);
    }
  }

  values(x) {
    // Angular and typescript neither like Object.values()
    return Object.keys(x).map(key => x[key]);
  }
}
