import { Component, Input } from "@angular/core";

import "./tooltip.scss";

@Component({
  selector: "tooltip",
  template: `
    <div class="tooltip-component">
      <div class="content-wrapper">
        <div class="tooltip-text small" role="tooltip" [innerHTML]="msg"></div>
        <ng-content></ng-content>
      </div>
    </div>
  `
})
export class TooltipComponent {
  @Input() msg: string;
}
