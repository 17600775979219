"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { sortBy } from "lodash";
import { StateService } from "@uirouter/core";

@Component({
  selector: "routes-page",
  template: `
    <h1>routes</h1>
    <div class="table-responsive">
      <table class="table table-condensed">
        <tr>
          <th>name</th>
          <th>url</th>
        </tr>
        <tr *ngFor="let route of allRoutes">
          <td>{{ route.name }}</td>
          <td>{{ route.url }}</td>
        </tr>
      </table>
    </div>
  `
})
export class RoutesPageComponent {
  allRoutes;

  constructor(
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("App Routes | MovieLens");
    this.allRoutes = sortBy(this.stateService.get(), ["url", "name"]);
  }
}
