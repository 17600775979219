"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { DateFormatterService } from "../../common/date-formatter/date-formatter.service";
import "./movie-page.scss";
import { ImgPreloadDirective } from "../../directives/img-preload/img-preload.directive";

@Component({
  selector: "movie-page",
  template: `
    <div class="movie-alt" *ngIf="movie">
      <div class="row movie-details-header movie-details-block">
        <!-- display only one of the following cols, see ng-if -->
        <div
          class="col-md-5 header-backdrop"
          *ngIf="movie.firstBackdropOrigUrl"
        >
          <i
            class="fa fa-play-circle-o movie-trailer-play cursor-pointer"
            title="Watch Trailer"
            (click)="openYoutubeModal()"
            *ngIf="movie.hasTrailer"
          >
        </i>
          <div style="width: 100%; height: 100%"> 
          <img
            [src]="movie.firstBackdropOrigUrl"
            class="full-width"
            alt="movie backdrop image"
            [ngStyle]="!movie.hasTrailer && { cursor: 'pointer' }"
            (click)="handleBackdropClick()"
            imgPreload
          />
          </div>
        </div>
        <div
          class="col-md-3"
          *ngIf="!movie.firstBackdropOrigUrl && movie.posterW500Url"
        >
          <img
            [src]="movie.posterW500Url"
            class="rounded img-responsive"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="openPoster()"
          />
        </div>

        <div class="col-md-7">
          <h1 class="movie-title">
            {{ movie.title }}
          </h1>

          <div
            class="original-movie-title"
            *ngIf="movie.title !== movie.originalTitle"
          >
            {{ movie.originalTitle }}
          </div>

          <div class="movie-widgets">
            <rating-smart class="movie-widget-box"
              style="margin-right: 20px;"
              [movieId]="movieId"
              [movieUserData]="movieUserData"
            ></rating-smart>

            <div class="movie-widget-box">
              <ml4-wishlist
                [movieId]="movieId"
                [wishlist]="movieUserData.wishlist"
              ></ml4-wishlist>
            </div>
            <div class="movie-widget-box">
              <ml4-hide-this
                [movieId]="movieId"
                [isHidden]="movieUserData.hidden"
                [predictedRating]="movieUserData.prediction"
              ></ml4-hide-this>
            </div>
            <div class="movie-widget-box" [ngStyle]="{ marginLeft: '10px' }">
              <user-lists-add-dropdown
                [movieId]="movieId"
              ></user-lists-add-dropdown>
            </div>
          </div>

          <div class="row movie-highlights">
            <div class="col-md-4">
              <div class="heading-and-data">
                <div class="movie-details-heading">
                  MovieLens predicts for you
                </div>
                <div>
                  {{ movieUserData.prediction | number: "1.1-2" }} stars
                </div>
              </div>

              <div class="heading-and-data">
                <div class="movie-details-heading">
                  Average of
                  {{ movie.numRatings | number }}
                  {{ movie.numRatings | i18nPlural: ratingsPlural }}
                </div>
                <div>
                  {{ movie.avgRating | number: "1.1-2" }} stars
                  <rating-dist [movieId]="movieId"></rating-dist>
                </div>
              </div>

              <div class="heading-and-data">
                <div class="movie-details-heading" *ngIf = "dateRated != null">
                  Date Rated:
                </div>
                <div>
                  <p *ngIf = "dateRated != null"> {{ dateRated }}</p>
                </div>
              </div>
              
            </div>
            <!-- end inner col-6 -->

            <div class="col-md-8">
              <div class="heading-and-data" *ngIf="movie.genres">
                <div class="movie-details-heading">Genres</div>

                <span *ngFor="let genre of movie.genres; last as isLast">
                  <a
                    uiSref="exploreGenreShortcut"
                    [uiParams]="{ id: genre | urlFragmentNew }"
                    [uiOptions]="{ inherit: false }"
                    ><b>{{ genre }}</b></a
                  >
                  {{ isLast ? "" : ",  " }}
                </span>
              </div>

              <div class="heading-and-data">
                <div class="movie-details-heading">Links</div>
                <a [href]="movie.imdbUrl" target="_blank">imdb</a
                >{{ movie.tmdbUrl ? "," : "" }}
                <span *ngIf="movie.tmdbUrl">
                  <a [href]="movie.tmdbUrl" target="_blank">tmdb</a>
                </span>
              </div>
            </div>
            <!-- end inner col-6 -->
          </div>
          <!-- end inner row -->
        </div>
        <!-- end col-8 -->
      </div>
      <!-- end row movie-details-header -->

      <!-- plot and metadata -->
      <div class="row movie-details-block">
        <div class="col-md-6">
          <p class="lead plot-summary">{{ movie.plotSummary }}</p>
        </div>

        <div class="col-md-6">
          <ul class="movie-attr">
            <li *ngIf="movie.releaseDate">
              {{ movie.releaseDate | date: "yyyy" }}
            </li>
            <li class="mpaa" *ngIf="movie.mpaa">{{ movie.mpaa }}</li>
            <li *ngIf="movie.runtime">{{ movie.runtime }} minutes</li>
          </ul>

          <div class="heading-and-data" *ngIf="movie.languages">
            <div class="movie-details-heading">Languages</div>
            <span *ngFor="let language of movie.languages; last as isLast">
              <a
                uiSref="base.explore"
                [uiParams]="{
                  languages: language.toLowerCase(),
                  sortBy: 'prediction'
                }"
                [uiOptions]="{ inherit: false }"
                >{{ language }}</a
              >{{ isLast ? "" : ", " }}
            </span>
          </div>

          <div class="heading-and-data" *ngIf="movie.directors">
            <div class="movie-details-heading">Directors</div>
            <span *ngFor="let director of movie.directors; last as isLast">
              <a
                uiSref="base.explore"
                [uiParams]="{
                  people: director.toLowerCase(),
                  sortBy: 'prediction'
                }"
                [uiOptions]="{ inherit: false }"
                >{{ director }}</a
              >{{ isLast ? "" : ", " }}
            </span>
          </div>

          <div class="heading-and-data" *ngIf="actorsList">
            <div class="movie-details-heading">Cast</div>
            <span *ngFor="let actor of actorsList; last as isLast">
              <a
                uiSref="base.explore"
                [uiParams]="{
                  people: actor.toLowerCase(),
                  sortBy: 'prediction'
                }"
                [uiOptions]="{ inherit: false }"
                >{{ actor }}</a
              >{{ isLast ? "" : ", " }}
            </span>
            <a
              class="cursor-pointer"
              *ngIf="movie.actors && !showFullActorsList"
              (click)="showFullActorsList = true"
              >, more...</a
            >
          </div>

          <div class="heading-and-data">
            <div class="movie-details-heading">Movie Maintenance</div>

            <div>
              <span *ngIf="movie.tmdbEditUrl">
                <a [href]="movie.tmdbEditUrl" target="_blank">edit on tmdb</a>
              </span>
            </div>

            <flag-movie [movieId]="movieId"></flag-movie>
          </div>
        </div>
      </div>

      <movie-tags [movieId]="movieId"></movie-tags>

      <!-- similar movies -->
      <div
        class="row movie-details-block"
        *ngIf="similarMovies && similarMovies.length"
      >
        <div class="col-sm-12">
          <h2 class="movie-details-sub-heading">
            Similar Movies &nbsp;
            <a
              uiSref="base.movieTuner"
              [uiParams]="{ id: movieId }"
              title="go to the full list"
              class="btn btn-default btn-sm"
              >see more</a
            >
          </h2>

          <ml4-movie-row [movieDetailsList]="similarMovies"></ml4-movie-row>
        </div>
      </div>
      <!-- end similar movies -->

      <!-- media -->
      <div class="row movie-details-block">
        <!-- poster -->
        <div class="col-md-2">
          <h2 class="movie-details-sub-heading" *ngIf="movie.posterW185Url">
            Poster
          </h2>

          <div class="movie-details-media-block">
            <a class="cursor-pointer" (click)="openImagesModal(0)"
              ><img
                [src]="movie.posterW185Url"
                class="img-responsive rounded ml4-bordered"
            /></a>
          </div>
        </div>
        <!-- end poster -->

        <!-- backdrops -->
        <div class="col-md-6 container">
          <h2 class="movie-details-sub-heading" *ngIf="movie.hasBackdrop">
            Images
          </h2>

          <div class="row">
            <div class="col-md-4 col-sm-6 col-sm-12"
              *ngFor="
                let backUrl of movie.backdropW300Urls.slice(0, backdropLimit(showingMoreImages))
                index as i
              "
            >
              
                <div class="movie-details-media-block">
                  <a class="cursor-pointer" (click)="openImagesModal(i + 1)"
                    ><img
                      [src]="backUrl"
                      class="img-responsive rounded ml4-bordered"
                  /></a>
                </div>
              
            </div>

            <div class="col-md-4 col-sm-6 col-sm-12" *ngIf="numAdditionalImages > 0">
                <div class="movie-details-media-block">
                  <a class="cursor-pointer" (click)="showMoreImages()"
                    >{{ numAdditionalImages }} more...</a
                  >
                </div>
            </div>
          </div>
        </div>
        <!-- end backdrops -->

        <!-- youtube -->
        <div class="col-md-4">
          <h2 class="movie-details-sub-heading" *ngIf="movie.hasTrailer">
            Trailers
          </h2>

          <div class="row">
            <div class="col-sm-6 col-sm-12 movie-details-media-block"
              *ngFor="
                let youtubeTrailerId of movie.youtubeTrailerIds.slice(0, backdropLimit(showingMoreTrailers));
                index as i
              "
            >
              
                <youtube-tile
                  *ngIf="youtubeTrailerId"
                  [ytid]="youtubeTrailerId"
                  [index]="i"
                  [movieId]="movieId"
                ></youtube-tile>
              </div>
            </div>
            <div *ngIf="numAdditionalTrailers > 0">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="movie-details-media-block">
                  <a class="cursor-pointer" (click)="showMoreTrailers()"
                    >{{ numAdditionalTrailers }} more...</a
                  >
                </div>
              </div>
            </div>
            <div *ngIf="numAdditionalTrailers > 0">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="movie-details-media-block">
                  <a class="cursor-pointer" (click)="showMoreTrailers()"
                    >{{ numAdditionalTrailers }} more...</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end youtube -->
      </div>
      <!-- end media block -->

      <!-- placeholder for youtube modal (launched from top image, not from tile) -->
      <youtube-modal
        [ytid]="movie.firstYoutubeTrailerId"
        [doShow]="doShowYoutubeModal"
        (onClose)="closeYoutubeModal()"
      ></youtube-modal>

      <!-- placeholder for images modal -->
      <movie-images-browser
        *ngIf="imagesModalIndex != null"
        [doShow]="doShowImagesModal"
        (onClose)="closeImagesModal()"
        [index]="imagesModalIndex"
        [movieId]="movieId"
        [images]="carouselConfig.images"
      ></movie-images-browser>
  `
})
export class MoviePageComponent {
  private movieId: number = null;
  private movieDetails: any = null;
  private movie: any = null;
  private movieUserData: any = null;
  private similarMovies: any = null;
  private showingMoreImages: boolean = false;
  private showingMoreTrailers: boolean = false;
  private ImgScale: number = Math.floor(window.innerHeight / 200)*Math.floor(window.innerWidth / 350);

  ratingsPlural: any = {
    "=0": "ratings",
    "=1": "rating",
    other: "ratings"
  };

  showFullActorsList = false;

  //perhaps add to parent class
  backdropLimit(isShowingMore) {
    
    if(isShowingMore) {
      return 100;
    }
    else if (this.ImgScale < 8) return this.ImgScale;
    else return 8;
  } 
  numAdditionalImages = 0;
  numAdditionalTrailers = 0;

  carouselConfig = {
    index: 0,
    images: []
  };
  doShowImagesModal = false;
  imagesModalIndex = null;

  doShowYoutubeModal = false;

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title,
    private dateFormatter: DateFormatterService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Movie Details | MovieLens");

    this.movieId = parseInt(this.stateService.params.id);

    this.movieDataService.getMovie(this.movieId, movieDetails => {
      this.movieDetails = movieDetails;
      let movie = (this.movie = movieDetails.movie);
      this.movieUserData = movieDetails.movieUserData;

      if (movie.posterOrigUrl) {
        this.carouselConfig.images.push(movie.posterOrigUrl);
      }
      movie.backdropOrigUrls.map(backUrl => {
        this.carouselConfig.images.push(backUrl);
      });
      this.numAdditionalImages =
        movie.backdropOrigUrls.length - this.backdropLimit(this.showingMoreImages);
      this.numAdditionalTrailers = 
        movie.youtubeTrailerIds.length - this.backdropLimit(this.showingMoreTrailers);
      // cast preview (limit to first n by default)
      if (movie.actors && movie.actors.length > 5) {
        movie.actorsAdjusted = movie.actors.slice(0, 5);
      } else {
        this.showFullActorsList = true;
      }

      this.titleService.setTitle(`${movie.title} | MovieLens`);
    });

    this.movieDataService.similarMovies(this.movieId, {}, data => {
      this.similarMovies = data.similarMovies.searchResults;
    });
  }

  get actorsList() {
    if (this.showFullActorsList) {
      return this.movie.actors;
    } else {
      return this.movie.actors.slice(0, 5);
    }
  }

  get dateRated(): String {
    return this.dateFormatter.formattedDate(get(this.movieDetails, "movieUserData.dateRated", null));
  }

  showMoreImages() {
    this.showingMoreImages = true;
    this.numAdditionalImages = 0;
  }

  showMoreTrailers() {
    this.showingMoreTrailers = true;
    this.numAdditionalTrailers = 0;
  }

  openImagesModal(idx) {
    this.imagesModalIndex = idx;
    this.doShowImagesModal = true;
  }

  openPoster() {
    if (this.movie.posterW185Url) {
      this.openImagesModal(0);
    }
  }

  closeImagesModal() {
    this.doShowImagesModal = false;
    this.imagesModalIndex = null;
  }

  openYoutubeModal() {
    this.doShowYoutubeModal = true;
  }

  handleBackdropClick() {
    if (this.movie.hasTrailer) {
      // has trailer, so need for this functionality
      return;
    }

    if (this.movie.posterW185Url) {
      this.openImagesModal(1);
    } else {
      // in case a backdrop exists, but a poster doesn't
      this.openImagesModal(0);
    }
  }

  closeYoutubeModal() {
    this.doShowYoutubeModal = false;
  }
}
