"use strict";

import { clone } from "lodash";

import { Component } from "@angular/core";
import { StateService } from "@uirouter/core";

/**
 * A list of shortcuts, represented as a map of url to state.
 * These are useful for sending experiment invitations by email, so you can
 * link users to (e.g.) /go/cc instead of /go?to=%2Fcc%2Fintro
 *
 * If we want to allow users to generate these, we'll need to push this feature to the database.
 */
const SHORTCODES = {
  // main shortcodes
  login: { state: "base.frontpage" },
  main: { state: "base.frontpage" },
  "new-password": { state: "base.profile.newPassword", direct: true },
  unsubscribe: { state: "base.profile.notifications", direct: true },
  "verify-email": { state: "base.frontpage", direct: true },

  // experiment-specific shortcodes
  feed: { state: "restricted.feedSurvey", direct: true },
  set: { state: "restricted.setsurveyconsent", direct: true },
  nlic: { state: "restricted.consentNLInteraction", direct: true },

  // for testing
  // /go/toy-story
  "toy-story": { state: "base.movie", params: {id: 1} },
  // /go/privacy-policy
  "privacy-policy": { state: "global.privacyPolicy" },

};

/**
 * simple redirect to the auto-login controller (if we found a valid shortcode) or to the login controller.
 */
@Component({
  selector: "auto-login-shortcode",
  template: `<div class="auto-login-shortcode"></div>`
})
export class AutoLoginShortcodeComponent {
  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.doRedirect();
  }

  doRedirect() {
    let shortcode = SHORTCODES[this.stateService.params.id];
    if (!shortcode || !shortcode.state) {
      this.stateService.go("auth.login");
      return;
    }

    let params = clone(this.stateService.params);
    delete params.id;
    params["to"] = this.stateService.href(shortcode.state, shortcode.params);
    if (shortcode.direct) {
      // direct means: do not show any interstitial pages
      params["direct"] = true;
    }
    this.stateService.go("auth.autoLogin", params, { location: false });
  }
}
