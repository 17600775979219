import { Component } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

import { StateService } from "@uirouter/core";
import { FlashService } from "../../common/flash/flash.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "emoji-add-tags",
  template: require("./emoji-add-tags.html")
})

export class EmojiAddTagsComponent{
  private addTagsForm: FormGroup;
  private movieDetails: any;
  private areMoviesLoaded: boolean = false;
  private movieDescription: any = "";
  private tagCount: number = 0;
  private picker: boolean = false;
  private validation: boolean = false;
  private checked: boolean = false;
  private movieIds: number[] = [];
  private movieRatings: string[] = [];
  private userData: {};
  private moviesRated: number = 0;
  private progress: number = 0;

  constructor(private movieDataService: MovieDataService, private fb: FormBuilder, private apiService: ApiService,
              private stateService: StateService, private flashService: FlashService) {
  }

  ngOnInit() {
    this.addTagsForm= this.fb.group({
      emojiTag: [
        "",
        {
          validators: [Validators.required]
        }
      ],
      textTag: [
        "",
        {
          validators: [Validators.required]
        }
      ],
      opinion: [
        "",
        {
          validators: [Validators.required]
        }
      ]
    });
    this.getMovies();
  }

  get emojiTag() {
    return this.addTagsForm.get("emojiTag");
  }

  get textTag() {
    return this.addTagsForm.get("textTag");
  }

  get opinion() {
    return this.addTagsForm.get("opinion");
  }

  loadMovie(movieId) {
    this.apiService.ml4Get(
      "GET /api/emoji/addTags/" + movieId,
      "/api/emoji/addTags/" + movieId,
      {},
      response => {
        this.movieDetails = response.data.movieDetails;
      }
    );
  }

  getMovies() {
    this.apiService.ml4Get(
      "GET /api/emoji/getMoviesToRate",
      "/api/emoji/getMoviesToRate",
      {},
      response => {
        for(let i=0; i<response.lowMovies.length; i++){
          this.movieIds.push(response.lowMovies[i]);
          this.movieRatings.push("low");
        }
        for(let i=0; i<response.averageMovies.length; i++){
          this.movieIds.push(response.averageMovies[i]);
          this.movieRatings.push("average");
        }
        for(let i=0; i<response.highMovies.length; i++){
          this.movieIds.push(response.highMovies[i]);
          this.movieRatings.push("high");
        }
        this.areMoviesLoaded = true;
        //Load the first movie right after getting all
        this.loadMovie(this.movieIds[this.moviesRated]);
        this.moviesRated++;
      },
    );
  }

  handleClick(event: any) {
    if(this.tagCount >=5 ){
      this.flashService.addError("Cannot select more than five emojis").show();
    }
    //Make sure emoji box is clear before next movie is rated
    else if(this.tagCount==0){
      this.movieDescription = event.emoji.native;
      this.addTagsForm.get('emojiTag').setValue(this.movieDescription);
      this.tagCount++;
    }
    else {
        this.movieDescription = this.addTagsForm.get('emojiTag').value + event.emoji.native;
        this.addTagsForm.get('emojiTag').setValue(this.movieDescription);
        this.tagCount++;
      }
    }

    togglePicker() {
      this.picker = !this.picker;
    }

    //On back key press
  validateNumber(event: any) {
    this.tagCount--;
  }

  onSubmit(): void {
    this.validation = true;
    if(this.addTagsForm.invalid) {
      return;
    }

    this.checked = true;
    this.userData = {
      "movieId": this.movieIds[this.moviesRated-1],
      "userRating": this.movieRatings[this.moviesRated-1],
      "emojiTag": this.emojiTag.value,
      "textTag": this.textTag.value,
      "opinion": this.opinion.value
    };
    this.apiService.ml4Post(
      "POST /api/emoji/storeMovieTags",
      "/api/emoji/storeMovieTags",
      this.userData,
      response => {
        //Show how many movies have been rated
        this.progress = this.moviesRated-1;
      },
    );
    if(this.moviesRated == this.movieIds.length) {
      this.stateService.go("restricted.emojiFeedback");
    }
    else {
      this.resetData();
      this.loadMovie(this.movieIds[this.moviesRated]);
      this.moviesRated++;
    }
  }

  resetData(): void {
    this.addTagsForm.reset();
    this.movieDescription = "";
    this.tagCount = 0;
    this.picker = false;
    this.validation = false;
    this.checked = false;
  }

}