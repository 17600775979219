import { Component, EventEmitter } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

import { StateService } from "@uirouter/core";
import { FlashService } from "../../common/flash/flash.service";

@Component({
  selector: "emoji-tutorial",
  template: require("./emoji-tutorial.html")
})
export class EmojiTutorialComponent {
  private tutorialForm: FormGroup;
  private emojiSelected: any = "";
  private movieDescribe: any = "";
  private movieSummaryCount: number = 0;
  private validation: boolean = false;
  private checked: boolean = false;
  private showAnswers: boolean = false;
  private picker1: boolean = false;
  private picker2: boolean = false;

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private flashService: FlashService
  ) {}

  ngOnInit() {
    this.tutorialForm = this.fb.group({
      emojiMeaning: [
        "",
        {
          validators: [Validators.required]
        }
      ],
      emojiSelection: [
        this.emojiSelected,
        {
          validators: [Validators.required]
        }
      ],
      movieDescription: [
        this.movieDescribe,
        {
          validators: [Validators.required]
        }
      ]
    });
  }

  get emojiMeaning() {
    return this.tutorialForm.get("emojiMeaning");
  }

  get emojiSelection() {
    return this.tutorialForm.get("emojiSelection");
  }

  get movieDescription() {
    return this.tutorialForm.get("movieDescription");
  }

  handleSelectionClick(event: any) {
    console.log("Selection click");
    this.emojiSelected = event.emoji.native;
    this.tutorialForm.get("emojiSelection").setValue(this.emojiSelected);
  }

  handleDescriptionClick(event: any) {
    console.log("Description click");
    if (this.movieSummaryCount >= 3) {
      this.flashService.addError("Cannot select more than three emojis").show();
    } else {
      this.movieDescribe =
        this.tutorialForm.get("movieDescription").value + event.emoji.native;
      this.tutorialForm.get("movieDescription").setValue(this.movieDescribe);
      this.movieSummaryCount++;
    }
  }

  //Handling enter key event for module picker
  //Known bug
  handleEnterKey(event: any) {}

  //If user presses back key, decrease count of emojis
  validateNumber(event: any) {
    this.movieSummaryCount--;
  }

  //Emoji picker 1 toggle when user clicks globe icon
  togglePicker1(): void {
    this.picker1 = !this.picker1;
  }

  //Emoji picker 2 toggle when user clicks globe icon
  togglePicker2(): void {
    this.picker2 = !this.picker2;
  }

  onSubmit(): void {
    if (this.tutorialForm.invalid) {
      this.validation = true;
      return;
    }
    this.checked = true;
    this.stateService.go("restricted.emojiSurvey");
  }

  showCorrectAnswers(): void {
    console.log("Show correct answers");
    if (this.tutorialForm.invalid) {
      this.validation = true;
      return;
    } else {
      console.log("Back in");
      this.picker1 = false;
      this.picker2 = false;
      this.showAnswers = true;
    }
  }
}
