import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { clamp } from "lodash";

/**
 * Keep track of the number of api calls in progress.
 */
@Injectable()
export class ApiActivityService {

  private callCount = new BehaviorSubject<number>(0);
  currentCallCount = this.callCount.asObservable();

  startApiCall() {
    this.callCount.next(this.callCount.getValue() + 1);
  }

  finishApiCall() {
    // use clamp to ensure count does not drop below 0
    this.callCount.next(clamp(this.callCount.getValue() - 1, 0, 999));
  }

  currentValue(): number {
    return this.callCount.getValue();
  }

}
