"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { FormsModule } from "@angular/forms";

import { ExplanationsConsentComponent } from "./explanations-consent.component";
import { ExplanationsSurveyComponent } from "./explanations-survey.component";
import { ExplanationsMovieCardComponent } from "./movie-card/explanations-movie-card.component";
import { NaturalLanguageExplanationsComponent } from "./visual/natural-language-explanations.component";
import { ExplanationsRatingStarsComponent } from "./rating-stars/explanations-rating-stars.component";
import { ExplanationsService } from "./explanations.service";
import { ExplanationsSurveyFormComponent } from "./survey-form/explanations-survey-form.component";
import { ModalDialogModule } from "../../components/modal-dialog/modal-dialog.module";
import {UIRouterModule} from "@uirouter/angular";

export const StaticPagesRoutes = {
  states: [
    {
      name: "restricted.explanations",
      url: "/explanations",
      component: ExplanationsConsentComponent
    },
    {
      name: "restricted.explanations.survey",
      url: "/survey",
      component: ExplanationsSurveyComponent,
      params: {
        design: "between_subjects"
      }
    },
    {
      name: "restricted.explanations.survey-within",
      url: "/survey-within",
      component: ExplanationsSurveyComponent,
      params: {
        design: "within_subjects"
      }
    }
  ]
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ModalDialogModule,
    UIRouterModule.forChild(StaticPagesRoutes)
  ],
  declarations: [
    ExplanationsConsentComponent,
    ExplanationsSurveyComponent,
    ExplanationsMovieCardComponent,
    NaturalLanguageExplanationsComponent,
    ExplanationsRatingStarsComponent,
    ExplanationsSurveyFormComponent
  ],
  entryComponents: [
    NaturalLanguageExplanationsComponent,
    ExplanationsSurveyFormComponent
  ],
  providers: [ExplanationsService]
})
export class ExplanationsModule {}
