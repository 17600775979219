"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { ImgPreloadDirective } from "./img-preload.directive";

@NgModule({
  imports: [BrowserModule],
  exports: [ImgPreloadDirective],
  declarations: [ImgPreloadDirective]
})
export class ImgPreloadModule {}
