"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

import { MovieDataService } from "../../common/movie-data/movie-data.service";

@Component({
  selector: "movie-card-demos-page",
  template: `
    <div class="movie-card-demos-page">
      <h1>movie cards</h1>

      <h2>default (md1)</h2>

      <div class="dev-cards-container clearfix">
        <movie-card-smart
          movieId="89745"
          [movieDetails]="theAvengers"
        ></movie-card-smart>
        <movie-card-smart movieId="7451"></movie-card-smart>
        <movie-card-smart [movieDetails]="tronLegacy"></movie-card-smart>
        <movie-card-smart [movieDetails]="pacificRim"></movie-card-smart>
        <movie-card-smart [movieDetails]="escape"></movie-card-smart>
      </div>

      <h2>default [modal]="true"</h2>

      <div class="dev-cards-container clearfix">
        <movie-card-smart
          [movieDetails]="wonderWoman"
          [modal]="true"
        ></movie-card-smart>
        <movie-card-smart
          [movieDetails]="aFurtherGesture"
          [modal]="true"
        ></movie-card-smart>
      </div>

      <h2>default [overrideClick]="true" (onClick)="aClickHandler()"</h2>

      <div class="dev-cards-container clearfix">
        <movie-card-smart
          [movieDetails]="wonderWoman"
          [overrideClick]="true"
          (onClick)="aClickHandler()"
        ></movie-card-smart>
      </div>

      <h2>sm1</h2>

      <div class="dev-cards-container clearfix">
        <movie-card-smart
          type="sm1"
          [movieDetails]="tronLegacy"
        ></movie-card-smart>
        <movie-card-smart
          type="sm1"
          [movieDetails]="pacificRim"
        ></movie-card-smart>
        <movie-card-smart type="sm1" [movieDetails]="escape"></movie-card-smart>
      </div>

      <h2>sm2</h2>

      <div class="dev-cards-container clearfix">
        <movie-card-smart
          type="sm2"
          [movieDetails]="tronLegacy"
        ></movie-card-smart>
        <movie-card-smart type="sm2" movieId="122886"></movie-card-smart>
        <movie-card-smart type="sm2" [movieDetails]="lotr"></movie-card-smart>
        <movie-card-smart type="sm2" [movieDetails]="escape"></movie-card-smart>
      </div>

      <h1>movie-row</h1>

      <div
        class="dev-cards-container clearfix"
        style="width: 80%; border: 2px dotted gray; padding: 4px; "
      >
        <ml4-movie-row [movieDetailsList]="aList"></ml4-movie-row>
      </div>
    </div>
  `
})
export class MovieCardDemosPageComponent implements OnInit {
  aFurtherGesture = null;
  camionisti = null;
  // escape is useful because it doesn't have a poster
  escape = null;
  lotr = null;
  pacificRim = null;
  theAvengers = null;
  theMatrix = null;
  toyStory = null;
  tronLegacy = null;
  wonderWoman = null;

  aList = [];

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Movie Card Demos | MovieLens");

    this.movieDataService.getMovie(1781, md => {
      this.aFurtherGesture = md;
    });
    this.movieDataService.getMovie(89745, md => {
      this.theAvengers = md;
    });
    this.movieDataService.getMovie(154130, md => {
      this.camionisti = md;
    });
    this.movieDataService.getMovie(156463, md => {
      this.escape = md;
    });
    this.movieDataService.getMovie(4993, md => {
      this.lotr = md;
    });
    this.movieDataService.getMovie(103228, md => {
      this.pacificRim = md;
    });
    this.movieDataService.getMovie(2571, md => {
      this.theMatrix = md;
    });
    this.movieDataService.getMovie(1, md => {
      this.toyStory = md;
    });
    this.movieDataService.getMovie(82461, md => {
      this.tronLegacy = md;
    });
    this.movieDataService.getMovie(143355, md => {
      this.wonderWoman = md;
    });

    this.movieDataService.movieSearch(
      { tag: "ninja", sortBy: "tagScore", pageSize: 5 },
      searchData => {
        this.aList = searchData.searchResults;
      }
    );
  }

  aClickHandler() {
    alert("clicked it");
  }
}
