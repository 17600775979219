"use strict";

import { Component } from "@angular/core";

@Component({
  selector: "alexa-setup-page",
  template: `
    <div class="alexa-setup-page">
      <div class="title">
        MovieLens TV Setup
      </div>

      <div>
        talk back condition:
        <a uiSref="tv.alexaMessage.welcome" [uiParams]="{cc: 1}">turn on</a>
        |
        <a uiSref="tv.alexaMessage.welcome" [uiParams]="{cc: 0}">turn off</a>
        <br />
        <a
          uiSref="tv.alexaRecommend"
          [uiParams]="{
            movies:
              '909,2502,1466,3039,90376,133645,2423,49286,6957,4823,72294,3489,317,8965'
          }"
          >Recommend page</a
        >
        <br />
        <a uiSref="tv.alexaAboutMovie" [uiParams]="{ movie: 2502 }"
          >About Movie</a
        >
        <br />
        <a uiSref="tv.alexaTrailer" [uiParams]="{ movie: 179819 }"
          >Movie Trailer</a
        >
        <br />
        <a uiSref="base.movie" [uiParams]="{ id: 179819 }">Exit</a>
      </div>
    </div>
  `
})
export class AlexaSetupPageComponent {}
