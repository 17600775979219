"use strict";

import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { Title } from "@angular/platform-browser";
import { clone, get } from "lodash";

import { ExploreService } from "./explore.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";

import "./explore-page.scss";

@Component({
  selector: "explore-page",
  template: `
    <div class="explore">
      <div class="explore-page-desc">
        <p>{{ searchDescriptionSmall }}</p>
        <h1>
          {{ searchDescriptionBig }}
        </h1>
      </div>

      <div *ngIf="searchResults != null">
        <explore-control-bar
          [totalItems]="pager.state.totalItems"
        ></explore-control-bar>
      </div>

      <div class="movie-search-results-container" *ngIf="mode == 'list'">
        <movie-table [movies]="searchResults"></movie-table>
      </div>

      <div class="movie-search-results-container" *ngIf="mode != 'list'">
        <div
          *ngFor="let searchResult of searchResults"
          class="ml4-movie-card-search-result"
        >
          <movie-card-smart [movieDetails]="searchResult"></movie-card-smart>
        </div>
      </div>

      <div *ngIf="pager.state.totalItems == 0" style="margin-top: 24px;">
        <p class="lead">
          No results! Is there a movie missing from MovieLens?
          <a uiSref="base.addTitle">Add it.</a>
        </p>
      </div>

      <div *ngIf="searchResults && pager.state.totalPages > 1">
        <pager
          [currentPage]="pager.state.currentPage"
          [totalPages]="pager.state.totalPages"
          (onChangePage)="changePage($event)"
        ></pager>
      </div>
    </div>
  `
})
export class ExplorePageComponent implements OnInit {
  mode;
  hasCustomHeadings;
  searchDescriptionSmall;
  searchDescriptionBig;
  pager;
  currentPage;
  searchResults;

  constructor(
    private exploreService: ExploreService,
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Explore");

    this.mode = localStorage.getItem("explore-mode");

    // build up a description of the current page
    let buildSearchDescription = () => {
      let qp = this.stateService.params;
      let headings = this.exploreService.getHeadings();
      const CUSTOM_SEARCH_TEXT = "all movies";

      this.hasCustomHeadings = !!headings;

      if (headings) {
        this.searchDescriptionSmall = headings.subHeading;
        this.searchDescriptionBig = headings.heading;
        this.exploreService.clearHeadings();
      } else if (qp.listId) {
        this.searchDescriptionSmall = "";
        this.searchDescriptionBig = "browsing by list";
      } else if (qp.myTag) {
        this.searchDescriptionSmall = "showing movies you have tagged";
        this.searchDescriptionBig = qp.myTag;
      } else if (qp.tag && qp.genre) {
        this.searchDescriptionSmall = "browsing by genre + tag";
        this.searchDescriptionBig = qp.genre + " + " + qp.tag;
      } else if (qp.tag) {
        this.searchDescriptionSmall = "browsing by tag";
        this.searchDescriptionBig = qp.tag;
      } else if (qp.hasRated === "yes") {
        this.searchDescriptionSmall = "";
        this.searchDescriptionBig = "your ratings";
      } else if (qp.hasHidden === "yes") {
        this.searchDescriptionSmall = "";
        this.searchDescriptionBig = "your hidden movies";
      } else if (qp.hasWishlisted === "yes") {
        this.searchDescriptionSmall = "";
        this.searchDescriptionBig = "your wishlist";
      } else if (qp.q) {
        this.searchDescriptionSmall = "you searched for titles like";
        this.searchDescriptionBig = qp.q;
      } else if (qp.actors) {
        this.searchDescriptionSmall = "browsing by actor";
        this.searchDescriptionBig = qp.actors;
      } else if (qp.directors) {
        this.searchDescriptionSmall = "browsing by director";
        this.searchDescriptionBig = qp.directors;
      } else if (qp.people) {
        this.searchDescriptionSmall = "browsing by name";
        this.searchDescriptionBig = qp.people;
      } else if (qp.genre) {
        this.searchDescriptionSmall = "browsing by genre";
        this.searchDescriptionBig = qp.genre;
      } else if (qp.languages) {
        this.searchDescriptionSmall = "browsing by language";
        this.searchDescriptionBig = qp.languages;
      } else if (qp.sortBy === "avgRating") {
        this.searchDescriptionSmall = "sorting by average rating";
        this.searchDescriptionBig = CUSTOM_SEARCH_TEXT;
      } else if (qp.sortBy === "popularity") {
        this.searchDescriptionSmall = "sorting by number of ratings";
        this.searchDescriptionBig = CUSTOM_SEARCH_TEXT;
      } else if (qp.sortBy === "releaseDate") {
        this.searchDescriptionSmall = "sorting by release date";
        this.searchDescriptionBig = CUSTOM_SEARCH_TEXT;
      } else if (qp.sortBy === "prediction") {
        this.searchDescriptionSmall = "sorting by MovieLens recommendation";
        this.searchDescriptionBig = CUSTOM_SEARCH_TEXT;
      } else if (qp.sortBy === "dateAdded") {
        this.searchDescriptionSmall = "sorting by date added to MovieLens";
        this.searchDescriptionBig = CUSTOM_SEARCH_TEXT;
      } else {
        this.searchDescriptionSmall = "";
        this.searchDescriptionBig = CUSTOM_SEARCH_TEXT;
      }
    };
    this.searchDescriptionSmall = "";
    this.searchDescriptionBig = "";
    buildSearchDescription();

    this.pager = {
      state: {
        // set in api callback
      }
    };

    this.movieDataService.movieSearch(this.stateService.params, searchData => {
      this.pager.state = searchData.pager;
      this.currentPage = this.pager.state.currentPage;
      this.searchResults = searchData.searchResults;
    });
  }

  changePage(newPage) {
    this.exploreService.restoreLastHeadings();
    let newStateParams = clone(this.stateService.params);
    newStateParams.page = newPage;
    this.stateService.transitionTo("base.explore", newStateParams, {
      reload: true
    });
  }
}
