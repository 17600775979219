"use strict";

import {Component, Input, OnChanges, SimpleChange, SimpleChanges} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { get } from "lodash";

/**
 * A responsive, embedded youtube video.
 *
 * see http://avexdesigns.com/responsive-youtube-embed/
 */
@Component({
  selector: "youtube-embed",
  template: `
    <div class="ratio ratio-16x9" *ngIf="ytid">
      <iframe class="embed-responsive-item" style="max-width: 100%;height: 100%;" [src]="ytUrl" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  `
})
export class YoutubeEmbedComponent implements OnChanges {
  @Input() ytid: string;
  @Input() autoplay: boolean = false;

  ytUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    const ytidChange: SimpleChange = changes["ytid"];
    if (ytidChange && ytidChange.currentValue) {

      // XXX: autoplay does not work if chrome is emulating a device
      const autoplayParam = this.autoplay ? "&autoplay=1" : "";

      const url = `//www.youtube.com/embed/${ytidChange.currentValue}?fs=1${autoplayParam}`;
      this.ytUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

}
