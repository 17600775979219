"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


import { PasswordResetPageComponent } from "./password-reset-page.component";
import {UIRouterModule} from "@uirouter/angular";

const resetPasswordPage = {
  name: "auth.passwordReset",
  url: "/password-reset",
  component: PasswordResetPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule.forChild({ states: [resetPasswordPage] })
  ],
  declarations: [PasswordResetPageComponent]
})
export class PasswordResetModule {}
