"use strict";

import { NgModule } from "@angular/core";

import { RectuneService } from "./rectune.service";

@NgModule({
  providers: [RectuneService]
})
export class RectuneModule {}
