"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { UserListsPageComponent } from "./user-lists-page.component";
import { PipesModule } from "../../common/pipes/pipes.module";
import { UIRouterModule } from "@uirouter/angular";
import { UserListMoviesPageComponent } from "./user-list-movies-page.component";
import { MovieCardModule } from "../../components/movie-card/movie-card.module";
import { PagerModule } from "../../components/pager/pager.module";
import { UserListsModalModule } from "../../components/user-lists-modal/user-lists-modal.module";

const userListsPage = {
  name: "base.userLists",
  url: "/lists",
  component: UserListsPageComponent
};

const listMoviesPage = {
  name: "base.userListMovies",
  url: "/users/:userId/lists/:listId?page",
  component: UserListMoviesPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    PipesModule,
    MovieCardModule,
    PagerModule,
    UserListsModalModule,
    UIRouterModule.forChild({ states: [userListsPage] }),
    UIRouterModule.forChild({ states: [listMoviesPage] })
  ],
  declarations: [UserListsPageComponent, UserListMoviesPageComponent],
  exports: []
})
export class UserListsModule {}
