import { Component, ViewChild } from "@angular/core";
import { StateService } from "@uirouter/core";

import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { SessionService } from "../../common/session/session.service";

import { isEmpty, get } from "lodash";

import "./base-notifications-dropdown.scss";
import "./base-template.scss";

@Component({
  selector: "base-notifications-dropdown",
  template: require("./base-notifications-dropdown.html")
})
export class BaseNotificationsDropdownComponent {
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;

  buttonClass = "btn base-compact-header-btn btn-sm ratings-alert";
  buttonContent = `
        <i class="fa fa-bell ml4-fa-1x"></i>
        <i class="fa fa-caret-down"></i>
    `;
  offset = "8px";
  name = "baseNotificationsDropdown";

  ratingThreshold = 15;
  store = {
    notifications: {
      hasNotPickedGroup: true,
      ratingsBelowThreshold: true,
      tooNewForSvd: true
    }
  };

  notifications: any = {};
  subscription = null;

  constructor(
    private stateService: StateService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.subscription = this.sessionService.currentNotifications.subscribe(
      val => {
        this.notifications = val;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hasNotifications() {
    return !isEmpty(this.notifications);
  }
}
