import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import { ExplanationsService } from "../explanations.service";
import { ExplanationsSurvey } from "./ExplanationsSurvey";

import explanationsSurveyQuestions from "./questions";
import { NgForm } from "@angular/forms";

const tmpl = require("./explanations-survey-form.component.html");

@Component({
  selector: "explanations-survey-form",
  template: tmpl
})
export class ExplanationsSurveyFormComponent {
  @Input() movies: string;
  @Input() step: number;
  @Input() design: string;

  @Output() onClose = new EventEmitter<null>();

  @ViewChild("form") surveyForm: NgForm;

  private model = this.createForm("effectiveness");
  private maxStep = 2;

  constructor(private explanationsService: ExplanationsService) {}

  createForm(type): ExplanationsSurvey {
    var form = null;
    if (type === "effectiveness") {
      var question = explanationsSurveyQuestions.effectiveness_statement;
      form = new ExplanationsSurvey(question.questionText, question.labels, "");
    }
    return form;
  }

  isDisabled(): boolean {
    return this.surveyForm.invalid;
  }

  onSubmit() {
    let strStep = "step_" + this.step;
    this.explanationsService.savePicks(
      {
        data: this.movies,
        step: strStep
      },
      response => {
        this.explanationsService.saveFeedback(
          {
            data: JSON.stringify(this.model.answer),
            step: strStep
          },
          response => {
            this.onClose.emit();

            // #TODO improve logic related to update step
            if (this.design == "between_subjects") {
              nextStep = -1;
            } else {
              var nextStep = this.step + 1;
              if (this.step == this.maxStep) {
                nextStep = -1;
              }
            }

            this.explanationsService.updateStatus(nextStep);
          }
        );
      }
    );
  }
}
