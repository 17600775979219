import { Injectable } from "@angular/core";
import { debounce } from "lodash";
import { BehaviorSubject } from "rxjs";
import { EventManager } from "@angular/platform-browser";

class WindowSize {
  width: number;
  height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

/**
 * allow code to track changes to window size
 */
@Injectable()
export class WindowService {
  private windowSize = new BehaviorSubject<WindowSize>(null);
  currentWindowSize = this.windowSize.asObservable();

  constructor(private eventManager: EventManager) {
    this.eventManager.addGlobalEventListener(
      "window",
      "resize",
      debounce(this.onResize.bind(this), 250)
    );
    this.windowSize.next(new WindowSize(window.innerWidth, window.innerHeight))
  }

  private onResize(event: UIEvent) {
      this.windowSize.next(
          // @ts-ignore
      new WindowSize(event.target.innerWidth, event.target.innerHeight)
    );
  }
}
