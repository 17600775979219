import { Component, EventEmitter, Input, Output } from "@angular/core";

import * as $ from "jquery";
require("corejs-typeahead/dist/typeahead.jquery.min.js");
const Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

const defaultBloodhoundOptions = {
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  rateLimitWait: 25
};
import "./add-tag.scss";

@Component({
  selector: "add-tag",
  template: require("./add-tag.html")
})
export class AddTagComponent {
  @Input() width: string = "128px";
  @Input() numToShow: number = 5;
  @Output() selectTag = new EventEmitter<string>();

  ngOnInit() {
    let ttOptions = {
      highlight: true
    };

    let ttData = {
      display: "tag",
      source: this.bloodhoundEngine.ttAdapter(),
      limit: this.numToShow
    };

    $(".add-tag-typeahead")
      .typeahead(ttOptions, ttData)
      .on("typeahead:selected", (ev, suggestion) => {
        this.currentVal = suggestion.tag;
        this.submit();
      });
  }

  currentVal = "";

  bloodhoundEngine = new Bloodhound({
    datumTokenizer: defaultBloodhoundOptions.datumTokenizer,
    queryTokenizer: defaultBloodhoundOptions.queryTokenizer,
    rateLimitWait: defaultBloodhoundOptions.rateLimitWait,
    indexRemote: true,
    remote: {
      url: "/api/searches/omni/%QUERY",
      wildcard: "%QUERY",
      transform: function(rsp) {
        return rsp.data.tunerViewModel.payload;
      }
    }
  });

  submit() {
    if (!this.currentVal) {
      return;
    }

    $(".add-tag-typeahead").typeahead("val", "");
    $(".add-tag-typeahead").typeahead("close");

    this.selectTag.emit(this.currentVal);

    this.currentVal = "";
  }
}
