"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { CarouselComponent } from "./carousel.component";

@NgModule({
  imports: [
    BrowserModule,
  ],
  exports: [
    CarouselComponent
  ],
  declarations: [
    CarouselComponent
  ]
})
export class CarouselModule {}
