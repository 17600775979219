"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import { AllGenresPageComponent } from "./all-genres-page.component";
import { PipesModule } from "../../common/pipes/pipes.module";
import {UIRouterModule} from "@uirouter/angular";

const allGenresPage = {
  name: "base.allGenres",
  url: "/genres",
  component: AllGenresPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    PipesModule,
    UIRouterModule.forChild({ states: [allGenresPage] })
  ],
  declarations: [AllGenresPageComponent]
})
export class AllGenresModule {}
