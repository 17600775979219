"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { ExternalRecsComponent } from "./external-recs.component";

@NgModule({
  imports: [
    BrowserModule,
  ],
  declarations: [ExternalRecsComponent],
  exports: [ExternalRecsComponent]
})
export class ExternalRecsModule {}
