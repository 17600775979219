"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MovieRowComponent } from "./movie-row.component";
import { MovieCardModule } from "../movie-card/movie-card.module";
import {ExptMovieCardModule} from "../../expt/rec-val/rec-val-movie-card/expt-movie-card.module";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
    imports: [CommonModule, MovieCardModule, ExptMovieCardModule],
  exports: [MovieRowComponent],
  declarations: [MovieRowComponent],
  entryComponents: [MovieRowComponent]
})
export class MovieRowModule {}
