import { Injectable } from "@angular/core";
import { extend, get } from "lodash";
import { BehaviorSubject } from "rxjs";


/**
 * track the status of our websocket connection with the alexa backend
 */
@Injectable()
export class AlexaConnectionService {

  private connected = new BehaviorSubject<boolean>(false);
  isConnected = this.connected.asObservable();

  alexaPing() {
    this.connected.next(true);
  }

  alexaClose() {
    this.connected.next(false);
  }
}
