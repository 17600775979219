"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { YoutubeModule } from "../../components/youtube/youtube.module";

import { AlexaConnectionService } from "./alexa-connection.service";
import { AlexaErrorPageComponent } from "./alexa-error-page.component";
import { AlexaMessageTemplateComponent } from "./alexa-message-template.component";
import { AlexaMoviePageComponent } from "./alexa-movie-page.component";
import { AlexaRecommendPageComponent } from "./alexa-recommend-page.component";
import { AlexaSetupPageComponent } from "./alexa-setup-page.component";
import { AlexaTrailerPageComponent } from "./alexa-trailer-page.component";
import { AlexaWelcomePageComponent } from "./alexa-welcome-page.component";
import { TvTemplateComponent } from "./tv-template.component";
import {UIRouterModule} from "@uirouter/angular";

// templates

const tvTemplate = {
  name: "tv",
  abstract: true,
  // to use the restricted state, you must be logged in
  parent: "loggedIn",
  component: TvTemplateComponent
};

const alexaMessageTemplate = {
  name: "tv.alexaMessage",
  abstract: true,
  component: AlexaMessageTemplateComponent
};



// pages

const alexaErrorPage = {
  name: "tv.alexaMessage.error",
  url: "/tv/error?{query:urlDecoded}&{msg:urlDecoded}",
  component: AlexaErrorPageComponent
};

const alexaMoviePage = {
  name: "tv.alexaAboutMovie",
  url: "/tv/about?{movie:urlDecoded}&{query:urlDecoded}}&{wishlisted:urlDecoded}&{more:urlDecoded}",
  component: AlexaMoviePageComponent
};

const alexaRecommendPage = {
  name: "tv.alexaRecommend",
  url: "/tv/recommend?{movies:urlDecoded}&{query:urlDecoded}",
  component: AlexaRecommendPageComponent
};

const alexaSetupPage = {
  name: "tv.alexaMessage.setup",
  url: "/tv/setup",
  component: AlexaSetupPageComponent
};

const alexaTrailerPage = {
  name: "tv.alexaTrailer",
  url: "/tv/trailer?{movie:urlDecoded}",
  component: AlexaTrailerPageComponent
};

const alexaWelcomePage = {
  name: "tv.alexaMessage.welcome",
  url: "/tv?{cc:urlDecoded}",
  component: AlexaWelcomePageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    UIRouterModule.forChild({
      states: [
        alexaErrorPage,
        alexaMessageTemplate,
        alexaMoviePage,
        alexaRecommendPage,
        alexaSetupPage,
        alexaTrailerPage,
        alexaWelcomePage,
        tvTemplate
      ]
    }),
    YoutubeModule
  ],
  declarations: [
    AlexaErrorPageComponent,
    AlexaMessageTemplateComponent,
    AlexaMoviePageComponent,
    AlexaRecommendPageComponent,
    AlexaSetupPageComponent,
    AlexaTrailerPageComponent,
    AlexaWelcomePageComponent,
    TvTemplateComponent
  ],
  providers: [AlexaConnectionService]
})
export class AlexaModule {}
