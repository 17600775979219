"use strict";

import { Injectable } from "@angular/core";

const ALL_GENRES = [
  "action",
  "adventure",
  "animation",
  "comedy",
  "crime",
  "documentary",
  "drama",
  "family",
  "fantasy",
  "history",
  "horror",
  "music",
  "mystery",
  "romance",
  "science fiction",
  "thriller",
  "tv movie",
  "war",
  "western"
];

/**
 * MovieLens genres info
 *
 * TODO: this should probably be made into an API call, so that we have some ability to change genres without a new release.
 */
@Injectable()
export class GenresService {
  private cache = {};

  constructor() {}

  /**
   * return all the genres as a list, alphabetical.
   */
  all() {
    return ALL_GENRES;
  }

  /**
   * return the number of genres.
   */
  static size() {
    return ALL_GENRES.length;
  }

  /**
   * return all the genres split into `numLists` sub-lists.
   */
  split(numLists) {
    if (this.cache[numLists]) {
      return this.cache[numLists];
    }

    // not in the cache.  compute the sub-lists.
    let numGenres = ALL_GENRES.length;
    let size = Math.ceil(numGenres / numLists);
    let returnVal = [];
    for (let i = 0; i < numGenres; i += size) {
      returnVal.push(ALL_GENRES.slice(i, i + size));
    }

    // add the computed splits to the cache, and return
    this.cache[numLists] = returnVal;
    return returnVal;
  }

}
