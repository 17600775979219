"use strict";

import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {get} from "lodash";

import "./expt-movie-card-md1.scss";
import {IMyDateModel, IMyOptions} from "angular-mydatepicker";


@Component({
    selector: "movie-card-md1",
    template: `
      <div class="expt-movie-card-md1">
        <div class="card">
          <div
              class="poster"
              [class.poster-hidden]="this.hidden"
              [class.empty-poster]="!posterSrc"
          >
            <img
                *ngIf="posterSrc"
                [src]="posterSrc"
                srcset="{{ posterSrcHq }} 2x"
                alt="poster for {{ getMovieData('title') }}"
            />
          </div>

          <div class="my-overlay">
            <div class="my-above-fold">
              <div [hidden]="finish"><span class="title2">{{ getMovieData("title") }}</span></div>
              <div [hidden]="!finish">
                <p><span class="green">&#10003;</span> <span class="title2"> {{getMovieData("title")}}</span></p>
              </div>
              <div [hidden]="!(bad_date&&isSeen)">
                <p><span class="red">Invalid Date in the future!</span></p>
              </div>
              <div class="row">
                <input [style.background-color]="this.isSeen ? 'yellow' : 'white'" type="submit" class="app-button"
                       id="btnSeen" (click)="onSeenButtonClick.emit()" value="Seen">
                <input [style.background-color]="this.isNotSeen ? 'yellow' : 'white'" type="submit" class="app-button"
                       id="btnNotSeen" (click)="onNotSeenButtonClick.emit()" value="Not Seen">
              </div>
            </div>
            <div class="my-below-fold" [hidden]="!isComplete()">
              <div [hidden]="!isSeen">
                <div class="rating-entry">
                  How would you rate this movie?
                  <rating
                      [size]="24"
                      [predictedRating]="trueRating"
                      [userRating]="trueRating"
                      [showTooltip]="false"
                      (onRate)="onRate.emit($event)"
                      (onClearRating)="onClearRating.emit($event)"
                  ></rating>
                </div>
                When did you watch it approximately? <a style="font-size: 12px; position: relative; padding: 0" class="btn btn-link" (click)="this.elicit = true;">&#9432;</a>
                <div class="input-box-container" style="position: relative;">
                  <input class="input-box" placeholder="Click to select a date"
                         angular-mydatepicker name="mydate" (click)="selecting=true;dp.toggleCalendar()"
                         [(ngModel)]="date" [options]="myDatePickerOptions" (inputFieldChanged)="selecting=true;dp.toggleCalendar()"
                         #dp="angular-mydatepicker" (dateChanged)="onDateChanged.emit($event); selecting=false"/>
                </div>
                <!--                <div [hidden]="selecting" >-->
                <!--                  <a style="font-size: 12px; position: relative; padding: 0" class="btn btn-link" (click)="this.elicit = true;">How to change year quickly?</a>-->
                <!--                </div>-->
              </div>
              <div [hidden]="!isNotSeen">
                <div class="rating-entry">
                  How do you think you would rate this movie?
                  <rating
                      [size]="24"
                      [predictedRating]="0"
                      [userRating]="predictRating"
                      [showTooltip]="false"
                      (onRate)="onPredictRate.emit($event)"
                      (onClearRating)="onClearPredictRating.emit($event)"
                  ></rating>
                </div>

                <div style="padding-bottom: 5px">
                  <div>How sure are you?</div>
                  <div>(1=Very Unsure, 5=Very Sure)</div>
                  <bar-rating [rate]="rate" (rateChange)="onRateSure.emit($event)" [max]="5"
                              [theme]="'square'"></bar-rating>
                </div>
              </div>
            </div>
            <div class="my-above-fold" [hidden]="isComplete()">
              <div [hidden]="!isSeen">
                <div class="rating-entry">
                  How would you rate this movie?
                  <rating
                      [size]="24"
                      [predictedRating]="trueRating"
                      [userRating]="trueRating"
                      [showTooltip]="false"
                      (onRate)="onRate.emit($event)"
                      (onClearRating)="onClearRating.emit($event)"
                  ></rating>
                </div>
                When did you watch it approximately? <a style="font-size: 12px; position: relative; padding: 0" class="btn btn-link" (click)="this.elicit = true;">&#9432;</a>
                <div class="input-box-container" style="position: relative;">
                  <input class="input-box" placeholder="Click to select a date"
                         angular-mydatepicker name="mydate" (click)="selecting=true;dp.toggleCalendar()"
                         [(ngModel)]="date" [options]="myDatePickerOptions" (inputFieldChanged)="selecting=true;dp.openCalendar()"
                         #dp="angular-mydatepicker" (dateChanged)="onDateChanged.emit($event);selecting=false"/>
                </div>
                <!--                <div [hidden]="selecting" >-->
                <!--                  <a style="font-size: 12px; position: relative; padding: 0" class="btn btn-link" (click)="this.elicit = true;">How to change year quickly?</a>-->
                <!--                </div>-->
              </div>
              <div [hidden]="!isNotSeen">
                <div class="rating-entry">
                  How do you think you would rate this movie?
                  <rating
                      [size]="24"
                      [predictedRating]="0"
                      [userRating]="predictRating"
                      [showTooltip]="false"
                      (onRate)="onPredictRate.emit($event)"
                      (onClearRating)="onClearPredictRating.emit($event)"
                  ></rating>
                </div>

                <div style="padding-bottom: 5px">
                  <div>How sure are you?</div>
                  <div>(1=Very Unsure, 5=Very Sure)</div>
                  <bar-rating [rate]="rate" (rateChange)="onRateSure.emit($event)" [max]="5"
                              [theme]="'square'"></bar-rating>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rec-val-overlay-3" [hidden]="!elicit">
        <div class="popup" style="width: 80%; max-width: 500px; ">

          <div id="x" style="display: inline-block;">
            <a class="btn btn-link" (click)="elicit=false" style="display: inline-block;" >&#10006;</a>
            <img src="/images/expt/rec_val/helper.png" alt="helper"  style="display: inline-block;">
          </div>



          <div style="margin-top: 20px; ">
            <a class="btn btn-primary" (click)="elicit=false">Close Helper Info</a>
          </div>

        </div>
      </div>
    `
})

export class ExptMovieCardMd1Component {
    @Input() movieDetails: any = null;
    @Input() doLink: boolean = true;
    @Input() isSeen: boolean = false;
    @Input() isNotSeen: boolean = false;
    @Input() rate: number = 0;
    @Input() date: Date = null;
    @Input() predictRating: number = 0;
    @Input() trueRating: number = 0;
    @Input() finish: boolean = false;
    @Input() bad_date: boolean = false;
    @Input() source: string = "";
    @Output() onDateChanged = new EventEmitter<IMyDateModel>();
    private today = new Date();
    elicit = false;
    selecting: boolean = false;


    @Input() myDatePickerOptions: IMyOptions = {
        dateFormat: "yyyy-mm-dd",
        selectorWidth: "150px",
        selectorHeight: "150px",
    };


    @Output() onClick = new EventEmitter<null>();
    @Output() onSeenButtonClick = new EventEmitter<null>();
    @Output() onNotSeenButtonClick = new EventEmitter<null>();
    @Output() onRate = new EventEmitter<number>();
    @Output() onClearRating = new EventEmitter<null>();

    @Output() onPredictRate = new EventEmitter<number>();
    @Output() onClearPredictRating = new EventEmitter<null>();
    @Output() onHide = new EventEmitter<null>();
    @Output() onWishlist = new EventEmitter<null>();
    @Output() onRateSure = new EventEmitter<number>();
    @Output() onDate = new EventEmitter<Date>();

    selectedDate: any;

    get movieId(): number {

        return get(this.movieDetails, "movieId");
    }

    get movie(): object {
        return get(this.movieDetails, "movie");
    }

    get movieUserData(): object {
        return get(this.movieDetails, "movieUserData");
    }

    get rating(): number {
        return get(this.movieDetails, "movieUserData.rating", null);
    }

    get prediction(): number {
        return get(this.movieDetails, "movieUserData.prediction", null);
    }


    get hidden(): boolean {
        return get(this.movieDetails, "movieUserData.hidden", false);
    }

    get wishlisted(): boolean {
        return get(this.movieDetails, "movieUserData.wishlist", false);
    }

    get posterSrc(): string {
        return get(this.movieDetails, "movie.posterW185Url");
    }

    get posterSrcHq(): string {
        return get(this.movieDetails, "movie.posterW500Url");
    }

    getMovieData(path) {
        return get(this.movie, path);
    }

    isComplete(){
        if (this.isSeen){
            return this.date && (this.trueRating > 0);

        }
        if (this.isNotSeen){
            return (this.predictRating>0) && (this.rate>0);
        }
        if (!this.isSeen && !this.isNotSeen) return true;
        return false;
    }

    issafari() {
        const uA = navigator.userAgent;
        const vendor = navigator.vendor;
        if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
            return true;
        }
        return false;
    }


    MainCtrl($scope, dateFilter) {
        $scope.date = new Date(-8640000000000000);

        $scope.$watch('date', function (date) {
            $scope.dateString = dateFilter(date, 'yyyy-MM-dd');
            console.log('A', $scope.date, $scope.dateString);
        });

        $scope.$watch('dateString', function (dateString) {
            $scope.date = new Date(dateString);
            console.log('B', $scope.date, $scope.dateString);
        });
    }

}