import { UIRouterModule, UIRouter, is } from "@uirouter/angular";
import { NgModule } from "@angular/core";

function routerConfigFn(router: UIRouter) {
  // add a "deliberately encode/decode url components type"
  router.urlService.config.type("urlDecoded", {
    encode: obj => obj.toString(), // the return value from this gets urlenoded - avoid that.
    decode: str => decodeURIComponent(str),
    is: is(String),
    pattern: new RegExp(".*"),
    equals: (a: any, b: any) => a == b
  });
}

@NgModule({
  imports: [
    // 3rd party
    UIRouterModule.forChild({ config: routerConfigFn })
  ]
})
export class UrlEncoderTypeModule {}
