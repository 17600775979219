import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { get } from "lodash";
import { RecaptchaComponent } from "ng-recaptcha";

import { UserDataService } from "../../common/user-data/user-data.service";

@Component({
  selector: "register-form",
  template: `
    <div class="register-form">
    
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      
        <div class="row">
          <div class="col-md-6">
        
            <div class="form-group">
              <label>
                email
                <div class="validation-msg" *ngIf="email.invalid && (email.dirty || email.touched)">
                  <span class="small error" *ngIf="email.errors.required">please enter an email address</span>
                  <span class="small error" *ngIf="email.errors.email">please enter a valid email address</span>
                  <span class="small error" *ngIf="email.errors.notUnique">already in use</span>
                  <span class="small error" *ngIf="email.errors.serverError">server error (sorry! try back later)</span>
                </div>
                <span class="small" *ngIf="email.pending"><i class="fa fa-cog fa-spin"></i></span>
                <span class="small success" *ngIf="email.valid && email.dirty"><i class="fa fa-check"></i></span>
                <input type="email" class="form-control" formControlName="email" autofocus required>
              </label>
            </div>
          
            <div class="form-group">
              <label>
                username
                <div class="validation-msg" *ngIf="username.invalid && (username.dirty || username.touched)">
                  <span class="small error" *ngIf="username.errors.required">please enter a username</span>
                  <span class="small error" *ngIf="username.errors.minlength">username must be at least 3 characters</span>
                  <span class="small error" *ngIf="username.errors.maxlength">username must be at most 30 characters</span>
                  <span class="small error" *ngIf="username.errors.notUnique">already in use</span>
                  <span class="small error" *ngIf="username.errors.serverError">server error (sorry! try back later)</span>
                </div>
                <span class="small" *ngIf="username.pending"><i class="fa fa-cog fa-spin"></i></span>
                <span class="small success" *ngIf="username.valid && username.dirty"><i class="fa fa-check"></i></span>
                <input type="text" class="form-control" formControlName="username" minlength="3" maxlength="30" required>
              </label>
            </div>
                        
            <div class="form-group">
              <label>
                password
                <div class="validation-msg" *ngIf="inputPass1.invalid && inputPass1.touched">
                  <span class="small error" *ngIf="inputPass1.errors.required">please enter a password</span>
                  <span class="small error" *ngIf="inputPass1.errors.minlength">password must be at least 6 characters</span>
                  <span class="small error" *ngIf="inputPass1.errors.maxlength">password must be at most 50 characters</span>
                </div>
                <input type="password" class="form-control" formControlName="inputPass1" minlength="6" maxlength="50" required>
              </label>
            </div>
            
            <div class="form-group">
              <label>
                retype password
                <div class="validation-msg" *ngIf="inputPass2.invalid && inputPass2.touched">
                  <span class="small error" *ngIf="inputPass2.errors.required">please enter a password</span>
                  <span class="small error" *ngIf="inputPass2.errors.badMatch">passwords do not match</span>
                </div>
                <input type="password" class="form-control" formControlName="inputPass2" required>
              </label>
            </div>
            
        
          </div><!-- end col -->
          <div class="col-md-6">
            
            <div class="form-group">
              <label>
                current city
                <location-field-new formControlName="location"></location-field-new>
              </label>
            </div>
            
            <div class="form-group">
              <label>
                how did you hear about movielens?
                <select class="form-control" formControlName="survey">
                  <option value="" selected disabled>select one...</option>
                  <option *ngFor="let opt of surveyOpts" [value]="opt.val">{{ opt.desc }}</option>
                </select>
                <input type="text" class="form-control" style="margin-top: 10px;" formControlName="surveyMore" placeholder="more details">
              </label>
            </div>
            
          </div><!-- end col -->
        </div><!-- end row -->
        
        <div style="margin-top: 10px;">
          <label>
            prove that you are not a computer program
            <div class="validation-msg" *ngIf="recaptcha.invalid && clickedButton">
              <span class="small error" *ngIf="recaptcha.errors.required">recaptcha is required</span>
            </div>
            <re-captcha #captchaRef siteKey="6LciTEoqAAAAAIybm0Ys7-oFr7efx851dLQCxfow" formControlName="recaptcha" required></re-captcha>
          </label>
        </div>
        
        <div style="margin-top: 20px;">
          <label>
            <input type="checkbox" formControlName="iAgree" required>
            I agree to the MovieLens
            <a uiSref="global.termsOfUse" target="_blank">Terms of Use</a> and
            <a uiSref="global.privacyPolicy" target="_blank">Privacy Policy</a>.
            <div class="validation-msg" *ngIf="iAgree.invalid && clickedButton">
              <span class="small error" *ngIf="iAgree.errors.required">required</span>
            </div>
          </label>
        </div>
                
        <div class="form-group" style="margin-top: 15px;">
          <div *ngIf="serverValidationMsg">
            <span class="small error">{{ serverValidationMsg }}</span>
          </div>
          <button type="submit" class="btn btn-default" [disabled]="thinking">register</button>
          <span style="margin-left: 5px;" [hidden]="!thinking">
            <i class="fa fa-cog fa-spin"></i>
          </span>
        </div>
      
      </form>
      
    </div>
  `,
  styles: [
    `
      label {
        width: 100%;
      }
      .validation-msg {
        display: inline;
      }
    `
  ]
})
export class RegisterFormComponent {
  @Output() onRegisterSuccess = new EventEmitter<null>();
  @ViewChild("captchaRef") captchaRef: RecaptchaComponent;
  private registerForm: FormGroup;
  private clickedButton: boolean = false;
  private thinking: boolean = false;
  private serverValidationMsg: string = null;
  private surveyOpts = [
    { val: "search_engine", desc: "Search engine" },
    { val: "friend", desc: "Friend" },
    { val: "website", desc: "Web site" },
    { val: "print_article", desc: "Print article" },
    { val: "radio", desc: "Radio" },
    { val: "academic", desc: "School or scholarly research" },
    { val: "Other", desc: "Other" }
  ];

  constructor(
    private fb: FormBuilder,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    // docs: https://angular.io/guide/reactive-forms
    // docs: https://angular.io/guide/form-validation
    this.registerForm = this.fb.group({
      email: [
        "",
        {
          validators: [Validators.required, Validators.email],
          asyncValidators: this.userDataService.getEmailIsUniqueValidator(),
          // run validators when field is blurred, rather than on change
          updateOn: "blur"
        }
      ],
      username: [
        "",
        {
          validators: [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30)
          ],
          asyncValidators: this.userDataService.getUsernameIsUniqueValidator(),
          updateOn: "blur"
        }
      ],
      inputPass1: [
        "",
        {
          validators: [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(50)
          ]
          // updateOn: "blur"
        }
      ],
      inputPass2: [
        "",
        {
          validators: [Validators.required, this.validatePasswordMatch]
          // updateOn: "blur"
        }
      ],
      location: [""],
      survey: [""],
      surveyMore: [""],
      // docs: https://github.com/DethAriel/ng-recaptcha
      recaptcha: [null, Validators.required],
      iAgree: [null, Validators.required]
    });
  }

  get email() {
    return this.registerForm.get("email");
  }
  get username() {
    return this.registerForm.get("username");
  }
  get inputPass1() {
    return this.registerForm.get("inputPass1");
  }
  get inputPass2() {
    return this.registerForm.get("inputPass2");
  }
  get recaptcha() {
    return this.registerForm.get("recaptcha");
  }
  get iAgree() {
    return this.registerForm.get("iAgree");
  }

  // validation method to ensure the two passwords are equal
  validatePasswordMatch(control: AbstractControl) {
    let inputPass1 = control.root.get("inputPass1");
    let inputPass2 = control;

    if (!inputPass1 || inputPass1.pristine || inputPass2.pristine) {
      return null;
    }

    return inputPass1.value === inputPass2.value
      ? null
      : {
          badMatch: true
        };
  }

  _onServerSideError(msg: String) {
    this.thinking = false;

    // reload the captcha because each challenge can be checked just once
    this.captchaRef.reset();

    if (msg === "captcha") {
      this.serverValidationMsg = "incorrect response to recaptcha, try again";
    } else if (msg === "exception") {
      this.serverValidationMsg = "server error (sorry! try again later)";
    } else {
      this.serverValidationMsg = "registration failed, please check your work";
    }
  }

  // noinspection JSUnusedGlobalSymbols
  onSubmit(): void {
    // display all validation
    this.clickedButton = true;

    if (this.registerForm.invalid) {
      // form is not ready to submit, short circuit
      this.serverValidationMsg = "please double-check your responses";
      return;
    }

    // clear any errors
    this.serverValidationMsg = "";

    this.thinking = true;

    this.userDataService.register(
      this.registerForm.value,
      response => {
        this.thinking = false;
        const status = get(response, "status");
        if (status === "fail") {
          this._onServerSideError(get(response, "message"));
        } else {
          this.onRegisterSuccess.emit(null);
        }
      },
      error => {
        this.thinking = false;
        console.error(error);
        this._onServerSideError("exception");
      }
    );
  }
}
