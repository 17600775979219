import { Component, Input, OnInit } from "@angular/core";

const tmpl = require("./explanations-rating-stars.component.html");

@Component({
  selector: "explanations-rating-stars",
  template: tmpl
})
export class ExplanationsRatingStarsComponent implements OnInit {
  @Input() recPrediction: number;

  private size: number = 24;
  private recPredictionRounded = null;
  private values = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  ngOnInit(): void {
    // round
    this.recPredictionRounded = Math.round(this.recPrediction * 2) / 2;
  }

  getStarBoxStyle(odd) {
    // hard code spacing between stars
    const spacing = odd ? 2 : 0;
    const w = Math.floor(this.size / 2.0) + spacing;
    const h = this.size + 2;
    return {
      width: `${w}px`,
      height: `${h}px`
    };
  }

  getStarStyle(odd) {
    const w = Math.floor(this.size / 2.0);
    const marginLeft = odd ? `-${w}px` : "0px";
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      "margin-left": marginLeft
    };
  }

  getStarShapeStyle(i) {
    if (this.values[i] <= this.recPredictionRounded) {
      return { fill: "#3bace1" };
    }
  }
}
