"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { get } from "lodash";

import "./movie-card-md1.scss";
import { DateFormatterService } from "../../common/date-formatter/date-formatter.service";


/**
 * Fully featured medium portrait card (176w x 320h)
 */
@Component({
  selector: "movie-card-md1",
  template: `
    <div class="movie-card-md1">
      <movie-card-actions-menu
        [wishlisted]="wishlisted"
        [hidden]="hidden"
        [userRating]="rating"
        [movieId] = "movieId"
        (onWishClick)="this.onWishlist.emit()"
        (onHideClick)="this.onHide.emit()"
        (onClearRatingClick)="this.onClearRating.emit()"
      ></movie-card-actions-menu>

      <div class="card">
        <link-or-emit
          [movieId]="movieId"
          [doLink]="doLink"
          (onClick)="onClick.emit()"
        >
          <div
            class="poster"
            [class.poster-hidden]="this.hidden"
            [class.empty-poster]="!posterSrc && !posterPath"
          >
            <img
              *ngIf="posterSrc || posterPath"
              [src]="posterSrc || this.getPosterSrcFromPath()"
              srcset="{{ posterSrcHq || this.getHQPosterSrcFromPath() }} 2x"
              alt="poster for {{ getMovieData('title') }}"
            />
          </div>
        </link-or-emit>

        <div class="overlay">
          <div class="above-fold">
            <link-or-emit
              [movieId]="movieId"
              [doLink]="doLink"
              (onClick)="onClick.emit()"
            >
              <p class="title">{{ getMovieData("title") }}</p>
            </link-or-emit>

            <div class="rating-indicator">
              <ml4-rating-or-prediction
                [userRating]="rating"
                [predictedRating]="prediction"
                [averageRating]="getMovieData('avgRating')"
              ></ml4-rating-or-prediction>
              <span class="wishlist" *ngIf="wishlisted">
                <span class="fa fa-thumb-tack"></span>
              </span>
            </div>
            <p class="year">{{ getMovieData("releaseYear") }}</p>
            <p class="dateRated" *ngIf = "dateRated != null"> Rated: {{ dateRated }}</p>
          </div>

          <div class="below-fold">
            <div class="genre-list">
              <div class="genre" *ngFor="let genre of getMovieData('genres')">
                <a
                  uiSref="exploreGenreShortcut"
                  [uiParams]="{ id: genre | urlFragmentNew }"
                  [uiOptions]="{ inherit: false }"
                  ><b>{{ genre }}</b></a
                >
              </div>
            </div>

            <div class="ratings-display">
              <div class="rating-predicted">
                <span class="rating-large">{{
                  prediction | number: "1.1-1"
                }}</span>
                <span class="rating-total">/5</span>
                <p class="rating-caption">predicted</p>
              </div>
              <div class="rating-average">
                <span class="rating-large">{{
                  getMovieData("avgRating") | number: "1.1-1"
                }}</span>
                <span class="rating-total">/5</span>
                <p class="rating-caption">
                  {{ getMovieData("numRatings") }} ratings
                </p>
              </div>
            </div>

            <div class="rating-entry">
              <rating
                [size]="24"
                [predictedRating]="prediction"
                [userRating]="rating"
                [showTooltip]="false"
                [ratingTooltipPosition]="'top'"
                (onRate)="onRate.emit($event)"
                (onClearRating)="onClearRating.emit()"
              ></rating>
            </div>
          </div>
          <!-- end overlay -->
        </div>
        <!-- end card -->
      </div>
    </div>
  `
})


export class MovieCardMd1Component {
  @Input() movieDetails: any = null;
  @Input() doLink: boolean = true;
  @Output() onClick = new EventEmitter<null>();
  @Output() onRate = new EventEmitter<number>();
  @Output() onClearRating = new EventEmitter<null>();
  @Output() onHide = new EventEmitter<null>();
  @Output() onWishlist = new EventEmitter<null>();

  constructor(private dateFormatter: DateFormatterService) {}

  get movieId(): number {
    return get(this.movieDetails, "movieId");
  }

  get movie(): object {
    return get(this.movieDetails, "movie");
  }

  get movieUserData(): object {
    return get(this.movieDetails, "movieUserData");
  }

  get rating(): number {
    return get(this.movieDetails, "movieUserData.rating", null);
  }

  get prediction(): number {
    return get(this.movieDetails, "movieUserData.prediction", null);
  }

  get dateRated(): String {
    return this.dateFormatter.formattedDate(get(this.movieDetails, "movieUserData.dateRated", null));
  }

  get hidden(): boolean {
    return get(this.movieDetails, "movieUserData.hidden", false);
  }

  get wishlisted(): boolean {
    return get(this.movieDetails, "movieUserData.wishlist", false);
  }

  get posterSrc(): string {
    return get(this.movieDetails, "movie.posterW185Url");
  }

  get posterPath() : string {
      return get(this.movieDetails, "movie.posterPath");
  }

  getPosterSrcFromPath() {
      return "//image.tmdb.org/t/p/w185" + get(this.movieDetails, "movie.posterPath");
  }

  getHQPosterSrcFromPath() {
        return "//image.tmdb.org/t/p/w500" + get(this.movieDetails, "movie.posterPath");
  }

  get posterSrcHq(): string {
    return get(this.movieDetails, "movie.posterW500Url");
  }

  getMovieData(path) {
    return get(this.movie, path);
  }
}
