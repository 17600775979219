"use strict";

import { Component } from "@angular/core";
import { get } from "lodash";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";

import { DeferredUrlService } from "../../common/deferred-url/deferred-url.service";
import { SessionService } from "../../common/session/session.service";
import { FlashService } from "../../common/flash/flash.service";
import { StateService, UrlService } from "@uirouter/core";
import {ApiService} from "../../common/api/api.service";
import {Subscription} from "rxjs";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

@Component({
  selector: "after-login-redirect",
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  template: `<div class="after-login-redirect"></div>`
})
export class AfterLoginRedirectComponent {

    // llm-rec sophia sun00587 start
    private userSubscription: Subscription;
    // llm-rec sophia sun00587 end
  constructor(
    private deferredUrlService: DeferredUrlService,
    private flashService: FlashService,
    private location: Location,
    private urlService: UrlService,
    private sessionService: SessionService,
    private stateService: StateService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.maybeShowPrivacyPolicyUpdate();
    this.redirectOrGoHome();
  }

  redirectOrGoHome() {
      if (this.deferredUrlService.hasNextUrl()) {
          setTimeout(() => {
              this.location.go(this.deferredUrlService.popNextUrl());
              this.urlService.sync();
          });
      } else {
          setTimeout(() => this.stateService.go("base.frontpage"));
      }
  }

  maybeShowPrivacyPolicyUpdate() {
    const privacyPolicyHasChanged = get(
      this.sessionService.getUserData(),
      "account.privacyPolicyHasChanged",
      false
    );

    if (privacyPolicyHasChanged) {
      this.flashService
        .addInfo(
          "Welcome back! " +
            "We have updated our <a href='/info/terms-of-use'>terms of use</a> " +
            "and <a href='/info/privacy-policy'>privacy policy</a>."
        )
        .show();
    }
  }
}
