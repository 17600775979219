"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";

/**
 * Either wrap the ng-content in a link, or emit onClick.
 */
@Component({
  selector: "link-or-emit",
  template: `
    <a
      uiSref="base.movie"
      [uiParams]="{ id: movieId }"
      *ngIf="doLink; else elseTpl"
    >
      <ng-content *ngTemplateOutlet="elseTpl"></ng-content>
    </a>
    <ng-template #elseTpl>
      <span (click)="maybeEmitOnClick()">
        <ng-content></ng-content>
      </span>
    </ng-template>
  `
})
export class LinkOrEmitComponent {
  @Input() doLink: boolean = true;
  @Input() movieId: number;
  @Output() onClick = new EventEmitter<null>();

  maybeEmitOnClick() {
    if (!this.doLink) {
      this.onClick.emit();
    }
  }
}
