"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AdminTemplateComponent } from "./admin-template.component";
import { AdminIndexPageComponent } from "./admin-index-page.component";
import { AdminMovieFlagsPageComponent } from "./admin-movie-flags-page.component";
import { AdminMovieFlagRowComponent } from "./admin-movie-flag-row.component";
import { SessionService } from "../../common/session/session.service";
import { ResolvableLiteral, TransitionService } from "@uirouter/core";
import { UIRouterModule } from "@uirouter/angular";
import { AdminMoviePageComponent } from "./admin-movie-page.component";
import { AdminMovieHistoryRowComponent } from "./admin-movie-history-row.component";
import { AdminMovieEditPageComponent } from "./admin-movie-edit-page.component";
import { AdminAutoAddMoviePageComponent } from "./admin-add-movie-page.component";
import { AdminTasksPageComponent } from "./admin-tasks-page.component";
import { AddMovieService } from "../add-movie/add-movie.service";
import { AdminAddMovieService } from "./admin-add-movie.service";
import { AdminExptTable } from "./admin-expt-table.component";
import { AdminExptPageComponent } from "./admin-expt-page.component";

export function configureAdmin(transitionService: TransitionService) {
  const requiresAdmin = {
    to: state => state.data && state.data.requiresAdmin
  };

  const checkAdmin = async transition => {
    const sessionService: SessionService = transition
      .injector()
      .get(SessionService);
    const stateService = transition.router.stateService;
    const redirect = await sessionService.verifyAdmin();
    if (redirect) {
      return stateService.target(redirect);
    }
    return;
  };

  transitionService.onBefore(requiresAdmin, checkAdmin, { priority: 10 });
}

const adminTemplate = {
  name: "admin",
  abstract: true,
  data: { requiresAdmin: true },
  component: AdminTemplateComponent
};

const adminIndexPage = {
  name: "admin.adminHome",
  url: "/admin",
  component: AdminIndexPageComponent
};

const adminMoviePage = {
  name: "admin.adminMovie",
  url: "/admin/movie/:id",
  component: AdminMoviePageComponent
};
const adminTasksPage = {
  name: "admin.adminTasks",
  url: "/admin/tasks",
  component: AdminTasksPageComponent
};

const adminMovieEditPage = {
  name: "admin.adminMovieEdit",
  url: "/admin/movie/:id/edit",
  component: AdminMovieEditPageComponent
};

const adminMovieFlagsPage = {
  name: "admin.movieFlagEvents",
  url: "/admin/movie-flag-events",
  component: AdminMovieFlagsPageComponent
};

const adminAutoAddMovie = {
  name: "admin.addMovie",
  url: "/admin/add-a-title",
  component: AdminAutoAddMoviePageComponent
};

const adminEditExpt = {
  name: "admin.adminExpt",
  url: "/admin/expt/:exptId",
  component: AdminExptPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({
      states: [
        adminTemplate,
        adminIndexPage,
        adminMovieFlagsPage,
        adminMoviePage,
        adminMovieEditPage,
        adminAutoAddMovie,
        adminTasksPage,
        adminEditExpt
      ]
    })
  ],
  declarations: [
    AdminTemplateComponent,
    AdminIndexPageComponent,
    AdminMovieFlagsPageComponent,
    AdminMovieFlagRowComponent,
    AdminMovieHistoryRowComponent,
    AdminMoviePageComponent,
    AdminMovieEditPageComponent,
    AdminAutoAddMoviePageComponent,
    AdminTasksPageComponent,
    AdminExptTable,
    AdminExptPageComponent
  ],
  providers: [AdminAddMovieService]
})
export class AdminModule {}
