import { Component } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "emoji-feedback",
  template: require("./emoji-feedback.html")
})

export class EmojiFeedbackComponent {
  private show: boolean = true;
  private surveyData: {};

  private feedbackForm: FormGroup;

  constructor(private fb: FormBuilder, private apiService: ApiService) { }

  ngOnInit() {
    this.feedbackForm= this.fb.group({
      opinion: [
        ""
      ],
      feedback: [
        ""
      ],
    });
  }

  get opinion() {
    return this.feedbackForm.get("opinion");
  }

  get feedback() {
    return this.feedbackForm.get("feedback");
  }

  onSubmit(): void {
    this.surveyData = {
      "opinion" : this.opinion.value,
      "feedback" : this.feedback.value
    };
    this.apiService.ml4Post(
      "POST /api/emoji/storeFeedback",
      "/api/emoji/storeFeedback",
      this.surveyData,
      response => {
        this.show = false;
      }
    );
  }
}