"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import "./alexa-movie-page.scss";
import { StateService } from "@uirouter/core";
import { MovieDataService } from "../../common/movie-data/movie-data.service";

@Component({
  selector: "alexa-movie-page",
  template: `
    <div class="alexa-about-movie" *ngIf="movie">
      <div class="tv-nav tv-nav-top">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        "go back"
      </div>

      <!-- multiple matches -->
      <div *ngIf="hasMore">
        <div class="tv-nav tv-nav-top text-center">
          there are <u>multiple matches</u> for "{{ query }}"
        </div>
        <div class="tv-nav tv-nav-top text-right">
          <i class="fa fa-clone" aria-hidden="true"></i>
          "show me the next one"
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
      </div>

      <!-- default -->
      <div *ngIf="!hasMore">
        <div class="tv-nav tv-nav-top text-center" *ngIf="query">
          showing results for: "{{ query }}"
        </div>

        <div class="tv-nav tv-nav-top text-right">
          "show me similar movies"
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
      </div>

      <div class="tv-content">
        <div class="left-container">
          <img [src]="movie.posterOrigUrl" class="big-poster" imgPreload />
        </div>

        <div class="right-container">
          <h1 class="movie-title huge">{{ movie.title }}</h1>

          <div class="about-movie-section">
            <span *ngIf="movie.releaseDate">{{
              movie.releaseDate | date: "yyyy"
            }}</span>
            &nbsp;
            <span class="mpaa" *ngIf="movie.mpaa">{{ movie.mpaa }}</span>
            <span *ngIf="movie.runtime" style="margin-left: 18px;">{{ movie.runtime }} minutes</span>
          </div>

          <div class="about-movie-section" *ngIf="movie.genres">
            <span *ngFor="let genre of movie.genres; let last = last">
              {{ genre }}{{ last ? "" : ",  " }}
            </span>
          </div>

          <div class="plot-summary about-movie-section">
            {{ movie.plotSummary }}
          </div>

          <div class="about-movie-section">
            <span
              *ngFor="let actor of movie.actors.slice(0, 5); let last = last"
              >{{ actor }}{{ last ? "" : ", " }}</span
            >
          </div>

          <div class="star-prediction about-movie-section">
            <span style="display: inline-block; width: 310px;"
              >MovieLens predicts:</span
            >
            <i class="fa fa-star-o" aria-hidden="true"></i>
            {{ movieUserData.prediction | number: "1.1-1" }}
          </div>

          <div class="avg-rating">
            <span style="display: inline-block; width: 310px;">
              Average of {{ movie.numRatings | number }} ratings:
            </span>
            <i class="fa fa-star-o" aria-hidden="true"></i>
            {{ movie.avgRating | number: "1.1-1" }}
          </div>

          <div class="play-trailer-container" *ngIf="movie.hasTrailer">
            <img [src]="movie.firstBackdropW300Url" imgPreload />
            <div class="play-trailer-button">
              <i class="fa fa-play-circle"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="tv-nav tv-nav-bottom text-center">
        <i class="fa fa-microphone"></i>
        "add to wishlist"

        <span *ngIf="hasTrailer">
          &nbsp; &nbsp;
          <i class="fa fa-microphone"></i>
          "play trailer"
        </span>
      </div>
    </div>
  `
})
export class AlexaMoviePageComponent implements OnInit {
  query = "";
  id = null;
  hasMore = false;
  hasTrailer = false;

  movie = null;
  movieDetails = null;
  movieUserData = null;

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Movie Details | MovieLens");

    this.query = this.stateService.params.query;
    this.id = parseInt(this.stateService.params.movie);
    this.hasMore = this.stateService.params.more === "true";

    this.movieDataService.getMovie(this.id, movieDetails => {
      let movie = (this.movie = movieDetails.movie);
      this.movieDetails = movieDetails;
      this.movieUserData = movieDetails.movieUserData;

      this.titleService.setTitle(`${movie.title} | MovieLens`);
    });
  }
}
