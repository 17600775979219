"use strict";

import { Injectable, OnDestroy } from "@angular/core";

import { ApiService } from "../api/api.service";
import { SessionService } from "../session/session.service";

@Injectable()
export class RectuneService implements OnDestroy {
  private readonly userSubscription;
  private readonly ratingSubscription;

  private engineId: string;
  private numRatings: number;

  constructor(
    private apiService: ApiService,
    private sessionService: SessionService
  ) {
    this.userSubscription = this.sessionService.currentUserData.subscribe(
      data => {
        this.engineId = data.preferences.recommender.engineId;
      }
    );
    this.ratingSubscription = this.sessionService.currentRatingCount.subscribe(
      num => {
        this.numRatings = num;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.ratingSubscription) {
      this.ratingSubscription.unsubscribe();
    }
  }

  isUserQualified() {
    let hasTunableRecommender =
      this.engineId === "warrior" || this.engineId === "wizard";
    let hasEnoughRatings =
      this.numRatings >= this.sessionService.RATING_THRESHOLD;

    return hasTunableRecommender && hasEnoughRatings;
  }

  logInit() {
    this.apiService.ml4Post(
      "POST /api/rectune-expt/log-init",
      "/api/rectune-expt/log-init",
      {}
    );
  }

  logDone() {
    this.apiService.ml4Post(
      "POST /api/rectune-expt/log-done",
      "/api/rectune-expt/log-done",
      {}
    );
  }

  log(eventType, log) {
    this.apiService.ml4Post(
      "POST /api/rectune-expt/log",
      "/api/rectune-expt/log",
      { eventType, log }
    );
  }
}
