"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import { ApiActivityModule } from "../../common/api-activity/api-activity.module";

import { RestrictedTemplateComponent } from "./restricted-template.component";
import {UIRouterModule} from "@uirouter/angular";

const profileTemplate = {
  name: "restricted",
  abstract: true,
  // to use the restricted state, you must be logged in
  parent: "loggedIn",
  component: RestrictedTemplateComponent
};

@NgModule({
  imports: [
    ApiActivityModule,
    BrowserModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({
      states: [profileTemplate]
    })
  ],
  declarations: [RestrictedTemplateComponent]
})
export class RestrictedTemplateModule {}
