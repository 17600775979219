"use strict";

import { Component } from "@angular/core";

@Component({
  selector: "alexa-welcome",
  template: `
    <div class="alexa-welcome-page">
      <div class="title">
        Hi! Welcome to MovieLens TV
      </div>

      <div class="section hide-if-connected">
        To get started, say:
        <div class="emphasis">
          "Alexa, open movie lens"
        </div>
      </div>

      <div class="section if-connected">
        Instructions:

        <ul>
          <li>
            Ask for movie searches starting with "Alexa". For example: "Alexa,
            show me action movies"
          </li>
          <li>
            Say "help" any time to learn more about what you can do.
          </li>
          <li>
            Each screen will show you things you can say in "quotes". Try these
            out!
          </li>
        </ul>
      </div>

      <div class="section if-connected">
        To get started, try one of these:

        <ul>
          <li>
            "Alexa, show me action movies"
          </li>
          <li>
            "Alexa, tell me about the avengers"
          </li>
          <li>
            "Alexa, I'm looking for movies like the godfather"
          </li>
        </ul>
      </div>
    </div>
  `
})
export class AlexaWelcomePageComponent {}
