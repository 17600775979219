"use strict";

import {Component, NgModule} from "@angular/core";
import {UserProfilePageComponent} from "./user-profile-page.component";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalDialogModule} from "../../components/modal-dialog/modal-dialog.module";
import {CommonModule} from "@angular/common";
import {DropdownModule} from "../../components/dropdown-new/dropdown.module";
import {PipesModule} from "../../common/pipes/pipes.module";
import {RatingModule} from "../../components/rating/rating.module";
import {RatingOrPredictionModule} from "../../components/rating-or-prediction/rating-or-prediction.module";
import {YoutubeModule} from "../../components/youtube/youtube.module";
import {UIRouterModule} from "@uirouter/angular";
import {ExternalRecsModule} from "../externalrecs/external-recs.module";
import {AngularMyDatePickerModule} from "angular-mydatepicker";
import { InteractiveProfileService } from "./interactive_profile.service";

export const userProfilePage = {
    states: [
        {
            name: "base.userProfilePage",
            url: "/userProfilePage",
            component: UserProfilePageComponent
        }
    ]
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ModalDialogModule,
        CommonModule,
        DropdownModule,
        ModalDialogModule,
        PipesModule,
        RatingModule,
        RatingOrPredictionModule,
        YoutubeModule,
        UIRouterModule.forChild(userProfilePage),
        ExternalRecsModule,
        ReactiveFormsModule,
        AngularMyDatePickerModule
    ],
    exports: [
    ],
    declarations: [
        UserProfilePageComponent
    ],
    entryComponents: [
        UserProfilePageComponent
    ],
    providers : [InteractiveProfileService]
    // providers: []
})

export class UserProfilePageModule {
}