"use strict";

import { NgModule } from "@angular/core";
import { NvD3Module } from "ngx-nvd3";

import { BrowserModule } from "@angular/platform-browser";


import { AboutYourRatingsPageComponent } from "./about-your-ratings-page.component";
import { MovieRowModule } from "../../components/movie-row/movie-row.module";
import {UIRouterModule} from "@uirouter/angular";

const aboutYourRatingsPage = {
  name: "base.aboutYourRatings",
  url: "/profile/about-your-ratings",
  component: AboutYourRatingsPageComponent
};

@NgModule({
  imports: [
    NvD3Module,
    MovieRowModule,
    BrowserModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [aboutYourRatingsPage] })
  ],
  declarations: [AboutYourRatingsPageComponent]
})
export class AboutYourRatingsModule {}
