import {
  Component,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import {TagPageService} from "./tag-page.service";
import {FlashService} from "../../common/flash/flash.service";

@Component({
  selector: "tag-report",
  template: require("./tag-report.html")
})
export class TagReportComponent implements OnInit {
  @Input() tag: string;
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;

  reportForm: FormGroup;

  isOpen = false;

  constructor(private fb: FormBuilder, private tagPageService: TagPageService, private flashService: FlashService) {
    this.reportForm = this.fb.group({
      reason: [null, Validators.required],
      other: [{ value: null, disabled: true }]
    });
  }

  clear() {
    this.reportForm.setValue({
      reason: null,
      other: null
    });
    this.submitReport();
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
  open() {
    this.isOpen = true;
  }
  close() {
    this.isOpen = false;
  }

  submitReport() {
    const data = {
      tag: this.tag,
      reason: this.reportForm.controls.reason.value,
      other: this.reportForm.controls.other.value
    };
    // console.log("submitting data: ", data);
    this.tagPageService.submitTagReport(data, () => {
      this.flashService.addInfo("thank you for your report").show();
    }, () => {})
    this.close();
  }

  ngOnInit() {
    this.reportForm.get("reason").valueChanges.subscribe(val => {
      if (val == "other") {
        this.reportForm.get("other").enable();
      } else {
        this.reportForm.get("other").disable();
      }
    });
  }
}
