"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../../common/api/api.service";

import { UserListsService } from "../../common/user-lists/user-lists.service";
import { DateFormatterService } from "../../common/date-formatter/date-formatter.service";

@Component({
  selector: "user-lists-page",
  template: `
    <div class="user-lists-page">
      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '22px 0px 11px 0px',
          textAlign: 'center'
        }"
      >
        <h1 [ngStyle]="{ margin: '0px' }">Your Lists</h1>
        <button
          class="btn btn-default"
          [ngStyle]="{ marginLeft: 'auto'}"
          (click)="openCreateModal()"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <div class="table-responsive" *ngIf="lists">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Title</th>
              <th>Visibility</th>
              <th>Description</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of lists; let i = index">
              <td>
                <a
                  uiSref="base.userListMovies"
                  [uiParams]="{ userId: list.userId, listId: list.listId }"
                >
                  {{ list.title }}
                </a>
              </td>
              <td>
                {{ list.publicList ? "Public" : "Private" }}
              </td>
              <td>
                {{ list.description }}
              </td>
              <td>
                {{ this.dateFormatterService.formattedDateFromTimestamp(list.lastUpdated) }}
              </td>
              <td>
                <span>
                  <button
                    class="btn btn-primary"
                    [ngStyle]="{ marginRight: '10px' }"
                    (click)="openEditModal(i)"
                  >
                    Edit
                  </button>
                  <button class="btn btn-danger" (click)="deleteList(i)">
                    Delete
                  </button>
                  <span
                    *ngIf="list.busy"
                    class="tag-busy-indicator-cog"
                    [ngStyle]="{ marginLeft: '10px' }"
                    ><i class="fa fa-cog fa-spin"></i
                  ></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <user-lists-modal
        [editModal]="isEditModal"
        [modalData]="modalData"
        (updateExistingUserList)="updateUserList($event)"
        (createNewUserList)="addNewUserList($event)"
      ></user-lists-modal>
    </div>
  `
})
export class UserListsPageComponent {
  lists;
  isEditModal = true;

  modalData = {
    showModal: false
  };

  constructor(
    private apiService: ApiService,
    private titleService: Title,
    private userListsService: UserListsService,
    private dateFormatterService: DateFormatterService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Your Lists | MovieLens");
    this.loadListData();
  }

  openEditModal(index) {
    let newModalData = {
      title: this.lists[index].title,
      description: this.lists[index].description,
      publicList: this.lists[index].publicList,
      showModal: true,
      listId: this.lists[index].listId,
      index: index
    };

    this.isEditModal = true;
    this.modalData = newModalData;
  }

  openCreateModal() {
    this.isEditModal = false;
    this.modalData = { showModal: true };
  }

  updateUserList(obj) {
    let index = obj.index;
    this.lists[index].title = obj.title;
    this.lists[index].description = obj.description;
    this.lists[index].publicList = obj.publicList;
    this.lists[index].lastUpdated = this.dateFormatterService.formattedDateFromTimestamp(new Date().getTime());
  }

  addNewUserList(obj) {
    obj.lastUpdated = this.dateFormatterService.formattedDateFromTimestamp(new Date().getTime());
    console.log(obj);
    this.lists.unshift(obj);
  }

  deleteList(index) {
    if (this.lists[index].busy) {
      return;
    }
    this.lists[index].busy = true;
    this.userListsService.deleteUserList(this.lists[index].listId, () => {
      this.lists[index].busy = false;
      this.lists.splice(index, 1);
    });
  }


  loadListData() {
    this.apiService.ml4Get(
      "GET /api/users/me/lists",
      "/api/users/me/lists",
      {},
      response => {
        this.lists = response.data;
      }
    );
  }
}
