"use strict";

import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { Title } from "@angular/platform-browser";
import { clone, get, isObject, merge } from "lodash";

import { MovieDataService } from "../../common/movie-data/movie-data.service";

import "./movie-tuner-page.scss";
import {Location} from "@angular/common";

@Component({
  selector: "movie-tuner-page",
  template: `
    <div class="movie-tuner" *ngIf="movieTitle">
      <h1>
        Movies like
        <a uiSref="base.movie" [uiParams]="{ id: id }">{{ movieTitle }}</a>
      </h1>

      <div *ngIf="similarMovies">
        <div class="tuner-subhead" *ngIf="critique == null">
          want more {{ defaultTags[0] }}?
        </div>

        <div
          class="tuner-subhead"
          *ngFor="
            let critique of getCritiqueArrayWithoutOk() | keyvalue;
            let last = last;
            let first = first
          "
        >
          <span *ngIf="value != 'ok'">
            {{ first ? "but " : "" }}<b>{{ critique.value }}</b>
            {{ critique.key }}{{ last ? "" : ",&nbsp;" }}
          </span>
        </div>

        <button
         this class="btn btn-default btn-xs"
          (click)="showTuner = !showTuner"
          *ngIf="critiqueArray"
        >
          <i class="fa fa-gear fa-1x"></i>
        </button>
      </div>

      <div class="tuner-controls small" *ngIf="showTuner">
        <table class="table table-condensed">
          <thead>
            <tr>
              <th class="space"></th>
              <th class="text-center">less</th>
              <th class="text-center">ok</th>
              <th class="text-center">more</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let tag of allTags">
              <td class="tag">{{ tag }}</td>
              <td class="tuner-radio">
                <input
                  type="radio"
                  [(ngModel)]="critiqueArray[tag]"
                  value="less"
                  name="{{ tag }}"
                  (click)="updateCritique(tag, 'less')"
                />
              </td>
              <td class="tuner-radio">
                <input
                  type="radio"
                  [(ngModel)]="critiqueArray[tag]"
                  value="ok"
                  name="{{ tag }}"
                  (click)="updateCritique(tag, 'ok')"
                />
              </td>
              <td class="tuner-radio">
                <input
                  type="radio"
                  [(ngModel)]="critiqueArray[tag]"
                  value="more"
                  name="{{ tag }}"
                  (click)="updateCritique(tag, 'more')"
                />
              </td>
            </tr>

            <!-- Enter a new tag, checking for validity -->
            <td class="tag">
              <movie-tuner-tag-autocomplete (selectTag)="addNewTag( $event)"></movie-tuner-tag-autocomplete>
            </td>
            <td class="tuner-radio">
              <input
                type="radio"
                value="less"
                [disabled]="true"
                style = "margin-top: 25px"
              />
            </td>
            <td class="tuner-radio">
              <input
                type="radio"
                value="ok"
                [disabled]="true"
                style = "margin-top: 25px"
              />
            </td>
            <td class="tuner-radio">
              <input
                type="radio"
                value="more"
                [disabled]="true"
                style = "margin-top: 25px"
              />
            </td>
          </tbody>
        </table>
      </div>

      <div class="movie-search-results-container" *ngIf="mode != 'list'">
        <div
          *ngFor="let similarMovie of similarMovies"
          class="ml4-movie-card-search-result"
        >
          <movie-card-smart [movieDetails]="similarMovie"></movie-card-smart>

          <div class="clearfix"></div>

          <div class="text-center small">
            <a
              uiSref="base.movieTuner"
              [uiParams]="{ id: similarMovie.movieId }"
              [uiOptions]="{ inherit: false }"
            >
              use this movie <i class="fa fa-refresh"> </i>
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="pager.state.totalItems == 0">
        <p>No results!</p>
      </div>

      <div *ngIf="similarMovies && pager.state.totalPages > 1">
        <pager
          [currentPage]="pager.state.currentPage"
          [totalPages]="pager.state.totalPages"
          (onChangePage)="changePage($event)"
        ></pager>
      </div>
    </div>
  `
})
export class MovieTunerPageComponent implements OnInit {
  id;
  movieTitle = "";
  page;
  pager = {
    state: {
      // set in api callback
      currentPage: 0,
      totalPages: 0
    }
  };
  critiqueArray = {};
  showTuner = false;
  newTag = "";

  similarMovies;
  critique;
  similarMoviesDefaultTags;
  defaultTags;
  allTags : string[];

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Movie Tuner | MovieLens");

    this.id = this.stateService.params.id;
    this.page = this.stateService.params.page;

    this.load();
  }

  load() {
    this.movieDataService.getMovie(this.id, movieDetails => {
      this.movieTitle = movieDetails.movie.title;
    });

    this.movieDataService.similarMovies(
      this.id,
      {
        num: 24,
        page: this.page,
        critique: this.stateService.params.critique,
        includeDefaultTags: true
      },
      data => {
        this.similarMovies = get(data, "similarMovies.searchResults");
        this.pager.state.currentPage = get(
          data,
          "similarMovies.pager.currentPage"
        );
        this.pager.state.totalPages = get(
          data,
          "similarMovies.pager.totalPages"
        );

        //Since the include default tags flag is true, we now handle the default tags
        this.similarMoviesDefaultTags = data.tunerTags;
        this.defaultTags = Object.keys(this.similarMoviesDefaultTags);

        //set the radio button value from this.stateService.params.critique
        if (
          this.stateService.params.critique &&
          this.stateService.params.critique.length > 0
        ) {
          this.critique = this.stateService.params.critique;
          this.showTuner = true;
          let tagCritiques = this.stateService.params.critique.split("|");
          for (let i = 0; i < tagCritiques.length; i++) {
            let tag = tagCritiques[i].substr(5, tagCritiques[i].length);
            let critique = tagCritiques[i].substr(0, 4);
            if (critique.length === 4 && tag.indexOf("<") === -1) {
              this.critiqueArray[tag] = critique;
            }
          }
        }

        //set the critique to default "ok" when radio button is not selected
        this.defaultTags.map(tag => {
          if (!this.critiqueArray[tag]) {
            this.critiqueArray[tag] = "ok";
          }
        });

        this.allTags = Object.keys(this.critiqueArray);
      }
    );
  }

  changePage(newPage) {
    let newStateParams = clone(this.stateService.params);
    newStateParams.page = newPage;
    this.stateService.transitionTo("base.movieTuner", newStateParams, {
      reload: true
    });
  }

  reload() {
    this.stateService.params.page = 1;
    this.stateService.go("base.movieTuner", this.stateService.params, {
      reload: true
    });
  }

  updateCritique(tag, value) {
    this.critiqueArray[tag] = value;
    let url = "";
    for (let key in this.critiqueArray) {
      if (this.critiqueArray[key] !== "ok") {
        url += this.critiqueArray[key] + "-" + key + "|";
      }
    }
    url = url.substr(0, url.length - 1);
    this.stateService.params.critique = url;
    this.page=1;
    this.load();
  }

  addNewTag(tag) {
      if(this.allTags.indexOf(tag) == -1) {
          this.allTags.push(tag);
          this.critiqueArray[tag] = "ok";
      }
  }

  getCritiqueArrayWithoutOk() {
    let filteredCritiques = {};
    for (let key in this.critiqueArray) {
      if (this.critiqueArray[key] !== "ok") {
        filteredCritiques[key] = this.critiqueArray[key];
      }
    }
    return filteredCritiques;
  }
}
