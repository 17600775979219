"use strict";

import { Injectable } from "@angular/core";

@Injectable()
export class DeferredUrlService {
  constructor() {}

  next = null;

  hasNextUrl() {
    return !!this.next;
  }

  popNextUrl() {
    let n = !!this.next ? this.next.url : null;
    this.next = null;
    return n;
  }

  nextUrlIsDirect() {
    return this.next && this.next.direct;
  }

  setNextUrl(url, direct) {
    if (!this.next) {
      // if one is already set, don't overrwrite
      this.next = { url: url, direct: !!direct };
    } else {
      console.log("multiple next urls set");
    }
  }
}
