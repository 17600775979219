"use strict";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { get } from "lodash";

import { SessionService } from "../../common/session/session.service";

import "./restricted-template.scss";

@Component({
  selector: "restricted-template",
  template: `
    <div class="restricted-template">
      <header class="compact-header restricted-compact-header">
        <div class="ml4-container">
          <div class="row main-menu">
            <div class="col-sm-4 unpadded">&nbsp;</div>

            <div class="col-sm-4 unpadded text-center">
              <img
                src="/images/movielens-logo.svg"
                width="100px"
                height="30px"
                alt="MovieLens logo"
              />
            </div>

            <div class="col-sm-4 unpadded text-right">
              <api-activity-indicator-cog></api-activity-indicator-cog>
              <span class="restricted-header-username"
                >logged in as {{ email }}</span
              >
            </div>
          </div>
          <!-- end row -->

          <div class="positioned-right">
            <div id="alert-box" class="default-positioned-alert-box"></div>
          </div>
        </div>
        <!-- end ml4-container -->
      </header>

      <div class="ml4-container" ng-style="ml4ContainerCss()">
        <div id="main-container">
          <ui-view ></ui-view>
        </div>
      </div>
    </div>
  `
})
export class RestrictedTemplateComponent implements OnInit, OnDestroy {
  currentUserSub: Subscription;
  email: string = "";

  constructor(private sessionService: SessionService) {}

  ngOnInit() {
    this.currentUserSub = this.sessionService.currentUserData.subscribe(val => {
      this.email = val.account.email;
    });
  }

  ngOnDestroy(): void {
    this.currentUserSub.unsubscribe();
  }
}
