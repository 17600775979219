"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { get } from "lodash";

import "./movie-card-sm2.scss";

/**
 * Small card (240w x 100h)
 */
@Component({
  selector: "movie-card-sm2",
  template: `
    <div class="movie-card-sm2" (click)="onClick.emit()">
      <div class="poster-container" [class.empty-poster]="!posterSrc">
        <img [src]="posterSrc" srcset="{{ posterSrcHq }} 2x" class="small-movie-poster" alt="movie poster" *ngIf="posterSrc"/>
      </div>

      <div class="metadata-container">
        <div class="card-title">{{ title }}</div>

        <ul>
          <li>{{ releaseYear }} /</li>
          <li *ngIf="mpaa">{{ mpaa }} /</li>
          <li *ngIf="runtime">{{ runtime }} min</li>
        </ul>
        <ul *ngIf="genres">
          <li>{{ genres[0] }}</li>
        </ul>

        <div style="margin-top: 6px;">
          <rating
              [size]="20"
              [readOnly]="true"
              [showTooltip]="false"
              [userRating]="rating"
              [predictedRating]="prediction"
          ></rating>
        </div>

      </div>
    </div>
  `
})
export class MovieCardSm2Component {
  @Input() movieDetails: any = null;

  @Output() onClick = new EventEmitter<null>();

  get genres(): Array<string> {
    return get(this.movieDetails, "movie.genres");
  }

  get mpaa(): string {
    return get(this.movieDetails, "movie.mpaa");
  }

  get posterSrc(): string {
    return get(this.movieDetails, "movie.posterW92Url");
  }

  get posterSrcHq(): string {
    return get(this.movieDetails, "movie.posterW185Url");
  }

  get prediction(): number {
    return get(this.movieDetails, "movieUserData.prediction", null);
  }

  get rating(): number {
    return get(this.movieDetails, "movieUserData.rating", null);
  }

  get releaseYear(): string {
    return get(this.movieDetails, "movie.releaseYear");
  }

  get runtime(): string {
    return get(this.movieDetails, "movie.runtime");
  }

  get title(): string {
    return get(this.movieDetails, "movie.title");
  }

}
