"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalDialogModule } from "../modal-dialog/modal-dialog.module";

import { YoutubeEmbedComponent } from "./youtube-embed.component";
import { YoutubeModalComponent } from "./youtube-modal.component";
import { YoutubeTileComponent } from "./youtube-tile.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule, ModalDialogModule],
  exports: [YoutubeEmbedComponent, YoutubeModalComponent, YoutubeTileComponent],
  declarations: [
    YoutubeEmbedComponent,
    YoutubeModalComponent,
    YoutubeTileComponent
  ],
  entryComponents: [
    YoutubeEmbedComponent,
    YoutubeModalComponent,
    YoutubeTileComponent
  ]
})
export class YoutubeModule {}
