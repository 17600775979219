"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { ClickOutsideDirective } from "./click-outside.directive";

@NgModule({
  imports: [
    BrowserModule,
  ],
  exports: [ClickOutsideDirective],
  declarations: [ClickOutsideDirective]
})
export class ClickOutsideModule {}
