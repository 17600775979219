"use strict";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { FlashService } from "../../common/flash/flash.service";
import { SessionService } from "../../common/session/session.service";
import { UserDataService } from "../../common/user-data/user-data.service";

@Component({
  selector: "notifications-page",
  template: `
    <div class="profile-notifications">
      <h1>notifications</h1>

      <h4>
        may we email you?
        <button
          class="btn btn-link btn-sm"
          (click)="canSendEmailHelp = !canSendEmailHelp"
        >
          <i class="fa fa-question-circle"></i>
        </button>
      </h4>
      <div
        class="alert alert-info"
        *ngIf="canSendEmailHelp"
        style="margin-bottom: 12px;"
      >
        Selecting "yes" allows us to tell you about changes to MovieLens, and
        occasionally to ask if you'd be willing to be involved in testing future
        features or participate in research experiments. Please check "yes"! We
        promise to never give your personal information to anyone; see our
        <a uiSref="global.privacyPolicy" target="_blank">privacy policy</a> for
        more information.
      </div>

      <div [formGroup]="notificationsForm">
        <div class="checkbox">
          <label>
            <input type="radio" formControlName="canSendEmail" [value]="true" />
            Yes
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="radio"
              formControlName="canSendEmail"
              [value]="false"
            />
            No
          </label>
        </div>
      </div>

      <div class="clearfix"></div>
    </div>
  `
})
export class NotificationsPageComponent implements OnInit, OnDestroy {
  canSendEmailHelp = false;

  canSendEmail: FormControl = new FormControl("");
  notificationsForm: FormGroup = new FormGroup({
    canSendEmail: this.canSendEmail
  });

  formSub: Subscription = null;

  constructor(
    private flashService: FlashService,
    private sessionService: SessionService,
    private titleService: Title,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Notifications | MovieLens");

    const userData = this.sessionService.getUserData();
    const initialVal = get(userData, "preferences.canSendEmail");
    this.canSendEmail.setValue(initialVal);

    this.formSub = this.canSendEmail.valueChanges.subscribe(val => {
      this.setCanSendEmail(val);
    });
  }

  ngOnDestroy(): void {
    this.formSub && this.formSub.unsubscribe();
  }

  setCanSendEmail(val) {
    this.sessionService.setCanSendEmail(val);

    this.userDataService.update({ canSendEmail: val }, () => {
      const msg = val
        ? "Thanks! We changed your notification setting."
        : "Ok, we will not send you email.";
      this.flashService.addInfo(msg);
      this.flashService.show();
    });
  }
}
