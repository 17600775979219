"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { get } from "lodash";

@Component({
  selector: "movie-modal-new",
  template: `
    <modal-dialog (onClose)="onClose.emit()" *ngIf="doShow">
      <div title>{{ getMovieData('title') }}</div>
      <div main>

        <div class="row">

          <div class="col-sm-4">
            <img [src]="posterSrc" class="large-movie-poster" alt="movie poster" *ngIf="posterSrc" />
          </div>

          <div class="col-sm-8">

            <div class="movie-meta-header movie-details-section">
              <span *ngIf="getMovieData('releaseYear')">{{ getMovieData('releaseYear') }}</span>
              <span class="mpaa small" *ngIf="getMovieData('mpaa')">{{ getMovieData('mpaa') }}</span>
              <span *ngIf="getMovieData('runtime')">{{ getMovieData('runtime') }} minutes</span>
            </div>

            <div *ngIf="getMovieData('genres')">
              <div class="movie-details-heading">Genres</div>
              <div class="movie-details-section">{{ getMovieData('genres') | join }}</div>
            </div>

            <div *ngIf="getMovieData('languages')">
              <div class="movie-details-heading">Languages</div>
              <div class="movie-details-section">{{ getMovieData('languages') | join }}</div>
            </div>

            <div *ngIf="getMovieData('directors')">
              <div class="movie-details-heading">Directors</div>
              <div class="movie-details-section">
                {{ getMovieData('directors') | join }}
              </div>
            </div>

            <div *ngIf="getMovieData('actors')">
              <div class="movie-details-heading">Cast</div>
              <div class="movie-details-section">
                {{ getMovieData('actors') | slice:0:5 | join }}
              </div>
            </div>

          </div>
        </div><!-- /.row -->

        <div class="row">

          <div class="col-sm-12">

            <div class="plot-summary">
              {{ getMovieData('plotSummary') }}
            </div>

            <div *ngIf="getMovieData('youtubeTrailerIds')">
              <youtube-embed [ytid]="getMovieData('youtubeTrailerIds')[0]"></youtube-embed>
            </div>

          </div>
        </div><!-- /.row -->
        
      </div>
    </modal-dialog>
  `,
  styles: [
    `
      .movie-meta-header {
        font-size: 14px;
        margin-bottom: 6px;
      }

      .movie-details-section {
        margin-bottom: 12px;
      }

      .plot-summary {
        font-size: 18px;
        margin: 12px 0;
      }

      .large-movie-poster {
        width: 185px;
      }

      .mpaa {
        margin: 0 10px;
      }
    `
  ]
})
export class MovieModalNewComponent {
  @Input() doShow: boolean = false;
  @Input() movieDetails: any = null;

  @Output() onClose = new EventEmitter<null>();

  get movie(): object {
    return get(this.movieDetails, "movie");
  }

  get posterSrc(): string {
    return get(this.movieDetails, "movie.posterW185Url");
  }

  getMovieData(path) {
    return get(this.movie, path);
  }
}
