import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";

import * as $ from "jquery";
require("corejs-typeahead/dist/typeahead.jquery.min.js");
const Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

const defaultBloodhoundOptions = {
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  rateLimitWait: 25
};

@Component({
  selector: "base-omnisearch",
  template: require("./base-omnisearch.html"),
  styles: [String(require("./base-omnisearch.scss"))]
})
export class BaseOmnisearchComponent implements OnInit {
  constructor(private stateService: StateService) {}

  ngOnInit() {
    $("#omnisearch-typeahead")
      .typeahead(this.ttOptions, this.ttData[0], this.ttData[1], this.ttData[2])
      .on("typeahead:select", (ev, suggestion) => {
        this.currentObj = suggestion;
        this.submit();
      });
  }

  // this stores only what text the user has enetered
  currentVal = "";
  // this is set only if they choose from the dropdown
  currentObj = null;

  hasMoreMovies = false;

  bloodhoundEngines = {
    movies: new Bloodhound({
      datumTokenizer: defaultBloodhoundOptions.datumTokenizer,
      queryTokenizer: defaultBloodhoundOptions.queryTokenizer,
      rateLimitWait: defaultBloodhoundOptions.rateLimitWait,
      indexRemote: true,
      identify: function(obj) {
        return obj.movieId;
      },
      remote: {
        url: "/api/searches/omni/%QUERY",
        wildcard: "%QUERY",
        transform: rsp => {
          this.hasMoreMovies = rsp.data.movieViewModel.hasMoreResults;
          return rsp.data.movieViewModel.payload;
        }
      }
    }),

    people: new Bloodhound({
      datumTokenizer: defaultBloodhoundOptions.datumTokenizer,
      queryTokenizer: defaultBloodhoundOptions.queryTokenizer,
      rateLimitWait: defaultBloodhoundOptions.rateLimitWait,
      indexRemote: true,
      remote: {
        url: "/api/searches/omni/%QUERY",
        wildcard: "%QUERY",
        transform: function(rsp) {
          return rsp.data.peopleViewModel.payload;
        }
      }
    }),

    tags: new Bloodhound({
      datumTokenizer: defaultBloodhoundOptions.datumTokenizer,
      queryTokenizer: defaultBloodhoundOptions.queryTokenizer,
      rateLimitWait: defaultBloodhoundOptions.rateLimitWait,
      indexRemote: true,
      remote: {
        url: "/api/searches/omni/%QUERY",
        wildcard: "%QUERY",
        transform: function(rsp) {
          return rsp.data.tunerViewModel.payload;
        }
      }
    })
  };

  ttOptions = {
    highlight: true
  };

  ttData = [
    {
      name: "movies",
      display: "label",
      source: this.bloodhoundEngines.movies.ttAdapter(),
      templates: {
        header: '<div class="tt-header">movies</div>',
        footer: state => {
          if (this.hasMoreMovies) {
            var link = this.stateService.href(
              "base.explore",
              { q: state.query },
              { inherit: false }
            );
            return (
              '<div class="tt-suggestion tt-selectable"><a href="' +
              link +
              '">more...</a></div>'
            );
          }
          return "";
        }
      }
    },
    {
      name: "people",
      display: "name",
      source: this.bloodhoundEngines.people.ttAdapter(),
      templates: {
        header: '<div class="tt-header spaced">people</div>'
      }
    },
    {
      name: "tags",
      display: "tag",
      source: this.bloodhoundEngines.tags.ttAdapter(),
      templates: {
        header: '<div class="tt-header spaced">tags</div>'
      }
    }
  ];

  submit() {
    if (!this.currentVal) {
      return;
    }

    // clear/close the typeahead widget
    // XXX: angular-typeahead provides no api for controlling the widget
    const ttEl = $("#omnisearch-typeahead");
    ttEl.typeahead("val", "");
    ttEl.typeahead("close");

    // transition to a new page
    if (this.currentObj) {
      if (this.currentObj.hasOwnProperty("movieId")) {
        this.stateService.go("base.movie", { id: this.currentObj.movieId });
      } else if (this.currentObj.hasOwnProperty("name")) {
        this.stateService.go(
          "base.explore",
          {
            people: this.currentObj["name"].toLowerCase(),
            sortBy: "prediction"
          },
          { inherit: false }
        );
      } else if (this.currentObj.hasOwnProperty("tag")) {
        this.stateService.go(
          "base.explore",
          { tag: this.currentObj.tag.toLowerCase(), sortBy: "tagScore" },
          { inherit: false }
        );
      } else if (
        this.currentObj.hasOwnProperty("seeMoreType") &&
        this.currentObj.seeMoreType === "Movies"
      ) {
        this.stateService.go(
          "base.explore",
          { q: this.currentObj.keyword },
          { inherit: false }
        );
      }
    } else {
      this.stateService.go(
        "base.explore",
        { q: this.currentVal },
        { inherit: false }
      );
    }

    this.currentVal = "";
    this.currentObj = null;
  }

  focus() {
    $("#omnisearch-typeahead").focus();
  }
}
