"use strict";

import { NgModule } from "@angular/core";

import { GenresService } from "./genres.service";

@NgModule({
  providers: [GenresService]
})
export class GenresModule {}
