"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";

import { UserListsService } from "../../common/user-lists/user-lists.service";
import {ApiService} from "../../common/api/api.service";
import {Location} from "@angular/common";

@Component({
  selector: "user-list-movies-page",
  template: `
    <div *ngIf="listData" [ngStyle]="{ marginBottom: '20px' }">
      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '20px',
          margin: '20px 0px 10px 0px'
        }"
      >
        <h1 [ngStyle]="{ margin: '0px' }">
          {{ listData.title }}
        </h1>
        <button
          class="btn btn-default"
          (click)="copyToClipboard()"
          *ngIf="listData.publicList"
        >
          Copy Link
        </button>
      </div>
      <p>{{ listData.description }}</p>
      <div *ngIf="listData.isAuthorOfList">
        <button
          class="btn btn-primary"
          [ngStyle]="{ marginRight: '5px' }"
          (click)="openEditModal()"
        >
          Edit
        </button>
        <button class="btn btn-danger" [ngStyle]="" (click)="deleteList()">
          Delete
        </button>
        <span
          *ngIf="busy"
          class="tag-busy-indicator-cog"
          [ngStyle]="{ marginLeft: '10px' }"
          ><i class="fa fa-cog fa-spin"></i
        ></span>
      </div>
    </div>
    <div class="movie-search-results-container" *ngIf="searchResults">
      <div
        *ngFor="let searchResult of searchResults"
        class="ml4-movie-card-search-result"
      >
        <movie-card-smart [movieDetails]="searchResult"></movie-card-smart>
      </div>
    </div>

    <div *ngIf="pager && pager.totalItems == 0 && listData.isAuthorOfList" style="margin-top: 24px;">
      <p class="lead">
        Empty list! Try adding movies to this list.
      </p>
    </div>
    
    <div *ngIf="pager && pager.totalItems == 0 && !listData.isAuthorOfList" style="margin-top: 24px;">
      <p class="lead">
        Empty list!
      </p>
    </div>

    <div *ngIf="searchResults && pager.totalPages > 1">
      <pager
        [currentPage]="pager.currentPage"
        [totalPages]="pager.totalPages"
        (onChangePage)="changeToNewPage($event)"
      ></pager>
    </div>

    <div *ngIf="error403" class="ml4-narrow-container error-404-container">

      <div class="jumbotron ml4-jumbotron">
        <h1> Private List</h1>
        <p>
          <span> This list is private and cannot be viewed </span>
        </p>

        <p>
          <button class="btn btn-primary btn-lg" type="button" uiSref="base.frontpage">Go to the MovieLens front page</button>
        </p>

      </div>

    </div>
    
    <user-lists-modal
      [editModal]="true"
      [modalData]="modalData"
      (updateExistingUserList)="updateUserList($event)"
    ></user-lists-modal>
  `
})
export class UserListMoviesPageComponent {
  userId;
  listId;

  pager;
  currentPage;
  searchResults;

  listData;
  modalData;

  busy = false;
  error403 = false;

  constructor(
    private titleService: Title,
    private userListsService: UserListsService,
    private stateService: StateService,
    private apiService: ApiService
  ) {}

  openEditModal() {
    let newModalData = {
      title: this.listData.title,
      description: this.listData.description,
      publicList: this.listData.publicList,
      showModal: true,
      listId: this.listData.listId,
    };

    this.modalData = newModalData;
  }

  //https://gist.github.com/sandcastle/00aaa8a820061c899edf76c3ed3c8bac
  copyToClipboard() {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", window.location.href);
      e.preventDefault();
      document.removeEventListener("copy", listener);
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
  }

  updateUserList(obj) {
    this.listData.title = obj.title;
    this.listData.description = obj.description;
    this.listData.publicList = obj.publicList;
  }

  deleteList() {
    if (this.busy) {
      return;
    }

    this.busy = true;

    this.userListsService.deleteUserList(this.listData.listId, () => {
      this.busy = false;
      this.stateService.transitionTo(
        "base.userLists",
        {},
        {
          reload: true
        }
      );
    });
  }

  loadMovies() {
      if(this.stateService.params.page == null){
          this.stateService.params.page = 1;
      }
      this.userListsService.getUserListMovies(
          this.stateService.params.userId,
          this.stateService.params.listId,
          this.stateService.params.page,
          response => {
              let searchData = response.data.searchData;
              this.pager = searchData.pager;
              this.currentPage = this.pager.currentPage;
              this.searchResults = searchData.searchResults;
              this.listData = response.data.listData;
              this.titleService.setTitle(this.listData.title + " | MovieLens");
          },
          response => {
            if (response.status === 403) {
              this.error403 = true;
            } else {
              // use the standard error response for other errors.
              this.apiService._standardErrorCallback(response)
            }
          }
      );
  }

  changeToNewPage(newPage) {
      let newStateParams = this.stateService.params
      newStateParams.page = newPage;
      this.stateService.transitionTo("base.userListMovies", newStateParams, {
          reload: true
      });
  }

  ngOnInit() {
    this.loadMovies();
  }

}
