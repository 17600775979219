"use strict";

import { Component, Input } from "@angular/core";

import "./rating-or-prediction.scss";

@Component({
  selector: "ml4-rating-or-prediction",
  template: `
    <div class="rating-or-prediction" [ngClass]="getStarType()">
      <svg class="star-icon" width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <polygon stroke="none" fill-rule="evenodd" points="13.7714286 5.4939887 9.22142857 4.89188383 7.27142857 0.790044361 5.32142857 4.89188383 0.771428571 5.4939887 4.11428571 8.56096041 3.25071429 13.0202996 7.27142857 10.8282616 11.2921429 13.0202996 10.4285714 8.56096041"></polygon>
      </svg>
      <div class="rating-value">{{ getRatingValue() | number:'1.1-1' }}</div>
    </div>
  `
})
export class RatingOrPredictionComponent {
  @Input() averageRating: number = null;
  @Input() predictedRating: number = null;
  @Input() userRating: number = null;

  constructor() {}

  ngOnInit() {}

  getRatingValue() {
    if (this.userRating) {
      return this.userRating;
    } else if (this.predictedRating) {
      return this.predictedRating;
    } else if (this.averageRating) {
      return this.averageRating;
    } else {
      return "0";
    }
  }

  getStarType() {
    if (this.userRating) {
      return "rated";
    } else if (this.predictedRating) {
      return "predicted";
    } else if (this.averageRating) {
      return "average";
    } else {
      return "none";
    }
  }
}
