"use strict";

import { Pipe, PipeTransform } from "@angular/core";

/**
 * Custom pipe for generating url fragments: lowercase and replace spaces with dashes.
 */
@Pipe({ name: "urlFragmentNew" })
export class UrlFragmentPipe implements PipeTransform {
  transform(value: string): string {
    return value.toLowerCase().replace(/\s/g, "-");
  }
}
