import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StateService, UrlService } from "@uirouter/core";
import { get, wrap } from "lodash";
import { finalize } from 'rxjs/operators';

import { FlashService } from "../flash/flash.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { ApiActivityService } from "../api-activity/api-activity.service";
import { DeferredUrlService } from "../deferred-url/deferred-url.service";

@Injectable()
export class ApiService {
  constructor(
    private analyticsService: AnalyticsService,
    private apiActivityService: ApiActivityService,
    private deferredUrlService: DeferredUrlService,
    private flashService: FlashService,
    private http: HttpClient,
    private stateService: StateService,
    private urlService: UrlService
  ) {}

  // no-op
  static _emptyCallback() {}

  // standard error handler
  _standardErrorCallback(response) {
    if (response.status === 404) {
      const msg = get(response, "data.message", "(resource not found)");
      console.warn("404: " + msg);
      this.stateService.transitionTo("404");
    } else if (response.status === 401) {
      this.deferredUrlService.setNextUrl(this.urlService.path(), true);
      this.flashService
        .addInfo("Your session has expired. Please log in to continue.")
        .show();
      this.stateService.transitionTo("auth.login");
    } else if (response.status === 403) {
      this.stateService.transitionTo("403");
    } else {
      // console.error(status, response);
    }
  }

  // always run this after successful return
  _onComplete(beginTs, name) {
    return () => {
      this.apiActivityService.finishApiCall();

      const msTaken = Date.now() - beginTs;
      // console.log("completed API call, took ", msTaken);

      this.analyticsService.gTiming({
        event_category: "API",
        name: name,
        value: msTaken
      });
    };
  }

  ml4ApiCall(
    httpVerb: string,
    name: string,
    url: string,
    options: any,
    successCallback: Function,
    errorCallback: Function
  ) {
    this.apiActivityService.startApiCall();

    const beginTs = Date.now();
    successCallback = successCallback || ApiService._emptyCallback.bind(this);
    errorCallback = errorCallback || this._standardErrorCallback.bind(this);
    const onComplete = this._onComplete(beginTs, name).bind(this);

    this.http
      .request(httpVerb, url, options)
      .pipe(finalize(onComplete))
      .subscribe(
        response => successCallback(response),
        error => errorCallback(error),
      );
  }

  ml4Get(
    name: string,
    url: string,
    params: any,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    this.ml4ApiCall(
      "GET",
      name,
      url,
      { params: params },
      successCallback,
      errorCallback
    );
  }

  ml4Post(
    name: string,
    url: string,
    data: any,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    this.ml4ApiCall(
      "POST",
      name,
      url,
      { body: data },
      successCallback,
      errorCallback
    );
  }

  ml4Put(
    name: string,
    url: string,
    data: any,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    this.ml4ApiCall(
      "PUT",
      name,
      url,
      { body: data },
      successCallback,
      errorCallback
    );
  }

  ml4Delete(
    name: string,
    url: string,
    params: any,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    this.ml4ApiCall(
      "DELETE",
      name,
      url,
      { params: params },
      successCallback,
      errorCallback
    );
  }
}
