import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserListsService } from "../../common/user-lists/user-lists.service";

@Component({
  selector: "user-lists-modal",
  template: `
    <modal-dialog
      *ngIf="modalData != null && modalData.showModal"
      (onClose)="closeModal()"
    >
      <div title>{{editModal ? "Edit" : "Create"}}</div>
      <div main>
        <div class="form-group">
          <label>Title</label>
          <input class="form-control" value="{{ modalData.title }}" #title />
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea
            class="form-control"
            value="{{ modalData.description }}"
            #description
          ></textarea>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              [checked]="modalData.publicList"
              #publicList
            />
            Public list
            <button
                class="btn btn-link btn-sm"
                (click)="publicListHelp = !publicListHelp"
            >
              <i class="fa fa-question-circle"></i>
            </button>
            <div
                class="alert alert-info"
                *ngIf="publicListHelp"
                style="margin-bottom: 12px;"
            >
              Check this box if you want other users to be able to see your list.
              If you leave this unchecked then only you will be able to see the list.
              Either way, only you will be able to modify, edit, or delete the list. 
              This can be changed after you make the list.
            </div>
            
          </label>
        </div>
        <button
          class="btn btn-primary"
          (click)="
            modifyUserList(title.value, description.value, publicList.checked)
          "
        >
          {{editModal ? "Save Changes" : "Create"}}
        </button>
        <span
          *ngIf="busy"
          class="tag-busy-indicator-cog"
          [ngStyle]="{ marginLeft: '10px' }"
          ><i class="fa fa-cog fa-spin"></i
        ></span>
      </div>
    </modal-dialog>
  `
})
export class UserListsModalComponent implements OnInit {
  @Input() editModal: boolean;
  @Input() modalData: object = null;
  @Output() updateExistingUserList = new EventEmitter<any>();
  @Output() createNewUserList = new EventEmitter<any>();

  busy = false;
  publicListHelp = false;

  constructor(
    private userListsService: UserListsService
  ) {}

  modifyUserList(title, description, publicList) {

      if(this.editModal){
          return this.updateUserList(title, description, publicList);
      }
      else{
          return this.createUserList(title, description, publicList);
      }

  }

  createUserList(title, description, publicList){
      if(this.busy){
          return;
      }

      this.busy = true;

      this.userListsService.createUserList(
          title,
          description,
          publicList,
          (response) => {
              this.createNewUserList.emit({
                  title: title,
                  description: description,
                  publicList: publicList,
                  listId : response.data.listId,
                  userId : response.data.userId
              });
              this.busy = false;
              this.modalData["showModal"] = false;
          }
      );
  }

  updateUserList(title, description, publicList) {
    if (this.busy) {
      return;
    }

    this.busy = true;
    this.userListsService.updateUserList(
      this.modalData["listId"],
      title,
      description,
      publicList,
      () => {
        this.updateExistingUserList.emit({
          title: title,
          description: description,
          publicList: publicList,
          index: this.modalData["index"]
        });
        this.busy = false;
        this.modalData["showModal"] = false;
      }
    );
  }

  closeModal() {
    this.modalData["showModal"] = false;

  }

  ngOnInit() {
  }
}
