"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropdownModule } from "../dropdown-new/dropdown.module";
import { UserListsModalModule } from "../user-lists-modal/user-lists-modal.module";
import { UserListsAddDropdownComponent } from "./user-lists-add-dropdown.component";

@NgModule({
  imports: [CommonModule, DropdownModule, UserListsModalModule],
  exports: [UserListsAddDropdownComponent],
  declarations: [UserListsAddDropdownComponent]
})
export class UserListsAddDropdownModule {}
