"use strict";

import { Component, OnInit } from "@angular/core";
import { StateService, Transition } from "@uirouter/core";
import { clone, merge } from "lodash";

import { SessionService } from "../../common/session/session.service";
import { ExploreService } from "./explore.service";

const shortcuts = {
  // common searches
  "top-picks": {
    hasRated: "no",
    sortBy: "prediction",
    heading: "top picks",
    subHeading: "MovieLens recommends these movies"
  },
  "recent-releases": {
    hasRated: "no",
    sortBy: "releaseDate",
    maxDaysAgo: 90,
    maxFutureDays: 0,
    heading: "recent releases",
    subHeading: "movies released in last 90 days "
  },
  "rate-more": {
    hasRated: "no",
    sortBy: "popularity",
    minYear: 2000,
    heading: "movies to rate",
    subHeading:
      "improve your recommendations by rating as many of these movies as you can"
  },
  "highest-rated": {
    hasRated: "no",
    sortBy: "avgRating",
    minPop: 100,
    heading: "highest-rated movies",
    subHeading: "the highest-rated movies with at least 100 ratings"
  },
  "highest-rated-past-year": {
    extends: "highest-rated",
    maxDaysAgo: 365,
    maxFutureDays: 0,
    heading: "favorites from the past year",
    subHeading:
      "the highest-rated movies in the past year with at least 100 ratings"
  },
  "recently-added": {
    sortBy: "dateAdded",
    heading: "new additions",
    subHeading: "the movies most recently added to MovieLens"
  },

  // your stuff
  "your-ratings": {
    hasRated: "yes",
    sortBy: "userRatedDate",
    heading: "movies you've rated",
    subHeading: ""
  },
  "your-wishlist": {
    hasWishlisted: "yes",
    sortBy: "userListedDate",
    heading: "your wishlist",
    subHeading: ""
  },
  // XXX: we would prefer to sort by hidden date
  "your-hidden-movies": {
    hasHidden: "yes",
    heading: "movies you've hidden",
    subHeading: ""
  }
};

@Component({
  selector: "explore-shortcut",
  template: ""
})
export class ExploreShortcutComponent implements OnInit {
  constructor(
    private exploreService: ExploreService,
    private sessionService: SessionService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.redirect()
  }

    redirect() {
        let userPref = this.sessionService.getUserData().preferences;
        let userRectune = userPref.recommender;

        let paramsOrig = shortcuts[this.stateService.params.id];
        if (paramsOrig) {
            let params = clone(paramsOrig);

            // XXX: For movie group recommender, set query parameters differently.
            if (
                userRectune.engineId === "bard" &&
                this.stateService.params.id === "top-picks"
            ) {
                params.tag = userPref.movieGroupTags.join(",");
            }

            if (params.extends) {
                params = merge({}, shortcuts[params.extends], params);
                delete params.extends;
            }

            if (params.heading) {
                this.exploreService.setHeadings(params.heading, params.subHeading);
                delete params.heading;
                delete params.subHeading;
            }

            this.stateService.go("base.explore", params, {
                inherit: false,
                location: false
            });
        } else {
            this.stateService.go("base.explore", {}, {location: false});
        }
    }
}