import { Component, Input, OnInit } from "@angular/core";
import { MovieDataService } from "../../../common/movie-data/movie-data.service";

import "./explanations-movie-card.scss";
const tmpl = require("./explanations-movie-card.component.html");

@Component({
  selector: "explanations-movie-card",
  template: tmpl
})
export class ExplanationsMovieCardComponent implements OnInit {
  @Input() movieId: number;
  @Input() status: number;
  @Input() recPrediction: number;

  questionMarkPath = "/images/expt/explanations/solid_black2.png";

  isSelected = false;
  fullPosterPath = null;
  movie = null;

  private values = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
  private size: number = 24;
  private recPredictionRounded = null;

  constructor(private movieDataService: MovieDataService) {}

  ngOnInit(): void {
    this.recPredictionRounded = Math.round(this.recPrediction * 2) / 2;

    this.movieDataService.getMovie(this.movieId, response => {
      this.movie = response.movie;
      if (this.movie.posterPath) {
        this.fullPosterPath =
          "//image.tmdb.org/t/p/w185" + this.movie.posterPath;
      }
    });
  }

  // menu
  onSelectionButtonClick(): void {
    this.isSelected = !this.isSelected;
  }

  getStarBoxStyle(odd) {
    // hard code spacing between stars
    const spacing = odd ? 2 : 0;
    const w = Math.floor(this.size / 2.0) + spacing;
    const h = this.size + 2;
    return {
      width: `${w}px`,
      height: `${h}px`
    };
  }

  getStarStyle(odd) {
    const w = Math.floor(this.size / 2.0);
    const marginLeft = odd ? `-${w}px` : "0px";
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      "margin-left": marginLeft
    };
  }

  getStarShapeStyle(i) {
    if (this.values[i] <= this.recPredictionRounded) {
      return { fill: "#3bace1" };
    }
  }
}
