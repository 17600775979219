"use strict";

import { Component } from "@angular/core";

import "./dev-template.scss";

@Component({
  selector: "dev-template",
  template: `
    <div class="dev-tmpl" style="margin: 20px 0 80px 0;">
      <div class="row">
        <div class="col-sm-2 left-nav" style="margin-top: 80px;">
          <strong>app configuration</strong>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.dev.index">routes</a>
            </li>
          </ul>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.dev.styles">styles</a>
            </li>
          </ul>

          <strong>component demos</strong>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.dev.movieCardDemos">movie cards</a>
            </li>
          </ul>

          <strong>service demos</strong>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.dev.flashDemos">flash</a>
            </li>
          </ul>

          <strong>app testing</strong>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.dev.testSearch">search</a>
            </li>
          </ul>

          <ul class="nav nav-pills nav-stacked">
            <li uiSrefActive="active">
              <a uiSref="base.dev.testWs">websocket</a>
            </li>
          </ul>
          
        </div>
        <!-- end col -->

        <div class="col-sm-10">
          <div ui-view ></div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  `
})
export class DevTemplateComponent {}
