"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { JoinPipe } from "./join.pipe";
import { UrlFragmentPipe } from "./url-fragment.pipe";

@NgModule({
  declarations: [JoinPipe, UrlFragmentPipe],
  imports: [CommonModule],
  exports: [JoinPipe, UrlFragmentPipe]
})
export class PipesModule {}
