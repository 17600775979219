"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

import { FlashService } from "../../common/flash/flash.service";
import { UserDataService } from "../../common/user-data/user-data.service";

@Component({
  selector: "new-password-page",
  template: `
    <div class="new-password">
      <h1>change your password</h1>

      <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>
            password
            <div
              class="validation-msg"
              *ngIf="inputPass1.invalid && inputPass1.touched"
            >
              <span class="small error" *ngIf="inputPass1.errors.required"
                >please enter a password</span
              >
              <span class="small error" *ngIf="inputPass1.errors.minlength"
                >password must be at least 6 characters</span
              >
              <span class="small error" *ngIf="inputPass1.errors.maxlength"
                >password must be at most 50 characters</span
              >
            </div>
            <input
              type="password"
              class="form-control"
              formControlName="inputPass1"
              minlength="6"
              maxlength="50"
              required
              autofocus
            />
          </label>
        </div>

        <div class="form-group">
          <label>
            retype password
            <div
              class="validation-msg"
              *ngIf="inputPass2.invalid && inputPass2.touched"
            >
              <span class="small error" *ngIf="inputPass2.errors.required"
                >please enter a password</span
              >
              <span class="small error" *ngIf="inputPass2.errors.badMatch"
                >passwords do not match</span
              >
            </div>
            <input
              type="password"
              class="form-control"
              formControlName="inputPass2"
              required
            />
          </label>
        </div>

        <div class="form-group" style="margin-top: 15px;">
          <button
            type="submit"
            class="btn btn-default"
            [disabled]="theForm.invalid || thinking"
          >
            update
          </button>
          <span style="margin-left: 5px;" [hidden]="!thinking">
            <i class="fa fa-cog fa-spin"></i>
          </span>
        </div>
      </form>
    </div>
  `
})
export class NewPasswordPageComponent implements OnInit {
  private theForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private flashService: FlashService,
    private titleService: Title,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Change Password | MovieLens");

    this.theForm = this.fb.group({
      inputPass1: [
        "",
        {
          validators: [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(50)
          ]
          // updateOn: "blur"
        }
      ],
      inputPass2: [
        "",
        {
          validators: [Validators.required, this.validatePasswordMatch]
          // updateOn: "blur"
        }
      ]
    });
  }

  get inputPass1() {
    return this.theForm.get("inputPass1");
  }

  get inputPass2() {
    return this.theForm.get("inputPass2");
  }

  // validation method to ensure the two passwords are equal
  validatePasswordMatch(control: AbstractControl) {
    let inputPass1 = control.root.get("inputPass1");
    let inputPass2 = control;

    if (!inputPass1 || inputPass1.pristine || inputPass2.pristine) {
      return null;
    }

    return inputPass1.value === inputPass2.value
      ? null
      : {
          badMatch: true
        };
  }

  onSubmit() {
    this.userDataService.update(
      { password: this.inputPass1.value },
      () => {
        this.flashService
          .addSuccess("You successfully changed your password. Way to go!")
          .show();
      },
      response => {
        this.flashService.addError("Uh oh: " + response.message);
        this.flashService.show();
      }
    );
  }
}
