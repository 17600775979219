"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserListsModalComponent } from "./user-lists-modal.component";
import { ModalDialogModule } from "../modal-dialog/modal-dialog.module";

@NgModule({
  imports: [CommonModule, ModalDialogModule],
  exports: [UserListsModalComponent],
  declarations: [UserListsModalComponent]
})
export class UserListsModalModule {}
