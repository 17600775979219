import { Component, EventEmitter, Input, Output } from "@angular/core";

import * as $ from "jquery";
require("corejs-typeahead/dist/typeahead.jquery.min.js");
const Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

const defaultBloodhoundOptions = {
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    rateLimitWait: 25
};

@Component({
    selector: "movie-tuner-tag-autocomplete",
    template: `
      <form role="form" class="form-inline" autocomplete="off">
        <input class="movie-tuner-tag-autocomplete" type="text" [(ngModel)]="currentVal" name="currentVal" placeholder="custom tag" />
      </form>
    `
})
export class MovieTunerTagAutocompleteComponent {
    @Input() numToShow: number = 5;
    @Output() selectTag = new EventEmitter<string>();

    ngOnInit() {
        let ttOptions = {
            highlight: true
        };

        let ttData = {
            display: "tag",
            source: this.bloodhoundEngine.ttAdapter(),
            limit: this.numToShow
        };

        $(".movie-tuner-tag-autocomplete")
            .typeahead(ttOptions, ttData)
            .on("typeahead:selected", (ev, suggestion) => {
                this.currentVal = suggestion.tag;
                this.submit();
            });
    }

    currentVal = "";

    bloodhoundEngine = new Bloodhound({
        datumTokenizer: defaultBloodhoundOptions.datumTokenizer,
        queryTokenizer: defaultBloodhoundOptions.queryTokenizer,
        rateLimitWait: defaultBloodhoundOptions.rateLimitWait,
        indexRemote: true,
        remote: {
            url: "/api/searches/movie-tuner-tag-prefix/%QUERY",
            wildcard: "%QUERY",
            transform: function(rsp) {
                return rsp.data.payload;
            }
        }
    });

    submit() {
        if (!this.currentVal) {
            return;
        }

        $(".add-tag-typeahead").typeahead("val", "");
        $(".add-tag-typeahead").typeahead("close");

        this.selectTag.emit(this.currentVal);

        this.currentVal = "";
    }
}
