import { Injectable } from "@angular/core";
import { ApiService } from "../../common/api/api.service";

import { BehaviorSubject } from "rxjs";

@Injectable()
export class ExplanationsService {
  public step: BehaviorSubject<number>;

  constructor(private apiService: ApiService) {
    this.step = new BehaviorSubject(1);
  }

  updateStatus(nextStep) {
    this.step.next(nextStep);
  }

  getSurveyConfig(successCallback, errorCallback = () => {}) {
    this.apiService.ml4Get(
      "GET /api/explanations/survey-config",
      "/api/explanations/survey-config",
      {},
      successCallback,
      errorCallback
    );
  }

  getSurveyData(step, design, successCallback, errorCallback = () => {}) {
    this.apiService.ml4Get(
      "GET /api/explanations/survey-data/:step",
      "/api/explanations/survey-data/" + step,
      { design: design },
      successCallback,
      errorCallback
    );
  }

  getSurveyStatus(successCallback, errorCallback = () => {}) {
    this.apiService.ml4Get(
      "GET /api/explanations/survey-status",
      "/api/explanations/survey-status",
      {},
      successCallback,
      errorCallback
    );
  }

  savePicks(data, successCallback, errorCallback = () => {}) {
    this.apiService.ml4Post(
      "POST /api/explanations/save-picks",
      "/api/explanations/save-picks",
      data,
      successCallback,
      errorCallback
    );
  }

  saveFeedback(data, successCallback, errorCallback = () => {}) {
    this.apiService.ml4Post(
      "POST /api/explanations/save-feedback",
      "/api/explanations/save-feedback",
      data,
      successCallback,
      errorCallback
    );
  }

  consent(successCallback, errorCallback = () => {}) {
    this.apiService.ml4Post(
      "POST /api/explanations/consent",
      "/api/explanations/consent",
      {},
      successCallback,
      errorCallback
    );
  }
}
