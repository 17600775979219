import { Injectable } from "@angular/core";
import { SessionService } from "../../common/session/session.service";
import { ApiService } from "../../common/api/api.service";

@Injectable()
export class MovieGroupsService {
  constructor(
    private apiService: ApiService,
    private sessionService: SessionService
  ) {}

  groups(successCallback, errorCallback) {
    this.apiService.ml4Get(
      "GET /api/movie-groups",
      "/api/movie-groups/",
      {},
      successCallback,
      errorCallback
    );
  }

  pick(data, successCallback, errorCallback) {
    this.apiService.ml4Post(
      "POST /api/actions/user/pick-movie-groups",
      "/api/actions/user/pick-movie-groups",
      data,
      () => {
        this.sessionService.reloadSession();
        successCallback();
      },
      errorCallback
    );
  }

}
