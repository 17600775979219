import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { FlashService } from "../../common/flash/flash.service";

import * as _ from "lodash";

@Component({
  selector: "tag-survey",
  template: require("./tag-survey.html"),
  styles: [String(require("./tag-survey.scss"))]
})
export class TagSurveyComponent implements OnInit {
  @Input() tag: string;
  @Input() shown: boolean;
  @Input() userSurvey: any;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;

  tagForm: FormGroup;

  questionIndex = 0;
  questions = ["quality", "type", "subtype", "similar", "dissimilar"];
  question = this.questions[this.questionIndex];

  similarView = [];
  dissimilarView = [];

  toggle() {
    this.shown = !this.shown;
  }
  open() {
    this.shown = true;
  }
  close() {
    this.shown = false;
  }

  nextInvalid() {
    return this.questionIndex >= this.questions.length - 1;
  }

  backInvalid() {
    return this.questionIndex <= 0;
  }

  next() {
    this.questionIndex++;
    if (this.questionIndex > this.questions.length - 1) {
      this.questionIndex = this.questions.length - 1;
    }
    this.question = this.questions[this.questionIndex];
    if (this.question == "subtype" && !this.tagForm.get("type").value) {
      this.next();
    }
  }

  back() {
    this.questionIndex--;
    if (this.questionIndex < 0) {
      this.questionIndex = 0;
    }
    this.question = this.questions[this.questionIndex];
    if (this.question == "subtype" && !this.tagForm.get("type").value) {
      this.back();
    }
  }

  addSimilarTag(tag) {
    var string = this.tagForm.get("similar").value;
    if (this.similarView.indexOf(tag) != -1) {
      return;
    }
    if (string == null || string.length <= 0) {
      string = tag;
    } else {
      string = tag + ", " + string;
    }
    this.tagForm.patchValue({
      similar: string
    });
  }

  removeSimilarTag(tag) {
    var string = "";
    for (var ix = 0; ix < this.similarView.length; ix++) {
      if (this.similarView[ix] != tag) {
        string += this.similarView[ix] + ", ";
      }
    }
    if (string.substring(string.length - 2, string.length) == ", ") {
      string = string.substring(0, string.length - 2);
    }
    this.tagForm.patchValue({
      similar: string
    });
  }

  addDissimilarTag(tag) {
    var string = this.tagForm.get("dissimilar").value;
    if (this.dissimilarView.indexOf(tag) != -1) {
      return;
    }
    if (string == null || string.length <= 0) {
      string = tag;
    } else {
      string = tag + ", " + string;
    }
    this.tagForm.patchValue({
      dissimilar: string
    });
  }

  removeDissimilarTag(tag) {
    var string = "";
    for (var ix = 0; ix < this.dissimilarView.length; ix++) {
      if (this.dissimilarView[ix] != tag) {
        string += this.dissimilarView[ix] + ", ";
      }
    }
    if (string.substring(string.length - 2, string.length) == ", ") {
      string = string.substring(0, string.length - 2);
    }
    this.tagForm.patchValue({
      dissimilar: string
    });
  }

  clear() {
    let cleanValue: any = {};
    cleanValue[this.question] = null;
    this.tagForm.patchValue(cleanValue);
  }

  thankUser() {
    this.flashService.addSuccess("Thanks for giving feedback!");
    this.flashService.show();
    this.close();
  }

  updateResponses(userSurvey) {
    this.userSurvey = userSurvey;
    if (userSurvey.quality != null) {
      this.userSurvey.quality = String(this.userSurvey.quality);
    }
    this.tagForm.patchValue({
      quality: _.get(this.userSurvey, "quality"),
      type: _.get(this.userSurvey, "type"),
      subtype: _.get(this.userSurvey, "subtype"),
      similar: _.get(this.userSurvey, "similar"),
      dissimilar: _.get(this.userSurvey, "dissimilar")
    });
  }

  constructor(private fb: FormBuilder, private flashService: FlashService) {}

  ngOnInit() {
    this.tagForm = this.fb.group({
      quality: _.get(this.userSurvey, "quality", null),
      type: _.get(this.userSurvey, "type", null),
      subtype: _.get(this.userSurvey, "subtype", null),
      similar: _.get(this.userSurvey, "similar", null),
      dissimilar: _.get(this.userSurvey, "dissimilar", null)
    });
    this.tagForm.valueChanges.subscribe(value => {
      let oldType = this.userSurvey.type;
      _.assign(this.userSurvey, value);
      if (value.type != oldType && this.userSurvey.subtype != null) {
        this.userSurvey.subtype = null;
        this.tagForm.patchValue({ subtype: null });
      }
      if (this.userSurvey.quality != null) {
        this.userSurvey.quality = Number(this.userSurvey.quality);
      }
      this.submit.emit(this.userSurvey);
      if (this.userSurvey.similar) {
        this.similarView = this.userSurvey.similar.split(", ");
      } else {
        this.similarView = [];
      }
      if (this.userSurvey.dissimilar) {
        this.dissimilarView = this.userSurvey.dissimilar.split(", ");
      } else {
        this.dissimilarView = [];
      }
    });
  }
}
