"use strict";

import { NgModule } from "@angular/core";

import { WsService } from "./ws.service";

@NgModule({
  providers: [WsService]
})
export class WsModule {}
