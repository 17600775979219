"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ClickOutsideModule } from "../../directives/click-outside/click-outside.module";

import { DropdownComponent } from "./dropdown.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [ClickOutsideModule, CommonModule],
  exports: [DropdownComponent],
  declarations: [DropdownComponent]
})
export class DropdownModule {}
