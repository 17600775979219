"use strict";

import { BaseTemplateComponent } from "./base-template.component";
import { BaseUserDropdownComponent } from "./base-user-dropdown.component";
import { BaseStarDropdownComponent } from "./base-star-dropdown.component";
import { BaseNotificationsDropdownComponent } from "./base-notifications-dropdown.component";
import { BaseMenuDropdownComponent } from "./base-menu-dropdown.component";
import { BaseOmnisearchComponent } from "./base-omnisearch.component";

import { NgModule } from "@angular/core";
import { ApiActivityModule } from "../../common/api-activity/api-activity.module";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { DropdownModule } from "../../components/dropdown/dropdown.module";
import { FormsModule } from "@angular/forms";
import {UIRouterModule} from "@uirouter/angular";
import {IfExptModule} from "../../components/if-expt/if-expt.module";

const baseState = {
  name: "base",
  abstract: true,
  parent: "loggedIn",
  component: BaseTemplateComponent
};

@NgModule({
    imports: [
        ApiActivityModule,
        CommonModule,
        BrowserModule,
        DropdownModule,
        FormsModule,
        UIRouterModule.forChild({states: [baseState]}),
        IfExptModule
    ],
    declarations: [
        BaseTemplateComponent,
        BaseMenuDropdownComponent,
        BaseOmnisearchComponent,
        BaseNotificationsDropdownComponent,
        BaseStarDropdownComponent,
        BaseUserDropdownComponent
    ],
    exports: [
        BaseTemplateComponent
    ],

    entryComponents: [
        BaseTemplateComponent,
        BaseMenuDropdownComponent,
        BaseNotificationsDropdownComponent,
        BaseOmnisearchComponent,
        BaseStarDropdownComponent,
        BaseUserDropdownComponent
    ]
})
export class BaseTemplateModule {}
