import {enableProdMode, NgModule} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMdModule } from "ngx-md";
import { NvD3Module } from "ngx-nvd3";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha/forms";

import { ClickOutsideModule } from "./directives/click-outside/click-outside.module";
import { ImgPreloadModule } from "./directives/img-preload/img-preload.module";

import { AlexaModule } from "./expt/alexa/alexa.module";
import { ExplanationsModule } from "./expt/explanations/explanations.module";
import { ExternalRecsModule } from "./expt/externalrecs/external-recs.module";
import { EmojiExptModule } from "./expt/emoji/emoji.module";

import { configureAdmin } from "./ui/admin/admin.module";
import { DevModule } from "./ui/dev/dev.module";

import { MLCommonModule } from "./common/CommonModule";
import Constants from "./constants";
import { ComponentsModule } from "./components/components.module";
import { UIRouterModule, UIRouter, UIView } from "@uirouter/angular";
import { FlashModule } from "./common/flash/flash.module";
import "./styles/framework.scss";
import "nvd3/build/nv.d3.css"; // not used a lot, but this is still the right place to include this.

import {
  configureLoginRequired,
  configureScrollOnTransition,
  UiModule
} from "./ui/ui.module";

// llm_rec expt sophia sun00587 start
import {LLMRecExptModule} from "./expt/llm_rec/llm_rec.module";
// llm_rec expt sophia sun00587 end

// interactive_profile expt sophia sun00587 start
import {InteractiveProfileExptModule} from "./expt/interactive_profile/interactive_profile.module";
import {UserProfilePageModule} from "./expt/interactive_profile/user-profile-page.module";
import {TreemapPageModule} from "./expt/interactive_profile/treemap-page.module";
// interactive_profile expt sophia sun00587 end

// do not include the dev module in production
// @ts-ignore
let devModule = ML4_DEV_MODE_MACRO ? DevModule : [];
// @ts-ignore
if(!(ML4_DEV_MODE_MACRO)) {
    enableProdMode();
}


function routerConfigFn(router: UIRouter) {
  const transitionService = router.transitionService;

  configureScrollOnTransition(transitionService);
  configureLoginRequired(transitionService);
  configureAdmin(transitionService);
}


@NgModule({
  imports: [
    // 3rd party
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxMdModule.forRoot(),
    NvD3Module,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    UIRouterModule.forRoot({
      otherwise: "/404",
      config: routerConfigFn,
      useHash: false,
      deferInitialRender: true
    }),

    MLCommonModule,
    ComponentsModule,

    ClickOutsideModule,
    devModule,
    ExternalRecsModule,
    ImgPreloadModule,
    UiModule,

    // movielens experiments
    AlexaModule,
    EmojiExptModule,
    ExplanationsModule,
    FlashModule,
    // llm_rec expt sophia sun00587 start
    LLMRecExptModule,
    // llm_rec expt sophia sun00587 end
    // llm_rec expt sophia sun00587 start
    InteractiveProfileExptModule,
    UserProfilePageModule,
    TreemapPageModule
    // llm_rec expt sophia sun00587 end


  ],
  bootstrap: [UIView],
  providers: [Constants]
})
export class AppModule {}
