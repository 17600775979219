import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

import { isNil, pickBy } from "lodash";
import Constants from "../../constants";
import {TransitionService} from "@uirouter/core";

@Injectable()
export class LoggerService {
    constructor(
        private transitions: TransitionService,
        private constants: Constants,
        private apiService: ApiService
    ) {
        transitions.onSuccess({}, transition => {
            if (constants.DO_NOT_LOG_STATES.has(transition.to().name)) {
                return;
            }

            this.pageView(transition.to(), transition.params());
        });
    }

  imageView(movieId, imageId, type, imagePosition) {
    this.apiService.ml4Post("POST /api/log/image-view", "/api/log/image-view", {
      movieId,
      imageId,
      type,
      imagePosition
    });
  }

  pageView(toState, toParams) {
    const toStateName = toState.name;

    // remove keys that have empty values
    const cleanToParams = pickBy(toParams, (value, key) => {
      return !isNil(value);
    });

    this.apiService.ml4Post("POST /api/log/page-view", "/api/log/page-view", {
      pageName: toStateName,
      params: cleanToParams
    });
  }

  trailerView(movieId, trailerId, trailerPosition) {
    this.apiService.ml4Post(
      "POST /api/log/trailer-view",
      "/api/log/trailer-view",
      {
        movieId,
        trailerId,
        trailerPosition
      }
    );
  }
}
