import { Component, OnInit, ViewChild } from "@angular/core";
import { StateService } from "@uirouter/core";

import { TagPageService } from "./tag-page.service";
import { TagSurveyComponent } from "./tag-survey.component";
import { TagReportComponent } from "./tag-report.component";
import {MovieDataService} from "../../common/movie-data/movie-data.service";

import "./tag-page.scss";
import {TagDataService} from "../../common/tag-data/tag-data.service";

@Component({
  selector: "tag-page",
  template: require("./tag-page.html")
})
export class TagPageComponent implements OnInit {
  @ViewChild(TagSurveyComponent) mySurvey: TagSurveyComponent;
  @ViewChild(TagReportComponent) myReport: TagReportComponent;

  similarTagsPlaceholder = [];

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private tagPageService: TagPageService,
    private tagDataService: TagDataService,
  ) {}

  ngOnInit() {
    this.tagPageService.fetchTagData(
      this.stateService.params.name,
      value => {
        this.tagData = value.data.tagViewModel;
        this.userSurvey = value.data.userTagSurvey;
        this.mySurvey.updateResponses(this.userSurvey);
        this.globalSurvey = value.data.tagSurveySummaryModel;
        this.updateTypes();
      },
      error => {
        // no tag found?
        if (error.status === 404) {
          const msg = error.error.message || "(resource not found)";
          console.warn("404: " + msg);
          this.stateService.transitionTo("404");
        } else {
          console.log("error", error);
        }
      }
    );
    this.tagDataService.getMovieTags(
      1,
      value => {
        this.similarTagsPlaceholder = value.data.scoredTags.slice(0, 6);
      },
      () => {}
    );
    this.movieDataService.movieSearch(
      { tag: this.stateService.params.name, sortBy: "tagScore" },
      searchData => {
        this.similarMovies = searchData.searchResults.splice(0, 8);
      }
    );
  }

  getTypeDropdownButton() {
    if (this.tagType == "unsure") {
      return `<span class="fa fa-exclamation-triangle"></span>`;
    } else {
      return `<span class="fa fa-info-circle"></span>`;
    }
  }
  getTypeDropdownContent() {
    if (this.tagType == "unsure") {
      return `Uh-oh, it looks like we don't have enough data to determine what kind of
                tag this is!`;
    } else {
      if (this.tagType == "objective") {
        return `Tags that describe indisputable facts about a movie such as date produced,
          directors, genre, or origin.`;
      } else {
        return `Tags that represent an individual's opinion about a movie, such as its quality,
          or their opinions about actors or producers.`;
      }
    }
  }

  getSubtypeDropdownButton() {
    if (this.tagSubtype == "unsure") {
      return `<span class="fa fa-exclamation-triangle"></span>`;
    } else {
      return `<span class="fa fa-info-circle"></span>`;
    }
  }
  getSubtypeDropdownContent() {
    if (this.tagSubtype == "unsure") {
      return `Uh-oh, it looks like we don't have enough data to determine what kind of
                tag this is!`;
    } else {
      if (this.tagSubtype == "content") {
        return `Tags that describe the movie itself, including genre, narrative, or theme (e.g., "sci-fi" or "time-travel").`;
      } else if (this.tagSubtype == "production") {
        return `Tags that describe the movie's production, including cast/crew, or awards won (e.g., "Pixar" or "Will Smith").`;
      } else if (this.tagSubtype == "quality") {
        return `Tags that characterize the good or bad aspects of a movie in a debatable way (e.g., "classic" or "long").`;
      } else if (this.tagSubtype == "emotion") {
        return `Tags that characterize the good or bad aspects of a movie in a debatable way (e.g., "classic" or "long").`;
      } else if (this.tagSubtype == "personal") {
        return `Tags that only have meaning to one user, or a small group of users (e.g., "want to watch").`;
      }
    }
  }

  // this is not necessary, but included to show the model I am working with for tags
  // definitely needs some refinement, I believe that name, type, typeProportion,
  // subtypeProportion should all be contained somewhere, it seems like an unnecessary step
  // to have the client calculate all that from raw survey data/proportions
  tagData: any = {
    tag: "null",
    numApps: "null",
    numPositive: "null",
    numNeutral: "null",
    numNegative: "null",
    numDownvotes: "null",
    numMovies: "null",
    numUsers: "null"
  };

  userSurvey: any = {
    userId: null,
    quality: null,
    tag: null,
    type: null,
    subtype: null,
    similar: null,
    dissimilar: null
  };

  globalSurvey: any = {
    tag: null,
    avgQuality: null,
    typeList: null,
    subtypeList: null
  };

  tagType = "unsure";
  tagTypeProportion = 0;
  tagSubtype = "unsure";
  tagSubtypeProportion = 0;
  similarMovies = [];

  dataset = "global";
  selectStats(data) {
    this.dataset = data;
  }

  openSurvey() {
    this.mySurvey.close();
    this.myReport.close();
    setTimeout(() => {
      this.mySurvey.open();
    }, 1);
  }
  toggleSurvey() {
    this.mySurvey.toggle();
    this.myReport.close();
  }
  toggleReport() {
    this.myReport.toggle();
    this.mySurvey.close();
  }

  submitSurvey(response) {
    this.tagPageService.submitSurveyResponse(
      response,
      success => {
        this.userSurvey = response;
        this.updateTagData();
      },
      () => {}
    );
  }

  updateTagData() {
    this.tagPageService.fetchTagData(
      this.stateService.params.name,
      value => {
        this.globalSurvey = value.data.tagSurveySummaryModel;
        this.updateTypes();
      },
      error => {}
    );
  }

  updateTypes() {
    var unsure = true;
    for (var ix = 0; ix < this.globalSurvey.typeList.length; ix++) {
      let typeData = this.globalSurvey.typeList[ix];
      if (typeData.proportion > 0.5) {
        this.tagType = typeData.type.toLowerCase();
        this.tagTypeProportion = typeData.proportion.toPrecision(2) * 100;
        unsure = false;
      }
    }
    if (unsure) {
      this.tagType = "unsure";
      this.tagTypeProportion = null;
    }
    unsure = true;
    for (var ix = 0; ix < this.globalSurvey.subtypeList.length; ix++) {
      let subtypeData = this.globalSurvey.subtypeList[ix];
      if (subtypeData.proportion > 0.5) {
        this.tagSubtype = subtypeData.type.toLowerCase();
        this.tagSubtypeProportion = subtypeData.proportion.toPrecision(2) * 100;
        unsure = false;
      }
    }
    if (unsure) {
      this.tagSubtype = "unsure";
      this.tagSubtypeProportion = null;
    }
  }
}
