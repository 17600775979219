"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { ApiService } from "../../common/api/api.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { UrlsService } from "../../common/urls/urls.service";

import "./admin-tasks-page.scss";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddMovieService} from "../add-movie/add-movie.service";
import {FlashService} from "../../common/flash/flash.service";
import {AdminAddMovieService} from "./admin-add-movie.service";

@Component({
  selector: "admin-tasks-page",
  template: `
    <div class="admin-tasks-page">
      <h1>tasks</h1>
      <table *ngIf="tasks" class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Last Run</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor = "let item of tasks | keyvalue :sortByDate">
          <td> {{item.key}} </td>
          <td> {{item.value}} </td>
        </tr>
        </tbody>
      </table>
    </div> 
  `
})
export class AdminTasksPageComponent {

  private tasks: any;

  constructor(
    private titleService: Title,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.tasks = false;
    this.titleService.setTitle("Admin | MovieLens | tasks");

    this.reloadData();
  }

  reloadData() {
    this.apiService.ml4Get(
      "GET /api/admin/logJobHistory",
      "/api/admin/logJobHistory",
      {},
      response => {
        var data = get(response, "data.adminLogJobRunnerHistory.jobRunTable");
        //console.log(data);
        this.tasks = data
      }
    );
  }

  /**
   * Comparator function for sorting the table
   */
  sortByDate(a, b) {
    if (a.value < b.value) {
      return 1;
    } else if (a.value > b.value) {
      return -1;
    } else {
      return 0;
    }
  }
}
