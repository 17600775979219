import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export enum AlertType {
  Error = "error",
  Info = "info",
  Success = "success"
}

export class AlertMessage {
  alertType: AlertType;
  msg: string;

  constructor(alertType: AlertType, msg: string) {
    this.alertType = alertType;
    this.msg = msg;
  }
}

/**
 * DOCS (communication between component and service)
 * https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
 */
@Injectable()
export class FlashService {
  private flash: Array<AlertMessage> = [];
  private alertSource = new Subject<AlertMessage>();
  alert$ = this.alertSource.asObservable();

  private add(alertType: AlertType, msg: string) {
    this.flash.push(new AlertMessage(alertType, msg));
    return this;
  }

  addInfo(msg: string) {
    return this.add(AlertType.Info, msg);
  }

  addSuccess(msg: string) {
    return this.add(AlertType.Success, msg);
  }

  addError(msg: string) {
    return this.add(AlertType.Error, msg);
  }

  show() {
    while (this.flash.length > 0) {
      this.alertSource.next(this.flash.pop());
    }
  }
}
