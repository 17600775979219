import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StateService } from "@uirouter/core";

import { AddMovieService } from "./add-movie.service";
import { FlashService } from "../../common/flash/flash.service";
import { UrlsService } from "../../common/urls/urls.service";

import "./add-movie-page.scss";

@Component({
  selector: "add-movie",
  template: require("./add-movie-page.html")
})
export class AddMoviePageComponent implements OnInit {
  public imdbForm: FormGroup;
  errors = <any>{};
  warnings = {};
  response = null;
  imdbUrl = null;
  rottenTomatoesUrl = null;
  canIStreamItUrl = null;
  tmdbUrl = null;
  tmdb = null;
  existingMovieId = null;

  imdbRegex = /^https?:\/\/.*\.imdb\.com\/title\/tt(\d{7,8}).*|^(tt)?(\d{7,8})$/;

  constructor(
    private formBuilder: FormBuilder,
    private svc: AddMovieService,
    private flash: FlashService,
    private stateService: StateService,
    private urls: UrlsService
  ) {}

  state = {
    hasErrors: false,
    busy: false,
    imdbIsValid: () => {
      return this.imdbForm.controls.imdbUrl.valid;
    },
    imdbIsInvalid: () => {
      return (
        this.imdbForm.controls.imdbUrl.dirty &&
        this.imdbForm.controls.imdbUrl.invalid
      );
    }
  };

  clearState() {
    this.state.hasErrors = false;
    this.state.busy = false;
    this.errors = {};
    this.warnings = {};
    this.response = null;
    this.rottenTomatoesUrl = null;
    this.canIStreamItUrl = null;
    this.tmdbUrl = null;
    this.tmdb = null;
    this.existingMovieId = null;
  }

  ngOnInit() {
    this.imdbForm = this.formBuilder.group({
      imdbUrl: ["", [Validators.required, Validators.pattern(this.imdbRegex)]]
    });
    this.imdbForm.get("imdbUrl").valueChanges.subscribe(val => {
      this.clearState();
    });
  }

  checkAdd() {
    this.svc.suggestImdbUrl(
      { imdbId: this.imdbForm.controls.imdbUrl.value },
      response => {
        this.clearState();
        let respData = response.data;
        this.response = response;
        this.tmdb = respData.tmdb;

        respData.errors.forEach(error => {
          this.errors[error] = true;
          this.state.hasErrors = true;
        });
        respData.warnings.forEach(warning => {
          this.warnings[warning] = true;
        });

        if (this.errors["ALREADY_SUGGESTED"]) {
          this.existingMovieId = respData.existingMovieId;
        }

        this.imdbUrl = this.urls.imdbUrl(respData.imdbId);
        this.rottenTomatoesUrl = this.urls.rottenTomatoesUrl(respData.imdbId);
        this.canIStreamItUrl = this.urls.canIStreamItUrl(
          respData.imdbId,
          "mini-bar"
        );
        if (respData.tmdb) {
          this.tmdbUrl = this.urls.tmdbUrl(respData.tmdb.tmdbId);
        }
      },
      response => {
        this.flash.addError(
          "Sorry, we ran into trouble processing your request. " +
            "Try again, or let us know that something is broken."
        );
        this.flash.show();
      }
    );
  }

  addMovie() {
    if (this.state.busy) {
      console.log("suppressing extra submit");
      return;
    }

    this.state.busy = true;
    this.svc.addMovie(
      { imdbId: this.imdbForm.controls.imdbUrl.value },
      response => {
        const respData = response.data;
        this.flash.addInfo(
          "Thanks! We've added your suggested title to MovieLens."
        );
        this.flash.show();
        this.stateService.go("base.movie", { id: respData.movieId });
        this.state.busy = false;
      },
      response => {
        this.flash.addError(
          "Sorry, we ran into trouble processing your request. " +
            "Try again, or let us know that something is broken."
        );
        this.flash.show();
        this.state.busy = false;
      }
    );
  }
}
