"use strict";

import { Injectable } from "@angular/core";
import { clone } from "lodash";

@Injectable()
export class ExploreService {
  headings = null;
  lastHeadings = null;

  constructor() {}

  setHeadings(myHeading, mySubHeading) {
    this.headings = {
      heading: myHeading,
      subHeading: mySubHeading
    };
  }

  clearHeadings() {
    this.lastHeadings = clone(this.headings);
    this.headings = null;
  }

  restoreLastHeadings() {
    this.headings = clone(this.lastHeadings);
  }

  getHeadings() {
    return this.headings === null ? null : this.headings;
  }
}
