"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { sortBy } from "lodash";
import { StateService } from "@uirouter/core";

import "./styles-page.scss";
import {TagDataService} from "../../common/tag-data/tag-data.service";


@Component({
  selector: "styles-page",
  template: `
    <div class="dev-styles">
      <h1>styles</h1>

      <h2>Colors</h2>

      <div class="colors-container small">
        <div class="colors">
          <div class="color-box blue-light">blue light</div>
          <div class="color-box blue">blue</div>
          <div class="color-box blue-dark">blue dark</div>
          <div class="color-box orange">orange</div>
          <div class="color-box orange-dark">orange dark</div>
        </div>
        <div class="colors">
          <div class="color-box off-white">off white</div>
          <div class="color-box gray-lighter">gray lighter</div>
          <div class="color-box gray-light">gray light</div>
          <div class="color-box gray">gray</div>
          <div class="color-box gray-dark">gray dark</div>
        </div>
      </div>

      <hr>
      <h2>Typography</h2>
      <div class="card">
        <div class="card-body">
          <h1 class="huge">&lt;h1.huge&gt; hipster lorem! <small>Secondary text</small></h1>
          <h1>&lt;h1&gt; Farm-to-table bitters <small>Secondary text</small></h1>
          <h2>&lt;h2&gt; pour-over gluten-free <small>Secondary text</small></h2>
          <h3>&lt;h3&gt; trust fund crucifix ennui squid <small>Secondary text</small></h3>
          <h4>&lt;h4&gt; locavore tattooed PBR&B meh <small>Secondary text</small></h4>
          <h5>&lt;h5&gt; trust fund skateboard <small>Secondary text</small></h5>
          <h6>&lt;h6&gt; Pop-up kale chips tote bag <small>Secondary text</small></h6>
          <p class="lead">&lt;p.lead&gt;Roof party forage pug bicycle rights salvia la croix deep v vaporware tbh venmo enamel pin kinfolk. Gluten-free post-ironic church-key, typewriter XOXO pork belly snackwave kickstarter farm-to-table put a bird on it unicorn normcore jianbing. XOXO offal lumbersexual hell of. Echo park four dollar toast thundercats kogi.</p>
          <p>&lt;p&gt;Deep v fixie Echo Park, cliche organic pour-over Williamsburg meggings Carles Cosby sweater. Lomo slow-carb chillwave, gluten-free ethnic twee craft beer letterpress. Drinking vinegar artisan Pinterest, art party ugh skateboard beard trust fund roof party. Pour-over viral small batch selfies PBR Schlitz, chia keffiyeh butcher artisan. Drinking vinegar next level vinyl locavore semiotics, keytar DIY plaid asymmetrical. Shoreditch bicycle rights Bushwick mixtape. Literally gastropub irony, kogi pop-up hoodie shabby chic ethical Godard McSweeney's chambray bespoke Marfa.</p>
          <p class="small">&lt;p.small&gt;Retro iPhone ramps, green juice keffiyeh thundercats keytar vape tousled roof party disrupt. Shoreditch green juice bespoke next level typewriter gentrify PBR&B pitchfork pabst. Keytar unicorn YOLO bicycle rights cronut artisan woke flannel. Vinyl kogi sustainable pug chillwave thundercats waistcoat stumptown iceland cray hexagon vape cornhole green juice raclette. Twee chicharrones asymmetrical literally gluten-free. Gentrify trust fund messenger bag, thundercats meditation twee wayfarers hell of photo booth af pickled chillwave tattooed. Twee paleo mixtape, slow-carb skateboard everyday carry squid.</p>
        </div>
      </div>

      <hr>
      <h2>Tag Colors</h2>
      <div class="colors-container">
        <p>The fractions are positive/total, negative/total</p>
        <div>
          <p>one-sided</p>
          <div class="color-box" [ngStyle]="pick(255, 0, 255)">255/255, 0/255</div>
          <div class="color-box" [ngStyle]="pick(127, 127, 255)">127/255, 127/255</div>
          <div class="color-box" [ngStyle]="pick(0, 255, 255)">0/255, 255/255</div>
          <div class="color-box" [ngStyle]="pick(0, 0, 255)">0/255, 0/255</div>
        </div>
        <div>
          <p>divided</p>
          <div class="color-box" [ngStyle]="pick(127, 127, 255)">127/255, 127/255</div>
          <div class="color-box" [ngStyle]="pick(20, 20, 40)">20/40, 20/40</div>
          <div class="color-box" [ngStyle]="pick(20, 10, 40)">20/40, 10/40</div>
          <div class="color-box" [ngStyle]="pick(10, 10, 40)">10/40, 10/40</div>
        </div>
        <div>
          <p>neutral</p>
          <div class="color-box" [ngStyle]="pick(0, 0, 255)">0/255, 0/255</div>
          <div class="color-box" [ngStyle]="pick(5, 1, 50)">5/50, 1/50</div>
          <div class="color-box" [ngStyle]="pick(1, 5, 50)">1/50, 5/50</div>
        </div>
        <div>
          <p>low activity</p>
          <div class="color-box" [ngStyle]="pick(1, 0, 1)">1/1, 0/1</div>
          <div class="color-box" [ngStyle]="pick(0, 1, 1)">0/1, 1/1</div>
          <div class="color-box" [ngStyle]="pick(1, 1, 3)">1/3, 1/3</div>
          <div class="color-box" [ngStyle]="pick(2, 3, 10)">2/10, 3/10</div>
        </div>
      </div>
    </div>
  `
})
export class StylesPageComponent {

  constructor(
    private stateService: StateService,
    private tagDataService: TagDataService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("App Styles | MovieLens");
  }

  pick(pos, neg, total) {
    const param = {
      positive: pos,
      negative: neg,
      total: total
    };
    const tagColor = this.tagDataService.chooseAColor(param);
    return { "background-color": tagColor };
  };
}
