import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AbstractControl } from "@angular/forms";
import { get, toLower } from "lodash";
import { of } from "rxjs";

import { ApiService } from "../api/api.service";
import { catchError, map } from "rxjs/operators";

enum UniqueValidatorType {
  EMAIL,
  USERNAME
}

@Injectable()
export class UserDataService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  /**
   * Get a validator that checks that the email doesn't already exist.
   *
   * Use currentVal if the user already has a setting that we don't wish to validate.
   */
  getEmailIsUniqueValidator(currentVal: string = null) {
    return this.getIsUniqueValidator(UniqueValidatorType.EMAIL, currentVal);
  }

  /**
   * Get a validator that checks that the username doesn't already exist.
   *
   * Use currentVal if the user already has a setting that we don't wish to validate.
   */
  getUsernameIsUniqueValidator(currentVal: string = null) {
    return this.getIsUniqueValidator(UniqueValidatorType.USERNAME, currentVal);
  }

  /**
   * returns a an angular async validator function for reactive forms
   * docs:
   * - https://angular.io/guide/reactive-forms
   * - https://angular.io/guide/form-validation
   */
  private getIsUniqueValidator(t: UniqueValidatorType, currentVal: string = null) {
    const endpoint =
      t === UniqueValidatorType.EMAIL ? "is-unique-email" : "is-unique-name";

    return (control: AbstractControl) => {
      if (currentVal && currentVal === control.value) {
        // short circuit if there is a current value and it hasn't changed
        return Promise.resolve(null);
      }

      const body =
        t === UniqueValidatorType.EMAIL
          ? { email: control.value }
          : { username: control.value };

      return this.http.post(`/api/actions/registration/${endpoint}`, body).pipe(
        // delay(1000),
        map(rsp => {
          return get(rsp, "status") === "fail" ? { notUnique: true } : null;
        }),
        catchError(() => {
          return of({ serverError: true });
        })
      );
    };
  }

  /**
   * @deprecated (see validators above)
   */
  checkEmailIsUnique(data, successCallback, errorCallback = () => {}) {
    this.apiService.ml4Post(
      "POST /api/actions/registration/is-unique-email",
      "/api/actions/registration/is-unique-email",
      data,
      successCallback,
      errorCallback
    );
  }

  /**
   * @deprecated (see validators above)
   */
  checkUsernameIsUnique(data, successCallback, errorCallback = () => {}) {
    return this.apiService.ml4Post(
      "POST /api/actions/registration/is-unique-name",
      "/api/actions/registration/is-unique-name",
      data,
      successCallback,
      errorCallback
    );
  }

  getProfile(successCallback, errorCallback = () => {}) {
    this.apiService.ml4Get(
      "GET /api/users/me",
      "/api/users/me",
      {},
      successCallback,
      errorCallback
    );
  }

  register(data, successCallback, errorCallback = error => {}) {
    this.apiService.ml4Post(
      "POST /api/users",
      "/api/users",
      data,
      successCallback,
      errorCallback
    );
  }

  update(data, successCallback, errorCallback = error => {}) {
    this.apiService.ml4Put(
      "PUT /api/users/me",
      "/api/users/me",
      data,
      successCallback,
      errorCallback
    );
  }

  deleteAccount(data, successCallback, errorCallback = () => {}) {
    this.apiService.ml4Delete(
      "DELETE /api/users/me",
      "/api/users/me",
      {},
      successCallback,
      errorCallback
    );
  }
}
