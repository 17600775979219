"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { get } from "lodash";

import "./movie-card-sm1.scss";

/**
 * Minimal tiny card (140w x 100h)
 */
@Component({
  selector: "movie-card-sm1",
  template: `
    <div class="movie-card-sm1" (click)="onClick.emit()">
      <div class="poster-container" [class.empty-poster]="!posterSrc">
        <img [src]="posterSrc" srcset="{{ posterSrcHq }} 2x" class="small-movie-poster" alt="movie poster" *ngIf="posterSrc"/>
      </div>

      <div class="metadata-container">
        <div class="card-title">{{ title }}</div>
      </div>

    </div>
  `
})
export class MovieCardSm1Component {
  @Input() movieDetails: any = null;

  @Output() onClick = new EventEmitter<null>();

  get posterSrc(): string {
    return get(this.movieDetails, "movie.posterW92Url");
  }

  get posterSrcHq(): string {
    return get(this.movieDetails, "movie.posterW185Url");
  }

  get title(): string {
    return get(this.movieDetails, "movie.title");
  }

}
