"use strict";

import { Component, Input } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

import { FlashService } from "../../common/flash/flash.service";
import { ApiService } from "../../common/api/api.service";
import { DeferredUrlService } from "../../common/deferred-url/deferred-url.service";
import { LoginService } from "./login.service";

@Component({
  selector: "login-page",
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  template: `
    <div class="login-page">
      <h3 class="page-description">sign in</h3>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>
            username or email
            <input
              type="text"
              class="form-control"
              formControlName="userName"
              autocapitalize="off"
              required
              autofocus
            />
          </label>
        </div>

        <div class="form-group">
          <label>
            password
            <input
              type="password"
              class="form-control"
              formControlName="password"
              autocapitalize="off"
              required
            />
          </label>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-default" style="margin-top: 5px" [disabled]="thinking">
            sign in
          </button>
          <span style="margin-left: 5px;" [hidden]="!thinking">
            <i class="fa fa-cog fa-spin"></i>
          </span>
        </div>
      </form>

      <p class="spaced">
        <a uiSref="auth.register">register</a> |
        <a uiSref="auth.passwordReset">forgot password</a>
      </p>
    </div>
  `
})
export class LoginPageComponent {
  @Input() alexa: boolean = false;

  private loginForm: FormGroup;
  private thinking: boolean = false;

  constructor(
    private apiService: ApiService,
    private deferredUrlService: DeferredUrlService,
    private fb: FormBuilder,
    private flashService: FlashService,
    private location: Location,
    private loginService: LoginService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Login | MovieLens");

    // common login page params
    const nextUrl = this.stateService.params.next;
    if (nextUrl) {
      this.deferredUrlService.setNextUrl(nextUrl, false);
    }

    this.loginForm = this.fb.group({
      userName: [
        "",
        {
          validators: [Validators.required]
        }
      ],
      password: [
        "",
        {
          validators: [Validators.required]
        }
      ]
    });
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  onSubmit() {
    this.thinking = true;
    this.alexa ? this.loginForAlexa() : this.login();
  }

  login() {
    this.apiService.ml4Post(
      "POST /api/sessions",
      "/api/sessions",
      this.loginForm.value,
      () => {
        this.thinking = false;
        this.loginService.loginSuccessRedirect();
      },
      (error) => {
        this.thinking = false;
        if(error.status == 504 || error.status == 502) {
            this.flashService.addError("Login failed. The login server appears to be down. Please try again later.").show();
        } else {
            this.flashService.addError("Login failed").show();
        }
      }
    );
  }

  loginForAlexa() {
    this.apiService.ml4Post(
      "POST /api/sessions/alexa",
      "/api/sessions/alexa",
      this.loginForm.value,
      response => {
        this.thinking = false;

        // redirect to the url that alexa provided
        const redirectUri = this.stateService.params.redirect_uri;
        const state = this.stateService.params.state;
        const token = get(response, "token");
        window.location.href = `${redirectUri}#state=${state}&access_token=${token}&token_type=Bearer`;
      },
      () => {
        this.thinking = false;
        this.flashService.addError("login failed").show();
      }
    );
  }
}
