import { Component, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { orderBy } from "lodash";

import { TagDataService } from "../../common/tag-data/tag-data.service";

import "./movie-tags.scss";

@Component({
  selector: "movie-tags",
  template: `
    <div class="movie-tags">
      <!-- your tags -->
      <div class="movie-details-block" *ngIf="model != null">
        <h2 class="movie-details-sub-heading">Your Tags</h2>

        <add-tag
          width="300px"
          style="margin-bottom: 24px"
          (selectTag)="submit($event)"
        ></add-tag>
        <span *ngIf="busy" class="tag-busy-indicator-cog"><i class="fa fa-cog fa-spin"></i></span>

        <div class="row">
          <div class="col-sm-12">
            <div
              class="tagBox"
              *ngFor="let tag of userTags"
              style="display: inline-block;"
            >
              <ml4-tag
                [movieId]="movieId"
                [movieTag]="tag"
                max-app="100"
                (unapply)="unapplyTag($event)"
              ></ml4-tag>
            </div>
          </div>
        </div>

        <div *ngIf="model.length == 0">
          No community tags yet - help MovieLens by being the first member to
          add one!
        </div>
      </div>

      <!-- community tags -->
      <div class="movie-details-block" *ngIf="model.length > 0">
        <h2 class="movie-details-sub-heading">
          Community Tags
        </h2>

        <div class="control-group not-spaced">
          view:
          <div class="btn-group">
            <button
              class="btn btn-default btn-xs no-glow"
              (click)="showFew()"
              [ngClass]="{ active: !isShowingAllTags }"
            >
              top
            </button>
            <button
              class="btn btn-default btn-xs no-glow"
              (click)="showAll()"
              [ngClass]="{ active: isShowingAllTags }"
            >
              all
            </button>
          </div>
        </div>

        <div class="control-group">
          sort by:

          <dropdown-new>
            <ng-container dropdownTrigger>
              <button type="button" class="btn btn-default">
                {{ currentSort }} <span class="caret"></span>
              </button>
            </ng-container>
            <ng-container dropdownContent>
              <li *ngFor="let option of sortOptions">
                <a (click)="updateSort(option)">{{ option }}</a>
              </li>
            </ng-container>
          </dropdown-new>

          <button
            type="button"
            class="btn btn-default not-spaced"
            (click)="reverse()"
          >
            <span
              class="fa"
              [ngClass]="{
                'fa-sort-amount-desc': currentOrder == 'desc',
                'fa-sort-amount-asc': currentOrder == 'asc'
              }"
            ></span>
          </button>
        </div>

        <!-- XXX: for some reason, the dropdown scrolls the page if it's not in a row/col layout -->
        <div class="row" style="margin-top:12px;">
          <div class="col-sm-12">
            <div
              class="tagBox"
              *ngFor="let tag of communityTags; let tagIndex = index"
              style="display: inline-block;"
            >
              <ml4-tag
                *ngIf="shouldShow(tag, tagIndex)"
                [movieId]="movieId"
                [movieTag]="tag"
                (apply)="applyExistingTag($event)"
              ></ml4-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class MovieTagsComponent implements OnInit {
  @Input() movieId: number;

  model = [];
  userTags = [];
  communityTags = [];
  tagMinScore = 2;
  tagsToShow = 24;
  doShowHidden = false;
  isShowingAllTags = false;
  busy = false;
  currentSort = "relevance";
  currentOrder = "desc";
  sortOptions = ["relevance", "alphabetical", "affect", "times used"];
  sortFunctions = {
    relevance: function(e) {
      return e.tagCountsViewModel.score;
    },
    alphabetical: function(e) {
      return e.tag.toLowerCase();
    },
    affect: function(e) {
      return e.tagCountsViewModel.positive - e.tagCountsViewModel.negative;
    },
    times_used: function(e) {
      return e.tagCountsViewModel.total;
    }
  };

  constructor(
    private http: HttpClient,
    private tagDataService: TagDataService
  ) {}

  ngOnInit() {
    this.tagDataService.getMovieTags(
      this.movieId,
      response => {
        this.model = response.data.scoredTags;
        for (var ix = 0; ix < this.model.length; ix++) {
          if (this.model[ix].tagId) {
            this.userTags.push(this.model[ix]);
          } else {
            this.communityTags.push(this.model[ix]);
          }
        }
        this.sort();
      },
      error => {}
    );
  }

  shouldShow(tagObj, tagIndex) {
    return (
      tagObj.tagCountsViewModel.score >= this.tagMinScore &&
      (this.doShowHidden == true || (tagIndex < (Math.floor(window.innerHeight / 40)*Math.floor(window.innerWidth / 250)) && tagObj.userRating != -1))
    );
  }

  

  showAll() {
    this.tagMinScore = -9999;
    this.tagsToShow = 9999;
    this.doShowHidden = true;
    this.isShowingAllTags = true;
  }

  showFew() {
    if (
      this.model.length < 5 ||
      (this.model.length > 0 && this.model[0].tagCountsViewModel.total === 1)
    ) {
      this.showAll();
    } else {
      this.tagMinScore = 2;
      this.tagsToShow = 24;
      this.doShowHidden = false;
      this.isShowingAllTags = false;
    }
  }

  reverse() {
    this.currentOrder = this.currentOrder == "asc" ? "desc" : "asc";
    this.sort();
  }

  updateSort(order) {
    if (order == "alphabetical") {
      this.currentOrder = "asc";
    } else {
      this.currentOrder = "desc";
    }
    this.currentSort = order;
    this.sort();
  }

  sort() {
    if (this.currentSort == "times used") {
      this.communityTags = orderBy(
        this.communityTags,
        this.sortFunctions.times_used,
        [this.currentOrder]
      );
      this.userTags = orderBy(this.userTags, this.sortFunctions.times_used, [
        this.currentOrder
      ]);
    } else {
      this.communityTags = orderBy(
        this.communityTags,
        this.sortFunctions[this.currentSort],
        [this.currentOrder]
      );
      this.userTags = orderBy(
        this.userTags,
        this.sortFunctions[this.currentSort],
        [this.currentOrder]
      );
    }
  }

  addTagToSorted(tag, list) {
    let cleanSort = this.currentSort.split(" ").join("_"); // turn "times used" into "times_used"
    let sortFunction = this.sortFunctions[cleanSort];
    for (var ix = 0; ix < list.length; ix++) {
      if (this.currentOrder == "asc") {
        if (sortFunction(tag) < sortFunction(list[ix])) {
          list.splice(ix, 0, tag);
          return;
        }
      } else {
        if (sortFunction(tag) > sortFunction(list[ix])) {
          list.splice(ix, 0, tag);
          return;
        }
      }
    }
    list.push(tag);
  }

  //given a string, return the object of that tag from the model or null
  getTagObjFromModel(tag) {
    for (var ix = 0; ix < this.model.length; ix++) {
      let check = this.model[ix];
      if (check.tag == tag) {
        return check;
      }
    }
    return null;
  }

  //apply a tag never before applied to this movie
  applyNewTag(tagObj) {
    if (!tagObj) {
      return;
    }
    this.addTagToSorted(tagObj, this.userTags);
    this.model.push(tagObj);
  }

  //apply a tag already applied to the movie
  applyExistingTag(tagObj) {
    if (!tagObj) {
      return;
    }
    this.communityTags.splice(this.communityTags.indexOf(tagObj), 1);
    this.addTagToSorted(tagObj, this.userTags);
  }

  unapplyTag(tagObj) {
    this.userTags.splice(this.userTags.indexOf(tagObj), 1);
    if (tagObj.tagCountsViewModel.total > 0) {
      this.addTagToSorted(tagObj, this.communityTags);
    } else {
      this.model.splice(this.userTags.indexOf(tagObj), 1);
    }
  }

  submit(tag) {
    if (!tag || this.busy) {
      return;
    }

    this.busy = true;
    this.tagDataService.applyTag(this.movieId, tag, 0, null, response => {
      let tagId = response.data.tagApplication.tagEventId;
      let normalizedTag = response.data.tagApplication.tag;

      var tagObj = this.getTagObjFromModel(normalizedTag);
      if (tagObj && tagObj.tagId) {
          // The user applied a tag they already have applied. Update only TagId
          // (Shouldn'thave changed, but if it did...)
          tagObj.tagId = tagId;
      } else if (tagObj && !tagObj.tagId) {
        tagObj.tagId = tagId;
        tagObj.tagCountsViewModel.total++;
        this.applyExistingTag(tagObj);
      } else {
        tagObj = {
          tag: normalizedTag,
          tagCountsViewModel: {
            dominantAffect: "neutral",
            total: 1
          },
          userAffect: "neutral",
          tagId: tagId,
          userRating: null
        };
        this.applyNewTag(tagObj);
      }
      this.busy = false;
    });
  }
}
