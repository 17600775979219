"use strict";

import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SessionService } from "../../common/session/session.service";
import { StateService } from "@uirouter/core";

@Component({
  selector: "configure-bard-page",
  template: require("./configure-bard-page.html")
})
export class ConfigureBardPageComponent implements OnInit {
  constructor(
    private stateService: StateService,
    private page: Title,
    private session: SessionService
  ) {}

  ngOnInit() {
    this.page.setTitle("Pick Movie Groups");
  }
  onSubmit(evt) {
    if (evt.success) {
      this.session.reloadSession();
    }

    this.stateService.go("base.frontpage");
  }
}
