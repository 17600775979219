"use strict";

import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { MovieDataService } from "../movie-data/movie-data.service";
import { SessionService } from "../session/session.service";

@Injectable()
export class UserActionsService {
  constructor(
    private apiService: ApiService,
    private movieDataService: MovieDataService,
    private sessionService: SessionService
  ) {}

  // ratings

  private onRatingSuccess(
    movieId: number,
    response: any,
    successCallback?: Function
  ) {
    this.sessionService.setRatingCount(response.data.numRatings);
    this.movieDataService.invalidate(movieId);
    if (successCallback) {
      successCallback(response);
    }
  }

  private onRatingError(response: object, errorCallback?: Function) {
    if (errorCallback) {
      errorCallback(response);
    }
  }

  rate(
    movieId: number,
    rating: number,
    previousRating: number,
    predictedRating: number,
    successCallback?: Function,
    errorCallback?: Function
  ): void {
    const data = {
      movieId: movieId,
      rating: rating,
      predictedRating: predictedRating,
      previousRating: previousRating
    };

    this.apiService.ml4Post(
      "POST /api/users/me/ratings",
      "/api/users/me/ratings",
      data,
      response => {
        this.onRatingSuccess(movieId, response, successCallback);
      },
      response => {
        this.onRatingError(response, errorCallback);
      }
    );
  }

  unrate(
    movieId: number,
    successCallback?: Function,
    errorCallback?: Function
  ): void {
    this.apiService.ml4Delete(
      "DELETE /api/users/me/ratings",
      `/api/users/me/ratings/${movieId}`,
      {},
      response => {
        this.onRatingSuccess(movieId, response, successCallback);
      },
      response => {
        this.onRatingError(response, errorCallback);
      }
    );
  }

  hide(
    movieId: number,
    predictedRating: number,
    successCallback?: Function,
    errorCallback?: Function
  ): void {
    this.rate(
      movieId,
      -1,
      null,
      predictedRating,
      successCallback,
      errorCallback
    );
  }

  unhide(
    movieId: number,
    successCallback?: Function,
    errorCallback?: Function
  ): void {
    this.unrate(movieId, successCallback, errorCallback);
  }

  wishlist(
    movieId: number,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    const data = { movieId: movieId };
    this.apiService.ml4Post(
      "POST /api/users/me/wishlist",
      "/api/users/me/wishlist",
      data,
      successCallback,
      errorCallback
    );
  }

  unwishlist(
    movieId: number,
    successCallback?: Function,
    errorCallback?: Function
  ) {
    this.apiService.ml4Delete(
      "DELETE /api/users/me/wishlist/:id",
      `/api/users/me/wishlist/${movieId}`,
      {},
      successCallback,
      errorCallback
    );
  }
}
