import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "theme-change-modal",
  template: `
    <modal-dialog (onClose)="onClose.emit()" *ngIf="doShow">
      <div title>New Feature!</div>
      <div main>
        <p>Movielens has recently added theme selection and Dark Mode. To change your theme you may follow this link or navigate to 
          <a (click)="onClick.emit()" uiSref="base.profile.theme">user profile settings.</a>
        </p>
        <div class="col-sm-6 unpadded text-center" style="display: inline-block;">
          <img class="img-responsive" src="/images/site/movielens-dark.png" alt="helper" style="height: 100%; width: 100%;" >
        </div>
        <div class="col-sm-6 unpadded text-center" style="display: inline-block;">
          <img class="img-responsive" src="/images/site/movielens-light.png" alt="helper" style="height: 100%; width: 100%;" >
        </div>
        </div>
    </modal-dialog>
  `
})
export class ThemeChangeModalComponent implements OnInit {
  @Input() doShow: boolean = true;
  @Input() modalData: object = null;
  @Input() doLink: boolean = true;


  @Output() onClose = new EventEmitter<null>();
  @Output() onClick = new EventEmitter<null>();



  constructor(
  ) {}


  // Closes the modal box when called
  closeModal() {
    this.modalData["doShow"] = false;
  }

  maybeEmitOnClick() {
    if (!this.doLink) {
      this.onClick.emit();
    }
  }

  ngOnInit() {
  }
}
