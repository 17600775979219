"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { FormsModule } from "@angular/forms";

import { DevTemplateComponent } from "./dev-template.component";
import { FlashDemosPageComponent } from "./flash-demos-page.component";
import { MovieCardDemosPageComponent } from "./movie-card-demos-page.component";
import { MovieCardModule } from "../../components/movie-card/movie-card.module";
import { MovieRowModule } from "../../components/movie-row/movie-row.module";
import { RoutesPageComponent } from "./routes-page.component";
import { StylesPageComponent } from "./styles-page.component";
import { TestSearchPageComponent } from "./test-search-page.component";
import { TestWsPageComponent } from "./test-ws-page.component";
import {UIRouterModule} from "@uirouter/angular";

const devTemplate = {
  name: "base.dev",
  abstract: true,
  component: DevTemplateComponent
};

const routesPage = {
  name: "base.dev.index",
  url: "/dev",
  component: RoutesPageComponent
};

const movieCardDemosPage = {
  name: "base.dev.movieCardDemos",
  url: "/dev/demos/movie-cards",
  component: MovieCardDemosPageComponent
};

const flashDemosPage = {
  name: "base.dev.flashDemos",
  url: "/dev/demos/flash",
  component: FlashDemosPageComponent
};

const stylesPage = {
  name: "base.dev.styles",
  url: "/dev/styles",
  component: StylesPageComponent
};

const testSearchPage = {
  name: "base.dev.testSearch",
  url: "/dev/demos/test-search",
  component: TestSearchPageComponent
};

const testWsPage = {
  name: "base.dev.testWs",
  url: "/dev/demos/test-ws",
  component: TestWsPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MovieCardModule,
    MovieRowModule,
    UIRouterModule.forChild({
      states: [
        devTemplate,
        routesPage,
        movieCardDemosPage,
        flashDemosPage,
        stylesPage,
        testSearchPage,
        testWsPage
      ]
    })
  ],
  declarations: [
    DevTemplateComponent,
    FlashDemosPageComponent,
    MovieCardDemosPageComponent,
    RoutesPageComponent,
    StylesPageComponent,
    TestSearchPageComponent,
    TestWsPageComponent
  ]
})
export class DevModule {}
