"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";

import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { RectuneService } from "../../common/rectune/rectune.service";

import "./frontpage.scss";
import {ApiService} from "../../common/api/api.service";
import { SessionService } from "../../common/session/session.service";
import { UserDataService } from "../../common/user-data/user-data.service";

@Component({
  selector: "frontpage",
  template: `
    <if-expt exptId="WaterlooRecruit2023" *ngIf="listOfSearchResults">
      <ng-template>
        <div class="alert alert-info" style="margin-top: 24px;">
          University of Waterloo researchers are looking for Movielens users to participate in a study that measures
          recommendation system quality.
          Participants will get an Amazon e-gift card worth $15 US or $20 CAD per hour (2-5 hours of time commitment).
          <a href="http://mooneye.cs.uwaterloo.ca:3624/overview/">Click here for more information</a>.
          [<em>This study has been reviewed and received clearance through a University of Waterloo Research Ethics
          Board</em> ]
        </div>
      </ng-template>
    </if-expt>
    <div class="frontpage" *ngIf="listOfSearchResults">

      <div *ngIf="showExternalInvite">
        <external-recs></external-recs>
      </div>

      <!--        expt rec-val dataset start-->
      <div *ngIf="show_rec_val_column && elicit" class="form-popup">
        <div id="cross-v1" style="font-size: 15px">
          <div><a class="btn btn-link" (click)="elicit=false;hide=false;" style="float: right">&#10006;</a></div>
          <div style="padding: 5px">Unlike elsewhere on this website, we’re asking you to tell us what you think about
            movies you possibly have not seen already.
          </div>
          <br>
          <div style="padding: 5px">Please do not look up information about these movies as we’re interested in what you
            think of them right now. This data will be used to improve our recommendation systems.
          </div>
          <br>
          <div style="padding: 5px">Contact movielens@umn.edu for any questions regarding this study.</div>
          <br>

          <a class="btn btn-link" style="font-size: 15px; padding: 5px" (click)="hide=true">I want to hide this row (opt
            out of this study)</a>

          <div *ngIf="hide" style="margin-top: 20px; padding: 5px; font-size: 15px;">
            Are you sure to opt out of this study?
            <div>
              <a class="btn btn-link" (click)="elicit=false; hide=false;" style="font-size: 15px">No, I want to stay in
                this study.</a>
              <a class="btn btn-link" (click)="optout_recval()" style="font-size: 15px">Yes, I want to opt out.</a>
            </div>
          </div>
          <a class="btn btn-primary" (click)="elicit=false; hide=false;" style="float: right; font-size: 15px">Close</a>

        </div>
      </div>
      <!--        expt rec-val dataset end -->

      <theme-change-modal
          [doShow]="showModal"
          (onClose)="closeModal()"
          (onClick)="closeModal()"
      ></theme-change-modal>
      <div *ngFor="let searchResultContainer of listOfSearchResults" class="frontpage-section-top">

        <!--        expt rec-val dataset start-->
        <div class="explore-header frontpage-section-header" *ngIf="searchResultContainer.searchResults">
          <span
              *ngIf="show_rec_val_column && searchResultContainer.title==='What do you think of these movies?'">{{ searchResultContainer.title }}
            <button (click)="elicit=true" class="button button5">&#9432;</button>
          <p *ngIf="show_rec_val_column && searchResultContainer.title==='What do you think of these movies?'"
             style="font-size: 15px; color: #777777; margin: 0">
            * We are collecting data on what you think about movies you haven’t seen before in order to design better recommendations – please fill this out to help us do so for you.
          </p>
          </span>

          <a *ngIf="searchResultContainer.title!='What do you think of these movies?'"
             [href]="searchResultContainer.url"
             title="go to the full list" class="plainlink">{{ searchResultContainer.title }}</a>
          <a uiSref="base.rectune" title="configure"
             *ngIf="isRectuneQualified && searchResultContainer.title == 'top picks'"
             class="btn btn-xs btn-default" style="margin-left: 6px;"><i class="fa fa-cog"></i></a>
        </div>

        <ml4-movie-row
            *ngIf="!(!show_rec_val_column && searchResultContainer.title==='What do you think of these movies?')"
            [title]="searchResultContainer.title"
            [movieDetailsList]="searchResultContainer.searchResults"></ml4-movie-row>


        <!--        expt rec-val dataset end-->
        <!--        below is the original code-->
        <!--        <div class="explore-header frontpage-section-header" *ngIf="searchResultContainer.searchResults">-->
        <!--          <a [href]="searchResultContainer.url"-->
        <!--             title="go to the full list" class="plainlink">{{ searchResultContainer.title }}</a>-->
        <!--          <a uiSref="base.rectune" title="configure"-->
        <!--             *ngIf="isRectuneQualified && searchResultContainer.title == 'top picks'"-->
        <!--             class="btn btn-xs btn-default" style="margin-left: 6px;"><i class="fa fa-cog"></i></a>-->
        <!--        </div>-->
        <!--        <ml4-movie-row [movieDetailsList]="searchResultContainer.searchResults"></ml4-movie-row>-->

      </div>

      <div class="frontpage-section-top">

        <div class="row">
          <div class="col-md-6">

            <h1>add a movie</h1>

            <div class="jumbotron ml4-jumbotron" style="padding-top: 0;">
              <p>
                Is there a movie that you'd like to add to MovieLens?
                You can do it!
              </p>
              <p>
                <a class="btn btn-primary btn-lg" role="button" uiSref="base.addTitle">add a movie</a>
              </p>
            </div>

          </div> <!-- end .col-md-6 -->

        </div>

      </div>

  `
})
export class FrontpageComponent implements OnInit {
  showModal = false;
  listOfSearchResults = null;
  isRectuneQualified = false;
  // expt rec-val dataset start
    elicit = false;
    show_rec_val_column = false;
    hide = false;
  // expt rec-val dataset end
  private readonly userSubscription;
  private Preferences; 

  constructor(
    private movieDataService: MovieDataService,
    private rectuneService: RectuneService,
    private stateService: StateService,
    private titleService: Title,
    // expt rec-val dataset start
    private apiService: ApiService,
    // expt rec-val dataset end
    private sessionService: SessionService,
    private userDataService: UserDataService,
  )
    {
      this.userSubscription = this.sessionService.currentUserData.subscribe(
        data => {
          try {
            this.Preferences = data.preferences;
          } catch {}
        }
      )
      this.userSubscription.unsubscribe();
      this.updateFirstLoginTheme();
    }

  ngOnInit(): void {
    this.titleService.setTitle("Home | MovieLens");

    this.movieDataService.frontpage(listOfSearchResults => {
      this.listOfSearchResults = listOfSearchResults;
    });

    this.isRectuneQualified = this.rectuneService.isUserQualified();
  }

  get showExternalInvite() {
    return this.stateService.params.q === "use-external-recs";
  }

    // expt rec-val dataset start
  optout_recval(){
      this.elicit=false;
      this.show_rec_val_column = false;
      this.apiService.ml4Post(
          "POST /api/rec-val/quit",
          "/api/rec-val/quit",
          {
          },
          response => {
          },
          response => {
          }
      );
  }
    // expt rec-val dataset end
  updateFirstLoginTheme() {
    if (this.Preferences.options.firstLoginTheme == true) {
      this.showModal = true;
    }
  }

  closeModal() {
    this.sessionService.setFirstTime(false)
    this.userDataService.update({options:{ firstLoginTheme: false} }, (error) => {
      console.log(error);
    });
    this.showModal = false;
  }
}
