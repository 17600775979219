"use strict";

import { Component } from "@angular/core";
import { get } from "lodash";

@Component({
  selector: "admin-template",
  template: `
    <div class="admin-page">
      <div class="ml4-container admin-header">
        <nav class="navbar navbar-default">
          <div class="container-fluid">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
              <a class="navbar-brand" uiSref="admin.adminHome"
                >MovieLens admin</a
              >
            </div>

            <ul class="nav navbar-nav navbar-right">
              <li><a uiSref="base.frontpage">exit admin</a></li>
            </ul>
          </div>
          <!-- /.container-fluid -->
        </nav>

        <div class="positioned-right">
          <div id="alert-box" class="default-positioned-alert-box"></div>
        </div>
      </div>

      <div class="ml4-container">
        <div ui-view class="contentWrapper"></div>
      </div>
    </div>

    <!--<ui-view></ui-view>-->
  `,
  styles: [
    `
      #alert-box .alert {
        top: 0;
        right: 0;
      }
    `
  ]
})
export class AdminTemplateComponent {}
