"use strict";

import { Component } from "@angular/core";

/**
 * Hidden control to turn externalrecs on for the user
 *
 * To activate, visit home page with param:
 *   http://localhost:9009/home?q=use-external-recs
 */
@Component({
  selector: "external-recs",
  template: `
    <div class="centered">
      <h1>Would you like to try our new recommender?</h1>
      <button type="submit" 
              class="btn btn-primary" 
              (click)="useExternalRecs()">Yes!</button>
    </div>
  `
})
export class ExternalRecsComponent {
  constructor() {}

  useExternalRecs() {
    fetch("/api/users/me", {
      method: "PUT",
      body: JSON.stringify({ engineId: "external" }),
      headers: new Headers({
        "Content-Type": "application/json"
      })
    })
      .then(res => document.location.reload())
      .catch(error => {});
  }
}
