import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { StateService } from "@uirouter/core";

import { DropdownComponent } from "../dropdown/dropdown.component";
import { TagDataService } from "../../common/tag-data/tag-data.service";

import "./tag.scss";

@Component({
  selector: "ml4-tag",
  template: require("./movie-tag.html")
})
export class MovieTagComponent implements OnInit {
  @Input() movieId: any = null;
  @Input() movieTag: any;
  @ViewChild(DropdownComponent) myDropdown: DropdownComponent;
  @Output() apply = new EventEmitter<any>();
  @Output() unapply = new EventEmitter<any>();
  tagColor = null;
  busy = false;

  constructor(
    private tagDataService: TagDataService,
    private stateService: StateService
  ) {}

  getDropdownButton() {
    if (this.isUserTagged()) {
      return "<span><i class='fa fa-ellipsis-h ml4-fa-1x'></i></span>";
    }
    return "<span><i class='fa fa-plus ml4-fa-10pt'></i></span>";
  }

  isUserTagged() {
    return this.movieTag.tagId;
  }

  setNotApplied() {
    this.movieTag.tagId = null;
  }

  setTagId(id) {
    if (id && !this.isUserTagged()) {
      this.movieTag.tagCountsViewModel.total++;
    } else if (!id && this.isUserTagged()) {
      this.movieTag.tagCountsViewModel.total--;
    }
    this.movieTag.tagId = id;
  }

  setUserRating(rating) {
    this.movieTag.userRating = rating;
  }

  applyTag(affect) {
    if (this.busy) {
      return;
    }

    this.busy = true;
    if (this.isUserTagged()) {
      this.setTagId(this.movieTag.tagId);
    }

    this.movieTag.userAffect = this.tagDataService.ML4_TAG_CONFIG.tagAffect[
      "" + affect
    ];

    this.tagDataService.applyTag(
      this.movieId,
      this.movieTag.tag,
      affect,
      this.movieTag.tagId,
      response => {
        if (this.movieTag.tagId == null) {
          this.setTagId(response.data.tagApplication.tagEventId);
        }
        this.apply.emit(this.movieTag);
        this.busy = false;
      }
    );
    this.myDropdown.closeMenu();
  }

  unapplyTag() {
    this.movieTag.userAffect = null;
    this.tagDataService.unapplyTag(
      this.movieId,
      this.movieTag.tag,
      this.movieTag.tagId
    );
    this.setNotApplied();
    this.myDropdown.closeMenu();
    this.unapply.emit(this.movieTag);
  }

  hideTag() {
    this.setUserRating(-1);
    this.tagDataService.rateTag(this.movieId, this.movieTag.tag, -1);
    this.myDropdown.closeMenu();
  }

  unhideTag() {
    this.setUserRating(null);
    this.tagDataService.unrateTag(this.movieId, this.movieTag.tag);
    this.myDropdown.closeMenu();
  }

  coloredButtonStyle() {
    if (this.isUserTagged()) {
      return null;
    }
    return { "background-color": this.tagColor };
  }

  ngOnInit() {
    this.movieTag.tagLinkText = this.movieTag.tag;
    // XXX: the page this links to is case sensitive. Normalizing the tag seems inappropriate therefore.
    // removed code for nromatlization: this.tagDataService.normalizeName(this.movieTag.tag);
    this.tagColor = this.tagDataService.chooseAColor(
      this.movieTag.tagCountsViewModel
    );
  }
}
