"use strict";

import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  OnInit
} from "@angular/core";

@Directive({
  selector: "[imgPreload]"
})
export class ImgPreloadDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, "fade");
  }

  @HostListener("load") onLoad() {
    this.renderer.addClass(this.el.nativeElement, "in");
  }
}
