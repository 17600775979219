"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

@Component({
  selector: "welcome-page",
  template: `
    <div class="welcome-interstitial">

      <h1>
        Tutorial!
        <small><a uiSref="base.frontpage">skip <i class="fa fa-chevron-right"></i></a></small>
      </h1>

      <p class="lead welcome-block section-top">
        Nice picks!
        <em>Start rating movies</em> by clicking the stars below the movie - the more you rate, the more
        personalized your recommendations will get.
      </p>

      <div class="text-center welcome-block">
        <img src="/images/rate-more.jpg" width="546" height="428" class="img-responsive img-thumbnail" alt="rate movies">
      </div>
      <div class="clearfix"></div>

      <p class="lead welcome-block section-top">
        A tag is a word or short phrase that describes a movie. Good tags add information beyond what
        is already displayed about the movie.
        To tag a movie, <em> type your tag in the box "Your tags" </em> and click on
        <img src="/images/add-button.png" width="30px" height="30px"/> to
        add it.
      </p>

      <div class="text-center welcome-block">
        <img src="/images/tag-more.jpg" width="546" height="428" class="img-responsive img-thumbnail" alt="rate movies">
      </div>
      <div class="clearfix"></div>

      <p class="lead welcome-block section-top">
        To keep track of the movies you'd like to see, you can <em>add them to your wish list</em>.
      </p>

      <div class="text-center welcome-block">
        <img src="/images/wish-list.jpg" width="543" height="400" class="img-responsive img-thumbnail"
             alt="add to wish list">
      </div>
      <div class="clearfix"></div>

      <div class="text-center welcome-block">
        <img src="/images/wish-list-card.jpg" width="542" height="332" class="img-responsive img-thumbnail"
             alt="add to wish list">
      </div>
      <div class="clearfix"></div>


      <div class="text-center welcome-block">
        <a uiSref="base.frontpage" class="btn btn-primary btn-lg">
          Got it <i class="fa fa-chevron-right"></i>
        </a>
      </div>

    </div>
  `,
  styles: [
    `
      .welcome-interstitial {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .welcome-block {
        margin: 24px 0;
      }

      .welcome-block.section-top {
        margin-top: 36px;
      }
    `
  ]
})
export class WelcomePageComponent {
  constructor(
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Welcome | MovieLens");
  }

  ok() {
    this.stateService.go("base.frontpage");
  }
}
