"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";
import { ApiService } from "../../common/api/api.service";
import { MovieDataService } from "../../common/movie-data/movie-data.service";
import { UrlsService } from "../../common/urls/urls.service";

import "./admin-movie-flags-page.scss";
import "./admin-movie-page.scss";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddMovieService} from "../add-movie/add-movie.service";
import {FlashService} from "../../common/flash/flash.service";
import {AdminAddMovieService} from "./admin-add-movie.service";

@Component({
  selector: "admin-add-movie-page",
  template: `
    <div class="add-a-title">
      <h1>add a title</h1>
      <div class="row">
        <div class="col-md-5">
          <div class="add-title-by-imdb">
            <p>Find the movie you want to add on IMDb and enter its URL:</p>
            <form [formGroup]="imdbForm" class="imdb-form" role="form" (ngSubmit)="checkAdd()">
              <div class="form-group has-feedback" [ngClass]="{'has-success':state.imdbIsValid(), 'has-error': state.imdbIsInvalid()}">
                <label class="sr-only">IMDb URL</label>
                <input type="text" class="form-control" formControlName="imdbUrl">
                <span class="fa fa-exclamation-triangle form-control-feedback" style="margin-top: 10px;" *ngIf="state.imdbIsInvalid()"></span>
              </div>

              <div class="alert alert-danger" [hidden]="!state.imdbIsInvalid()">
                Invalid IMDb url.  Please check its format.  Example: http://www.imdb.com/title/tt0114709/
              </div>

              <button type="submit" class="btn btn-default" [disabled]="state.imdbIsInvalid()">Submit</button>
            </form>

            <div [hidden]="!response">
              
              <pre>{{response | json}}</pre>
            </div>
          </div>
        </div><!-- end col6 -->
        <div class="col-md-5">
          <div [hidden]="!errors.INVALID_IMDB_ID">
              <div class="alert alert-danger">
                That URL does not contain a valid IMDb ID.
              </div>
            </div>
          <div [hidden]="!errors.ALREADY_SUGGESTED">
              <div class="alert alert-danger">
                That movie has already been suggested.
                <a uiSref="base.movie" [uiParams]="{ id: existingMovieId }">See the movie page</a>
              </div>
            </div>
          <div [hidden]="!errors.NOT_FOUND_IN_IMDB">
              <div class="alert alert-danger">
                We were unable to fetch that URL from imdb.com.
              </div>
            </div>
          <div [hidden]="!errors.NOT_FOUND_IN_TMDB">
              <div class="alert alert-danger">
                We were unable to fetch that URL from tmdb.org, our source of movie metadata.
                Possible, this means that this title is not suitable for MovieLens (e.g., it is not a movie).
              </div>
            </div>
          <div [hidden]="!errors.POLICY_VIOLATION_ADULT">
              <div class="alert alert-danger">
                TMDB, our source of movie metadata, says that movie is an adult title.
              </div>
            </div>
          <div [hidden]="!errors.POLICY_VIOLATION_FAR_FUTURE_RELEASE">
              <div class="alert alert-danger">
                TMDB, our source of movie metadata, says that movie has a release date more than two months from now.
              </div>
            </div>
          <div [hidden]="!warnings.POLICY_VIOLATION_TOO_SHORT">
              <div class="alert alert-warning">
                TMDB, our source of movie metadata, indicates that this title is very short.
                Please confirm that it is indeed a movie before continuing.
              </div>
            </div>
          <div [hidden]="!warnings.POLICY_VIOLATION_UNKNOWN_RELEASE_DATE">
              <div class="alert alert-warning">
                TMDB, our source of movie metadata, does not know the release date of this movie.
                Please confirm that it is not a far-future release (> 2 months from today) before adding.
              </div>
            </div>
          <div class="card" [hidden]="!response">
                <div class="card-body">
                  <dl [hidden]="!tmdb">
                    <dt>title</dt>
                    <dd>{{ tmdb?.title }}</dd>
                    <dt>release date</dt>
                    <dd>{{ tmdb?.releaseDate | date }}</dd>
                    <dt>runtime</dt>
                    <dd>{{ tmdb?.runtime }} minutes</dd>
                    <dt>genres</dt>
                    <dd>{{ tmdb?.genres.join(', ') }}</dd>
                    <dt>plot summary</dt>
                    <dd>{{ tmdb?.plotSummary }}</dd>
                  </dl>

                  <p>Links:</p>
                  <ul>
                    <li><a [href]="imdbUrl" target="_blank">IMDb</a></li>
                    <li><a [href]="tmdbUrl" target="_blank">TMDB</a></li>
                  </ul>

                  <div *ngIf="response && !state.hasErrors && canIStreamItUrl" class="can-i-stream-it-container">
                    <p>Availability (from www.canistream.it):</p>
                    <iframe [src]="svc.trustResourceUrl(canIStreamItUrl)" frameborder="0" width="100%" height="140" scrolling="no">
                      {{canIStreamItUrl}}
                    </iframe>
                  </div>

                  <button type="submit" class="btn btn-primary" [disabled]="state.imdbIsValid() == false || state.busy || errors.ALREADY_SUGGESTED" (click)="addMovie()">
                    Yes, add this movie to MovieLens
                  </button>

                  <button type="submit" class="btn btn-default" (click)="clearState()">
                    Cancel
                  </button>

                </div>
              </div>
        </div> <!-- end col5 -->
      </div>
    </div> 
  `
})
export class AdminAutoAddMoviePageComponent {
    public imdbForm: FormGroup;
    errors = <any>{};
    warnings = {};
    response = null;
    imdbUrl = null;
    rottenTomatoesUrl = null;
    canIStreamItUrl = null;
    tmdbUrl = null;
    tmdb = null;
    existingMovieId = null;

    imdbRegex = /^https?:\/\/.*\.imdb\.com\/title\/tt(\d{7,8}).*|^(tt)?(\d{7,8})$/;

    constructor(
        private formBuilder: FormBuilder,
        private svc: AdminAddMovieService,
        private flash: FlashService,
        private stateService: StateService,
        private urls: UrlsService
    ) {}

    state = {
        hasErrors: false,
        busy: false,
        imdbIsValid: () => {
            return this.imdbForm.controls.imdbUrl.valid;
        },
        imdbIsInvalid: () => {
            return (
                this.imdbForm.controls.imdbUrl.dirty &&
                this.imdbForm.controls.imdbUrl.invalid
            );
        }
    };

    clearState() {
        this.state.hasErrors = false;
        this.state.busy = false;
        this.errors = {};
        this.warnings = {};
        this.response = null;
        this.rottenTomatoesUrl = null;
        this.canIStreamItUrl = null;
        this.tmdbUrl = null;
        this.tmdb = null;
        this.existingMovieId = null;
    }

    ngOnInit() {

        this.imdbForm = this.formBuilder.group({
            imdbUrl: ["", [Validators.required, Validators.pattern(this.imdbRegex)]]
        });
        this.imdbForm.get("imdbUrl").valueChanges.subscribe(val => {
            this.clearState();
        });
    }

    // called on submit.
    checkAdd() {

        this.svc.suggestImdbUrl(
            { imdbId: this.imdbForm.controls.imdbUrl.value },
            response => {
                this.clearState();
                let respData = response.data;
                this.response = response;
                this.tmdb = respData.tmdb;

                respData.errors.forEach(error => {
                    this.errors[error] = true;
                    this.state.hasErrors = true;
                });
                respData.warnings.forEach(warning => {
                    this.warnings[warning] = true;
                });

                if (this.errors["ALREADY_SUGGESTED"]) {
                    this.existingMovieId = respData.existingMovieId;
                }

                this.imdbUrl = this.urls.imdbUrl(respData.imdbId);
                this.rottenTomatoesUrl = this.urls.rottenTomatoesUrl(respData.imdbId);
                this.canIStreamItUrl = this.urls.canIStreamItUrl(
                    respData.imdbId,
                    "mini-bar"
                );
                if (respData.tmdb) {
                    this.tmdbUrl = this.urls.tmdbUrl(respData.tmdb.tmdbId);
                }
            },
            response => {
                this.flash.addError(
                    "Sorry, we ran into trouble processing your request. " +
                    "Try again, or let us know that something is broken."
                );
                this.flash.show();
            }
        );
    }

    addMovie() {
        if (this.state.busy) {
            console.log("suppressing extra submit");
            return;
        }

        this.state.busy = true;
        this.svc.addMovie(
            { imdbId: this.imdbForm.controls.imdbUrl.value },
            response => {
                const respData = response.data;
                this.flash.addInfo(
                    "Thanks! We've added your suggested title to MovieLens."
                );
                this.flash.show();
                this.stateService.go("base.movie", { id: respData.movieId });
                this.state.busy = false;
            },
            response => {
                this.flash.addError(
                    "Sorry, we ran into trouble processing your request. " +
                    "Try again, or let us know that something is broken."
                );
                this.flash.show();
                this.state.busy = false;
            }
        );
    }
}
/*
  private movieId: number;
  private adminMovieData: any;
  private movieData: any;

  title: String;
  actors: String;
  directors: String;
  releaseDate: String;
  languages: String;
  comment: String;

  constructor(
    private titleService: Title,
    private apiService: ApiService,
    private stateService: StateService,
    private urlService: UrlsService
  ) {}

  ngOnInit() {
    this.adminMovieData = false;
    this.titleService.setTitle("Admin | MovieLens | Movie");
    this.movieId = parseInt(this.stateService.params.id);

    this.reloadData();
  }

  reloadData() {
    this.movieData = null;
    this.apiService.ml4Get(
      "GET /api/admin/movie/:id",
      "/api/admin/movie/" + this.movieId,
      {},
      response => {
        var data = get(response, "data.adminMovieData");
        console.log(data);
        this.movieData = data.current;
        this.title = data.current.title;
        this.actors = data.current.actors.join(", ");
        this.directors = data.current.directors.join(", ");
        this.languages = data.current.languages.join(", ");
        this.releaseDate = data.current.releaseDate;
        this.comment = "update legacy data"
      }
    );
  }


  submit() {
    this.apiService.ml4Put(
      "PUT /api/admin/movie/:id",
      "/api/admin/movie/" + this.movieId,
      {'title':this.title, 'actors':this.actors, 'directors': this.directors, 'releaseDate':this.releaseDate, 'languages':this.languages, "comment":this.comment},
      response => {
        this.reloadData();
        },
      response => {alert("Failed to update");
      console.log(response);
      this.reloadData();}
    )
    console.log()
  }
}
*/