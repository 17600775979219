"use strict";

import { Component,
OnInit } from "@angular/core";
import { ApiService } from "../../common/api/api.service";


@Component({
  selector: "admin-expt-table",
  template: `
    <h1> Currently Loaded Experiments </h1>
    <table class = "table">
      <thead>
      <tr>
        <th> Experiment Id </th>
        <th> Owner </th>
        <th> Status </th>
        <th> Notes </th>
        <th> Active </th>
        <th> Edit </th>
      </tr>
      </thead>
      <tbody></tbody>
      <tr *ngFor="let expt of  expts">
        <td> {{expt.experimentId}} </td>
        <td> {{expt.owner }} </td>
        <td> {{expt.status }} </td>
        <td> {{expt.notes }}</td>
        <td> {{expt.active }}</td>
        <td>  <button
            uiSref="admin.adminExpt"
            [uiParams]="{ exptId: expt.experimentId }"
            target="_blank"
        >EDIT</button
        ></td>
      </tr>
    </table>
  `
})
export class AdminExptTable implements OnInit {
  expts = []

  constructor(
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.apiService.ml4Get(
      "GET /api/admin/expts",
      "/api/admin/expts",
      {},
      response => {
        this.expts = (response.data);
      },
      response => {
        alert("Failed to update experiment list");
        this.expts = (response.data);
      }
    )
  }

  protected readonly JSON = JSON;
}