"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DropdownNewComponent } from "./dropdown-new.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule],
  exports: [DropdownNewComponent],
  declarations: [DropdownNewComponent]
})
export class DropdownModule {}
