"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MovieTableComponent } from "./movie-table.component";
import { RatingModule } from "../rating/rating.module";

import { ToggleModule } from "../toggle/toggle.module";
import {UIRouterModule} from "@uirouter/angular";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [
    CommonModule,
    RatingModule,
    ToggleModule,
    UIRouterModule.forChild()
  ],
  exports: [MovieTableComponent],
  declarations: [MovieTableComponent],
  entryComponents: [MovieTableComponent]
})
export class MovieTableModule {}
