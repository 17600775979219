import {Component, OnInit} from "@angular/core";

import "./user-profile-page.scss";
import {FlashService} from "../../common/flash/flash.service";
import {StateService} from "@uirouter/core";
import {ApiService} from "../../common/api/api.service";
import { get, sortBy } from "lodash";

@Component({
    selector: "user-profile-page",
    template: require("./user-profile-page.html")
})

export class UserProfilePageComponent implements OnInit {
    check = false;

    editingField: string | null = null;

    public data;
    public shortTermInterest;
    public likedLongTermInterest;
    public dislikedLongTermInterest;
    public updatedAt;
    public numberRated;
    public ratingHistory;

    // Temporary storage for edits
    tempShortTermInterest = this.shortTermInterest;
    tempLikedLongTermInterest = this.likedLongTermInterest;
    tempDislikedLongTermInterest = this.dislikedLongTermInterest;

    constructor(
        private flashService: FlashService,
        private stateService: StateService,
        private apiService: ApiService,
    ) {
    }

    ngOnInit(): void {
        // GET user interests
        this.apiService.ml4Get(
            "GET /api/interactive-profile/get_user_interest",
            "/api/interactive-profile/get_user_interest",
            {},
            response => {
                this.shortTermInterest = get(response, "shortTermInterest");
                this.likedLongTermInterest = get(response, "likedLongTermInterest");
                this.dislikedLongTermInterest = get(response, "dislikedLongTermInterest");
                this.updatedAt = this.formatDate(get(response, "updatedAt"));
            }
        )
    }

    dismiss(): void {
        this.goHome();
    }

    private formatInterest(interest: string): string {
        if (!interest) return "No data available.";
        return interest
            .split(/\n|(?<=\S)\s+-\s+(?=\S)/) // Split only when a hyphen is surrounded by spaces
            .map(item => item.trim())
            .filter(item => item.length > 0)
            .map(item => item.replace(/^[-•]\s*/, "• ")) // Ensure single bullet point, remove leading hyphen if present
            .join("\n");
    }

    private formatDate(date: string): string {
        if (!date) return "Date not available.";
        const parsedDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short",
        };
        return parsedDate.toLocaleString("en-US", options);
    }

    goHome(): void {
        this.stateService.go("base.frontpage");
    }

    goProfile(): void {
        this.stateService.go("base.userProfilePage")
    }

    goTreemap(): void {
        this.stateService.go("base.treemapPage")
    }

    goTimeline(): void {
        this.stateService.go("base.timelinePage")
    }

    toggleEditMode(field: string) {
        if (this.editingField === field) {
            // Cancel editing and restore original values
            if (field === 'shortTermInterest') {
                this.shortTermInterest = this.tempShortTermInterest;
            } else if (field === 'likedLongTermInterest') {
                this.likedLongTermInterest = this.tempLikedLongTermInterest;
            } else if (field === 'dislikedLongTermInterest') {
                this.dislikedLongTermInterest = this.tempDislikedLongTermInterest;
            }
            this.editingField = null;
        } else {
            // Start editing and save current values
            this.tempShortTermInterest = this.shortTermInterest;
            this.tempLikedLongTermInterest = this.likedLongTermInterest;
            this.tempDislikedLongTermInterest = this.dislikedLongTermInterest;
            this.editingField = field;
        }
    }

    saveChanges(flag: string) {
        const payload = {
            shortTermInterest: this.shortTermInterest,
            likedLongTermInterest: this.likedLongTermInterest,
            dislikedLongTermInterest: this.dislikedLongTermInterest,
            updatedAt: new Date().toISOString(),
            flag: flag
        };

        this.apiService.ml4Post(
            "POST /api/interactive-profile/save_user_interest",
            "/api/interactive-profile/save_user_interest",
            payload,
            response => {
                console.log('Save successful:', response);
                this.updatedAt = new Date().toLocaleString();
                this.editingField = null;
            },
            error => {
                console.error('Save failed:', error);
            }
        );
    }
}