import { Injectable } from "@angular/core";

@Injectable()
export class UrlsService {
  constructor() {}

  canIStreamItUrl(imdbId: string, layout: string = "default") {
    return `https://www.canistream.it/external/imdb/tt${imdbId}?l=${layout}`;
  }

  imdbUrl(imdbId) {
    return `https://www.imdb.com/title/tt${imdbId}`;
  }

  rottenTomatoesUrl(imdbId) {
    return `https://www.rottentomatoes.com/alias?type=imdbid&s=${imdbId}`;
  }

  tmdbUrl(tmdbId) {
    return `https://www.themoviedb.org/movie/${tmdbId}`;
  }

  tmdbEditUrl(tmdbId) {
    return `https://www.themoviedb.org/movie/${tmdbId}/edit`;
  }

  tmdbPosterImgUrl(
    imagePath: string,
    width: "original" | "w92" | "w154" | "w185" | "w342" | "w500" | "w780"
  ) {
    return this.tmdbImageUrl(imagePath, width);
  }

  tmdbBackdropImgUrl(
    imagePath: string,
    width: "original" | "w300" | "w780" | "w1280"
  ) {
    return this.tmdbImageUrl(imagePath, width);
  }

  // sizes: https://www.themoviedb.org/talk/5a5bf3860e0a260d9d0013c5?language=en-US
  private tmdbImageUrl(imagePath: string, width: string) {
    return `//image.tmdb.org/t/p/${width}${imagePath}`;
  }

  youtubeThumbnailUrl(ytid: string) {
    return `//img.youtube.com/vi/${ytid}/0.jpg`
  }
}
