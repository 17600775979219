import { Component, EventEmitter } from "@angular/core";

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

import { StateService } from "@uirouter/core";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "emoji-survey",
  template: require("./emoji-survey.html"),
})

export class EmojiSurveyComponent {
  private emojiForm: FormGroup;
  private notListedSelected: boolean = false;
  private userListedGender: any = null;
  private checked: boolean = false;
  private validation: boolean = false;
  private surveyData: {};

  constructor(private fb: FormBuilder, private apiService: ApiService, private stateService: StateService) {}

  ngOnInit() {
    this.emojiForm = this.fb.group({
      ageGroup: [
        "",
        {
          validators: [Validators.required],
        }
      ],
      genderIdentity: [
        "",
        {
          validators: [Validators.required],
        }
      ],
      userListedGender: [
        this.userListedGender,
      ],
      emojiUsage: [
        "",
        {
          validators: [Validators.required],
        }
      ],
      emojiMeaning: [
        "",
        {
          validators: [Validators.required],
        }
      ]
    });
  }

  get ageGroup(){
    return this.emojiForm.get("ageGroup");
  }

  get genderIdentity() {
    return this.emojiForm.get("genderIdentity");
  }

  get emojiUsage() {
    return this.emojiForm.get("emojiUsage");
  }

  get emojiMeaning() {
    return this.emojiForm.get("emojiMeaning");
  }

  onSubmit(): void {
    this.validation = true;
    if(this.emojiForm.invalid) {
      return;
    }
      this.checked = true;
      this.surveyData = {
        "ageGroup" : this.ageGroup.value,
        "gender" : this.genderIdentity.value,
        "userListedGender": this.userListedGender,
        "frequency" : this.emojiUsage.value,
        "ability" : this.emojiMeaning.value
      };
      this.apiService.ml4Post(
        "POST /api/emoji/survey",
        "/api/emoji/survey",
        this.surveyData,
        response => {
          this.stateService.go("restricted.emojiAddTags");
        }
      );
    }

  listGender(): void {
    this.notListedSelected = true;
  }

  onKey(event: any) {
    this.userListedGender = event.target.value;
  }

}
