import { ViewportScroller } from "@angular/common";
import { SessionService } from "../common/session/session.service";
import { Component, NgModule } from "@angular/core";

import { AddMovieModule } from "./add-movie/add-movie.module";
import { AdminModule } from "./admin/admin.module";
import { AboutYourRatingsModule } from "./about-your-ratings/about-your-ratings.module";
import { AllGenresModule } from "./all-genres/all-genres.module";
import { AuthTemplateModule } from "./auth-template/auth-template.module";
import { BaseTemplateModule } from "./base-template/base-template.module";
import { ErrorsModule } from "./errors/errors.module";
import { ExploreModule } from "./explore/explore.module";
import { ExternalrecsStatusModule } from "./externalrecs-status/externalrecs-status.module";
import { FlashModule } from "../common/flash/flash.module";
import { FrontpageModule } from "./frontpage/frontpage.module";
import { InfoPagesModule } from "./info-pages/info-pages.module";
import { JoinModule } from "./join/join.module";
// llm-rec sophia sun00587 start
import { LLMRecExptModule } from "../expt/llm_rec/llm_rec.module";
// llm-rec sophia sun00587 end
// interactive-profile sophia sun00587 start
import { InteractiveProfileExptModule } from "../expt/interactive_profile/interactive_profile.module";
// interactive-profile sophia sun00587 end
import { LoginModule } from "./login/login.module";
import { MovieModule } from "./movie/movie.module";
import { MovieTunerModule } from "./movie-tuner/movie-tuner.module";
import { MovieTunerTagAutocompleteModule } from "./movie-tuner/movie-tuner-tag-autocomplete.module";
import { PasswordResetModule } from "./password-reset/password-reset.module";
import { PopTunerService } from "./rectune/pop-tuner.service";
import { PopTunerServiceModule, RecTunePageModule } from "./rectune/rectune.module";
import { ProfileModule } from "./profile/profile.module";
import { RegisterModule } from "./register/register.module";
import { RestrictedTemplateModule } from "./restricted-template/restricted-template.module";
import { TagPageModule } from "./tag-page/tag-page.module";
import { ThemeModule } from "../common/theme/theme.module";
import { TransitionService, UIRouterModule } from "@uirouter/angular";
import { UserTagsModule } from "./user-tags/user-tags.module";
import { UserListsModule } from "./user-lists/user-lists.module";

import { get } from "lodash";
import { load } from "@angular/core/src/render3/instructions";

// configure function for autoscrolling to top of page
export function configureScrollOnTransition(
  transitionService: TransitionService
) {
  function scrollToTop(transition) {
    const viewpointScroller: ViewportScroller = transition
      .injector()
      .get(ViewportScroller);
    setTimeout(() => viewpointScroller.scrollToPosition([0, 0]));
  }

  transitionService.onSuccess({}, scrollToTop);
}

// root state and component - sits at the root of all other states.
@Component({
  template: `
    <div>
      <alert-container></alert-container>
      <ui-view ></ui-view>
    </div>
  `
})
export class GlobalComponent {}

const root = {
  name: "global",
  abstract: true,
  component: GlobalComponent
};

// logged in root - parent to all states that require login
@Component({
  template: `
    <ui-view ></ui-view>
  `
})
export class LoggedInComponent {}

const loggedIn = {
  name: "loggedIn",
  parent: "global",
  abstract: true,
  component: LoggedInComponent,
  data: { requiresAuth: true }
};

// configuration function, goes with data component in loggedIn state, enforces logging in.
export function configureLoginRequired(transitionService: TransitionService) {
  const requiresLogin = {
    to: state => state.data && state.data.requiresAuth
  };

  const checkLogin = async transition => {
    const sessionService: SessionService = transition
      .injector()
      .get(SessionService);
    const stateService = transition.router.stateService;
    const redirect = await sessionService.verifyLoggedIn();
    if (redirect) {
      return stateService.target(redirect);
    }
    return;
  };

  transitionService.onBefore(requiresLogin, checkLogin, { priority: 10 });
}

@NgModule({
  imports: [
    AddMovieModule,
    AboutYourRatingsModule,
    AdminModule,
    AllGenresModule,
    AuthTemplateModule,
    BaseTemplateModule,
    ErrorsModule,
    ExploreModule,
    ExternalrecsStatusModule,
    FrontpageModule,
    InfoPagesModule,
    JoinModule,
    ThemeModule,
    // llm-rec sophia sun00587 start
    LLMRecExptModule,
    // llm-rec sophia sun00587 end
    // interactive-profile sophia sun00587 start
    InteractiveProfileExptModule,
    // interactive-profile sophia sun00587 end
    LoginModule,
    MovieModule,
    MovieTunerModule,
    MovieTunerTagAutocompleteModule,
    PasswordResetModule,
    PopTunerServiceModule,
    ProfileModule,
    RecTunePageModule,
    RegisterModule,
    RestrictedTemplateModule,
    TagPageModule,
    UserTagsModule,
    FlashModule,
    UserListsModule,
    UIRouterModule.forChild({ states: [root, loggedIn] })
  ],
  declarations: [GlobalComponent, LoggedInComponent],
  entryComponents: [GlobalComponent],
  providers: [PopTunerService]
})
export class UiModule {}
