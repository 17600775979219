import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";


@Injectable()
export class UserListsService {
    constructor(private apiService: ApiService) {}

    updateUserList(listId: number, title: string, description: string, publicList: boolean, successCallback = null, errorCallback = null) {
        let data = {
            listId : listId,
            title: title,
            description: description,
            publicList: publicList
        };
        this.apiService.ml4Put(
            "PUT /api/users/me/lists",
            "/api/users/me/lists",
            data,
            successCallback,
            errorCallback
        );
    }

    deleteUserList(listId: number, successCallback = null, errorCallback = null) {
        let data = {
            listId : listId,
        };

        let url = `/api/users/me/lists/${listId}`

        this.apiService.ml4Delete(
            "DELETE " + url,
            url,
            data,
            successCallback,
            errorCallback
        );
    }

    getUserListMovies(userId: string, listId: string, page: number, successCallback = null, errorCallback = null) {
        let url = `/api/users/${userId}/lists/${listId}`;
        this.apiService.ml4Get(
            "GET " + url,
            url,
            {page: page},
            successCallback,
            errorCallback
        );
    }

    getUserLists(movieId: number = -1, successCallback = null, errorCallback = null){
        let data = {
            movieId : movieId,
        };
        this.apiService.ml4Get(
            "GET /api/users/me/lists",
            "/api/users/me/lists",
            data,
            successCallback,
            errorCallback
        );
    }

    addMovieToUserList(listId: number, movieId: number, successCallback = null, errorCallback = null){
        let url = `/api/users/me/lists/${listId}/movies`;
        let data = {
            movieId : movieId
        };

        this.apiService.ml4Post(
            "POST " + url,
            url,
            data,
            successCallback,
            errorCallback
        );
    }

    deleteMovieFromUserList(listId: number, movieId: number, successCallback = null, errorCallback = null) {
        let url = `/api/users/me/lists/${listId}/movies/${movieId}`;
        let data = {};

        this.apiService.ml4Delete(
            "DELETE " + url,
            url,
            data,
            successCallback,
            errorCallback
        );
    }

    createUserList(title: string, description: string, publicList: boolean, successCallback = null, errorCallback = null){
        let data = {
            title : title,
            description: description,
            publicList: publicList
        };

        this.apiService.ml4Post(
            "POST /api/users/me/lists",
            "/api/users/me/lists",
            data,
            successCallback,
            errorCallback
        );
    }

}
