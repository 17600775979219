"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


import { InfoPageComponent } from "./info-page.component";

import * as aboutMd from "./about.md";
import * as termsOfUseMd from "./terms-of-use.md";
import * as contactMd from "./contact.md";
import * as privacyPolicyMd from "./privacy-policy.md";
import {UIRouterModule} from "@uirouter/angular";

const aboutPage = {
  name: "global.about",
  url: "/info/about",
  resolve: {
    title: () => "About",
    content: () => aboutMd
  },
  component: InfoPageComponent
};

const termsOfUsePage = {
  name: "global.termsOfUse",
  url: "/info/terms-of-use",
  resolve: {
    title: () => "Terms of Use",
    content: () => termsOfUseMd
  },
  component: InfoPageComponent
};

const contactPage = {
  name: "global.contact",
  url: "/info/contact",
  resolve: {
    title: () => "Contact Us",
    content: () => contactMd
  },
  component: InfoPageComponent
};

const privacyPolicyPage = {
  name: "global.privacyPolicy",
  url: "/info/privacy-policy",
  resolve: {
    title: () => "Privacy Policy",
    content: () => privacyPolicyMd
  },
  component: InfoPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({
      states: [aboutPage, termsOfUsePage, contactPage, privacyPolicyPage]
    })
  ],
  declarations: [InfoPageComponent]
})
export class InfoPagesModule {}
