"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha/forms";

import { LocationFieldComponent } from "./location-field.component";
import { RegisterFormComponent } from "./register-form.component";
import { RegisterPageComponent } from "./register-page.component";
import {UIRouterModule} from "@uirouter/angular";

const registerPage = {
  name: "auth.register",
  url: "/register",
  component: RegisterPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [registerPage] })
  ],
  declarations: [
    LocationFieldComponent,
    RegisterFormComponent,
    RegisterPageComponent
  ]
})
export class RegisterModule {}
