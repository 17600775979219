"use strict";

import { Component } from "@angular/core";

import "./alexa-message-template.scss";

@Component({
  selector: "alexa-message-template",
  template: `
    <div class="alexa-message-template">
      <div ui-view></div>
    </div>
  `
})
export class AlexaMessageTemplateComponent {}
