"use strict";

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

import { TagDataService } from "../../common/tag-data/tag-data.service";

@Component({
  selector: "user-tags-page",
  template: `
    <div class="your-tags">
      <h1>your tags</h1>

      <div class="explore-control-panel" *ngIf="scoredTags">
        <div class="current-search">
          <p class="small">found {{ scoredTags.length }}.</p>
        </div>
      </div>

      <div class="tag-box">
        <div *ngFor="let tag of scoredTags" style="display: inline-block;">
          <ml4-tag [movieTag]="tag"></ml4-tag>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .tag-box {
        margin: 10px 0;
      }
    `
  ]
})
export class UserTagsPageComponent implements OnInit {
  scoredTags = null;

  constructor(
    private stateService: StateService,
    private tagDataService: TagDataService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Your Tags | MovieLens");

    this.tagDataService.userTags(response => {
      this.scoredTags = response.data.scoredTags;
    });
  }
}
