"use strict";

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges
} from "@angular/core";

import { LoggerService } from "../../common/logger/logger.service";

@Component({
  selector: "movie-images-browser",
  template: `
    <modal-dialog (onClose)="onClose.emit()" *ngIf="doShow" cls="modal-lg" mode="minimal">
      <div main>
        <div style="width: 100%; height: 80vh;">
          <carousel 
              [images]="images" 
              [index]="index"
              (onPrev)="onPrev()"
              (onNext)="onNext()"
              (onGoTo)="goTo($event)"
          ></carousel>
        </div>
      </div>
    </modal-dialog>
  `
})
export class MovieImagesBrowserComponent implements OnChanges {
  @Input() movieId;
  @Input() images;
  @Input() index?: number = 0;
  @Input() doShow: boolean = false;

  @Output() onClose = new EventEmitter<null>();

  constructor(private loggerService: LoggerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const indexChange: SimpleChange = changes["index"];
    if (indexChange && indexChange.currentValue >= 0) {
      this.logView(indexChange.currentValue);
    }
  }

  onPrev() {
    this.goTo(this.index - 1);
  }

  onNext() {
    this.goTo(this.index + 1);
  }

  private outOfBounds(i) {
    return i < 0 || i >= this.images.length;
  }

  goTo(i: number) {
    if (this.outOfBounds(i)) {
      return;
    }
    // console.log(`MIB goto ${i}`);
    this.logView(i);
    this.index = i;
  }

  logView(idx) {
    if (!this.images || !this.images[idx]) {
      console.debug("invalid", idx, this.images[idx]);
      return;
    }

    const url = this.images[idx].toString();
    const imageId = url
      .split("/")
      .pop()
      .split(".")[0];
    const imageType = idx === 0 ? "poster" : "image";
    this.loggerService.imageView(this.movieId, imageId, imageType, idx);
  }
}
