"use strict";

import { DomSanitizer } from "@angular/platform-browser";
import { Component, Input } from "@angular/core";

import { LoggerService } from "../../common/logger/logger.service";
import { UrlsService } from "../../common/urls/urls.service";

@Component({
  selector: "youtube-tile",
  template: `
    <div class="ml4-youtube-tile ml4-bordered">
      <img [src]="ytThumbUrl" class="img-responsive rounded" (click)="onOpen()">
      <youtube-modal [ytid]="ytid" [doShow]="doShowModal" (onClose)="onClose()"></youtube-modal>
    </div>
  `,
  styles: [
    `
      img {
        width: 100%;
        cursor: pointer;
      }
    `
  ]
})
export class YoutubeTileComponent {
  @Input() ytid: string;
  @Input() index;
  @Input() movieId;

  ytThumbUrl;
  doShowModal = false;

  constructor(
    private santizer: DomSanitizer,
    private loggerService: LoggerService,
    private urlsService: UrlsService
  ) {}

  ngOnInit() {
    this.ytThumbUrl = this.urlsService.youtubeThumbnailUrl(this.ytid);
  }

  onOpen() {
    this.loggerService.trailerView(this.movieId, this.ytid, this.index);
    this.doShowModal = true;
  }

  onClose() {
    this.doShowModal = false;
  }
}
