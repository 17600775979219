"use strict";

import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { Title } from "@angular/platform-browser";

import { MovieDataService } from "../../common/movie-data/movie-data.service";

import "./alexa-trailer-page.scss";

@Component({
  selector: "alexa-trailer-page",
  template: `
    <div class="alexa-trailer">

      <div class="tv-nav tv-nav-top" style="z-index: 9999;">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        "go back"
      </div>

      <youtube-embed [ytid]="ytid" [autoplay]="true"></youtube-embed>
    </div>

  `
})
export class AlexaTrailerPageComponent implements OnInit {
  movie = null;
  movieDetails = null;
  ytid = null;

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Trailer | MovieLens");

    const id = parseInt(this.stateService.params.movie);

    this.movieDataService.getMovie(id, movieDetails => {
      let movie = (this.movie = movieDetails.movie);
      this.movieDetails = movieDetails;
      this.ytid = movie.firstYoutubeTrailerId;

      this.titleService.setTitle(`${movie.title} Trailer | MovieLens`);
    });
  }
}
