"use strict";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { FlashService } from "../../common/flash/flash.service";
import { SessionService } from "../../common/session/session.service";
import { UserDataService } from "../../common/user-data/user-data.service";
import { ThemeService } from "../../common/theme/theme.service";

@Component({
  selector: "theme-page",
  template: `
    <div class="profile-theme">
      <h1>theme</h1>
      <div [formGroup]="themeForm">
        <div class="checkbox">
          <label>
            <input 
                type="radio" 
                formControlName="themeControl" 
                value="dark" />
            dark
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="radio"
              formControlName="themeControl"
              value="light"
            />
            light
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input
              type="radio"
              formControlName="themeControl"
              value="system"
            />
            system
          </label>
        </div>
      </div>

      <div class="clearfix"></div>
    </div>
  `
})
export class ThemePageComponent implements OnInit, OnDestroy {
  themeControl: FormControl = new FormControl("");
  themeForm: FormGroup = new FormGroup({
    themeControl: this.themeControl
  });

  formSub: Subscription = null;

  constructor(
    private flashService: FlashService,
    private sessionService: SessionService,
    private titleService: Title,
    private themeService: ThemeService,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Theme | MovieLens");

    const curTheme = this.themeService.getTheme();
    this.themeControl.setValue(curTheme);

    this.formSub = this.themeControl.valueChanges.subscribe(val => {
      this.setThemePreference(val);
      this.themeService.setThemeFrontend(val);
    });
  }

  ngOnDestroy(): void {
    this.formSub && this.formSub.unsubscribe();
  }

  setThemePreference(val) {
    this.sessionService.setThemePreference(val);

    this.userDataService.update({options: { theme: val }}, () => {
      const msg = `Theme set to ${val}`;
      this.flashService.addInfo(msg);
      this.flashService.show();
    });
  }
}
