"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TooltipComponent } from "./tooltip.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule],
  exports: [TooltipComponent],
  declarations: [TooltipComponent]
})
export class TooltipXModule {}
