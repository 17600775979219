"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AutoLoginComponent } from "./auto-login.component";
import { AutoLoginShortcodeComponent } from "./auto-login-shortcode.component";
import { LoginService } from "./login.service";
import { LoginPageComponent } from "./login-page.component";
import { AfterLoginRedirectComponent } from "./after-login-redirect.component";
import { UIRouterModule } from "@uirouter/angular";
import { UrlEncoderTypeModule } from "../../common/urlEncoderTypeModule";

const autoLoginShortcodeRedirect = {
  name: "auth.autoLoginWithShortCode",
  url: "/go/:id?token&emailValidation",
  component: AutoLoginShortcodeComponent
};

const autoLoginRedirect = {
  name: "auth.autoLogin",
  url: "/go?{to:urlDecoded}&{token:urlDecoded}&{emailValidation:urlDecoded}&{direct:urlDecoded}",
  component: AutoLoginComponent
};

const afterLoginRedirect = {
  name: "afterLoginRedirect",
  url: "/successful-login",
  parent: "loggedIn",
  component: AfterLoginRedirectComponent
};

const loginPage = {
  name: "auth.login",
  url: "/login?{next:urlDecoded}",
  component: LoginPageComponent
};

const alexaLoginPage = {
  name: "auth.alexaLogin",
  url: "/alexa?{client_id:urlDecoded}&{response_type:urlDecoded}&{state:urlDecoded}&{redirect_uri:urlDecoded}",
  component: LoginPageComponent,
  resolve: {
    alexa: () => true
  }
};

@NgModule({
  imports: [
    UrlEncoderTypeModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule.forChild({
      states: [
        autoLoginShortcodeRedirect,
        autoLoginRedirect,
        afterLoginRedirect,
        loginPage,
        alexaLoginPage
      ]
    })
  ],
  declarations: [
    AfterLoginRedirectComponent,
    AutoLoginComponent,
    AutoLoginShortcodeComponent,
    LoginPageComponent
  ],
  providers: [LoginService]
})
export class LoginModule {}
