"use strict";

import { Component, Input, OnInit } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";

@Component({
  selector: "error-page",
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  template: `
    <div class="ml4-narrow-container error-404-container">

      <div class="jumbotron ml4-jumbotron">
        <h1 [innerHTML]="title"></h1>
        <p>
          <span [innerHTML]="message"></span>
          Is this a recurring problem, or something you think we should know about?
          If so, please <a uiSref="global.contact">send us a message</a> 
          with enough details so that we can fix the problem. 
          Otherwise:
        </p>
        
        <p>
          <span [hidden]="!showBackLink">
            <button class="btn btn-primary btn-lg" type="button" (click)="goBack()">Go back</button>
            or
          </span>
          <button class="btn btn-primary btn-lg" type="button" uiSref="base.frontpage">Go to the MovieLens front page</button>
        </p>
        
      </div>
    
    </div>
  `
})
export class ErrorPageComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;

  showBackLink: boolean;

  constructor(
    private location: Location
  ) {}

  ngOnInit(): void {
    this.showBackLink = window.history.length > 1;
  }

  goBack(): void {
    this.location.back();
  }

  goHome(): void {
    this.location.go("/home");
  }
}
