"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";

@Component({
  selector: "admin-index-page",
  template: `
    <div>
      <h1>admin</h1>
      <ul>
        <li><a uiSref="admin.movieFlagEvents">movie status</a></li>
        <li><a uiSref="admin.externalRecsStatusPage">external recs</a></li>
        <li><a uiSref="admin.addMovie">auto-add movie</a> (imdb + tmdb)</li>
        <li><a uiSref="admin.adminTasks">task view</a></li>
      </ul>
      <admin-expt-table></admin-expt-table>
    </div>
  `
})
export class AdminIndexPageComponent {
  constructor(private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle("Admin | MovieLens");
  }
}
