"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { filter, get, map } from "lodash";

import { SessionService } from "../../common/session/session.service";

import "./admin-movie-flags-page.scss";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "admin-movie-flags-page",
  template: `
    <div>
      <h1>movie flag events</h1>
      Filter by type:
      <input type="text" (change)="changeFilter()" [(ngModel)]="filter" />

      <table class="table table-striped table-hover movie-flag-events">
        <tr>
          <th>title</th>
          <th></th>
          <th>flag date</th>
          <th>userId</th>
          <th>mark as<br />done</th>
        </tr>

        <tr
          admin-movie-flag-tr
          *ngFor="let movieFlagEvent of movieFlagEvents"
          [movieFlagEvent]="movieFlagEvent"
          [ngClass]="{ strikeout: movieFlagEvent.adminHasTakenAction }"
          (onMarkDone)="changeFlagStatus(movieFlagEvent)"
        ></tr>
      </table>
    </div>
  `
})
export class AdminMovieFlagsPageComponent {
  movieFlagEvents = [];
  filter: String;

  constructor(
    private apiService: ApiService,
    private sessionService: SessionService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Movie Flag Events | MovieLens");

    this.apiService.ml4Get(
      "GET /api/admin/movie-flag-events",
      "/api/admin/movie-flag-events",
      {},
      response => (this.movieFlagEvents = get(response, "data.movieFlagEvents"))
    );
  }

  changeFlagStatus(movieFlagEvent) {
    var originalStatus = movieFlagEvent.adminHasTakenAction;
    movieFlagEvent.adminHasTakenAction = !originalStatus;
    this.apiService.ml4Post(
      "POST /api/admin/movie-flag-events",
      "/api/admin/movie-flag-events",
      movieFlagEvent,
      response => {
        console.log("Admin action taken flag successfully changed");
      },
      response => {
        console.log("Admin action taken flag failed to change", response);
        movieFlagEvent.adminHasTakenAction = originalStatus;
      }
    );
  }

  changeFilter() {
    this.filter = this.filter.trim();
    if (this.filter.length > 0) {
      this.apiService.ml4Get(
        "GET /api/admin/movie-flag-events",
        "/api/admin/movie-flag-events",
        { onlyType: this.filter },
        response =>
          (this.movieFlagEvents = get(response, "data.movieFlagEvents"))
      );
    } else {
      this.apiService.ml4Get(
        "GET /api/admin/movie-flag-events",
        "/api/admin/movie-flag-events",
        {},
        response =>
          (this.movieFlagEvents = get(response, "data.movieFlagEvents"))
      );
    }
  }
}
