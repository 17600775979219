"use strict";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { filter, get, map } from "lodash";

import { ApiService } from "../../common/api/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";

import "./admin-movie-flags-page.scss";

@Component({
  selector: "[admin-movie-flag-tr]",
  template: `
    <td style="max-width: 400px;">
      <a
        uiSref="admin.adminMovie"
        [uiParams]="{ id: movieFlagEvent.movieId }"
        target="_blank"
        >{{ movieFlagEvent.title }}</a
      >
      <br />
      <div class="small">
        {{ movieFlagEvent.reportType }} -- {{ movieFlagEvent.comments }}
      </div>
    </td>
    <td>
      <span *ngIf="! movieFlagEvent.isMovieLive"> (Not Live) </span>
      <span *ngIf="movieFlagEvent.currentMovieVersion != movieFlagEvent.movieVersion"> (Older version) </span>
    </td>
    <td>{{ movieFlagEvent.created | date }}</td>
    <td>{{ movieFlagEvent.userId }}</td>
    <td>
      <input
        type="checkbox"
        [checked]="movieFlagEvent.adminHasTakenAction"
        (change)="onMarkDone.emit()"
      />
    </td>
  `
})
export class AdminMovieFlagRowComponent implements OnInit {
  @Input() movieFlagEvent;
  @Output() onMarkDone = new EventEmitter<null>();

  // saving this for later...
  // movieStatusCodes = [
  //   { id: 0, label: "READY" },
  //   { id: 1, label: "REJECTED" },
  //   { id: 2, label: "LIVE" },
  //   { id: 6, label: "PENDING" },
  //   { id: 96, label: "HIJACKED" },
  //   { id: 98, label: "DUPLICATE" },
  //   { id: 100, label: "VOTEQ" },
  //   { id: 101, label: "BURIED" }
  // ];
  constructor(private apiService: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    // this.theForm = this.fb.group({
    //   status: [""]
    // });
  }
}
