import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";

import { ExplanationsService } from "./explanations.service";
import { FlashService } from "../../common/flash/flash.service";
import { StateService } from "@uirouter/core";
import { ExplanationsMovieCardComponent } from "./movie-card/explanations-movie-card.component";

import "./explanations-module.scss";
const tmpl = require("./explanations-survey.component.html");

@Component({
  selector: "explanations-survey",
  template: tmpl
})
export class ExplanationsSurveyComponent implements OnInit {
  private recIds = null;
  private surveyData = null;
  private isReady = false;

  private minLimit = 3;
  private step: number = 0;
  private design: String = "between_subjects";

  @ViewChildren(ExplanationsMovieCardComponent)
  private cards: QueryList<ExplanationsMovieCardComponent>;

  constructor(
    private explanationsService: ExplanationsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private flashService: FlashService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    // get design
    if (this.stateService.params.design != null) {
      this.design = this.stateService.params.design;
    }

    // check status before get data
    this.explanationsService.getSurveyStatus(response => {
      if (response.status == "empty") {
        this.goToConsent();
      } else if (response.status.includes("consent")) {
        this.explanationsService.step.subscribe(step => {
          this.step = step;
          if (this.step == -1) {
            this.recIds = this.shuffle(this.recIds);
          } else {
            this.explanationsService.getSurveyData(
              this.step,
              this.design,
              response => {
                if (
                  response.data == null ||
                  Object.keys(response.data).length == 0
                ) {
                  this.quit();
                } else {
                  this.recIds = this.shuffle(Object.keys(response.data));
                  this.surveyData = response.data;
                  this.isReady = true;
                }
              }
            );
          }
        });
      }
    });
  }

  private goToConsent(): void {
    this.flashService.addError("Please, give us your consent first.").show();
    this.stateService.go("restricted.explanations");
  }

  private shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  // XXX: converting from old ng1 modals service to <modal-dialog>
  selectedMoviesStr = null;
  doShowModal = false;
  onClose() {
    this.doShowModal = false;
  }

  submitPickBest() {
    // get picks
    let selectedMovies = this.getSelectedMovie();

    // save data
    if (selectedMovies.length != this.minLimit) {
      this.flashService
        .addError("Please, choose " + this.minLimit + " movies.")
        .show();
    } else {
      if (this.step == -1) {
        this.finish(selectedMovies);
      } else {
        this.selectedMoviesStr = JSON.stringify(selectedMovies);
        this.doShowModal = true;
      }
    }
  }

  isLastStep(): boolean {
    return this.step == -1;
  }

  private getSelectedMovie(): number[] {
    let selectedMovies = [];
    this.cards.forEach(cardInstance => {
      if (cardInstance.isSelected) {
        selectedMovies.push(cardInstance.movieId);
        cardInstance.isSelected = false;
      }
    });

    return selectedMovies;
  }

  private finish(selectedMovies) {
    let strStep = "step_" + this.step;
    this.explanationsService.savePicks(
      {
        data: JSON.stringify(selectedMovies),
        step: strStep
      },
      response => {
        this.flashService
          .addInfo("Thank you very much for your participation in this study!")
          .show();
        this.stateService.go("base.frontpage");
      }
    );
  }

  private quit() {
    this.flashService
      .addError("No data. Looks like something went wrong!")
      .show();
    this.stateService.go("base.frontpage");
  }
}
