import { Injectable } from "@angular/core";
import { get, partial } from "lodash";

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export interface AnalyticsEvent {
  event_category: string;
  event_label?: string;
  value?: string;
}

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export interface AnalyticsPageView {
  page_path: string;
  page_title: string;
  page_location?: string;
}

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
 */
export interface AnalyticsTiming {
  event_category: string;
  name: string;
  value: number;
  event_label?: string;
}

/**
 * Google analytics
 *
 * The core javascript is installed directly into app.html
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
@Injectable()
export class AnalyticsService {
  private readonly GA_TRACKING_ID: string = "UA-42433938-3";

  // reference to window.gtag
  private readonly gtag: Function;

  private readonly enabled: boolean;

  // set to true to print analytics messages to the console
  private readonly debug: boolean = false;

  constructor() {
    this.gtag = (<any>window).gtag;
    const hostname = get(document, "location.hostname", null);
    this.enabled = hostname === "movielens.org";
    if (this.debug) {
      console.log(`analytics startup: hostname=${hostname}, enabled=${this.enabled}`);
    }
  }

  private log(...args) {
    if (this.enabled) {
      this.gtag(...args);
    }
    if (this.debug) {
      console.log("analytics (enabled=" + this.enabled + "): ", ...args);
    }
  }

  gEvent(action: string, analyticsEvent: AnalyticsEvent) {
    this.log("event", action, analyticsEvent);
  }

  gPageView(analyticsPageView: AnalyticsPageView) {
    this.log("config", this.GA_TRACKING_ID, analyticsPageView);
  }

  gTiming(analyticsTiming: AnalyticsTiming) {
    this.log("event", "timing_complete", analyticsTiming);
  }

}
