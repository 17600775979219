import { Component, Input } from "@angular/core";
import { get, set } from "lodash";
import { UserActionsService } from "../../common/user-actions/user-actions.service";

@Component({
  selector: "rating-smart",
  template: `
    <div class="rating-smart" *ngIf="movieId" style="display: inline-block;">
      <rating [size]="size"
              [predictedRating]="predictedRating"
              [userRating]="userRating"
              (onRate)="onRate($event)" 
              (onClearRating)="onClearRating()"></rating>
    </div>
  `
})
export class RatingSmartComponent {
  @Input() movieId: number = null;
  @Input() movieUserData = null

  @Input() size: number = 24;

  constructor(private userActionsService: UserActionsService) {}

  get predictedRating() {
    return get(this.movieUserData, "prediction", null);
  }

  get userRating() {
    return get(this.movieUserData, "rating", null);
  }

  set userRating(val: number) {
    set(this.movieUserData, "rating", val);
  }

  set dateRated(val: String) {
    set(this.movieUserData, "dateRated", val);
  }

  onRate(rating: number) {
    const prevRating = this.userRating;
    this.userRating = rating;
    this.dateRated = new Date().toISOString();

    this.userActionsService.rate(
      this.movieId,
      rating,
      prevRating,
      this.predictedRating,
      () => {},
      () => {
        this.userRating = prevRating;
      }
    );
  }

  onClearRating() {
    const prevRating = this.userRating;
    this.userRating = null;
    this.dateRated = null;

    this.userActionsService.unrate(
      this.movieId,
      () => {},
      () => {
        this.userRating = prevRating;
      }
    );
  }
}
