"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { IfExptComponent } from "./if-expt.component";

@NgModule(
  {imports: [
    BrowserModule,
  ],
  exports: [
    IfExptComponent
  ],
  declarations: [
    IfExptComponent
  ]
})
export class IfExptModule {}
