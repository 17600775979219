"use strict";

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { debounce, get } from "lodash";

import { WindowService } from "../../common/window/window.service";
import "./movie-row.scss";
import {round} from "d3";

const MOVIE_CARD_WIDTH = 160;
const MAX_CARD_SCROLL = 3;
// XXX: should be more than the CSS transition time
const VISIBILITY_CHECK_DELAY = 250;

// expt rec-val-dataset start
import {ExptMovieCardModule} from "../../expt/rec-val/rec-val-movie-card/expt-movie-card.module";
import {FlashService} from "../../common/flash/flash.service";
import {ApiService} from "../../common/api/api.service";
import {StateService} from "@uirouter/core";
// expt rec-val-dataset end

@Component({
  selector: "ml4-movie-row",
  template: `
    <div #movieRow class="movie-row">

      <div class="movie-row-bounds" #outerEl>
        <div class="movie-row-scrollable" [ngStyle]="scrollStyle">
          <div class="movie-row-item" *ngFor="let movieDetails of movieDetailsList; index as i">
            <expt-movie-card-smart *ngIf="movieDetails&&(movieDetails.rec_val_type!=='')"
                type="md1" [movieId]="movieDetails.movieId" [modal]="false" style="padding-bottom: 20px"
                              [id]="i"
                              [isSeen]="false"
                               [today_date]="null"
                               [true_rating]="0"
                               [predict_rating]="0"
                               [sure_rating]="0"
                               [source] = "movieDetails.rec_val_type"
                               [tstamp] = "movieDetails.tstamp"
                               [system_predict_rating]="movieDetails.movieUserData.prediction"
                               (onFeedback)="onFeedback($event)"
            ></expt-movie-card-smart>
            <movie-card-smart [movieDetails]="movieDetails" *ngIf="movieDetails&&(movieDetails.rec_val_type==='')"></movie-card-smart>
<!--            original code-->
<!--            <movie-card-smart [movieDetails]="movieDetails" *ngIf="movieDetails"></movie-card-smart>-->
          </div>
          <span #trackerEl></span>
        </div>
      </div>

      <button class="btn btn-default movie-row-back-button" [class.invisible]="backButtonInvisible"
              (click)="scrollBack()"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
      <button class="btn btn-default movie-row-forward-button" [class.invisible]="forwardButtonInvisible" 
              (click)="scrollForward()"><i class="fa fa-angle-right" aria-hidden="true"></i></button>

      <div class="clearfix"></div>

    </div>
  `
})
export class MovieRowComponent implements OnInit, OnChanges {
  @ViewChild("outerEl", { read: ElementRef })
  outerEl: ElementRef;

  @ViewChild("trackerEl", { read: ElementRef })
  trackerEl: ElementRef;

  @ViewChild("movieRow", { read: ElementRef })
  movieRow: ElementRef;

  @Input() movieDetailsList = null;

  // expt rec-val-dataset start
  @Input() title = null;
  // expt rec-val-dataset end

  leftMarginPx = 0;
  backButtonInvisible = true;
  forwardButtonInvisible = false;


    // expt rec-val-dataset start
    constructor(private windowService: WindowService,
                private flashService: FlashService,
                private apiService: ApiService,) {}
    // expt rec-val-dataset end
    // below is the original code
  // constructor(private windowService: WindowService) {}

  ngOnInit(): void {
    this.windowService.currentWindowSize.subscribe(val => {
      this.checkButtonVis();
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    const listChange: SimpleChange = changes["movieDetailsList"];
    if (listChange && listChange.currentValue) {
      setTimeout(() => this.checkButtonVis(), VISIBILITY_CHECK_DELAY);
    }
  }

  checkButtonVis() {
    this.backButtonInvisible = this.leftMarginPx >= 0;
    if (this.outerEl && this.trackerEl) {
      this.forwardButtonInvisible =
        this.outerEl.nativeElement.getBoundingClientRect().right >
        this.trackerEl.nativeElement.getBoundingClientRect().right;
    }
  }

  get scrollStyle() {
    return {
      "margin-left": `${this.leftMarginPx}px`
    };
  }

  getScrollAmount()
  {
    var numCards = Math.floor(this.movieRow.nativeElement.offsetWidth / MOVIE_CARD_WIDTH);
    if (numCards < 1) {
      numCards = 1;
    }
    if (numCards > MAX_CARD_SCROLL) {
      numCards = MAX_CARD_SCROLL
    }
    return numCards*MOVIE_CARD_WIDTH;
  }

  scrollForward() {
    this.leftMarginPx -= this.getScrollAmount();

    setTimeout(() => this.checkButtonVis(), VISIBILITY_CHECK_DELAY);
  }

  scrollBack() {
    if (this.leftMarginPx < 0) {
      this.leftMarginPx += this.getScrollAmount();
    }
    if(this.leftMarginPx >0) {
        this.leftMarginPx = 0;
    }
    setTimeout(() => this.checkButtonVis(), VISIBILITY_CHECK_DELAY);
  }

    onFeedback({id, isSeen, today_date, true_rating, predict_rating, sure_rating, movieId, source, tstamp, system_predict_rating}) {
        if (id >= 0) {
            if ((isSeen && today_date && true_rating)||(!isSeen && predict_rating && sure_rating)){
                this.apiService.ml4Post(
                    "POST /api/rec-val/dataset",
                    "/api/rec-val/dataset",
                    {
                        movieId: movieId,
                        isSeen: isSeen,
                        watchDate: today_date,
                        userElicitRating: true_rating,
                        userPredictRating: predict_rating,
                        userCertainty: sure_rating,
                        tstamp: tstamp,
                        source: source,
                        systemPredictRating: system_predict_rating
                    },
                    response => {
                    },
                    response => {
                        this.flashService
                            .addInfo(
                                "Sorry something went wrong. Please check the answers and try again."
                            )
                            .show();
                    }
                );

            }
        }

    }
}
