"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RatingOrPredictionComponent } from "./rating-or-prediction.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule],
  exports: [RatingOrPredictionComponent],
  declarations: [RatingOrPredictionComponent]
})
export class RatingOrPredictionModule {}
