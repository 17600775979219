"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {MovieTunerTagAutocompleteComponent} from "./movie-tuner-tag-autocomplete.component";


@NgModule({
    imports: [
        BrowserModule,
        FormsModule
    ],
    declarations: [
        MovieTunerTagAutocompleteComponent
    ],
    exports: [MovieTunerTagAutocompleteComponent]
})
export class MovieTunerTagAutocompleteModule {}
