"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { sortBy } from "lodash";
import { StateService } from "@uirouter/core";
import {FlashService} from "../../common/flash/flash.service";

@Component({
  selector: "flash-demos-page",
  template: `
    <h1>flash demo</h1>

    <div>
      <button type="button" class="btn btn-default" (click)="info()">info</button>
      <button type="button" class="btn btn-info" (click)="success()">success</button>
      <button type="button" class="btn btn-danger" (click)="error()">error</button>
    </div>
    <div style="margin-top: 12px;">
      <button type="button" class="btn btn-default" (click)="show()">show all flash messages</button>
    </div>

    <div style="margin-top: 12px;">
      <button type="button" class="btn btn-default" (click)="addAndTransition()">go to another page with flash</button>
    </div>

  `
})
export class FlashDemosPageComponent {
  
  counter = 0;

  constructor(
    private flashService: FlashService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Flash Demos | MovieLens");
  }

  _msg() {
    this.counter++;
    return `<strong>hello:</strong> this is a flash message! ${this.counter}`;
  };

  info() {
    this.flashService.addInfo(this._msg());
  };

  success() {
    this.flashService.addSuccess(this._msg());
  };

  error() {
    this.flashService.addError(this._msg());
  };

  show() {
    this.flashService.show();
  };

  addAndTransition() {
    this.flashService.addInfo(this._msg()).show();
    this.stateService.go("base.movie", { id: 1 });
  };
}
