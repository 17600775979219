"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";
import { ApiService } from "../../common/api/api.service";
import { UrlsService } from "../../common/urls/urls.service";
import { NgForm } from "@angular/forms";

import "./admin-expt-page.scss";

@Component({
  selector: "admin-expt-page",
  template: `
    <div class="admin-expt-page" *ngIf="expt">
      <div class="actions">
        Actions:
        <button *ngIf="expt.started === null" (click)="start()"> Start </button>
        <button *ngIf="expt.started !== null && expt.completed === null" (click) = "stop()"> Stop </button>
        <button *ngIf="expt.started !== null && expt.completed !== null" (click) = "reset()"> Reset Started/Completed </button>
        <button *ngIf="locked" (click)="locked=false"> Edit </button>
        <button *ngIf="!locked" (click) = "save()"> Save Edits </button>
      </div>
      <h1>{{ expt.experimentId }}</h1>
      <div class="exptProperty">
        <h4>Active</h4>
        <span class="value">{{ expt.active }}</span>
      </div>
      <div class="exptProperty">
        <h4>Owner</h4>
        <span *ngIf = "locked" class="value">{{ expt.owner }}</span>
        <input *ngIf="!locked" [(ngModel)]="expt.owner">
      </div>
      <div class="exptProperty">
        <h4>Status</h4>
        <span *ngIf = "locked" class="value">{{ expt.status }}</span>
        <input *ngIf="!locked" [(ngModel)]="expt.status">
      </div>
      <div class="exptProperty">
        <h4>Started</h4>
        <span class="value">{{ expt.started }}</span>
      </div>
      <div class="exptProperty">
        <h4>Completed</h4>
        <span class="value">{{ expt.completed }}</span>
      </div>
      <div class="exptProperty">
        <h4>Notes</h4>
        <span class="multiline value" *ngIf = "locked">{{ expt.notes }}</span>
        <textarea *ngIf="!locked" [(ngModel)]="expt.notes"></textarea>
      </div>
    </div>
  `
})
export class AdminExptPageComponent {
  private exptId: string;
  private expt = {}
  private locked: boolean = true;

  constructor(
    private titleService: Title,
    private apiService: ApiService,
    private stateService: StateService,
    private urlService: UrlsService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Admin | MovieLens | experiment");
    this.exptId = this.stateService.params.exptId;
    this.reloadData();
  }

  reloadData() {
    this.apiService.ml4Get(
      "GET /api/admin/expts",
      "/api/admin/expts",
      {},
      response => {
        const expts:any[] = response.data;

        for (let e of expts) {
          if (e.experimentId == this.exptId) {
            this.expt = e;
          }
        }
      },
      response => {
        alert("Failed to update experiment data");
      }
    )
  }

  save() {
    const expt_copy:any = this.expt;
    this.expt = null;
    // don't save started or completed times. -- use start/end buttons for that.
    delete expt_copy.started;
    delete expt_copy.completed;
    this.upload(expt_copy);
  }

  upload(expt_copy) {
    this.locked = true
    this.apiService.ml4Post(
      "GET           /api/admin/expts/:exptId",
      "/api/admin/expts/"+this.exptId,
      expt_copy,
      response => {this.reloadData()},
      response => {
        alert("failed to update experiment data")
        this.reloadData();
      }
    )
  }

  stop() {
    const nowd = new Date();
    nowd.setMilliseconds(0);
    const now = nowd.toISOString();
    const expt_copy = {completed: now}
    this.upload(expt_copy);
  }

  start() {
    const nowd = new Date();
    nowd.setMilliseconds(0);
    const now = nowd.toISOString();
    const expt_copy = {started: now}
    this.upload(expt_copy);
  }

  reset() {
    const expt_copy = {started: null, completed: null}
    this.upload(expt_copy);
  }
}
