"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { DropdownModule } from "../dropdown-new/dropdown.module";
import { ModalDialogModule } from "../modal-dialog/modal-dialog.module";
import { PipesModule } from "../../common/pipes/pipes.module";
import { RatingModule } from "../rating/rating.module";
import { RatingOrPredictionModule } from "../rating-or-prediction/rating-or-prediction.module";
import { YoutubeModule } from "../youtube/youtube.module";

import { LinkOrEmitComponent } from "./link-or-emit.component";
import { MovieCardActionsMenuComponent } from "./movie-card-actions-menu.component";
import { MovieCardMd1Component } from "./movie-card-md1.component";
import { MovieCardSm1Component } from "./movie-card-sm1.component";
import { MovieCardSm2Component } from "./movie-card-sm2.component";
import { MovieCardSmartComponent } from "./movie-card-smart.component";
import { MovieModalNewComponent } from "./movie-modal-new.component";
import {UIRouterModule} from "@uirouter/angular";
import {UserListsAddDropdownModule} from "../user-lists-add-dropdown/user-lists-add-dropdown.module";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    ModalDialogModule,
    PipesModule,
    RatingModule,
    RatingOrPredictionModule,
    UIRouterModule.forChild(),
    YoutubeModule,
    UserListsAddDropdownModule,
  ],
  exports: [MovieCardSmartComponent],
  declarations: [
    LinkOrEmitComponent,
    MovieCardActionsMenuComponent,
    MovieCardMd1Component,
    MovieCardSm1Component,
    MovieCardSm2Component,
    MovieCardSmartComponent,
    MovieModalNewComponent
  ],
  entryComponents: [MovieCardSmartComponent]
})
export class MovieCardModule {}
