import { Component, Input } from "@angular/core";

@Component({
  selector: "closeable-alert",
  template: `
    <div class="alert alert-dismissible" role="alert"
         [class.alert-info]="isInfo"
         [class.alert-success]="isSuccess"
         [class.alert-danger]="isError"
         *ngIf="isOpen">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              (click)="onClickCloseButton()"
      ><span aria-hidden="true">&times;</span></button>
      <ng-content></ng-content>
    </div>
  `
})
export class CloseableAlertComponent {
  @Input() alertType: string;

  isOpen: boolean = true;

  isInfo: boolean = false;
  isSuccess: boolean = false;
  isError: boolean = false;

  // noinspection JSUnusedGlobalSymbols
  ngOnInit() {
    switch(this.alertType) {
      case "error": {
        this.isError = true;
        break;
      }
      case "success": {
        this.isSuccess = true;
        break;
      }
      default: {
        this.isInfo = true;
        break;
      }
    }
  }

  onClickCloseButton() {
    this.isOpen = false;
  }
}
