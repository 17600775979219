"use strict";

import {ChangeDetectorRef, Component} from "@angular/core";
import { Subscription } from "rxjs";

import { ApiActivityService } from "./api-activity.service";
import "./api-activity-indicator-cog.scss";

@Component({
  selector: "api-activity-indicator-cog",
  template: `
    <span class="api-activity-indicator-cog" *ngIf="c > 0"><i class="fa fa-cog fa-spin"></i></span>
  `
})
export class ApiActivityIndicatorCogComponent {
  private c: number = 1;
  private subscription: Subscription;

  constructor(private apiActivityService: ApiActivityService,
              private changeRef: ChangeDetectorRef) {
    this.c = apiActivityService.currentValue();
  }

  // noinspection JSUnusedGlobalSymbols
  ngAfterViewInit() {
      this.subscription = this.apiActivityService.currentCallCount.subscribe(
      (activeCallCount: number) => {
        this.c = activeCallCount;
        // explicitly say "check again for changes" -- this prevents a ExpressionChangedAfterItHasBeenCheckedError in dev mode.
        this.changeRef.detectChanges()
      });
  }

  // noinspection JSUnusedGlobalSymbols
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
