"use strict";

import { NgModule } from "@angular/core";
import { LoggerService } from "./logger.service";

@NgModule({
  providers: [LoggerService]
})
export class LoggerModule {
}
