"use strict";

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";

/**
 * Angular wrapper for a closable modal.  You must handle showing and
 * hiding through *ngIf, since this code relies on the constructor and
 * destructor to add/remove a class from the body tag.
 */
@Component({
  selector: "modal-dialog",
  template: `
    <div class="modal show" tabindex="-1" role="dialog">
      <div class="modal-dialog" [ngClass]="cls" role="document" #el>
        <div class="modal-content" [class.minimal]="minimalMode || darkMode">
          
          <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="onClose.emit()"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title"><ng-content select="[title]"></ng-content></h4>
          </div>
          
          <div class="modal-body">
            <ng-content select="[main]"></ng-content>
            <div class="clearfix"></div>
          </div>
          
          <div class="modal-footer" *ngIf="!hideFooter">
            <button type="button" class="btn btn-default" (click)="onClose.emit()">Close</button>
            <ng-content select="[footer]"></ng-content>
          </div>
          
        </div>
        <div (click)="onClose.emit()" *ngIf="minimalMode || darkMode" >
          <button class="btn btn-default close-modal" aria-label="Close"
                  (click)="onClose.emit()"><span aria-hidden="true">close</span></button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" [class.dark]="darkMode"></div>
  `,
  styles: [
    `
      .minimal .modal-header {
        display: none;
      }
      .minimal .modal-body {
        padding: 0;
      }
      .minimal .modal-footer {
        display: none;
      }

      .close-modal {
        display: inline-block;
        margin-top: 10px;
        font-weight: bold;
        color: #aaa;
        background-color: #333;
        border-color: #aaa;
      }
    `
  ]
})
export class ModalDialogComponent implements OnInit, OnDestroy {
  @Input() cls?: "modal-sm" | "modal-lg" = null;
  @Input() mode?: "minimal" | "dark" | "nofooter" = null;

  @Output() onClose = new EventEmitter<null>();

  @ViewChild("el", { read: ElementRef })
  el: ElementRef;
  items_to_destyle=[]

  clickSub: Subscription = null;
  darkMode: boolean = false;
  minimalMode: boolean = false;
  hideFooter: boolean = false;

  constructor(private renderer: Renderer2,
              // this tracks the "host element" reference
              private host: ElementRef) {
  }

  ngOnInit(): void {
    // docs: https://stackoverflow.com/questions/43542373/angular2-add-class-to-body-tag
    var elem:Node = this.host.nativeElement;
    this.items_to_destyle = []
    while (elem != document.body && elem != null) {
      this.renderer.addClass(elem, "modal-parent");
      this.items_to_destyle.push(elem)
      elem = elem.parentNode;
    }
    this.renderer.addClass(document.body, "modal-open");
    // wrap in timeout to ignore the click that caused this modal to open!
    setTimeout(() => this.listenForClicksOutside(), 0);

    this.darkMode = this.mode === "dark";
    this.minimalMode = this.mode === "minimal";
    this.hideFooter = this.mode === "nofooter";
  }

  ngOnDestroy() {
    for (const elem of this.items_to_destyle) {
      if (elem) {
        this.renderer.removeClass(elem, "modal-parent");
      }
    }
    this.renderer.removeClass(document.body, "modal-open");
    this.clickSub && this.clickSub.unsubscribe();
  }

  listenForClicksOutside() {
    if (this.clickSub && !this.clickSub.closed) {
      return;
    }

    this.clickSub = fromEvent(document, "click").subscribe(event => {
      if (!this.el.nativeElement.contains(event.target)) {
        this.onClose.emit();
      }
    });
  }

  @HostListener("document:keydown.escape", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    this.onClose.emit();
  }
}
