"use strict";

import { Injectable } from "@angular/core";
import { SessionService } from "../session/session.service";

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly userSubscription;
  private themeValue = {};

  constructor(
    private sessionService: SessionService
  ) {
      this.userSubscription = this.sessionService.currentUserData.subscribe(
        data => {
          try {
            this.themeValue = data.preferences.options.theme;
            this.setThemeFrontend(this.themeValue);
          }
          catch {}
        }
      );
      this.addOSThemeListener();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  getTheme() {
    return this.themeValue;
  }

  addOSThemeListener() {
    // NOTE: addEventListener is the recommended event listener here but our 
    // current version of TS doesn't support it.
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => {
      // only sets theme if user's theme is set to system
      if (this.themeValue === "system") {
        if (e.matches) {
          this.setThemeFrontend("dark");
        } else {
          this.setThemeFrontend("light");
        }
      }
    });
  }
    
  setThemeFrontend(val) {
    const theme = val;
    const themeElement = document.body;


    if (theme == "dark") {
      themeElement.dataset.bsTheme = "dark";
    } else if (theme == "light") {
      themeElement.dataset.bsTheme = "light";
    } else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const isDarkMode = mediaQuery.matches;
      themeElement.dataset.bsTheme = isDarkMode ? "dark": "light";
    }
  } 
}