import { Injectable } from "@angular/core";
import { ApiService } from "../../common/api/api.service";

@Injectable()
export class TagPageService {
  constructor(private apiService: ApiService) {}

  fetchTagData(tag, successCallback, errorCallback) {
    this.apiService.ml4Get(
      " GET /api/users/me/tag-survey/" + tag,
      "/api/users/me/tag-survey/" + tag,
      {},
      value => {
        successCallback(value);
      },
      error => {
        errorCallback(error);
      }
    );
  }

  submitSurveyResponse(data, successCallback, errorCallback) {
    this.apiService.ml4Post(
      "POST /api/users/me/tag-survey",
      "/api/users/me/tag-survey",
      data,
      value => {
        successCallback(value);
      },
      error => {
        errorCallback(error);
      }
    );
  }

  submitTagReport(data, successCallback, errorCallback) {
    this.apiService.ml4Post(
      "POST /api/users/me/tag-report",
      "/api/users/me/tag-report",
      data,
      value => {
        successCallback(value);
      },
      error => {
        errorCallback(error);
      }
    );
  }

}
