"use strict";

const explanationsSurveyQuestions = {
  transparency: {
    questionKey: "transparency",
    questionText:
      "Did this explanation help you understand the recommendation?",
    type: "likert",
    labels: [
      "strongly disagree",
      "disagree",
      "neutral",
      "agree",
      "strongly agree"
    ],
    values: [0, 1, 2, 3, 4],
    test: [{ name: "pardeep" }, { name: "Kumar" }, { name: "Jain" }],
    isRequired: true
  },
  effectiveness: {
    questionKey: "effectiveness",
    questionText: "Has your opinion changed?",
    type: "boolean",
    labels: ["Yes", "No"],
    values: [1, 0],
    isRequired: true
  },
  effectiveness_statement: {
    questionKey: "effectiveness_statement",
    questionText:
      "These explanations help me determine how well I will like these movies",
    type: "likert",
    labels: [
      "strongly disagree",
      "disagree",
      "neutral",
      "agree",
      "strongly agree"
    ],
    values: [0, 1, 2, 3, 4],
    isRequired: true
  }
};

export default explanationsSurveyQuestions;
