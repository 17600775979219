"use strict";

import { Component, Input } from "@angular/core";
const d3 = require("d3");

import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "rating-dist",
  template: `
    <div class="ml4-rating-dist">

      <button class="btn btn-xs btn-default" 
              (click)="onOpen()"><i class="fa fa-bar-chart" aria-hidden="true"></i></button>

      <modal-dialog (onClose)="onClose()" *ngIf="doShowModal">
        <div title>rating details</div>
        <div main>
          <nvd3 [options]="ratingDistOptions" [data]="ratingDistData"></nvd3>
        </div>
      </modal-dialog>
    </div>
  `,
  styles: [
    `
      .ml4-rating-dist {
        display: inline-block;
      }
      svg.nvd3-svg {
        margin: auto;
      }
      nv-axislabel {
        color: red;
        stroke: red;
      }
    `
  ]
})
export class RatingDistComponent {
  @Input() movieId: number;

  doShowModal = false;
  ratingDistData = null;
  ratingDistOptions = {
    chart: {
      type: "discreteBarChart",
      height: 200,
      // width: 450,
      x: function(d) {
        return d.rating;
      },
      y: function(d) {
        return d.pct;
      },
      showValues: false,
      duration: 350,
      color: function() {
        return "#3bace1";
      },
      tooltip: {
        contentGenerator: function(d) {
          return d.data.rating + " stars: " + d3.format("%")(d.data.pct);
        }
      },
      xAxis: {
        axisLabel: "stars",
        tickValues: [1, 2, 3, 4, 5]
      },
      yAxis: {
        axisLabel: "% of ratings",
        showMaxMin: false,
        tickFormat: function(d) {
          return d3.format("%")(d);
        }
      }
    }
  };

  constructor(private apiService: ApiService) {}

  onOpen() {
    this.doShowModal = true;

    if (!this.ratingDistData) {
      this.apiService.ml4Get(
        "GET /api/movies/:id/ratings/dist",
        `/api/movies/${this.movieId}/ratings/dist`,
        {},
        response => {
          this.ratingDistData = [
            {
              key: "Ratings",
              values: response.data.ratingCounts
            }
          ];
        }
      );
    }
  }

  onClose() {
    this.doShowModal = false;
  }
}
