"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { get } from "lodash";

import "./toggle.scss";

/**
 * On/off button (e.g., wishlist)
 */
@Component({
  selector: "toggle",
  template: `
    <div class="ml4-toggle">
      <tooltip [msg]="tooltip">
        <span [ngClass]="!!isOn ? 'toggle-on' : 'toggle-off'"
              (click)="onClick.emit()">
          <ng-content></ng-content>
        </span>
      </tooltip>
    </div>
  `
})
export class ToggleComponent {
  @Input() isOn: boolean = false;
  @Input() tooltip: string = "";
  @Output() onClick = new EventEmitter<null>();
}
