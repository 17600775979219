"use strict";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MovieCardModule } from "../movie-card/movie-card.module";

import { PickGroupsComponent } from "./pick-groups.component";

/**
 * XXX: currently need ng6 module as well as ng1 module
 */
@NgModule({
  imports: [CommonModule, MovieCardModule],
  exports: [PickGroupsComponent],
  declarations: [PickGroupsComponent],
  entryComponents: [PickGroupsComponent]
})
export class PickGroupsModule {}
