"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NvD3Module } from "ngx-nvd3";

import { AddTagModule } from "../../components/add-tag/add-tag.module";
import { CarouselModule } from "../../components/carousel/carousel.module";
import { DropdownModule } from "../../components/dropdown-new/dropdown.module";
import { ModalDialogModule } from "../../components/modal-dialog/modal-dialog.module";
import { MovieRowModule } from "../../components/movie-row/movie-row.module";
import { RatingModule } from "../../components/rating/rating.module";
import { TagModule } from "../../components/tag/tag.module";
import { ToggleModule } from "../../components/toggle/toggle.module";
import { YoutubeModule } from "../../components/youtube/youtube.module";

import { FlagMovieComponent } from "./flag-movie.component";
import { MovieImagesBrowserComponent } from "./movie-images-browser.component";
import { MoviePageComponent } from "./movie-page.component";
import { MovieTagsComponent } from "./movie-tags.component";
import { RatingDistComponent } from "./rating-dist.component";
import { PipesModule } from "../../common/pipes/pipes.module";
import { UIRouterModule } from "@uirouter/angular";
import { UserListsAddDropdownModule } from "../../components/user-lists-add-dropdown/user-lists-add-dropdown.module";

const moviePage = {
  name: "base.movie",
  url: "/movies/:id",
  component: MoviePageComponent
};

@NgModule({
  imports: [
    AddTagModule,
    BrowserModule,
    CarouselModule,
    DropdownModule,
    FormsModule,
    ModalDialogModule,
    MovieRowModule,
    NvD3Module,
    PipesModule,
    RatingModule,
    ReactiveFormsModule,
    TagModule,
    ToggleModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [moviePage] }),
    YoutubeModule,
    UserListsAddDropdownModule
  ],
  declarations: [
    FlagMovieComponent,
    MovieImagesBrowserComponent,
    MoviePageComponent,
    MovieTagsComponent,
    RatingDistComponent
  ]
})
export class MovieModule {}
