"use strict";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { filter, get, map } from "lodash";

import { ApiService } from "../../common/api/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";

import "./admin-movie-flags-page.scss";

@Component({
  selector: "[admin-movie-history-tr]",
  template: `
    <td>{{ versionData.versionNumber }}</td>
    <td>{{ versionData.movieId }}</td>
    <td>{{ versionData.userId }}</td>
    <td>{{ versionData.editDate * 1000 | date: "short" }}</td>
    <td>{{ versionData.comment }}</td>
  `
})
export class AdminMovieHistoryRowComponent implements OnInit {
  @Input() versionData: any;
  constructor() {}

  ngOnInit(): void {}
}
