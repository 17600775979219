"use strict";

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges} from "@angular/core";
import {get, set} from "lodash";

import {MovieDataService} from "../../../common/movie-data/movie-data.service";
import {UserActionsService} from "../../../common/user-actions/user-actions.service";
import {IMyOptions} from 'angular-mydatepicker';

@Component({
    selector: "expt-movie-card-smart",
    template: `
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'md1'">
          <movie-card-md1
              *ngIf="movieDetails"
              [movieDetails]="movieDetails"
              [trueRating]="true_rating"
              [rate]="sure_rating"
              [predictRating]="predict_rating"
              [myDatePickerOptions]="myDatePickerOptions"
              [finish]="finish"
              [bad_date]="bad_date"
              [doLink]="doLink"
              [isSeen]="isSeen"
              [isNotSeen]="isNotSeen"
              [source]="source"
              (onRate)="onRate($event)"
              (onClearRating)="onClearRating()"
              (onClearPredictRating)="onClearPredictRating()"
              (onPredictRate)="onPredictRate($event)"
              (onSeenButtonClick)="onSeenButtonClick()"
              (onNotSeenButtonClick)="onNotSeenButtonClick()"
              (onRateSure)="onRateSure($event)"
              (onDateChanged)="onDateChanged($event)"
              (onDate)='onDate($event)'

          ></movie-card-md1>
        </ng-container>
      </ng-container>
    `
})
export class ExptMovieCardSmartComponent implements OnChanges {
    // set one of these, no need for both
    @Input() movieId: string = null;
    @Input() movieDetails: any = null;

    // Open a modal on click, rather than triggering a state transition
    @Input() modal: boolean = false;

    // Custom click handler
    @Input() overrideClick: boolean = false;
    // @Output() onClick = new EventEmitter<null>();

    // Indicate which format to display
    @Input() type: string;

    // open/close state of the modal
    showModal: boolean = false;
    @Input() tomorrow: Date = new Date();
    myDatePickerOptions: IMyOptions;
    @Input() isSeen: boolean = false;
    @Input() isNotSeen: boolean = false;
    @Input() today_date: Date = null;
    @Input() bad_date: boolean = false;
    @Input() sure_rating: number = 0;
    @Input() predict_rating: number = 0;
    @Input() true_rating: number = 0;
    @Input() id: number = -1;
    @Input() finish: boolean = false;
    @Input() source: string = "";
    @Input() tstamp: Date = new Date();
    @Input() system_predict_rating: number = 0;

    @Output() onFeedback = new EventEmitter<{ id: number, isSeen: boolean, today_date: Date, true_rating: number, predict_rating: number, sure_rating: number, movieId: number, source: string, tstamp: Date, system_predict_rating: number}>();

    constructor(
        private movieDataService: MovieDataService,
        private userActionsService: UserActionsService
    ) {
        this.tomorrow.setDate(this.tomorrow.getDate() + 1);
        this.myDatePickerOptions = {
            dateFormat: "yyyy-mm-dd",
            selectorWidth: "150px",
            selectorHeight: "150px",
            disableSince: {
                year: this.tomorrow.getFullYear(),
                month: this.tomorrow.getMonth() + 1,
                day: this.tomorrow.getDate()
            },
        };
    }

    ngOnInit() {
        if (this.movieId && !get(this.movieDetails, "movieId")) {
            // fetch the movie
            this.movieDataService.getMovie(this.movieIdInt, movieDetails => {
                this.movieDetails = movieDetails;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const movieDetailsChange: SimpleChange = changes["movieDetails"];
        if (movieDetailsChange && movieDetailsChange.currentValue) {
            this.movieId = get(
                movieDetailsChange,
                "currentValue.movieId",
                this.movieId
            );
        }
    }

    get movieIdInt() {
        return parseInt(this.movieId);
    }

    get rating() {
        return get(this.movieDetails, "movieUserData.rating", null);
    }

    set rating(val: number) {
        set(this.movieDetails, "movieUserData.rating", val);
    }

    get wishlist() {
        return get(this.movieDetails, "movieUserData.wishlist", null);
    }

    set wishlist(val: boolean) {
        set(this.movieDetails, "movieUserData.wishlist", val);
    }

    get hidden() {
        return get(this.movieDetails, "movieUserData.hidden", null);
    }

    set hidden(val: boolean) {
        set(this.movieDetails, "movieUserData.hidden", val);
    }

    get prediction() {
        return get(this.movieDetails, "movieUserData.prediction");
    }

    openModal() {
        this.showModal = true;
    }

    closeModal() {
        this.showModal = false;
    }

    get doLink() {
        return this.overrideClick !== true && this.modal !== true;
    }


    onRate(rating) {
        this.isSeen = true;
        this.isNotSeen = false;
        const prevRating = this.rating;
        this.true_rating = rating;
        this.rating = rating;

        this.userActionsService.rate(
            this.movieIdInt,
            rating,
            prevRating,
            this.prediction,
            () => {
            },
            () => {
                this.rating = prevRating;
            }
        );
        this.onFinish();
    }

    onRateSure(sure_rating) {
        const prevRating = this.sure_rating;
        this.sure_rating = sure_rating;
        this.onFinish();
    }

    onPredictRate(rating) {
        const prevRating = rating;
        this.predict_rating = rating;
        this.onFinish();
    }

    onSeenButtonClick(): void {
        this.isSeen = true;
        this.isNotSeen = false;
        this.onFinish();
    }

    onSeenButtonClick2(): void {
        this.isSeen = !this.isSeen;
        this.isNotSeen = !this.isSeen;
        this.onFinish();
    }

    onNotSeenButtonClick(): void {
        this.isSeen = false;
        this.isNotSeen = true;
        this.onFinish();
    }

    onClearRating() {
        const prevRating = this.rating;
        this.rating = null;
        this.true_rating = 0;

        this.userActionsService.unrate(
            this.movieIdInt,
            () => {

            },
            () => {
                this.rating = prevRating;
            }
        );
        this.onFinish();
    }

    onClearPredictRating() {
        const prevRating = this.rating;
        this.predict_rating = 0;
        this.onFinish();
    }


    onHide() {
        this.hidden = !this.hidden;
        if (this.hidden) {
            this.userActionsService.hide(this.movieIdInt, this.prediction);
        } else {
            this.userActionsService.unhide(this.movieIdInt);
        }
    }

    onWishlist() {
        this.wishlist = !this.wishlist;
        if (this.wishlist) {
            this.userActionsService.wishlist(this.movieIdInt);
        } else {
            this.userActionsService.unwishlist(this.movieIdInt);
        }
    }

    onDateChanged(dataModel) {
        var val = dataModel.singleDate.formatted;
        var newValue = new Date(val);
        this.today_date = newValue;
        this.bad_date = false;
        this.onFinish();

    }

    onDate(val) {
        if (!this.today_date) {
            var newValue = new Date(val);
            var startDate = new Date('1900-01-01');

            var difference1 = (newValue.getFullYear() - startDate.getFullYear());

            var endDate = new Date();
            endDate.setHours(18, 0, 0);

            var difference2 = (newValue.getDate() - endDate.getDate());

            if (difference1 > 0 && difference2 < 0) {
                this.today_date = newValue;
                this.bad_date = false;
            } else {
                this.today_date = null;
                this.bad_date = true;
            }
        } else {
            var newValue = new Date(val);
            var startDate = new Date('1900-01-01');

            var difference1 = (newValue.getFullYear() - startDate.getFullYear());

            var endDate = new Date();
            endDate.setHours(18, 0, 0);

            const diffTime = Number(newValue) - Number(endDate);
            const difference2 = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (difference1 > 0 && difference2 < 0) {
                this.today_date = newValue;
                this.bad_date = false;
            } else {
                this.today_date = null;
                this.bad_date = true;
            }
        }
        this.onFinish();
    }

    onFinish() {
        if ((this.isSeen && this.today_date && (this.true_rating > 0)) || (this.isNotSeen && this.sure_rating && (this.predict_rating > 0))) {
            this.onFeedback.emit({
                id: this.id,
                isSeen: this.isSeen,
                today_date: this.today_date,
                true_rating: this.true_rating,
                predict_rating: this.predict_rating,
                sure_rating: this.sure_rating,
                movieId: this.movieIdInt,
                source: this.source,
                tstamp: this.tstamp,
                system_predict_rating: this.system_predict_rating,
            });
            this.finish = true;
        } else {
            this.finish = false;
            this.onFeedback.emit({
                id: this.id,
                isSeen: this.isSeen,
                today_date: this.today_date,
                true_rating: this.true_rating,
                predict_rating: this.predict_rating,
                sure_rating: this.sure_rating,
                movieId: this.movieIdInt,
                source: this.source,
                tstamp: this.tstamp,
                system_predict_rating: this.system_predict_rating,
            });
        }
    }
}
