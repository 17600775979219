"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StateService } from "@uirouter/core";
import { get } from "lodash";

@Component({
  selector: "register-page",
  template: `
    <div class="register-page">
      <h3 class="page-description">register</h3>
      
      <register-form (onRegisterSuccess)="onRegisterSuccess($event)"></register-form>
    
      <p class="spaced">
        <a uiSref="auth.login">already a user? sign in here.</a>
      </p>

    </div>
  `
})
export class RegisterPageComponent {
  constructor(
    private titleService: Title,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Register | MovieLens");
  }

  onRegisterSuccess = () => {
    this.stateService.go("restricted.pickGroupsNewUser");
  };
}
