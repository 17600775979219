import { Component, OnInit } from "@angular/core";

import "./explanations-module.scss";
import { ExplanationsService } from "./explanations.service";
import { FlashService } from "../../common/flash/flash.service";
import { StateService } from "@uirouter/core";

const tmpl = require("./explanations-consent.component.html");

@Component({
  template: tmpl
})
export class ExplanationsConsentComponent implements OnInit {
  private isReady: boolean = false;
  private isConsent: boolean = true;

  constructor(
    private explanationsService: ExplanationsService,
    private flashService: FlashService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.explanationsService.getSurveyStatus(response => {
      if (response.status.includes("step")) {
        this.quit();
      } else {
        this.isReady = true;
      }
    });
  }

  goHome(): void {
    this.stateService.go("base.frontpage");
  }

  quit(): void {
    this.flashService
      .addInfo(
        "This study has now concluded. We thank you for your interest and participation."
      )
      .show();
    this.goHome();
  }

  startSurvey(): void {
    this.explanationsService.consent(response => {
      if (response.status == null || response.status == "consent") {
        this.stateService.go("restricted.explanations.survey");
      } else {
        this.quit();
      }
    });
  }

  showInstructions(): void {
    this.isConsent = false;
  }
}
