"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import { ExternalrecsStatusPageComponent } from "./externalrecs-status-page.component";
import { ExternalrecsStatusPageService } from "./externalrecs-status-page.service";
import { FormsModule } from "@angular/forms";
import {UIRouterModule} from "@uirouter/angular";

const extrecsStatusPage = {
  name: "admin.externalRecsStatusPage",
  url: "/admin/externalrecs-status",
  component: ExternalrecsStatusPageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [extrecsStatusPage] })
  ],
  declarations: [ExternalrecsStatusPageComponent],
  providers: [ExternalrecsStatusPageService]
})
export class ExternalrecsStatusModule {}
