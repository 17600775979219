"use strict";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";

import { ApiService } from "../../common/api/api.service";
import {Ml4WebSocket, WsService} from "../../common/ws/ws.service";

@Component({
  selector: "test-search-page",
  template: `
    <div class="dev-test-search">
      <h1>test websockets</h1>
      
      <p>
        This page connects a websocket to the server. The server will simply
        echo back whatever you send and print it below.
      </p>

      <form role="form" class="form-inline">
        <input type="text" class="form-control" [(ngModel)]="q" name="foo" />

        <button class="btn btn-default" (click)="submit()">
          <i class="fa fa-search"></i>
        </button>
      </form>

      <hr />

      <div class="card">
        <div class="card-header">
          <h3 class="card-title">from server:</h3>
        </div>
        <div class="card-body">
          <ul>
            <li *ngFor="let res of responses">{{ res }}</li>
          </ul>
        </div>
      </div>
    </div>
  `
})
export class TestWsPageComponent implements OnInit, OnDestroy {
  q = "";
  responses = [];
  socket: Ml4WebSocket = null;

  constructor(
    private titleService: Title,
    private wsService: WsService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Test Websockets | MovieLens");

    this.socket = this.wsService.getSocket(
      "/ws/dev/echo",
      this.onOpen,
      this.onClose,
      this.onMessage.bind(this)
    )
  }

  ngOnDestroy(): void {
    this.socket && this.socket.close();
  }

  onOpen() {
    console.log(`onopen`);
  }

  onClose() {
    console.log(`onclose`);
  }

  onMessage(event) {
    let json = JSON.parse(event.data);
    const message = get(json, "message", "");
    console.log(`onmessage: ${message}`);
    this.responses.push(message);
  }

  submit() {
    this.socket.send(this.q);
    this.q = "";
  }
}
