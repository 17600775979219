"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import { AuthTemplateComponent } from "./auth-template.component";
import {UIRouterModule} from "@uirouter/angular";

const authTemplate = {
  name: "auth",
  abstract: true,
  parent: "global",
  component: AuthTemplateComponent
};

@NgModule({
  imports: [
    BrowserModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [authTemplate] })
  ],
  declarations: [
    AuthTemplateComponent
  ]
})
export class AuthTemplateModule {}
