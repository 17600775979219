"use strict";

import { Component, EventEmitter, Output } from "@angular/core";
import { forIn, toPairs } from "lodash";

import { MovieGroupsService } from "./movie-groups.service";
import { StateService } from "@uirouter/core";
import { UserDataService } from "../../common/user-data/user-data.service";

import "./pick-groups.scss";

@Component({
  selector: "ml4-pick-groups",
  template: `
    <div class="pick-groups" *ngIf="clusters">

      <div class="controls">
        <span *ngIf="hasMorePoints()">
          Remaining points: <span class="points-left">{{ pointsLeft }}</span>
        </span>
        <span *ngIf="!hasMorePoints()">
          <button type="button" class="btn btn-primary" 
                  [disabled]="hasMorePoints()"
                  (click)="submit()">Next <i class="fa fa-arrow-circle-right"></i></button>
        </span>
      </div>

      <div class="cluster-container">

        <div *ngFor="let cluster of clusters" class="cluster">

          <div class="cluster-points">
            <div>
              <button type="button" class="btn btn-default btn-sm"
                      (click)="plus(cluster.clusterId)"
                      [ngStyle]="plusStyle()"><i class="fa fa-plus"></i></button>
            </div>
            <div [ngStyle]="minusStyle(cluster.clusterId)" class="score">
              {{ score(cluster.clusterId) }}
            </div>
            <div>
              <button type="button" class="btn btn-default btn-sm"
                      (click)="minus(cluster.clusterId)"
                      [ngStyle]="minusStyle(cluster.clusterId)"><i class="fa fa-minus"></i></button>
            </div>
          </div>

          <div class="cluster-box">
            <div class="cluster-tags">{{ cluster.tags.join(', ') }}</div>
            <div *ngFor="let movie of cluster.movies" style="display: inline-block;">
              <movie-card-smart type="sm1" [movieId]="movie.movieId" [modal]="true"></movie-card-smart>
            </div>
          </div>

        </div> <!-- end ng-repeat clusters -->

      </div> <!-- end .pick-container -->

      <div class="controls">
        <span *ngIf="hasMorePoints()">
          Remaining points: <span class="points-left">{{ pointsLeft }}</span>
        </span>
        <span *ngIf="!hasMorePoints()">
          <button type="button" class="btn btn-primary"
                  [disabled]="hasMorePoints()"
                  (click)="submit()">Next <i class="fa fa-arrow-circle-right"></i></button>
        </span>
      </div>

    </div>
  `
})
export class PickGroupsComponent {
  @Output() onSubmit = new EventEmitter<any>();

  totalPointsAvailable: number = 3;
  pointsUsed: number = 0;
  picks: any = {};

  // loaded from server
  clusters: any = null;

  constructor(
    private movieGroupsService: MovieGroupsService,
    private stateService: StateService,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.movieGroupsService.groups(
      response => {
        this.clusters = response.data.groups;
      },
      () => {}
    );
  }

  get pointsLeft() {
    return this.totalPointsAvailable - this.pointsUsed;
  }

  hasMorePoints() {
    return this.totalPointsAvailable > this.pointsUsed;
  }

  // convert picks: { "33": 3 } to [33, 33, 33]
  get picksList() {
    const l = [];
    forIn(this.picks, (v, k) => {
      for (let i = 0; i < v; i++) {
        l.push(parseInt(k, 10));
      }
    });
    return l;
  }

  score(clusterId) {
    return this.picks[clusterId] || 0;
  }

  plus(clusterId) {
    if (!this.picks[clusterId]) {
      this.picks[clusterId] = 0;
    }
    this.picks[clusterId] += 1;
    this.pointsUsed += 1;
  }

  minus(clusterId) {
    this.picks[clusterId] -= 1;
    if (!this.picks[clusterId]) {
      delete this.picks[clusterId];
    }
    this.pointsUsed -= 1;
  }

  plusStyle() {
    // show plus if we have more points available
    return { visibility: this.hasMorePoints() ? "visible" : "hidden" };
  }

  minusStyle(clusterId) {
    return { visibility: !!this.picks[clusterId] ? "visible" : "hidden" };
  }

  submit() {
    this.movieGroupsService.pick(
      { picks: this.picksList },
      () => {
        this.userDataService.update({ engineId: "bard" }, () => {
          this.onSubmit.emit({ success: true });
        });
      },
      () => {
        this.onSubmit.emit({ success: false });
      }
    );
  }
}
