import {Component, OnInit} from "@angular/core";

import "./llm_rec_module.scss";
import {FlashService} from "../../common/flash/flash.service";
import {StateService} from "@uirouter/core";
import {ApiService} from "../../common/api/api.service";

@Component({
    selector: "llm_rec_info_window",
    template: require("./llm_rec_info_window.html")
})

export class LLMRecInfoWindowComponent implements OnInit {
    check = false;

    constructor(
        private flashService: FlashService,
        private stateService: StateService,
        private apiService: ApiService,
    ) {
    }

    get showExternalInvite() {
        return this.stateService.params.q === "use-external-recs";
    }

    ngOnInit(): void {
    }

    dismiss(): void {
        this.check = true;
        this.apiService.ml4Post("POST /api/llm-rec/save_enroll", "/api/llm-rec/save_enroll", {}, () => {
            this.goHome();
        })
    }

    goHome(): void {
        this.stateService.go("base.frontpage");
    }


}