"use strict";

import { Component, Input } from "@angular/core";
import { get } from "lodash";

import { UserActionsService } from "../../common/user-actions/user-actions.service";

const messages = {
  onMsg: "in wishlist (click to remove)",
  offMsg: "add to wishlist"
};

@Component({
  selector: "ml4-wishlist",
  template: `
    <toggle [isOn]="wishlist" [tooltip]="tip" (onClick)="toggle()">
      <span class="fa fa-thumb-tack ml4-fa-1x fa-fw"></span>
    </toggle>
  `
})
export class WishlistComponent {
  @Input() movieId: number;
  @Input() wishlist: boolean;

  constructor(private userActionsService: UserActionsService) {}

  get tip() {
    return this.wishlist ? messages.onMsg : messages.offMsg;
  }

  toggle() {
    // XXX: hack-y way to manage state
    this.wishlist = !this.wishlist;
    if (this.wishlist) {
      this.userActionsService.wishlist(this.movieId);
    } else {
      this.userActionsService.unwishlist(this.movieId);
    }
  }
}
