import {Component, OnInit} from "@angular/core";

import "./interactive_profile_module.scss";
import {FlashService} from "../../common/flash/flash.service";
import {StateService} from "@uirouter/core";
import {ApiService} from "../../common/api/api.service";

@Component({
    selector: "interactive_profile_info_window",
    template: require("./interactive_profile_info_window.html")
})

export class InteractiveProfileInfoWindowComponent implements OnInit {
    check = false;

    constructor(
        private flashService: FlashService,
        private stateService: StateService,
        private apiService: ApiService,
    ) {
    }

    ngOnInit(): void {
    }

    dismiss(): void {
        this.stateService.go("base.frontpage");
    }

    goHome(): void {
        this.stateService.go("base.frontpage");
    }

    goProfile(): void {
        this.check = true;
        this.stateService.go("base.userProfilePage");
        this.apiService.ml4Post("POST /api/interactive-profile/save_enroll", "/api/interactive-profile/save_enroll", {}, () => {
            this.stateService.go("restricted.userProfilePage");
        })
    }


}