"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "all-genres-page",
  template: `
    <div class="all-genres-page">
      
      <h1>all genres</h1>
      
      <div class="table-responsive" *ngIf="popularTagsForGenres">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>genre <small>(# movies)</small></th>
              <th>most popular tags</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let genre of allGenres">
              <td>
                <a uiSref="exploreGenreShortcut" [uiParams]="{ id: genre | urlFragmentNew }" [uiOptions]="{ inherit: false }"><b>{{ genre }}</b></a>
                &nbsp;
                <small>({{ numMoviesPerGenre[genre] }})</small>
              </td>
              <td>
                <span *ngFor="let tag of popularTagsForGenres[genre]; let last=last;">
                  <a uiSref="base.explore" 
                     [uiParams]="{ genre: genre | lowercase, tag: tag | lowercase, sortBy: 'tagScore' }" 
                     [uiOptions]="{inherit: false}"> {{ tag }} </a> 
                     {{ last ? '' : ' | ' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  `
})
export class AllGenresPageComponent {
  popularTagsForGenres;
  numMoviesPerGenre;
  allGenres;

  constructor(private apiService: ApiService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle("All Genres | MovieLens");
    this.loadGenreData();
  }

  loadGenreData() {
    this.apiService.ml4Get(
      "GET /api/movies/genres",
      "/api/movies/genres",
      {},
      response => {
        this.popularTagsForGenres = response.data.popularTagsForGenres;
        this.numMoviesPerGenre = response.data.numMoviesPerGenre;
        this.allGenres = Object.keys(this.popularTagsForGenres).sort();
      }
    );
  }
}
