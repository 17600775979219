"use strict";

import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { Title } from "@angular/platform-browser";

import { MovieDataService } from "../../common/movie-data/movie-data.service";

import "./alexa-recommend-page.scss";

@Component({
  selector: "alexa-recommend-page",
  template: `
    <div class="alexa-recommend">
      <div class="tv-nav tv-nav-top">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        "go back"
      </div>

      <div class="tv-nav tv-nav-top text-center" *ngIf="query">
        showing results for: "{{ query }}"
      </div>

      <div class="tv-nav tv-nav-top text-right" *ngIf="hasMore">
        "show me more"
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </div>

      <div class="tv-content">
        <div class="tile" *ngFor="let m of movies">
          <div class="poster-container">
            <img
              class="img-responsive movie-poster"
              [src]="m.movie.posterW342Url"
            />
            <div class="star-prediction">
              <i class="fa fa-star-o" aria-hidden="true"></i>
              {{ m.movieUserData.prediction | number: "1.1-1" }}
            </div>
          </div>

          <div class="tile-label">"tell me about {{ m.movie.title }}"</div>
        </div>
      </div>
    </div>
  `
})
export class AlexaRecommendPageComponent implements OnInit {
  query = "";
  hasMore = false;
  movieIds = [];
  movies = [];

  constructor(
    private movieDataService: MovieDataService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Recommendations | MovieLens");

    this.query = this.stateService.params.query;

    // limit to 12
    this.movieIds = this.stateService.params.movies
      ? this.stateService.params.movies.split(",").slice(0, 12)
      : [];

    // XXX: there may not be more, but let's guess that there are
    this.hasMore = this.movieIds.length >= 12;

    this.movieIds.map(movieId => {
      this.movieDataService.getMovie(movieId, movieDetails => {
        this.movies.push(movieDetails);
      });
    });
  }
}
