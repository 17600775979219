import { Injectable } from "@angular/core";
import { get, wrap } from "lodash";

// issue a keepalive request this often
const KEEP_OPEN_INTERVAL = 35000; // ms

/**
 * websocket helpers
 */
@Injectable()
export class WsService {
  constructor() {}

  getSocket(
    endpoint: string,
    onOpen: Function = null,
    onClose: Function = null,
    onMessage: Function = null,
    onError: Function = null
  ): Ml4WebSocket {
    const host = get(window, "location.host", "movielens.org");
    const protocol = get(window, "location.protocol", "https:");

    const prefix = protocol === "https:" ? "wss" : "ws";
    const uri = `${prefix}://${host}${endpoint}`;

    const WS = window["MozWebSocket"] ? window["MozWebSocket"] : WebSocket;

    const socket = new WS(uri);
    socket.onopen = onOpen;
    socket.onclose = onClose;
    socket.onmessage = onMessage;
    socket.onerror = onError;

    return new Ml4WebSocket(socket);
  }
}

export class Ml4WebSocket {
  socket;
  keepaliveRef;

  constructor(socket) {
    this.socket = socket;
    this.keepaliveRef = setInterval(
      this.keepalive.bind(this),
      KEEP_OPEN_INTERVAL
    );
  }

  private keepalive() {
    this.socket.send("keepalive");
  }

  close() {
    clearTimeout(this.keepaliveRef);
    this.socket && this.socket.close();
  }

  send(o) {
    this.socket && this.socket.send(o);
  }
}
