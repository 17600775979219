"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "pager",
  template: `
    <nav aria-label="navigation links">
      <ul class="pagination pager-links">
        <li [class.disabled]="disablePrev">
          <a class="cursor-pointer" (click)="prev()">previous</a>
        </li>
        <li [class.disabled]="disableNext">
          <a class="cursor-pointer" style="margin-left: 10px;" (click)="next()"
            >next</a
          >
        </li>
      </ul>
      <div class="small pager-info">page {{ currentPage }} / {{ totalPages }}</div>
    </nav>
  `,
  styles: [
    `
      .pager-links {
        vertical-align: middle;
      }
      .pager-info {
        display: inline-block;
        vertical-align: middle;
        margin-left: 24px;
        color: grey;
      }
    `
  ]
})
export class PagerComponent {
  @Input() currentPage: number = 0;
  @Input() totalPages: number = 0;
  @Output() onChangePage = new EventEmitter<number>();

  constructor() {}

  get disablePrev() {
    return this.currentPage <= 1;
  }

  get disableNext() {
    return this.currentPage >= this.totalPages;
  }

  prev() {
    if (this.disablePrev) {
      return;
    }
    this.onChangePage.emit(this.currentPage - 1);
  }

  next() {
    if (this.disableNext) {
      return;
    }
    this.onChangePage.emit(this.currentPage + 1);
  }
}
