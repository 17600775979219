"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AddTagModule } from "../../components/add-tag/add-tag.module";
import { MovieRowModule } from "../../components/movie-row/movie-row.module";

import { TagPageComponent } from "./tag-page.component";
import { TagSurveyComponent } from "./tag-survey.component";
import { TagReportComponent } from "./tag-report.component";
import { TagPageService } from "./tag-page.service";
import { TagModule } from "../../components/tag/tag.module";
import { UIRouterModule } from "@uirouter/angular";

const tagPage = {
  name: "base.tagPage",
  url: "/tag/:{name:urlDecoded}",
  component: TagPageComponent
};

@NgModule({
  imports: [
    AddTagModule,
    BrowserModule,
    FormsModule,
    MovieRowModule,
    ReactiveFormsModule,
    TagModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [tagPage] })
  ],
  declarations: [TagPageComponent, TagSurveyComponent, TagReportComponent],
  providers: [TagPageService]
})
export class TagPageModule {}
