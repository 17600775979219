"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { PickGroupsModule } from "../../components/pick-groups/pick-groups.module";

import { ColdstartPageComponent } from "./coldstart-page.component";
import { WelcomePageComponent } from "./welcome-page.component";
import {UIRouterModule} from "@uirouter/angular";

const coldstartPage = {
  name: "restricted.pickGroupsNewUser",
  url: "/join/pick-groups",
  component: ColdstartPageComponent
};

const welcomePage = {
  name: "restricted.newUser_welcome",
  url: "/join/welcome",
  component: WelcomePageComponent
};

@NgModule({
  imports: [
    BrowserModule,
    PickGroupsModule,
    // https://github.com/ui-router/angular-hybrid/issues/93
    UIRouterModule.forChild({ states: [coldstartPage, welcomePage] })
  ],
  declarations: [ColdstartPageComponent, WelcomePageComponent]
})
export class JoinModule {}
