import {
  Component,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { Subscription } from "rxjs";

import { AlertMessageComponent } from "./alert-message.component";
import { AlertMessage, FlashService } from "./flash.service";

import "./flash.scss";

@Component({
  selector: "alert-container",
  template: `
    <div class="alert-container">
      <template #alertGoesHere></template>
    </div>
  `
})
export class AlertContainerComponent  {
  private subscription: Subscription;
  @ViewChild("alertGoesHere", { read: ViewContainerRef })
  entry: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    private flashService: FlashService
  ) {}

  // noinspection JSUnusedGlobalSymbols
  ngOnInit() {
    this.subscription = this.flashService.alert$.subscribe(
      (alertMessage: AlertMessage) => {
        this.createComponent(alertMessage);
      }
    );
  }

  // noinspection JSUnusedGlobalSymbols
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * DOCS:
   * https://dzone.com/articles/how-to-dynamically-create-a-component-in-angular
   */
  createComponent(alertMessage: AlertMessage) {
    const factory = this.resolver.resolveComponentFactory(
      AlertMessageComponent
    );
    const componentRef = this.entry.createComponent(factory);
    componentRef.instance.msg = alertMessage.msg;
    componentRef.instance.alertType = alertMessage.alertType.toString();

    setTimeout(() => componentRef.destroy(), 12000);
  }
}
