import { Component } from "@angular/core";

import { StateService } from "@uirouter/core";
import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "emoji-consent",
  template: require("./emoji-consent.html")
})
export class EmojiConsentComponent {
  constructor(
    private stateService: StateService,
    private apiService: ApiService
  ) {}

  next() {
    this.apiService.ml4Post("POST /api/emoji", "/api/emoji", {}, () => {
      this.stateService.go("restricted.emojiTutorial");
    });
  }
}
