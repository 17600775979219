"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "movie-card-actions-menu",
  template: `
    <div class="movie-card-actions-menu">
      <dropdown-new>
        <ng-container dropdownTrigger>
          <div class="actions-button"></div>
        </ng-container>
        <ng-container dropdownContent>
          <li><a (click)="onWishClick.emit()">📌 {{ wishlistText }}</a></li>
          <li><a (click)="onHideClick.emit()">🚫 {{ hideThisText }}</a></li>
          <li *ngIf="userRating"><a (click)="onClearRatingClick.emit()">⭐ clear your rating</a></li>
          
          <user-lists-add-dropdown [movieId]="movieId" ></user-lists-add-dropdown>
          
        </ng-container>
      </dropdown-new>
    </div>
  `,
  styles: [
    `
      .movie-card-actions-menu {
        display: inline-block;
      }

      div.actions-button {
        position: relative;
        background: #f06624;
        width: 30px;
        height: 30px;
        color: white;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 1px #4185bc;
        cursor: pointer;
      }
      div.actions-button:after {
        content: "•••";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        font-size: 120%;
        letter-spacing: 2px;
      }
    `
  ]
})
export class MovieCardActionsMenuComponent {
  @Input() wishlisted: boolean = false;
  @Input() hidden: boolean = false;
  @Input() userRating: number = null;
  @Input() movieId: number;

  @Output() onWishClick = new EventEmitter<null>();
  @Output() onHideClick = new EventEmitter<null>();
  @Output() onClearRatingClick = new EventEmitter<null>();

  get wishlistText() {
    return this.wishlisted ? "remove from wishlist" : "add to wishlist";
  }

  get hideThisText() {
    return this.hidden ? "unhide" : "hide";
  }
}
