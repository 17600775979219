"use strict";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { get } from "lodash";

import { ApiService } from "../../common/api/api.service";
import { UserActionsService } from "../../common/user-actions/user-actions.service";

const messages = {
  onMsg: "hidden (click to restore)",
  offMsg: "not interested"
};

@Component({
  selector: "ml4-hide-this",
  template: `
    <toggle [isOn]="on" [tooltip]="tip" (onClick)="toggle()">
      <span class="fa fa-times ml4-fa-1x fa-fw"></span>
    </toggle>
  `
})
export class HideThisComponent {
  @Input() movieId: number;
  @Input() isHidden: boolean;
  @Input() predictedRating: number;
  @Output() onHideThis = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private userActionsService: UserActionsService
  ) {}

  get tip() {
    return this.isHidden ? messages.onMsg : messages.offMsg;
  }

  get on() {
    return this.isHidden === true;
  }

  toggle() {
    // XXX: hack-y way to manage state
    this.isHidden = !this.isHidden;
    if (this.isHidden) {
      this.userActionsService.hide(this.movieId, this.predictedRating);
    } else {
      this.userActionsService.unhide(this.movieId);
    }
    this.onHideThis.emit(this.isHidden);
  }
}
