"use strict";

import { NgModule } from "@angular/core";
import { ThemeService } from "./theme.service";

@NgModule({
  providers: [ThemeService]
})

export class ThemeModule {
  constructor(private ThemeService: ThemeService) {}
}
