"use strict";

import {Component, Input} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";

import "./info-page.scss";

declare var ML4_VERSION_MACRO: string;
declare var ML4_BUILD_ID_MACRO: string;
declare var ML4_COPYRIGHT_YEAR_MACRO: string;

@Component({
  selector: "info-page",
  template: `
    <div class="info-page">
    
      <header class="compact-header restricted-compact-header">
        <div class="ml4-container">
          <div class="row main-menu">
            <div class="col-sm-4 unpadded">&nbsp;</div>
            <div class="col-sm-4 unpadded text-center">
              <img src="/images/movielens-logo.svg" width="100px" height="30px" alt="MovieLens logo">
            </div>
          </div> <!-- end row -->
        </div> <!-- end ml4-container -->
      </header>
    
      <div class="ml4-container">
        <div class="info-container" [innerHTML]="content"></div>
      </div>
      
      <footer class="info-footer" style="text-align: center;">
        <p>
          <a href="/" target="_self">Go to MovieLens!</a>
        </p>
        <p>
          <a uiSref="global.about">about MovieLens</a> |
          <a uiSref="global.contact">contact us</a> |
          <a uiSref="global.privacyPolicy">privacy policy</a> |
          <a uiSref="global.termsOfUse">terms of use</a>
        </p>
        <p>
          version {{ ml4Version }} {{ ml4BuildId }}
        </p>
        <p>copyright &copy; {{ ml4CopyrightYear }}</p>
      </footer>
    
    </div>
  `
})
export class InfoPageComponent {
  @Input()
  title: string = "Info";

  @Input()
  content;

  private ml4Version = ML4_VERSION_MACRO;
  private ml4BuildId = ML4_BUILD_ID_MACRO;
  private ml4CopyrightYear = ML4_COPYRIGHT_YEAR_MACRO;

  constructor(
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title + " | MovieLens");
  }

}
