"use strict";

import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";

@Component({
  selector: "alexa-error-page",
  template: `
    <div class="alexa-error-page">
      <div class="title">Try Again</div>

      <div class="tv-nav tv-nav-top text-center" *ngIf="query">
        showing results for: "{{ query }}"
      </div>
  
      <div class="section">
        {{msg}}
      </div>
    </div>
  `
})
export class AlexaErrorPageComponent implements OnInit {

  query = "";
  msg = "";

  constructor(
    private stateService: StateService,
    private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle("Error | MovieLens");
    this.query = this.stateService.params.query;
    this.msg = this.stateService.params.msg;
  }

}
