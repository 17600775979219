"use strict";

import { Component } from "@angular/core";
import { get } from "lodash";

import "./auth-template.scss";

@Component({
  selector: "auth-template",
  template: `
    <div class="marketing-page">
      <div class="big-header">
        <div class="marketing-container">
          <a href="/" target="_self"
            ><img
              src="/images/movielens-logo-white.svg"
              width="180"
              height="35"
              alt="MovieLens"
          /></a>

          <div id="alert-box"></div>
        </div>
      </div>

      <div class="marketing-container">
        <ui-view ></ui-view>
      </div>
    </div>
  `
})
export class AuthTemplateComponent {}
