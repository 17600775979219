"use strict";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { MovieTunerPageComponent } from "./movie-tuner-page.component";
import { MovieCardModule } from "../../components/movie-card/movie-card.module";
import { PagerModule } from "../../components/pager/pager.module";
import { MovieTunerTagAutocompleteModule } from "./movie-tuner-tag-autocomplete.module";
import { UIRouterModule } from "@uirouter/angular";
import { UrlEncoderTypeModule } from "../../common/urlEncoderTypeModule";

const movieTunerPage = {
  name: "base.movieTuner",
  url: "/movies/:id/similar?num&page&{critique:urlDecoded}",
  component: MovieTunerPageComponent
};

@NgModule({
  imports: [
    UrlEncoderTypeModule,
    BrowserModule,
    FormsModule,
    MovieCardModule,
    PagerModule,
    MovieTunerTagAutocompleteModule,
    UIRouterModule.forChild({ states: [movieTunerPage] })
  ],
  declarations: [MovieTunerPageComponent]
})
export class MovieTunerModule {}
