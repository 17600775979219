import { Component, Input } from "@angular/core";

@Component({
  selector: "alert-message",
  template: `
    <closeable-alert [alertType]="alertType">
      <div [innerHTML]="msg"></div>
    </closeable-alert>
  `
})
export class AlertMessageComponent {
  @Input() msg: string;
  @Input() alertType: string;
}
