"use strict";

import { Component, Input } from "@angular/core";
import { get } from "lodash";
import { FormBuilder, FormGroup } from "@angular/forms";

import { ApiService } from "../../common/api/api.service";
import { FlashService } from "../../common/flash/flash.service";

@Component({
  selector: "flag-movie",
  template: `
    <div class="flag-movie">
      <div class="flag-movie-icon">
        <button
          type="button"
          class="btn btn-link no-glow"
          (click)="openModal()"
        >
          flag for removal
        </button>
      </div>

      <modal-dialog (onClose)="closeModal()" *ngIf="open">
        <div title>flag for removal</div>
        <div main>
          <form role="form" [formGroup]="theForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label>
                reason for flagging this movie?
                <select class="form-control" formControlName="reason">
                  <option value="" selected disabled>select one...</option>
                  <option
                    *ngFor="let opt of dropdownOptionsMap"
                    [value]="opt.value"
                    >{{ opt.label }}</option
                  >
                </select>
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: 10px;"
                  formControlName="comment"
                  placeholder="enter comment"
                />
              </label>
            </div>

            <button
              class="btn btn-warning"
              type="submit"
              [disabled]="!reason.value"
            >
              flag this movie
            </button>
          </form>
        </div>
      </modal-dialog>
    </div>
  `,
  styles: [
    `
      .flag-movie-icon button {
        padding: 0;
      }
    `
  ]
})
export class FlagMovieComponent {
  @Input() movieId: number;

  private dropdownOptionsMap = [
    { value: "notMovie", label: "not a movie" },
    { value: "duplicate", label: "duplicate movie" },
    { value: "imdbIdWrong", label: "IMDB link is wrong" },
    { value: "pornography", label: "porn/inappropriate" },
    { value: "other", label: "other" }
  ];
  private theForm: FormGroup;
  private open = false;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private flashService: FlashService
  ) {}

  ngOnInit() {
    this.theForm = this.fb.group({
      reason: [""],
      comment: [""]
    });
  }

  get reason() {
    return this.theForm.get("reason");
  }

  get comment() {
    return this.theForm.get("comment");
  }

  openModal() {
    this.open = true;
  }

  closeModal() {
    this.open = false;
  }

  onSubmit() {
    this.closeModal();

    this.apiService.ml4Post(
      "POST /api/actions/maint/report-movie",
      "/api/actions/maint/report-movie",
      {
        movieId: this.movieId,
        reportType: this.reason.value,
        comments: this.comment.value
      },
      () => {
        this.flashService.addSuccess("Thanks for flagging this movie").show();
      },
      () => {
        this.flashService.addError(
          "Something went wrong, please email us at movielens-support@umn.edu"
        );
      }
    );
  }
}
