"use strict";

import {
  Component,
  Input,
  OnDestroy,
  ContentChild,
  TemplateRef
} from "@angular/core";
import {SessionService} from "../../common/session/session.service";


/**
 * Angular component that is based on an experiment being active.
 * This is used like so:

 <if-expt exptId="WaterlooRecruit2023">
   <ng-template>
     (component code to only load+show+run when the experiment is live goes here)
   </ng-template>
 </if-expt>


 * or like so

 <if-expt exptId="LLMRecNature" user_status="yes">
 <ng-template>
 (component code to only load+show+run when the experiment is live, and the user is in expt_user table with status equal to "yes" goes here)
 </ng-template>
 </if-expt>

 * The <ng-template> part is required, and the tool shouldn't work if you.
 *
 * You may also find value in looking at how the constructor and ngOnDestroy functions work together to keep the
 * exptInfo property in-sync.
 */
@Component({
  selector: "if-expt",
  template: `
    <ng-container *ngIf="show">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ng-container>
  `,
})
export class IfExptComponent implements OnDestroy {
  @Input() exptId:string = "";
  @Input() user_status:string = null;

  @ContentChild(TemplateRef) content;


  private readonly userSubscription;
  private exptInfo = {};

  constructor(
    private sessionService: SessionService
  ) {
    this.userSubscription = this.sessionService.currentUserData.subscribe(
      data => {
        this.exptInfo = data.exptInfo;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  get show(): boolean {
    const expt = this.exptInfo[this.exptId];
    if (expt !== undefined) {
      if (this.user_status !== null ) {
        if (expt['user'] !== undefined && expt['user'] !== null && expt['user']['status'] === this.user_status) {
          return true;
        } else {
          return false;
        }
      } else {
        // active, no filter
        return true;
      }
    } else {
      // not active
      return false;
    }
  }

}
