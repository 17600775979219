"use strict";

import {Component, ElementRef, Input} from "@angular/core";

import { UserListsService } from "../../common/user-lists/user-lists.service";
import "./user-lists-add-dropdown.scss";
import {ModalDialogModule} from "../modal-dialog/modal-dialog.module";

@Component({
  selector: "user-lists-add-dropdown",
  template: `
<!--    don't propigate click events from this component (I.E. in a submenu &#45;&#45; keep the menu open)-->
    <div [ngStyle]="{ display: 'flex' }" (click)="$event.stopPropagation()" (mouseover)="load()">
      <dropdown-new>
        <ng-container dropdownTrigger>
          <div class="actions-button">
            <button class="btn btn-xs btn-default">
              Add to list <i class="fa fa-caret-down"> </i>
            </button>
          </div>
        </ng-container>
        <ng-container subMenuLabel>
              Add to list
          
        </ng-container>
        <ng-container dropdownContent *ngIf="lists">
          <span *ngIf="loading" class="tag-busy-indicator-cog">
            <i class="fa fa-cog fa-spin"></i>
          </span>
          <li *ngFor="let list of lists; let i = index">
            <a (click)="onListChange(list.listId, i)"
              ><input
                type="checkbox"
                id="{{ list.listId }}"
                [checked]="list.hasQueriedMovie"
              />
              <label [ngStyle]="{ paddingLeft: '10px' }"
                >{{ list.title }} ({{
                  list.publicList ? "Public" : "Private"
                }})</label
              ></a
            >
          </li>

          <hr class="item-divider" *ngIf="lists.length > 0" />
          <li>
            <a (click)="openModal()"
              ><i class="fa fa-plus" [ngStyle]="{ marginRight: '5px'}"></i
              >Create a new List</a
            >
          </li>
        </ng-container>
      </dropdown-new>
      <span
        *ngIf="busy"
        class="tag-busy-indicator-cog"
        [ngStyle]="{ marginLeft: '10px' }"
        ><i class="fa fa-cog fa-spin"></i
      ></span>
    </div>
    <user-lists-modal 
      (click)="$event.stopPropagation()"
      [editModal]="false"
      [modalData]="modalData"
      (createNewUserList)="addNewList($event)"
    ></user-lists-modal>
  `
})
export class UserListsAddDropdownComponent {
  @Input() movieId: number;
  lists=[];
  busy = false;
  loading = true;

  modalData = {
    showModal: false
  };

  constructor(private userListsService: UserListsService,
              ms: ModalDialogModule) {

  }

  load () {
    if (this.loading) {
      this.userListsService.getUserLists(this.movieId, response => {
        this.lists = response.data;
        this.loading = false
      });
    }
  }

  ngOnInit() {

  }

  openModal() {
    this.modalData.showModal = true;
  }

  onListChange(listId, index) {
    if (this.lists[index].hasQueriedMovie) {
      this.deleteMovieFromList(listId, index);
    } else {
      this.addMovieToList(listId, index);
    }
  }

  deleteMovieFromList(listId, index) {
    if (this.busy) {
      return;
    }
    this.busy = true;
    this.userListsService.deleteMovieFromUserList(listId, this.movieId, () => {
      this.lists[index].hasQueriedMovie = false;
      this.busy = false;
    });
  }

  addNewList(obj) {
    this.lists.unshift(obj);
  }

  addMovieToList(listId, index) {
    if (this.busy) {
      return;
    }
    this.busy = true;
    this.userListsService.addMovieToUserList(listId, this.movieId, () => {
      this.lists[index].hasQueriedMovie = true;
      this.busy = false;
    });
  }
}
