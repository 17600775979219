"use strict";

import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { get } from "lodash";
import { StateService } from "@uirouter/core";

import { ApiService } from "../../common/api/api.service";

@Component({
  selector: "test-search-page",
  template: `
    <div class="dev-test-search">
      <h1>test search</h1>

      <p>
        This page demonstrates title matching. Type a movie title, e.g.,
        "superman 2".
      </p>

      <form role="form" class="form-inline">
        <input type="text" class="form-control" [(ngModel)]="q" name="foo" />

        <button class="btn btn-default" (click)="submit()">
          <i class="fa fa-search"></i>
        </button>
      </form>

      <pre style="margin-top: 10px;">search: {{ q | json }}</pre>
      <pre>results: {{ res | json }}</pre>
    </div>
  `
})
export class TestSearchPageComponent {
  q = "";
  res = "";

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Test Search | MovieLens");
  }

  submit() {
    this.apiService.ml4Post(
      "POST /api/dev/search",
      "/api/dev/search",
      { q: this.q },
      rsp => {
        this.res = get(rsp, "movies", "(nothing found)");
      }
    );
  }
}
